import { MenuItem, TextField as MuiTextField, TextFieldProps } from '@mui/material'
import { useField } from 'formik'
import { RoleObject } from './CreateAccessDialog'

type Props = {
  name: string
  label?: string
  options: RoleObject[]
}

const SelectTextField = <T,>(props: Props) => {
  const [field, meta] = useField<T>(props.name)

  const configTextField: TextFieldProps = {
    ...field,
    ...props,
    select: true,
    fullWidth: true,
    variant: 'outlined'
  }

  if (meta && meta.touched && meta.error) {
    configTextField.error = true
    configTextField.helperText = meta.error
  }

  return (
    <MuiTextField {...configTextField}>
      {props.options.map((option) => (
        <MenuItem key={option.label.toLowerCase()} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </MuiTextField>
  )
}

export default SelectTextField
