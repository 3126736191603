import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import DataApi from '../../api/data/DataApi'
import { NewsletterListOptions } from '../../api/data/types'
import { showErrorToast } from '../../utils/error'
import { queryNames } from '../queries'
import { NewsletterListDto } from '../../types/newsletter'

const dataApi = DataApi.getInstance()

type Options = {
  enabled?: boolean
  onSuccess: (data: NewsletterListDto) => void
}

export default function useGetNewsletterList(options: NewsletterListOptions & Options) {
  const texts = useTranslation().t
  const key = [queryNames.newsletterList, 
    { 
      limit: options?.limit,
      offset: options?.offset,
      from: options?.from,
      to: options?.to
    }
  ]
  const query = useQuery(key, async () => {
    return dataApi.getNewsletterList(options)
  }, {
    staleTime: Infinity,
    refetchOnMount: 'always',
    onError: (error) => {
      showErrorToast(error, texts)
    },
    enabled: options?.enabled,
    onSuccess: options?.onSuccess
  })
  return query
}

