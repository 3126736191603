import { AccountListDto, CmsUserDetailsDto, CreateCmsUserBodyDto, CreateEmployeeBodyDto, EditCmsUserBodyDto, EditEmployeeBodyDto, EmployeeDetailsDto, NewPinDto } from '../../types/accounts'
import { BannerDto, BannerRequestDto } from '../../types/banner'
import { Success } from '../../types/common'
import { AdditionTemplateDto, AdditionTemplateListDto, FileListOptions, LoyaltyCardListOptions, ProductTemplateDto, ProductTemplateListDto, TagDto, TagListDto, TemplateListOptions, VariantTemplateDto, VariantTemplateListDto } from '../../types/menu'
import { NewsletterListDto } from '../../types/newsletter'
import { OrderListDto } from '../../types/order'
import { AdditionAvailabilityListDto, MenuCategoryDto, MenuRequestCategoryBodyDto, PlaceDetailsDto, ProductAvailabilityListDto, VariantAvailabilityListDto } from '../../types/place'
import { Api } from '../Api'
import { AccountListOptions, AvailabilityListOptions, BulkProductTemplateEntryDto, CreateLoyaltyCardDto, EditLoyaltyCardDto, FileListDto, LoyaltyCardDto, LoyaltyCardListDto, MutateActivateItemsBodyDto, MutateAdditionTemplateDto, MutateDeactivateItemsBodyDto, MutatePlaceDto, MutateProductTemplateDto, MutateTagDto, MutateVariantTemplateDto, NewsletterListOptions, OrderListOptions, UploadedPhotoResponseDto, UserSearchDto } from './types'

class DataApi extends Api {
  private constructor() {
    super({
      baseURL: `${process.env.REACT_APP_BASE_DATA}/api/v2/cms`,
      headers: {},
    })
  }
  private static instance: DataApi

  static getInstance(): DataApi {
    if (DataApi.instance) {
      return this.instance
    }
    return this.instance = new DataApi()
  }

  public getPlaceList(): Promise<PlaceDetailsDto[]> {
    return this.get<PlaceDetailsDto[]>(`/place/list`)
  }

  public getPlaceDetails(uuid: string): Promise<PlaceDetailsDto> {
    return this.get<PlaceDetailsDto>(`/place/${uuid}`)
  }

  public getPlaceMenu(uuid: string): Promise<MenuCategoryDto[]> {
    return this.get<MenuCategoryDto[]>(`/place/${uuid}/menu`)
  }

  public getPlaceProductAvailabilityList(placeUuid: string, options?: AvailabilityListOptions): Promise<ProductAvailabilityListDto> { 
    return this.get<ProductAvailabilityListDto>(`/place/${placeUuid}/menu/availability/product/list`, {
      params: {
        ...(options?.limit != null ? { limit: options.limit } : {}),
        ...(options?.offset != null ? { offset: options.offset } : {}),
        ...(options?.search != null ? { search: options.search } : {}),
        ...(options?.sort != null ? { sort: options.sort } : {})
      }
    })
  }

  public activateProducts(placeUuid: string, data: MutateActivateItemsBodyDto): Promise<Success> { 
    return this.patch<Success>(`/place/${placeUuid}/menu/availability/product/activate`, data)
  }

  public deactivateProducts(placeUuid: string, data: MutateDeactivateItemsBodyDto): Promise<Success> { 
    return this.patch<Success>(`/place/${placeUuid}/menu/availability/product/deactivate`, data)
  }

  public getPlaceVariantAvailabilityList(placeUuid: string, options?: AvailabilityListOptions): Promise<VariantAvailabilityListDto> {
    return this.get<VariantAvailabilityListDto>(`/place/${placeUuid}/menu/availability/variant/list`, {
      params: {
        ...(options?.limit != null ? { limit: options.limit } : {}),
        ...(options?.offset != null ? { offset: options.offset } : {}),
        ...(options?.search != null ? { search: options.search } : {}),
        ...(options?.sort != null ? { sort: options.sort } : {})
      }
    })
  }

  public activateVariantOptions(placeUuid: string, data: MutateActivateItemsBodyDto): Promise<Success> { 
    return this.patch<Success>(`/place/${placeUuid}/menu/availability/variant-option/activate`, data)
  }

  public deactivateVariantOptions(placeUuid: string, data: MutateDeactivateItemsBodyDto): Promise<Success> { 
    return this.patch<Success>(`/place/${placeUuid}/menu/availability/variant-option/deactivate`, data)
  }

  public getPlaceAdditionAvailabilityList(placeUuid: string, options?: AvailabilityListOptions): Promise<AdditionAvailabilityListDto> {
    return this.get<AdditionAvailabilityListDto>(`/place/${placeUuid}/menu/availability/addition/list`, {
      params: {
        ...(options?.limit != null ? { limit: options.limit } : {}),
        ...(options?.offset != null ? { offset: options.offset } : {}),
        ...(options?.search != null ? { search: options.search } : {}),
        ...(options?.sort != null ? { sort: options.sort } : {})
      }
    })
  }

  public activateAdditions(placeUuid: string, data: MutateActivateItemsBodyDto): Promise<Success> { 
    return this.patch<Success>(`/place/${placeUuid}/menu/availability/addition/activate`, data)
  }

  public deactivateAdditions(placeUuid: string, data: MutateDeactivateItemsBodyDto): Promise<Success> { 
    return this.patch<Success>(`/place/${placeUuid}/menu/availability/addition/deactivate`, data)
  }

  public putPlaceMenu(uuid: string, data: MenuRequestCategoryBodyDto[]): Promise<Success> {
    return this.put<Success>(`/place/${uuid}/menu`, data)
  }

  public getPlaceOrders(uuid: string, options?: OrderListOptions): Promise<OrderListDto> {
    return this.get<OrderListDto>(`/place/${uuid}/orders`, {
      params: {
        ...(options?.limit != null ? { limit: options.limit } : {}),
        ...(options?.offset != null ? { offset: options.offset } : {}),
        ...(options?.from != null ? { from: options.from } : {}),
        ...(options?.to != null ? { to: options.to } : {}),
        ...(options?.sort != null ? { sort: options.sort } : {})
      }
    })
  }

  public getNewsletterList(options?: NewsletterListOptions): Promise<NewsletterListDto> {
    return this.get<NewsletterListDto>('/newsletter/list', {
      params: {
        ...(options?.limit != null ? { limit: options.limit } : {}),
        ...(options?.offset != null ? { offset: options.offset } : {}),
        ...(options?.from != null ? { from: options.from } : {}),
        ...(options?.to != null ? { to: options.to } : {})
      }
    })
  }

  public getTagList(
    options: {
      search?: string
      sort?: string
      limit?: number
      offset?: number
    }
  ): Promise<TagListDto> {
    return this.get<TagListDto>('/tag/list', {
      params: {
        ...(options?.limit != null ? { limit: options.limit } : {}),
        ...(options?.offset != null ? { offset: options.offset } : {}),
        ...(options?.search != null ? { search: options.search } : {}),
        ...(options?.sort != null ? { sort: options.sort } : {})
      }
    })
  }

  public getTagDetails(uuid: string): Promise<TagDto> {
    return this.get<TagDto>(`/tag/${uuid}/details`)
  }

  public getAdditionTemplateList(options: TemplateListOptions): Promise<AdditionTemplateListDto> {

    return this.get<AdditionTemplateListDto>('/template/addition/list', {
      params: {
        ...(options?.limit != null ? { limit: options.limit } : {}),
        ...(options?.offset != null ? { offset: options.offset } : {}),
        ...(options?.search != null ? { search: options.search } : {}),
        ...(options?.exclude && options.exclude.length > 0 ? { exclude: options.exclude.join(';') } : {}),
        ...(options?.sort != null ? { sort: options.sort } : {})
      }
    })
  }

  public getAdditionTemplateDetails(uuid: string): Promise<AdditionTemplateDto> {
    return this.get<AdditionTemplateDto>(`/template/addition/${uuid}/details`)
  }

  public getVariantTemplateList(options: TemplateListOptions): Promise<VariantTemplateListDto> {
    return this.get<VariantTemplateListDto>('/template/variant/list', {
      params: {
        ...(options?.limit != null ? { limit: options.limit } : {}),
        ...(options?.offset != null ? { offset: options.offset } : {}),
        ...(options?.search != null ? { search: options.search } : {}),
        ...(options?.exclude && options.exclude.length > 0 ? { exclude: options.exclude.join(';') } : {}),
        ...(options?.sort != null ? { sort: options.sort } : {})
      }
    })
  }

  public getVariantTemplateDetails(uuid: string): Promise<VariantTemplateDto> {
    return this.get<VariantTemplateDto>(`/template/variant/${uuid}/details`)
  }

  public getProductTemplateList(options: TemplateListOptions): Promise<ProductTemplateListDto> {
    return this.get<ProductTemplateListDto>('/template/product/list', {
      params: {
        ...(options?.limit != null ? { limit: options.limit } : {}),
        ...(options?.offset != null ? { offset: options.offset } : {}),
        ...(options?.search != null ? { search: options.search } : {}),
        ...(options?.exclude && options.exclude.length > 0 ? { exclude: options.exclude.join(';') } : {}),
        ...(options?.sort != null ? { sort: options.sort } : {})
      }
    })
  }

  public getProductTemplateDetails(uuid: string): Promise<ProductTemplateDto> {
    return this.get<ProductTemplateDto>(`/template/product/${uuid}/details`)
  }

  public getBanners(): Promise<BannerDto[]> {
    return this.get<BannerDto[]>(`/banner`)
  }

  public getAccountList(options?: AccountListOptions): Promise<AccountListDto> {
    return this.get<AccountListDto>('/account/list', {
      params: {
        ...(options?.limit != null ? { limit: options.limit } : {}),
        ...(options?.offset != null ? { offset: options.offset } : {}),
        ...(options?.from != null ? { from: options.from } : {}),
        ...(options?.to != null ? { to: options.to } : {}),
        ...(options?.sort != null ? { sort: options.sort } : {}),
        ...(options?.name ? { name: options.name } : {})
      }
    })
  }

  public resetEmployeePin(uuid: string): Promise<Success<NewPinDto>> {
    return this.patch<Success<NewPinDto>>(`/account/employee/${uuid}/pin/reset`)
  }

  public editEmployeeData(uuid: string, data: EditEmployeeBodyDto): Promise<Success<EmployeeDetailsDto>> {
    return this.patch<Success<EmployeeDetailsDto>>(`/account/employee/${uuid}`, data)
  }

  public editCmsUserData(uuid: string, data: EditCmsUserBodyDto): Promise<Success<CmsUserDetailsDto>> {
    return this.patch<Success<CmsUserDetailsDto>>(`/account/cms-user/${uuid}`, data)
  }

  public createEmployee(data: CreateEmployeeBodyDto): Promise<Success<NewPinDto>> {
    return this.post<Success<NewPinDto>>(`/account/employee/create`, data)
  }

  public createCmsUserData(data: CreateCmsUserBodyDto): Promise<Success> {
    return this.post<Success>(`/account/cms-user/create`, data)
  }

  public editEmployeeActiveState(uuid: string, active: boolean): Promise<Success> {
    return this.patch<Success<EmployeeDetailsDto>>(`/account/employee/${uuid}/active`, { active: active })
  }

  public editCmsUserActiveState(uuid: string, active: boolean): Promise<Success> {
    return this.patch<Success<CmsUserDetailsDto>>(`/account/cms-user/${uuid}/active`, { active: active })
  }

  public sendProblemReport(data: FormData): Promise<Success> {
    return this.post<Success>(`/help/report-error`, data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }

  public putBanners(data: BannerRequestDto[]): Promise<Success<BannerDto[]>> { 
    return this.put<Success<BannerDto[]>>(`/banner`, data)
  }

  public editPlaceActiveState(uuid: string, active: boolean): Promise<Success> {
    return this.patch<Success, {active: boolean}>(`/place/${uuid}/active`, { active: active })
  }

  public editPlace(uuid: string, data: MutatePlaceDto): Promise<Success<PlaceDetailsDto>> {
    return this.patch<Success<PlaceDetailsDto>>(`/place/${uuid}/edit`, data)
  }

  public createNewPlace(data: MutatePlaceDto): Promise<Success> {
    return this.post<Success>(`/place/create`, data)
  }

  public createNewTag(data: MutateTagDto): Promise<Success> {
    return this.post<Success>(`/tag/create`, data)
  }

  public editTag(uuid: string, data: MutateTagDto): Promise<Success<TagDto>> {
    return this.patch<Success<TagDto>>(`/tag/${uuid}/edit`, data)
  }

  public removeTag(uuid: string): Promise<Success> {
    return this.delete<Success>(`/tag/${uuid}/remove`)
  }

  public createNewVariantTemplate(data: MutateVariantTemplateDto): Promise<Success> {
    return this.post<Success>(`/template/variant/create`, data)
  }

  public editVariantTemplate(uuid: string, data: MutateVariantTemplateDto): Promise<Success> {
    return this.patch<Success>(`/template/variant/${uuid}/edit`, data)
  }

  public removeVariantTemplate(uuid: string): Promise<Success> {
    return this.delete<Success>(`/template/variant/${uuid}/remove`)
  }

  public createNewAdditionTemplate(data: MutateAdditionTemplateDto): Promise<Success> {
    return this.post<Success>(`/template/addition/create`, data)
  }

  public editAdditionTemplate(uuid: string, data: MutateAdditionTemplateDto): Promise<Success> {
    return this.patch<Success>(`/template/addition/${uuid}/edit`, data)
  }

  public removeAdditionTemplate(uuid: string): Promise<Success> {
    return this.delete<Success>(`/template/addition/${uuid}/remove`)
  }

  public createNewProductTemplate(data: MutateProductTemplateDto): Promise<Success> {
    return this.post<Success>(`/template/product/create`, data)
  }

  public createBulkProductTemplates(data: BulkProductTemplateEntryDto[]): Promise<Success> {
    return this.post<Success>(`/template/product/create/bulk`, data)
  }

  public editProductTemplate(uuid: string, data: MutateProductTemplateDto): Promise<Success> {
    return this.patch<Success>(`/template/product/${uuid}/edit`, data)
  }

  public removeProductTemplate(uuid: string): Promise<Success> {
    return this.delete<Success>(`/template/product/${uuid}/remove`)
  }
  
  public uploadPhoto(data: FormData, signal: AbortSignal): Promise<UploadedPhotoResponseDto> {
    return this.post<UploadedPhotoResponseDto>(`/photo/upload`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
      signal: signal
    })
  }

  public searchUsers(search: string, withCard: boolean): Promise<UserSearchDto[]> {
    return this.get<UserSearchDto[]>('account/user/search', {
      params: {
        search,
        limit: 10,
        card: withCard
      }
    })
  }

  public createLoyaltyCard(data: CreateLoyaltyCardDto): Promise<Success<LoyaltyCardDto>> {
    return this.post<Success<LoyaltyCardDto>>('loyalty-card/create', data)
  }

  public getLoyaltyCardDetails(uuid: string): Promise<LoyaltyCardDto> {
    return this.get<LoyaltyCardDto>(`loyalty-card/${uuid}/details`)
  }

  public editLoyaltyCard(uuid: string, data: EditLoyaltyCardDto): Promise<Success<LoyaltyCardDto>> {
    return this.patch<Success<LoyaltyCardDto>>(`/loyalty-card/${uuid}/edit`, data)
  }

  public getLoyaltyCardList(options: LoyaltyCardListOptions): Promise<LoyaltyCardListDto> {
    return this.get<LoyaltyCardListDto>('/loyalty-card/list', {
      params: {
        ...(options?.limit != null ? { limit: options.limit } : {}),
        ...(options?.offset != null ? { offset: options.offset } : {}),
        ...(options?.search != null ? { search: options.search } : {})
      }
    })
  }

  public removeLoyaltyCard(uuid: string): Promise<Success> {
    return this.delete<Success>(`/loyalty-card/${uuid}/remove`)
  }

  public getFileList(options: FileListOptions): Promise<FileListDto> {
    return this.get<FileListDto>('/file/list', {
      params: {
        ...(options?.limit != null ? { limit: options.limit } : {}),
        ...(options?.offset != null ? { offset: options.offset } : {}),
        ...(options?.search != null ? { search: options.search } : {})
      }
    })
  }
}

export default DataApi