import { StringMappingType } from "typescript"
import { AllowedPickupTypes } from './place'

export type OrderListDto = {
  count: number
  data: OrderDetailsDto[]
}

export type OrderDetailsDto = {
  number: string
  externalPaymentId: string | null
  userUuid: string
  employee?: string
  createdAt: string
  deliveryDate?: string
  pickupDate?: string
  readyDate?: string
  pickupType: AllowedPickupTypes
  status: OrderState
  paymentStatus?: PaymentTransactionState
  summary: OrderSummary
  comment: string | null
  contactNumber: string | null
  rejection: Rejection | null
  rating: OrderRatingDto | null
  address?: AddressDto | null
}

export type OrderList = {
  count: number
  data: OrderDetails[]
}

export type OrderDetails = {
  number: string
  externalPaymentId: string | null
  userUuid: string
  employee?: string
  createdAt: string
  deliveryDate?: string
  pickupDate?: string
  readyDate?: string
  pickupType: AllowedPickupTypes
  status: OrderState
  paymentStatus?: PaymentTransactionState
  summary: OrderSummary & { noDiscountCost: number; discountValue?: number }
  comment: string | null
  contactNumber: string | null
  rejection: Rejection | null
  rating: OrderRatingDto | null
  address?: AddressDto | null
}

export type AddressDto = {
  street: string
  postalCode: string
  town: string
}

export type DiscountDto = {
  value: number
  percentage: number
}

export enum OrderRatingGradeValue {
  VERY_BAD = 1,
  BAD = 2,
  OK = 3,
  GOOD = 4,
  VERY_GOOD = 5
}

export enum OrderRatingGradeType {
  ORDER = 'order',
  PRODUCTS = 'products',
  SERVICE = 'service',
  APPLICATION = 'application'
}

export type OrderRatingGrade = {
  value: OrderRatingGradeValue
  type: OrderRatingGradeType
}

export type OrderRatingDto = {
  comment: string | null
  grades: OrderRatingGrade[]
}

export type OrderSummary = {
  totalCost: number
  discount?: number
  items: OrderedProduct[]
}

export type OrderedProduct = {
  amount: number
  totalCost: number
  product: OrderedProductItem
  additions?: OrderedProductAddition[] | null
  variants?: OrderedProductVariant[] | null
}

export type OrderedProductItem = {
  uuid: string
  price: number
  name: string
  internalNote: string | null
}

export type OrderedProductAddition = {
  uuid: string
  price: number
  name: string
}

export type OrderedProductVariant = {
  uuid: string
  name: string
  option: OrderedProductVariantOption
}

export type OrderedProductVariantOption = {
  uuid: string
  price: number
  name: string
}

export type Rejection = {
  type: RejectionType
  message?: string | null
  missingProducts?: RejectionMissingProducts[] | null
}

export type RejectionMissingProducts = {
  uuid: StringMappingType
  name: string
}

export enum RejectionType {
  UNKNOWN,
  PLACE_CLOSED,
  MISSING_PRODUCTS,
  OTHER
}

export enum OrderState {
  CREATED,
  IN_PAYMENT,
  ACCEPTED,
  IN_QUEUE,
  IN_PROGRESS,
  READY,
  ON_WAY,
  DELIVERED,
  REJECTED,
  CANCELED,
  NOT_DELIVERED,
  UNFULFILLED
}

export const enum PaymentTransactionState {
  NEW,
  PENDING,
  WAITING_FOR_CONFIRMATION,
  COMPLETED,
  CANCELED,
  REFUND_PENDING,
  REFUND_CANCELED,
  REFUND_FINALIZED
}

export type Column = {
  id: ColumnId
  label: string
  sortable?: boolean
  width?: string
  minWidth?: string
  wordBreak?: string
  maxWidth?: string
  contentTextAlign?: 'center' | 'right' | 'left'
}

export type ColumnId = 'orderNumber' | 'externalPaymentId' | 'userUuid' | 'employee' | 'createdAt' | 'deliveryDate' | 'pickupDate' | 'paymentStatus' | 'totalCost' | 'pickupType' | 'orderStatus' 
