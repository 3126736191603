import { Box, Button, Grid, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import PasswordField from '../../components/CustomMui/Fields/PasswordField'
import LoadingButton from '../../components/CustomMui/LoadingButton'
import { useActivateUser } from '../../hooks/auth/useActivateUser'
import { useCheckUserActivationToken } from '../../hooks/auth/useCheckUserActivationToken'
import { PasswordFormData } from '../../types/auth'
import { passwordValidation } from '../../validations/resetPasswordValidation'

const AccountActivationPage = () => {
  const texts = useTranslation().t
  const locations = useLocation()
  const navigate = useNavigate()

  const query = new URLSearchParams(locations.search)
  const token = query.get('token')
  const checkTokenMutation = useCheckUserActivationToken()
  const mutation = useActivateUser()

  useEffect(() => {
    checkTokenMutation.mutate({token})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) 

  const handleSubmit = async (data: PasswordFormData) => {
    mutation.mutate({
      password: data.password,
      token: token
    })
  }

  return (
    <Grid 
      container 
      direction={'column'} 
      justifyContent={'center'} 
      alignItems={'center'} 
      height={'100vh'}
    >
      <Typography 
        variant='h1' 
        sx={{marginBottom: '4.5rem'}}
      > 
        {texts(`auth:set_password_title`)}
      </Typography>
      <Formik<PasswordFormData>
        validationSchema={passwordValidation(texts)}
        initialValues={{
          password: '',
          passwordConfirmation: '',
        }}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Box width={'30rem'}>
            <Form>
              <Box>
                <PasswordField
                  name='password'
                  label={texts('auth:set_password_label')}
                />
              </Box>
              <Box>
                <PasswordField
                  name='passwordConfirmation'
                  label={texts('auth:repeat_password_label')}
                />
              </Box>
              <Grid 
                container 
                justifyContent={'space-between'}
              >
                <Button
                  variant={'outlined'}
                  size={'large'}
                  onClick={() => {navigate('/login')}}
                >
                  {texts('auth:back_to_login')}
                </Button>
                <LoadingButton
                  variant={'contained'}
                  type='submit'
                  size={'large'}
                  disabled={
                    !formikProps.isValid || !formikProps.dirty
                  }
                  loading={mutation.isLoading}
                >
                  {texts('auth:set_password_button')}
                </LoadingButton>
              </Grid>
            </Form>
          </Box>
        )}
      </Formik>
    </Grid>
  )
}

export default AccountActivationPage
