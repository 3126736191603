import { Box, Button, Grid } from '@mui/material'
import React from 'react'
import { DraggableProvided } from '@hello-pangea/dnd'
import { BannerFormData } from '../../types/common'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { createTheme, ThemeProvider } from '@mui/material/styles'

type Props = {
  photo: BannerFormData
  provided?: DraggableProvided
  onRemove: () => void
}

const theme = createTheme({
  palette: {
    neutral: {
      main: '#FFFFFF',
      contrastText: '#000000',
    },
  },
})

declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary']
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    neutral?: PaletteOptions['primary']
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true
  }
}

const BannerCard = (props: Props) => {
  return (
    <Grid
    {...props.provided?.dragHandleProps}
    >
      <Box
        sx={{
          gap: '2rem',
          position: 'relative',
          width: '22.25rem',
          height: '12.5rem',
          marginTop: '0.625rem',
        }}
      >
        <ThemeProvider theme={theme}>
          <Grid 
            item 
            display={'flex'}
            justifyContent={'center'}
            overflow={'hidden'}
          >
            <Box
              component={'img'}
              src={props.photo.photoUrl}
              alt={'img'}
              style={{
                minWidth: '100%',
                maxWidth: '100%',
                maxHeight: '100%'
              }}
            />
          </Grid>
          <Button
            color="neutral" 
            variant="contained"
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              height: '2.75rem',
              width: '2.75rem',
              borderRadius: '50%',
              marginRight: '0.270rem',
              marginTop: '0.375rem',
              minWidth: ''
            }}
            onClick={props.onRemove}
          >
            <DeleteForeverIcon 
              sx={{
                fontSize: '2rem',
                margin: 'auto'
              }}
            />
          </Button>
        </ThemeProvider>
      </Box>
    </Grid>
  )

}

export default BannerCard