import { combineReducers, configureStore } from '@reduxjs/toolkit'
import storageReducer from './redirectStorageToolkit'

const reducer = combineReducers({
  storage: storageReducer
})

const redirectStore = configureStore({
  reducer
})

export default redirectStore