import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'
import moment from 'moment-timezone'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { DecodedValueMap, NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import LoadingTableRow from '../../components/Common/LoadingTableRow'
import NetworkError from '../../components/Common/NetworkError'
import NoDataTableRow from '../../components/Common/NoDataTableRow'
import useGetNewsletterList from '../../hooks/newsletter/useGetNewsletterList'
import { NewsletterListSearchParams } from '../../types/newsletter'

const rowsPerPageOptions = [12,24,48,96]

function isSearchParamsValid(params: DecodedValueMap<NewsletterListSearchParams>): boolean {
  return Number.isInteger(params.page) // must be a number, not a NaN
    && params.page >= 1  // page cannot be lower than 1
    && (!params.from || moment(params.from).isValid()) // from should be empty or valid date string
    && (!params.to || moment(params.to).isValid()) // to should be empty or valid date string
    && rowsPerPageOptions.includes(params.size) // size should be from defined list
}

type Column = {
  id: 'newsletterSince' | 'email'
  label: string
}

const Newsletter = () => {
  const texts = useTranslation().t

  const columns: Column[] = texts('objects:newsletter_columns', {returnObjects: true}) as Column[]

  const [searchParams, setSearchParams] = useQueryParams<NewsletterListSearchParams>({ 
    page: withDefault(NumberParam, 1), 
    size: withDefault(NumberParam, rowsPerPageOptions[1]), 
    from: withDefault(StringParam, ''), 
    to: withDefault(StringParam, '')
  })

  const { isFetching, data, isError } = useGetNewsletterList({
    limit: searchParams.size,
    offset: searchParams.size * (searchParams.page - 1),
    ...(searchParams.from ? { from: moment(searchParams.from).toDate() } : {}),
    ...(searchParams.to ? { to: moment(searchParams.to).toDate() } : {}),
    enabled: isSearchParamsValid(searchParams),
    onSuccess: (data) => {
      if (searchParams.page > 1 && data.data.length === 0) {
        setSearchParams({
          page: 1,
          size: rowsPerPageOptions[1],
          from: '',
          to: ''
        }, 'replaceIn')
      }
    }
  })

  useEffect(() => {
    window.scrollTo(0,0)
  }, [searchParams.page])

  useEffect(() => {
    if (!isSearchParamsValid(searchParams)) {
      setSearchParams({
        page: 1,
        size: rowsPerPageOptions[1],
        from: '',
        to: ''
      }, 'replaceIn')
      return
    }

    if (searchParams.page === 1) {
      setSearchParams({
        page: 1
      }, 'replaceIn')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.page, searchParams.from, searchParams.to, searchParams.size])

  const onClick = () => {
    const params: string[] = [`tz=${process.env.REACT_APP_TZ}`]
    if (searchParams.from) {
      params.push(`from=${encodeURIComponent(searchParams.from)}`)
    }
    if (searchParams.to) {
      params.push(`to=${encodeURIComponent(searchParams.to)}`)
    }
    window.open(`${process.env.REACT_APP_BASE_DATA}${process.env.REACT_APP_NEWSLETTER_CSV_PATH}${params.length > 0 ? `?${params.join('&')}` : ''}`)
  }

  if (!isSearchParamsValid(searchParams)) {
    return <></>
  }

  return (
    <Grid 
      container
      flexDirection={'column'}
      gap={'2rem'}
      sx={{
        minHeight: 'calc(100vh - 130px)'
      }}
    >
      <Grid 
        item
        container
        justifyContent={'space-between'}
        alignItems={'center'}
      > 
        <Typography variant='h2'>
          {texts('newsletter:title')}
        </Typography> 
        <Button
          variant={'contained'}
          size={'medium'}
          onClick={onClick}
          sx={{
            width: 'fit-content',
            px: '1rem',
            whiteSpace: 'nowrap'
          }}
        >
          {texts('newsletter:export')}
        </Button>
      </Grid>
      <Grid
        item
        sx={{
          minWidth: '15rem',
          maxWidth: '20rem'
        }}
      >
        <DateRangePicker
          mask={'__.__.____'}
          maxDate={new Date()}
          value={
            [
              searchParams.from ? moment(searchParams.from).toDate() : null,
              searchParams.to ? moment(searchParams.to).toDate() : null
            ]
          }
          onChange={(newValue) => {
            setSearchParams({
              from: newValue[0] != null ? moment(newValue[0]).format() : '',
              to: newValue[1] != null ? moment(newValue[1]).endOf('day').format() : '',
              page: 1
            }, 'replaceIn')
          }}
          renderInput={(startProps, endProps) => (
            <Box>
              <Typography 
                variant={'clear'}
                sx={{
                  float: 'right'
                }}
                onClick={() => {
                  setSearchParams({
                    from: '',
                    to: '',
                    page: 1
                  }, 'replaceIn')
                }}
              >
                {texts('common:clear')}
              </Typography>
              <Grid 
                container
                flexWrap={'nowrap'}
                alignItems={'center'}
              >
                <TextField
                  {...startProps}
                  inputProps={{
                    ...startProps.inputProps,
                    placeholder: texts('common:dateInputPlaceholder'),
                    onKeyDown: (event) => {
                      event.preventDefault()
                    }
                  }}
                  label={texts('newsletter:date_range_from')}
                />
                <Box sx={{ mx: 2, fontFamily: 'RobotoRegular' }}> - </Box>
                <TextField 
                  {...endProps}
                  inputProps={{
                    ...endProps.inputProps,
                    placeholder: texts('common:dateInputPlaceholder'),
                    onKeyDown: (event) => {
                      event.preventDefault()
                    }
                  }}
                  label={texts('newsletter:date_range_to')}
                />
              </Grid>
            </Box>
          )}
        />
      </Grid>
      {
        isError
        ? <Grid 
            container 
            item
            flexGrow={1} 
            justifyContent={'center'} 
            alignItems={'center'}
          >
            <NetworkError />
          </Grid>
        : <Paper sx={{ width: '50%', overflow: 'hidden' }}>
            <TableContainer>
              <Table stickyHeader aria-label='sticky table'>
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        sx={{
                          textTransform: 'none',
                          fontFamily: 'RobotoMedium',
                          fontSize: '0.875rem',
                          paddingY: '1.5rem'
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    isFetching || (searchParams.page > 1 && data!.data.length === 0)
                    ? <LoadingTableRow colSpan={columns.length} />
                    : data == null || data.data.length === 0
                      ? <NoDataTableRow colSpan={columns.length} />
                      : <>
                          {
                            data.data.map((row, index) => (
                              <TableRow 
                                key={index}
                                sx={{
                                  '&:nth-of-type(even)': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                  }
                                }}
                              >
                                <TableCell 
                                  key={`${columns[0].id}-${index}`}
                                  sx={{
                                    fontSize: '0.875rem'
                                  }}
                                >
                                  {moment(row.newsletterUpdatedAt).format('DD.MM.YYYY HH:mm')}
                                </TableCell>
                                <TableCell 
                                  key={`${columns[1].id}-${index}`}
                                  sx={{
                                    fontSize: '0.875rem'
                                  }}
                                >
                                  {row.email}
                                </TableCell>
                                <TableCell 
                                  key={`${columns[2].id}-${index}`}
                                  sx={{
                                    fontSize: '0.875rem'
                                  }}
                                >
                                  {texts('newsletter:newsletter_state_label', { state: row.newsletter })}
                                </TableCell>
                              </TableRow>
                            ))
                          }
                        </>
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component='div'
              count={data?.count ?? 0}
              rowsPerPage={searchParams.size}
              page={searchParams.page - 1}
              onPageChange={(e, page) => setSearchParams({ page: page + 1 }, 'replaceIn' )}
              onRowsPerPageChange={(event) => 
                setSearchParams({
                  size: Number.parseInt(event.target.value), 
                  page: 1
                }, 'replaceIn' )
              }
              sx={{
                '.MuiTablePagination-selectLabel' : {
                  fontSize: '0.75rem',
                  textTransform: 'none'
                },
                '.MuiTablePagination-displayedRows' : {
                  fontSize: '0.75rem',
                  textTransform: 'none'
                },
              }}
            />
          </Paper>
      }
    </Grid>
  )
}

export default Newsletter