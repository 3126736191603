import { Button, Dialog, DialogActions, DialogTitle, Grid, Typography } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import { CSSProperties, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCSVReader, formatFileSize } from 'react-papaparse'
import { DataGrid } from '../../Common/DataGrid'
import { mapPreparationTime, mapPriceToString } from '../../../utils/mapping'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import { TFunction } from 'i18next'
import { BulkProductTemplateEntry } from '../../../types/menu'
import currency from 'currency.js'
import LoadingButton from '@mui/lab/LoadingButton'

function validateRows(data: string[][], texts: TFunction) {
  if (data.length > 100) {
    throw new Error(texts('errors:csv_too_many_records'))
  }
  data.forEach((row, index) => {
    if (row.length !== 5) {
      throw new Error(texts('errors:csv_row_bad_record', {
        index: index + 1
      }))
    }

    const name = row[0].trim()
    if (!name) {
      throw Error(texts('errors:csv_empty_name', {
        index: index + 1
      }))
    }
    const price = row[2].trim()
    if (price.includes(',')) {
      throw Error(texts('errors:csv_row_bad_price_format', {
        index: index + 1
      }))
    }

    if (!price || Number.isNaN(+price)) {
      throw Error(texts('errors:csv_row_price_nan', {
        index: index + 1
      }))
    }

    if (parseFloat(price) < 0) {
      throw Error(texts('errors:csv_negative_price', {
        index: index + 1
      }))
    }

    const prepTime = row[3].trim()
    if (!prepTime || !Number.isInteger(+prepTime)) {
      throw Error(texts('errors:csv_row_preparation_time_nan', {
        index: index + 1
      }))
    }

    if (parseInt(prepTime) < 0) {
      throw Error(texts('errors:csv_negative_preparation_time', {
        index: index + 1
      }))
    }
  })
}

const colDefinitions: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Nazwa',
    width: 200,
    sortable: false
  },
  {
    field: 'description',
    headerName: 'Opis',
    width: 200,
    sortable: false
  },
  {
    field: 'price',
    headerName: 'Cena bazowa',
    width: 150,
    sortable: false
  },
  {
    field: 'preparationTime',
    headerName: 'Czas przygotowania',
    width: 150,
    sortable: false
  },
  {
    field: 'internalNote',
    headerName: 'Notatka',
    flex: 3, //removes empty trailing cell
    sortable: false
  }
]

type Props = {
  open: boolean
  loading: boolean
  onCloseButtonClick: () => void
  onConfirmButtonClick: (data: BulkProductTemplateEntry[]) => void,
}

const DEFAULT_REMOVE_HOVER_COLOR = '#E02020'
const GREY_DIM = '#DDD'

const styles = {
  zone: {
    alignItems: 'center',
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='rgba(0,0,0,0.23)' stroke-width='2' stroke-dasharray='15%2c20' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
    borderRadius: '20px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    padding: '20px',
    marginRight: '0.625rem',
    marginLeft: '0.625rem'
  } as CSSProperties,
  file: {
    background: GREY_DIM,
    borderRadius: '20px',
    display: 'flex',
    height: '7.5rem',
    width: '7.5rem',
    position: 'relative',
    zIndex: '0.625rem',
    flexDirection: 'column',
    justifyContent: 'center',
  } as CSSProperties,
  info: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '0.625rem',
    paddingRight: '0.625rem',
  } as CSSProperties,
  size: {
    justifyContent: 'center',
    display: 'flex',
    fontSize: '0.625rem',
    color: '#686868',
    fontFamily: 'RobotoRegular'
  } as CSSProperties,
  name: {
    backgroundColor: GREY_DIM,
    borderRadius: 3,
    fontSize: '12px',
    color: '#383838',
    marginTop: '0.2rem',
    marginBottom: '0.2rem',
    fontFamily: 'RobotoRegular'
  } as CSSProperties,
  progressBar: {
    bottom: 14,
    position: 'absolute',
    width: '100%',
    color: '',
    paddingLeft: '0.625rem',
    paddingRight: '0.625rem',
  } as CSSProperties,
  zoneHover: {
    borderColor: GREY_DIM,
  } as CSSProperties,
  default: {
    borderColor: GREY_DIM,
  } as CSSProperties,
  remove: {
    height: '23px',
    position: 'absolute',
    right: 6,
    top: 6,
    width: '23px',
    ':hover': {
      cursor: 'pointer'
    }
  } as CSSProperties,
  dataGrid: {
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus': {
      outline: 'none',
    },
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '.MuiDataGrid-cell': {
      whiteSpace: 'normal !important',
      wordWrap: 'break-word !important',
      lineHeight: 'unset !important',
      maxHeight: 'none !important',
      padding: '12px',
      display: 'block'
    },
    '.MuiDataGrid-cellContent': {
      height: '100%'
    },
    '.MuiDataGrid-row': {
      maxHeight: 'none !important',
      height: 'auto !important'
    },
    '.MuiDataGrid-renderingZone': {
      maxHeight: 'none !important',
      height: 'auto !important',
    },
  } as CSSProperties
}

export const UploadProductCsvDialog = (props: Props) => {
  const texts = useTranslation().t
  const { CSVReader } = useCSVReader()

  const [zoneHover, setZoneHover] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [data, setData] = useState<BulkProductTemplateEntry[]>([])
  const [tableData, setTableData] = useState<any[]>([])

  const updateData = (data: string[][]) => {
    try {
      data.shift()
      validateRows(data, texts)

      let uploadedRows: BulkProductTemplateEntry[] = []
      let tableDataRow: any[] = []
      for (let i = 0; i < data.length; i++) {
        tableDataRow.push({
          id: i + 1,
          name: data[i][0].trim(),
          description: data[i][1].trim(),
          price: mapPriceToString(parseFloat(data[i][2].trim())),
          preparationTime: mapPreparationTime(parseInt(data[i][3].trim())),
          internalNote: data[i][4].trim()
        })
        uploadedRows.push({
          name: data[i][0].trim(),
          description: data[i][1].trim(),
          price: currency((data[i][2].trim())).intValue,
          preparationTime: parseInt(data[i][3].trim()) * 60,
          internalNote: data[i][4].trim()
        })
      }
      setData(uploadedRows)
      setTableData(tableDataRow)
    } catch (error) {
      setErrorMessage(error.message)
    }  
  }
  
  return (
    <Dialog 
      open={props.open} 
      onClose={() => {
        props.onCloseButtonClick()
        setErrorMessage('')
        setData([])
        setTableData([])
      }}
      maxWidth={'xl'}
    >
      <DialogTitle>{texts('dialogs:uploaded_products')}</DialogTitle>
      <DialogActions>
        <Grid
          container
          flexDirection={'column'}
          gap={'0.5rem'}
          width={'58.5rem'}
        >
          <CSVReader
            config={{
              fastMode: false
            }}
            maxSize={parseInt(process.env.REACT_APP_MAX_CSV_SIZE!)}
            onUploadRejected={(event: any) => {
              if (event && event.length > 0 && event[0].errors && event[0].errors.length > 0) {
                if (event[0].errors.findIndex((e: any) => e.code ==='file-invalid-type') > -1) {
                  setErrorMessage(texts('errors:invalid_file_type_only_csv'))
                } else if (event[0].errors.findIndex((e: any) => e.code ==='file-too-large') > -1) {
                  setErrorMessage(texts('errors:csv_size_too_large'))
                }
              }
            }}
            onUploadAccepted={({data, error}: {data: string[][], error: any}) => {
              setErrorMessage('')
              if (error) {
                setErrorMessage(texts('errors:invalid_file'))
                return
              } 
              setData([])
              setTableData([])
              updateData(data)
            }}
            onDragOver={(event: DragEvent) => {
              event.preventDefault()
              setZoneHover(true)
            }}
            onDragLeave={(event: DragEvent) => {
              event.preventDefault()
              setZoneHover(false)
            }}
          >
            {({
              getRootProps,
              acceptedFile,
              ProgressBar,
              getRemoveFileProps,
              Remove
            }: any) => (
              <>
                <Grid
                  {...getRootProps()}
                  style={Object.assign(
                    {},
                    styles.zone,
                    zoneHover && styles.zoneHover
                  )}
                >
                  {
                    acceptedFile 
                    ? <>
                        <Grid sx={styles.file}>
                          <Grid sx={styles.info}>
                            <InsertDriveFileIcon fontSize={'large'}/>
                            <Typography sx={styles.name}>
                              {acceptedFile.name}
                            </Typography>
                            <Typography sx={styles.size}>
                              {formatFileSize(acceptedFile.size)}
                            </Typography>
                          </Grid>
                          <Grid sx={styles.progressBar}>
                            <ProgressBar color={'black'} style={{ backgroundColor: 'black'}}/>
                          </Grid>
                          <Grid
                            onClick={(e) => {
                              getRemoveFileProps().onClick(e)
                              setData([])
                              setTableData([])
                              setErrorMessage('')
                            }}
                            sx={styles.remove}
                          >
                            <Remove color={DEFAULT_REMOVE_HOVER_COLOR}/>
                          </Grid>
                        </Grid>
                      </> 
                    : <Typography>{texts('common:upload_placeholder')}</Typography>
                  }
                </Grid>
                {
                  errorMessage
                  ? <Typography 
                      sx={{
                        fontFamily: 'RobotoCondensed',
                        fontSize: '0.75rem',
                        margin: '0.125rem 0 0 0.875rem',
                        color: 'error.main'
                      }}
                    > 
                      {errorMessage}
                    </Typography>
                  : null
                }
                {
                  tableData.length > 0 && acceptedFile && !errorMessage
                  ? <Grid
                      item 
                      sx={{ 
                        height: '40rem',
                        width: '58.5rem'
                      }}
                    >
                      <DataGrid
                        rows={tableData}
                        columns={colDefinitions}
                        checkboxSelection={false}
                        sortingMode={'server'}
                        sx={styles.dataGrid}
                      />
                    </Grid>
                  : null
                }
                <Grid
                  item
                  container
                  justifyContent={'flex-end'}
                  sx={{
                    mb: '0.5rem',
                    gap: '1rem'
                  }}
                >
                  <Button
                    variant={'contained'}
                    onClick={() => {
                      props.onCloseButtonClick()
                      setData([])
                      setTableData([])
                      setErrorMessage('')
                    }}
                  >
                    {texts('common:cancel')}
                  </Button>
                  <LoadingButton
                    variant={'contained'}
                    onClick={() => {
                      props.onConfirmButtonClick(data)
                      setData([])
                    }}
                    loading={props.loading}
                    disabled={data.length === 0 || acceptedFile == null || !!errorMessage}
                  >
                    {texts('common:upload_button')}
                  </LoadingButton>
                </Grid>
              </>
            )}
          </CSVReader>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
