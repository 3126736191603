import { Box, Grid, Input, Typography } from '@mui/material'
import { Form, Formik, FormikHelpers } from 'formik'
import { useState } from 'react'
import Dropzone from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import TextField from '../../components/CustomMui/Fields/TextField'
import LoadingButton from '../../components/CustomMui/LoadingButton'
import useSendProblemReport from '../../hooks/help/useSendProblemReport'
import { ReportFormData } from '../../types/help'
import { ATTACHMENT_MAX_FILE_SIZE_BYTES } from '../../utils/constants'
import { reportProblemValidation } from '../../validations/reportProblemValidation'
import FileTile from '../../components/Accounts/FileTile'

const GREY = '#CCC'
const GREY_DIM = '#DDD'

const ProblemReport = () => {
  const texts = useTranslation().t

  const [zoneHover, setZoneHover] = useState<boolean>(false)

  const sendMutation = useSendProblemReport()

  const handleSubmit = (data: ReportFormData, formikHelpers: FormikHelpers<ReportFormData>) => {
    sendMutation.mutate({
      data: data
    })
    formikHelpers.resetForm()
  }

  return (
    <Grid 
      container
      flexDirection={'column'}
      gap={'2.5rem'}
      sx={{ 
        minWidth: '30rem',
        maxWidth: '50rem' 
      }}
    >
      <Formik<ReportFormData>
        initialValues={{
          question: '',
          description: '',
          file: null
        }}
        onSubmit={handleSubmit}
        validationSchema={reportProblemValidation(texts)}
      >
        {(formikProps) => (
          <Form>
            <Typography 
              variant='h2'
              sx={{ mb: '2.5rem' }}
            >
              {texts('account:report')}
            </Typography>
            <Box width={'100%'}>
              <Box marginBottom={'0.125rem'}>
                <TextField
                  name='question'
                  label={texts('account:report_title')}
                />
              </Box>
              <Box marginBottom={'0.125rem'}>
                <TextField
                  name='description'
                  multiline
                  rows={4}
                  label={texts('account:report_description')}
                />
              </Box>
            </Box>
            <Dropzone 
              maxFiles={1}
              onDropAccepted={acceptedFiles => {
                formikProps.setFieldValue('file', acceptedFiles[0])
                formikProps.setFieldError('file', undefined)
                setZoneHover(false)
              }} 
              onDropRejected={(fileRejections) => {
                if (fileRejections[0].errors[0].code === 'file-invalid-type') {
                  formikProps.setFieldError('file', texts('account:invalid_file_format'))
                } else if (fileRejections[0].errors[0].code === 'file-too-large') {
                  formikProps.setFieldError('file', texts('account:file_too_large'))
                } else {
                  formikProps.setFieldError('file', texts('account:file_error'))
                }
                setZoneHover(false)
              }} 
              onDragOver={() => setZoneHover(true)}
              onDragLeave={() => setZoneHover(false)}
              accept={{
                'image/*': ['.jpg', '.png'],
                'application/pdf': ['.pdf'],
                'application/msword': ['.doc']
              }}
              multiple={false}
              maxSize={ATTACHMENT_MAX_FILE_SIZE_BYTES}
              disabled={formikProps.values.file ? true : false}
            >
              {({getRootProps, getInputProps}) => (
                <>
                  <Box
                    sx={{
                      fontFamily: 'RobotoRegular',
                      alignItems: 'center',
                      width: '100%',
                      minHeight: '6.25rem',
                      backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='rgba(0,0,0,0.23)' stroke-width='2' stroke-dasharray='15%2c20' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
                      borderRadius: '0.625rem',
                      borderColor: formikProps.errors.file ? '#d32f2f' : zoneHover ? GREY_DIM : GREY,
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                      justifyContent: 'center',
                      py: '0.5rem'
                    }}
                    {...getRootProps()}
                  >
                    <Box>
                      <Input 
                        inputProps={getInputProps()} 
                        sx={{ display: 'none' }}
                        type={'file'}
                      />
                      {
                        formikProps.values.file
                        ? <FileTile file={formikProps.values.file} /> 
                        : <Typography
                            sx={{
                              ':hover': {cursor: 'pointer'}
                            }}
                          >
                            {texts('account:report_file_placeholder')}
                          </Typography>
                      }
                    </Box>
                  </Box>
                  {
                    formikProps.errors.file
                      ? <Typography
                          sx={{
                            fontFamily: 'RobotoCondensed',
                            fontSize: '0.75rem',
                            margin: '0.125rem 0 0 0.875rem',
                            color: 'error.main'
                          }}
                        >
                          {formikProps.errors.file}
                        </Typography>
                      : null
                  }
                </>
              )}
            </Dropzone>
            <Typography
              sx={{
                fontSize: '0.75rem',
                color: 'RGBA(0, 0, 0, 0.6)',
                marginTop: '0.625rem'
              }}
            >
              {texts('account:report_file_label')}
            </Typography>
            <Grid
              item
              container
              justifyContent={'flex-end'}
              sx={{mt: '2rem'}}
            >
              <LoadingButton
                type='submit'
                variant={'contained'}
                size={'medium'}
                sx={{
                  px: '4rem',
                  gap: '0.5rem', 
                  width: 'max-content'
                }}
                loading={sendMutation.isLoading}
              >
                {texts('common:send')}
              </LoadingButton>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  )
}

export default ProblemReport