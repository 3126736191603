import { Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import Dialog from '../../CustomMui/Dialog'
import { useNavigate } from 'react-router-dom'
import { Addition } from '../../../types/menu'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import TimerIcon from '@mui/icons-material/Timer'
import Tag from '../../Common/Tag'
import Loader from '../../Common/Loader'
import { mapSecondsToString } from '../../../utils/mapping'
import useRemoveAdditionTemplate from '../../../hooks/template/addition/useRemoveAdditionTemplate'
import currency from 'currency.js'

type Props = {
  addition: Addition
}

const AdditionsListRow = (props: Props) => {
  const texts = useTranslation().t
  const navigate = useNavigate()

  const removeMutation = useRemoveAdditionTemplate()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [openRemoveDialog, setOpenRemoveDialog] = useState<boolean>(false)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  
  return (
    <Grid
      container
      position={'relative'}
      flexDirection={'column'}
      minHeight={'3rem'}
      py={'0.5rem'}
      px={'1rem'}
      marginTop={'0.938rem'}
      borderRadius={'0.938rem'}
      boxShadow={'0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.5)'}
      gap={'0.5rem'}
      sx={{backgroundColor: '#FFFFFF'}}
    >
      <Dialog
        open={openRemoveDialog}
        title={texts('dialogs:remove_title')}
        description={texts('dialogs:remove_description')}
        warning={texts('dialogs:addition_remove_warning')}
        closeButtonLabel={texts('common:cancel')}
        confirmButtonLabel={texts('common:remove')}
        onCloseButtonClick={() => {setOpenRemoveDialog(false)}}
        onConfirmButtonClick={() => {
          setOpenRemoveDialog(false)
          removeMutation.mutate({ uuid: props.addition.uuid })
        }}
      />
      {
        removeMutation.isLoading
        ? <Grid
            item
            position={'absolute'}
            width={'100%'}
            height={'100%'}
            right={0}
            bottom={0}
            borderRadius={'0.938rem'}
            overflow={'hidden'}
          >
            <Loader
              backgroundColor='rgba(0,0,0,0.08)'
              size={30}
            />
          </Grid>
        : null
      }
      <Grid
        container
        alignItems={'center'}
        justifyContent={'space-between'}
        flexWrap={'nowrap'}
      >
        <Grid
          item
          container
          sx={{
            ':hover': {cursor: 'pointer'}
          }}
          onClick={() => navigate(`/menu/additions/edit/${props.addition.uuid}`)}
          gap={'0.5rem'}
          alignItems={'center'}
        >
          <Grid
            item
            mr={'0.5rem'}
          >
            <Typography 
              variant='h2'
              sx={{fontSize: '1.125rem'}}
            >
              {props.addition.name}
            </Typography>
          </Grid>
          <Tag
            text={`+${mapSecondsToString(props.addition.additionalTime)}`}
            opacity={props.addition.additionalTime === 0}
          >
            <TimerIcon/>
          </Tag>
          <Tag
            text={texts('common:currency', { localValue: currency(props.addition.additionalPrice, { fromCents: true }).value })}
            opacity={props.addition.additionalPrice === 0}
          >
            <LocalOfferIcon/>
          </Tag>
        </Grid>
        <Grid item>
          <IconButton
            onClick={handleClick}
            sx={{color: '#000'}}
          >
            <MoreHorizIcon/>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{
              style: {
                width: '12rem',
              },
            }}
          >
            <MenuItem 
              onClick={() => {
                handleClose()
                setOpenRemoveDialog(true)
              }}
            >
              {texts('common:remove')}
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
      {
        props.addition.note
        ? <Grid 
            item
            sx={{
              ':hover': {cursor: 'pointer'},
              marginBottom: '0.375rem'
            }}
            onClick={() => navigate(`/menu/additions/edit/${props.addition.uuid}`)}
          >
            <Typography 
              variant='body2'
              sx={{
                textTransform: 'none',
                fontStyle: 'italic'
              }}
            >
              {`${texts('additions:note')}: ${props.addition.note}`}
            </Typography>
          </Grid>
        : null
      }
    </Grid>
  )
}

export default AdditionsListRow
