import { AuthDto, EmailDto, LoginDto, PasswordDto, TokenDto, ChangePasswordDto, CheckSessionDto } from './types'
import { Success } from '../../types/common'
import { Api } from '../Api'

class AuthApi extends Api {
  private constructor() {
    super({
      baseURL: `${process.env.REACT_APP_BASE_AUTH}/api/v2/auth/cms`,
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }
  private static instance: AuthApi

  static getInstance(): AuthApi {
    if (AuthApi.instance) {
      return this.instance
    }
    return this.instance = new AuthApi()
  }

  public login(data: LoginDto): Promise<AuthDto>{
    return this.post<AuthDto>(`/user/login`, data)
  }

  public logout(): Promise<Success>{
    return this.post<Success>(`/user/logout`)
  }

  public checkSession(): Promise<CheckSessionDto>{
    return this.post<CheckSessionDto>(`/user/session/check`)
  }

  public resendActivationEmail(data: EmailDto): Promise<Success> {
    return this.post<Success>(`/user/email/email-verification/resend`, { email: data.email })
  }

  public sendResetPasswordRequest(data: EmailDto): Promise<Success> {
    return this.post<Success>("/user/password/reset/start", { email: data.email })
  }

  public checkResetPasswordToken(data: TokenDto): Promise<Success> {
    return this.post<Success>(`/user/password/reset/check?token=${data.token}`)
  }

  public finishPasswordReset(data: Pick<PasswordDto, 'password'>): Promise<Success> {
    return this.post<Success>(`/user/password/reset/finish`, { password: data.password })
  }

  public verifyEmailChange(data: TokenDto): Promise<Success> {
    return this.post<Success>(`/user/email/verify-change?token=${data.token ?? ''}`)
  }

  public activateUser(data: PasswordDto): Promise<Success> {
    return this.post<Success>(`/user/email/verify?token=${data.token}`, { password: data.password })
  }

  public checkUserActivationToken(data: TokenDto): Promise<Success> {
    return this.post<Success>(`/user/email/verify/check?token=${data.token}`)
  }

  public changePassword(data: ChangePasswordDto): Promise<Success> {
    return this.patch<Success>(`/user/password/change`, data)
  }
}

export default AuthApi