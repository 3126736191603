import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import DataApi from '../../api/data/DataApi'
import { CreateCmsUserBodyDto } from '../../types/accounts'
import { showErrorToast } from '../../utils/error'
import { queryNames } from '../queries'

const dataApi = DataApi.getInstance()

type MutationParams = {
  data: CreateCmsUserBodyDto
}

export default function useCreateCmsUser(onSuccess: () => void) {
  const queryClient = useQueryClient()
  const texts = useTranslation().t
  const mutation = useMutation((params: MutationParams) => dataApi.createCmsUserData(params.data), {
    onError: (e) => {
      showErrorToast(e, texts)
    },
    onSuccess: () => {
      queryClient.resetQueries(queryNames.accountList)
      onSuccess()
      toast.success(texts('successes:account_created_successfully'))
    }
  })
  return mutation
}