import { LoadingButton } from "@mui/lab"
import { Button, Grid, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

type Props = {
  loading: boolean
  onBack: () => void
}

const CreatePlaceHeaderBar = (props: Props) => {
  const texts = useTranslation().t
  
  return (
    <Grid
      item
      container 
      wrap='nowrap'
      justifyContent={'space-between'} 
      alignItems={'center'}
    >
      <Grid item> 
        <Typography variant='h2' noWrap>
          {texts('place:add_place_title')}
        </Typography> 
      </Grid>
      <Grid 
        item 
        container 
        justifyContent={'flex-end'}
        rowGap={'0.5rem'}
      >
        <Grid item>
          <Button
            variant={'outlined'}
            size={'medium'}
            onClick={props.onBack}
          >
            {texts('place:go_to_place_list')}
          </Button>
        </Grid>
        <Grid item>
          <LoadingButton
            type='submit'
            variant={'contained'}
            size={'medium'}
            sx={{marginLeft: '0.625rem'}}
            loading={props.loading}
          >
            {texts('place:add_place')}
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CreatePlaceHeaderBar