import { Box, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import TextField from '../../CustomMui/Fields/TextField'
import AddImage from './AddImage'
import MapTile from './MapTile'

const PlaceDetails = () => {
  const texts = useTranslation().t

  return (
    <Grid container direction={'column'}>
      <Typography variant='subtitle1'>{texts('place:basic_info_section')}</Typography>
      <Grid 
        item 
        container
        marginBottom={'1rem'}
      >
        <AddImage/>
        <Box 
          flexGrow={1} 
          display='grid' 
          gridTemplateColumns='repeat(2, 1fr)' 
          gridTemplateRows='repeat(3, 1fr)' 
          columnGap='1.875rem'
          rowGap='0.125rem'
          marginTop='1.875rem'
        >
          <TextField
            name='name'
            label={texts('place:name')}
          />
          <TextField
            name='street'
            label={texts('place:address')}
          />
          <TextField
            name='phone'
            label={texts('place:phone_number')}
          />
          <TextField
            name='postalCode'
            label={texts('place:post_code')}
          />
          <TextField
            name='email'
            type='email'
            label={texts('place:email')}
          />
          <TextField
            name='town'
            label={texts('place:city')}
          />
        </Box>
      </Grid>
      <MapTile/>
    </Grid>
  )
}

export default PlaceDetails