import { Button, Grid, Typography } from '@mui/material'
import Pagination from '@mui/material/Pagination'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { HiPlusCircle } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'
import { DecodedValueMap, NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import Loader from '../../../components/Common/Loader'
import NetworkError from '../../../components/Common/NetworkError'
import NoDataPlaceholder from '../../../components/Common/NoDataPlaceholder'
import SearchBar from '../../../components/CustomMui/SearchBar'
import DiscountCodeListRow from '../../../components/Discount/Codes/List/DiscountCodeListRow'
import useGetLoyaltyCardList from '../../../hooks/loyaltyCard/useGetLoyaltyCardList'
import { SearchListSearchParams } from '../../../types/menu'

const discountCodesPerPage = Number.parseInt(process.env.REACT_APP_DEFAULT_PAGE_LIMIT!)

function isSearchParamsValid(params: DecodedValueMap<SearchListSearchParams>): boolean {
  return Number.isInteger(params.page) // must be a number, not a NaN
    && params.page >= 1  // page cannot be lower than 1
}

const DiscountCodeList = () => {
  const texts = useTranslation().t
  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useQueryParams<SearchListSearchParams>({ 
    page: withDefault(NumberParam, 1), 
    search: withDefault(StringParam, '')
  })

  const { isFetching, data, isError, maxPage } = useGetLoyaltyCardList({
    limit: discountCodesPerPage,
    offset: (searchParams.page - 1) * discountCodesPerPage,
    search: searchParams.search,
    enabled: isSearchParamsValid(searchParams),
    onSuccess: (data) => {
      if (searchParams.page > 1 && data.data.length === 0) {
        setSearchParams({
          page: 1,
          search: ''
        }, 'replaceIn')
      }
    }
  })

  useEffect(() => {
    window.scrollTo(0,0)
  }, [searchParams.page])

  useEffect(() => {    
    if (!isSearchParamsValid(searchParams)) {
      setSearchParams({
        page: 1,
        search: ''
      }, 'replaceIn')
      return
    }

    if (searchParams.page === 1) {
      setSearchParams({
        page: 1
      }, 'replaceIn')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.page])

  if (!isSearchParamsValid(searchParams) || (!isFetching && searchParams.page > 1 && data!.data.length === 0)) {
    return <></>
  }

  return (
    <Grid 
      container
      flexDirection={'column'}
      sx={{
        minHeight: 'calc(100vh - 130px)'
      }}
    >
      <Grid
        item
        container 
        wrap='nowrap'
        justifyContent={'space-between'} 
        alignItems={'center'}
      >
        <Grid item> 
          <Typography variant='h2' noWrap>
            {texts('discountCodes:list_title')}
          </Typography> 
        </Grid>
        <Grid 
          item 
          container 
          justifyContent={'flex-end'}
        >
          <Grid item>
            <Button
              variant={'contained'}
              size={'medium'}
              sx={{
                marginLeft: '0.625rem',
                gap: '0.5rem', 
                width: 'max-content',
                px: '1rem'
              }}
              onClick={() => navigate('/discount/codes/add')}
            >
              <HiPlusCircle size={25}/>
              {texts('discountCodes:add')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item 
        container
        justifyContent={'space-between'}
        wrap={'nowrap'}
        marginTop={'1.5rem'}
        marginBottom={'1rem'}
      >
        <Grid 
          item
          sx={{
            minWidth: '30rem',
            maxWidth: '40rem' 
          }}
        >
          <SearchBar
            initialValue={searchParams.search}
            onClick={(text) => setSearchParams({ search: text, page: 1 }, 'replaceIn')}
            placeholder={texts('discountCodes:search')}
          />
        </Grid>
      </Grid>
      {
        isError || isFetching
        ? <Grid 
            container 
            item
            flexGrow={1} 
            justifyContent={'center'} 
            alignItems={'center'}
          >
            {
              isError
              ? <NetworkError />
              : <Loader 
                  width={'100%'}
                  text={texts('common:loading')}
                />
            }
          </Grid>
        : <>
            {
              data == null || data.data.length === 0 
              ? <NoDataPlaceholder />
              : <>
                  {
                    data?.data.map((discountCode) => (
                      <DiscountCodeListRow 
                        key={discountCode.uuid}
                        discountCode={discountCode}
                      />
                    ))
                  }
                </>
            }
            {
              maxPage > 1
              ? <Grid
                  item
                  container
                  justifyContent={'center'}
                  mt={'1.5rem'}
                >
                  <Pagination 
                    page={searchParams.page}
                    onChange={(e, page) => setSearchParams({ page: page }, 'replaceIn')}
                    count={maxPage} 
                  />
                </Grid>
              : null
            }
          </>
      }   
    </Grid>
  )
}

export default DiscountCodeList