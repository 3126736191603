import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Box, Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import { styled } from '@mui/system'
import React, { useState } from 'react'
import { DraggableProvided } from '@hello-pangea/dnd'
import { useTranslation } from 'react-i18next'
import { AiFillPlusCircle } from 'react-icons/ai'
import { MenuProduct, MenuItemCardOption, MenuItemType } from '../../../types/menu'
import DraggableItem from '../../Common/DraggableItem'
import DroppableList from '../../Common/DroppableList'
import VariantOptionListCard from './VariantOptionListCard'
import Chip from '../../Common/Chip'
import AddVariantDialog from './Dialogs/AddVariantDialog'

type Props = {
  product: MenuProduct
  provided?: DraggableProvided
  categoryCardId: string
}

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowDropUpIcon sx={{color: '#94C11F'}} />}
    {...props}
  />
))(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  flexGrow: 1,
  paddingRight: '16px',
  minHeight: '3.6875rem',
  '&.Mui-expanded': {
    minHeight: '3.6875rem'
  },
  '& .MuiAccordionSummary-content': {
    margin: '0',
    flexGrow: 0
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: '0',
    minHeight: '0',
  },
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(180deg)'
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(0)'
  },
}))

const Accordion = styled(MuiAccordion)(({ theme }) => ({
  '&.Mui-expanded': {
    margin: '0'
  },
  '&:before': {
    display: 'none'
  },
  '&:last-of-type': {
    borderRadius: '0.98rem',
  }
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: '0'
}))


const VariantCard = (props: Props) => {
  const texts = useTranslation().t
  const cardOptions: MenuItemCardOption[] = texts('objects:menu_variant_list_options', { returnObjects: true }) as MenuItemCardOption[]

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItemClick = (type: MenuItemType) => {
    handleClose()
    switch (type) {
      case MenuItemType.ADD:
        setOpenAddDialog(true)
        break
    }
  }

  const [expandedVariant, setExpandedVariant] = useState<boolean>(false)
  const changeExpandStatus = (event: React.SyntheticEvent) => {
    setExpandedVariant(!expandedVariant)
  }

  return (
    <>
      <AddVariantDialog 
        open={openAddDialog}
        categoryCardId={props.categoryCardId}
        product={props.product}
        onCloseButtonClick={() => {setOpenAddDialog(false)}}
      />
      <Accordion expanded={expandedVariant} onChange={changeExpandStatus}
        sx={{
          background: '#F1F1F1',
          borderRadius:' 0.938rem'
        }}
      >
        <Grid
          container
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <AccordionSummary>
            <Box
              sx={{
                display: 'flex',
                gap: '0.938rem',
                flexGrow: 1
              }}
            >
              <Typography 
                variant='h2'
                sx={{
                  fontSize: '1.125rem',
                  marginTop: '0.0625rem'
                }}
              >
                {texts('menu:variant_title')}
              </Typography>
              {
                expandedVariant 
                ? null 
                : <Chip text={texts('menu:menu_value_of_variants',{val: props.product.variants?.length})} />
              }
              <Typography
                sx={{
                  fontSize: '1rem',
                  color: '#94C11F',
                  fontFamily: 'RobotoCondensedBold',
                  marginLeft: '0.625rem'
                }}
              >
                {expandedVariant ? texts('menu:menu_collapse'): texts('menu:menu_expand')}
              </Typography>
            </Box>
          </AccordionSummary>
          <Grid item>
            <IconButton
              onClick={handleClick}
              sx={{
                color: '#000',
                position: 'absolute',
                top: 0,
                right: 0
              }}
            >
              <MoreHorizIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              PaperProps={{
                style: {
                  minWidth: '12rem',
                },
              }}
            >
              {
                cardOptions.map((option) => (
                  <MenuItem 
                    key={option.value}                 
                    onClick={() => {
                      handleMenuItemClick(option.value)
                    }}
                  >
                    {option.label}
                  </MenuItem>
                ))
              }
            </Menu>
          </Grid>
        </Grid>
        <AccordionDetails>
          <Grid
            sx={{
              m: '1.5rem',
              marginTop: '-1rem'
            }}
          >
            {expandedVariant && <DroppableList droppableId={`variant:${props.categoryCardId}:${props.product.cardId}`} boxDirection='vertical'>
              {
                props.product.variants!.map((value, index) => (
                  <DraggableItem id={`variant:${props.categoryCardId}:${props.product.cardId}:${value.cardId}`} index={index} key={value.cardId}>
                    <VariantOptionListCard 
                      productCardId={props.product.cardId}
                      variant={value}
                      categoryCardId={props.categoryCardId}
                    />
                  </DraggableItem>
                ))
              }
            </DroppableList>}
            <Grid 
              item 
              container 
              wrap='nowrap' 
              alignItems={'center'}
              marginTop={'0.875rem'}
            >
              <MenuItem
                sx={{ padding: "0" }}
                onClick={() => {
                  handleClose()
                  setOpenAddDialog(true)
                }}
              >
                <AiFillPlusCircle 
                  size={30}
                  color={'#94C11F'}
                />
                <Typography
                  variant='body2' 
                  sx={{
                    fontFamily: 'RobotoBold',
                    marginLeft: '1.25rem',
                    paddingTop: '0.188rem',
                    color: '#94C11F'
                  }}
                >
                  {texts('menu:menu_add_variant')}
                </Typography>
              </MenuItem>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default VariantCard