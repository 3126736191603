import * as Yup from 'yup'
import { Role } from '../types/accounts'

export function changeAccountDetailsValidation(t: (val: string) => string) {
  return Yup.object({
    name: Yup.string()
      .max(120, t('validations:max_120_signs_validation'))
      .required(t('validations:field_required')),
    places: Yup.array().test('places_required', t('validations:field_required'), function (val: any) {
      let role = this.resolve(Yup.ref('role')) as Role
      return !((role === Role.EMPLOYEE || role === Role.PLACE_ADMIN) && (val.length === 0))
    })
  })
}