import { LoadingButton } from "@mui/lab"
import { Button, Grid, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import StatusButton from "./StatusButton"
import RoleGuard from '../../Common/RoleGuard'
import { Role } from "../../../types/accounts"

type Props = {
  saving: boolean
  statusChanging: boolean
  isPlaceActive: boolean
  onBack: () => void
  onStatusChanged: () => void
}

const EditPlaceHeaderBar = (props: Props) => {
  const texts = useTranslation().t
  
  return (
    <Grid
      item
      container 
      wrap='nowrap'
      justifyContent={'space-between'} 
      alignItems={'center'}
    >
      <Grid item> 
        <Typography variant='h2' noWrap>
          {texts('place:edit_place_title')}
        </Typography> 
      </Grid>
      <Grid 
        item 
        container 
        justifyContent={'flex-end'}
        rowGap={'0.5rem'}
      >
        <Grid item>
          <Button
            variant={'outlined'}
            size={'medium'}
            onClick={props.onBack}
          >
            {texts('place:go_to_place_list')}
          </Button>
        </Grid>
        <RoleGuard permittedRoles={[Role.SYSTEM_ADMIN]}>
          <Grid item>
            <StatusButton
              text={props.isPlaceActive ? texts('place:deactivate_place') : texts('place:activate_place')}
              loading={props.statusChanging}
              sx={{
                border: 0,
                ...(props.isPlaceActive 
                ? {
                    '&.MuiButton-root': 
                      {
                        backgroundColor: '#42a5f5',
                      ':hover': {backgroundColor: '#1976d2'}
                      },
                    marginLeft: '0.625rem'
                  }
                : {
                    '&.MuiButton-root': 
                      {
                        backgroundColor: '#4caf50',
                        ':hover': {backgroundColor: '#2e7d32'}
                      },
                    marginLeft: '0.625rem'
                  })
              }}
              onStatusChanged={props.onStatusChanged}
            />
          </Grid>
        </RoleGuard>
        <Grid item>
          <LoadingButton
            type='submit'
            variant={'contained'}
            size={'medium'}
            sx={{
              border: 0,
              marginLeft: '0.625rem'
            }}
            loading={props.saving}
          >
            {texts('place:save_edit')}
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default EditPlaceHeaderBar