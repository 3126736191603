import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import DataApi from '../../api/data/DataApi'
import { AccountListOptions } from '../../api/data/types'
import { showErrorToast } from '../../utils/error'
import { mapAccountListData } from '../../utils/mapping'
import { queryNames } from '../queries'
import { AccountListData } from '../../types/accounts'

const dataApi = DataApi.getInstance()

type Options = {
  enabled?: boolean
  onSuccess: (data: AccountListData) => void
}

export default function useGetAccountList(options: AccountListOptions & Options) {
  const texts = useTranslation().t
  const key = [queryNames.accountList, { 
    limit: options?.limit, 
    offset: options?.offset, 
    from: options?.from, 
    to: options?.to, 
    search: options?.name, 
    sort: options?.sort}
  ]
  const query = useQuery(key, async () => {
    return mapAccountListData(await dataApi.getAccountList(options))
  }, {
    staleTime: Infinity,
    refetchOnMount: 'always',
    onError: (error) => {
      showErrorToast(error, texts)
    },
    enabled: options.enabled,
    onSuccess: options.onSuccess
  })
  return query
}

