import { Box, Grid, Paper, Table as MuiTable, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import TableSortLabel from '@mui/material/TableSortLabel'
import { visuallyHidden } from '@mui/utils'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DecodedValueMap, NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import useGetAccountList from '../../hooks/accounts/useGetAccountList'
import { AccountListSearchParams, Column } from '../../types/accounts'
import { ASCENDING, DESCENDING } from '../../utils/constants'
import { mapOrder } from '../../utils/text'
import LoadingTableRow from '../Common/LoadingTableRow'
import NetworkError from '../Common/NetworkError'
import NoDataTableRow from '../Common/NoDataTableRow'
import AccountRow from './AccountRow'

const rowsPerPageOptions = [12,24,48,96]

function isSearchParamsValid(params: DecodedValueMap<AccountListSearchParams>, columns: Column[]): boolean {
  return Number.isInteger(params.page) // must be a number, not a NaN
    && params.page >= 1  // page cannot be lower than 1
    && Number.isInteger(params.size)  // must be a number, not a NaN
    && rowsPerPageOptions.includes(params.size) // size should be from defined list
    && (
      params.order == null || params.order === '' // no sort order defined
      || (
        columns.find(c => c.id === params.order.split(':')[0]) != null // first part of order string matches column
        && columns.find(c => c.id === params.order.split(':')[0])?.sortable === true // first part of order string must match sortable column
        && ['asc', 'desc'].includes(params.order.split(':')[1]?.toLowerCase()) // second part of sort string must be a sort order string
      ) 
    )
}

const AccountsTable = () => {
  const texts = useTranslation().t

  const columns: Column[] = texts('objects:accounts_columns', { returnObjects: true }) as Column[]
  
  const [searchParams, setSearchParams] = useQueryParams<AccountListSearchParams>({ 
    search: withDefault(StringParam, ''),
    page: withDefault(NumberParam, 1), 
    size: withDefault(NumberParam, rowsPerPageOptions[1]),
    order: withDefault(StringParam, 'joinDate:desc'),
  })
  
  const { isFetching, isError, data } = useGetAccountList({
    name: searchParams.search,
    offset: searchParams.size * (searchParams.page - 1),
    limit: searchParams.size,
    sort: searchParams.order,
    enabled: isSearchParamsValid(searchParams, columns),
    onSuccess: (data) => {
      if (searchParams.page > 1 && data.data.length === 0) {
        setSearchParams({
          page: 1, 
          size: rowsPerPageOptions[1],
          order: 'joinDate:desc',
          search: ''
        }, 'replaceIn')
      }
    }
  })

  useEffect(() => {
    window.scrollTo(0,0)
  }, [searchParams.page])

  useEffect(() => {
    if (!isSearchParamsValid(searchParams, columns)) {
      setSearchParams({
        page: 1, 
        size: rowsPerPageOptions[1],
        order: 'joinDate:desc',
        search: ''
      }, 'replaceIn')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.page, searchParams.size, searchParams.order])

  const order = useMemo(() => mapOrder(searchParams.order), [searchParams.order])

  if (!isSearchParamsValid(searchParams, columns)) {
    return <></>
  }

  return (
    <>
      {
        isError
        ? <Grid 
            container 
            item
            flexGrow={1} 
            justifyContent={'center'} 
            alignItems={'center'}
          >
            <NetworkError />
          </Grid>
        : <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer>
              <MuiTable stickyHeader aria-label='sticky table'>
                <TableHead>
                  <TableRow>
                    {
                      columns.map((column) => (
                        <TableCell
                          id={column.id}
                          key={column.id}
                          sortDirection={order.field === column.id ? order.direction : false}
                          sx={{
                            textTransform: 'none',
                            fontFamily: 'RobotoMedium',
                            fontSize: '0.875rem',
                            paddingY: '1.5rem',
                            ...(
                              column.width
                              ? { width: column.width }
                              : {}
                            ),
                            ...(
                              column.minWidth
                              ? { minWidth: column.minWidth }
                              : {}
                            ),
                            ...(
                              column.maxWidth
                              ? { maxWidth: column.maxWidth }
                              : {}
                            )
                          }}
                        >
                          {
                            column.sortable
                            ? <TableSortLabel
                                active={order.field === column.id}
                                hideSortIcon={order.field !== column.id}
                                direction={order.field === column.id 
                                  ? order.direction
                                  : ASCENDING}
                                onClick={() => {
                                  const newDirection = column.id === order.field
                                    ? order.direction === ASCENDING ? DESCENDING : ASCENDING
                                    : ASCENDING

                                  setSearchParams({ order: `${column.id}:${newDirection}`, page: 1 }, 'replaceIn')
                                }}
                                sx={{
                                  flexDirection: 'row-reverse',
                                  justifyContent: 'flex-end'
                                }}
                              >
                                {order.field === column.id ? (
                                  <Box component='span' sx={visuallyHidden}>
                                    {order.direction === DESCENDING ? 'sorted descending' : 'sorted ascending'}
                                  </Box>
                                ) : null}
                                {column.label}
                              </TableSortLabel>
                            : column.label
                          }
                        </TableCell>
                      ))
                    }
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    isFetching || (searchParams.page > 1 && data!.data.length === 0)
                    ? <LoadingTableRow colSpan={columns.length} />
                    : data == null || data.data.length === 0
                      ? <NoDataTableRow colSpan={columns.length} />
                      : <>
                          {
                            data?.data
                              .map((row, index) => (
                                <AccountRow
                                  row={row}
                                  key={index}
                                />
                              ))
                          }
                        </>
                  }
                </TableBody>
              </MuiTable>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component='div'
              count={data?.count ?? 0}
              rowsPerPage={searchParams.size}
              page={searchParams.page - 1}
              onPageChange={(e, page) => setSearchParams({ page: page + 1 }, 'replaceIn' )}
              onRowsPerPageChange={(event) => 
                setSearchParams({
                  size: Number.parseInt(event.target.value), 
                  page: 1 
                }, 'replaceIn' )
              }
              sx={{
                '.MuiTablePagination-selectLabel' : {
                  fontSize: '0.75rem',
                  textTransform: 'none'
                },
                '.MuiTablePagination-displayedRows' : {
                  fontSize: '0.75rem',
                  textTransform: 'none'
                },
              }}
            />
          </Paper>
      }
    </>
  )
}

export default AccountsTable