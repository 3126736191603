import { Grid, Typography } from "@mui/material"

type Props = {
  text: string
  uppercase?: boolean
  opacity?: number
  backgroundColor?: string
  color?: string
  width?: number | string
  paddingInline?: string
}

const Chip = (props: Props) => {
  return (
    <Grid
      item
      container
      alignItems={'center'}
      justifyContent={'center'}
      width={props.width ?? 'fit-content'}
      sx={{
        backgroundColor: props.backgroundColor ?? '#000',
        color: props.color ?? '#fff',
        borderRadius: '1rem',
        px: '1.5rem',
        ...(props.opacity != null ? { opacity: props.opacity } : {}),
        ...(props.paddingInline != null ? { paddingInline: props.paddingInline } : {})
      }}
    >
      <Typography 
        variant='body2'
        sx={{
          textTransform: props.uppercase ? 'uppercase' : 'none',
          fontWeight: 'bold'
        }}
      >
        {props.text}
      </Typography>
    </Grid>
  )
}

export default Chip