import { AxiosError } from 'axios'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import AuthApi from '../../api/auth/AuthApi'
import { PasswordDto } from '../../api/auth/types'
import { logOut } from '../../redux/storageToolkit'
import store from '../../redux/store'
import { HttpError } from '../../types/common'
import { showErrorToast } from '../../utils/error'

const authApi = AuthApi.getInstance()

export function useActivateUser() {
  const texts = useTranslation().t
  const navigate = useNavigate()
  const mutation = useMutation((data: PasswordDto) => authApi.activateUser(data), {
    onSuccess: () => {
      toast.success(texts('successes:panel_email_activated'))
      store.dispatch(logOut())
      navigate('/login', { replace: true })
    },
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts)
    },
  })
  return mutation
}