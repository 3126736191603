import { Grid, IconButton, Menu, MenuItem, TableCell, TableRow } from '@mui/material'
import { Role, AccountDataRow, Column } from '../../types/accounts'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Dialog from '../CustomMui/Dialog'
import ChangePinDialog from '../Accounts/ChangePinDialog/ChangePinDialog'
import AccountDetailsDialog from './AccountDetailsDialog'
import moment from 'moment'
import useEditEmployeeActiveState from '../../hooks/accounts/useEditEmployeeActiveState'
import useEditCmsUserActiveState from '../../hooks/accounts/useEditCmsUserActiveState'

type Props = {
  row: AccountDataRow
  backgroundColor?: string
}

const AccountRow = (props: Props) => {
  const texts = useTranslation().t

  const columns: Column[] = texts('objects:accounts_columns', { returnObjects: true }) as Column[]

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [openChangeActiveStateDialog, setOpenChangeActiveStateDialog] = useState<boolean>(false)
  const [openChangePinDialog, setOpenChangePinDialog] = useState<boolean>(false)
  const [openAccountDetailsDialog, setOpenAccountDetailsDialog] = useState<boolean>(false)

  const editEmployeeActiveState = useEditEmployeeActiveState()
  const removeCmsUserMutation = useEditCmsUserActiveState()

  const mapRow: string[] = useMemo(() => {
    const entries: string[] = []
    entries.push(props.row.name)
    entries.push(
      texts(`accounts:${
        props.row.role === Role.SYSTEM_ADMIN 
          ? 'admin' 
          : props.row.role === Role.PLACE_ADMIN
              ? 'place_admin'
              : 'user'
      }`)
    )
    entries.push(props.row.email ?? '-')
    entries.push(props.row.places.map(e => e.name).join(', '))
    entries.push(moment(props.row.joinDate).format('DD.MM.YYYY HH:mm'))
    entries.push(props.row.lastLogin ? moment(props.row.lastLogin).format('DD.MM.YYYY HH:mm') : '-')
    return entries
  }, [props.row, texts])

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const onChangeActiveState = () => {
    if (props.row.role === Role.EMPLOYEE) {
      editEmployeeActiveState.mutate({ uuid: props.row.uuid, active: !props.row.active })
    } else {
      removeCmsUserMutation.mutate({ uuid: props.row.uuid, active: !props.row.active })
    }
  }

  return (
    <TableRow
      sx={{
        '&:nth-of-type(even)': {
          backgroundColor: 'action.hover',
        }
      }}
    >
      <Dialog
        open={openChangeActiveStateDialog}
        title={texts(props.row.active ? 'dialogs:account_deactivate_title' : 'dialogs:account_activate_title')}
        description={texts(props.row.active ? 'dialogs:account_deactivate_description' : 'dialogs:account_activate_description')}
        closeButtonLabel={texts('common:cancel')}
        confirmButtonLabel={texts(props.row.active ? 'common:deactivate' : 'common:activate')}
        onCloseButtonClick={() => {
          setOpenChangeActiveStateDialog(false)
        }}
        onConfirmButtonClick={() => {
          onChangeActiveState()
          setOpenChangeActiveStateDialog(false)
        }}
      />
      {
        props.row.role === Role.EMPLOYEE
        ? <ChangePinDialog
            open={openChangePinDialog}
            uuid={props.row.uuid}
            onClose={() => setOpenChangePinDialog(false)}
          />
        : null
      }
      <AccountDetailsDialog
        open={openAccountDetailsDialog}
        onClose={() => setOpenAccountDetailsDialog(false)}
        initialValues={{
          uuid: props.row.uuid,
          role: props.row.role,
          name: props.row.name,
          email: props.row.email ?? '',
          places: props.row.places
        }}
      />
      <TableCell
        sx={{
          textTransform: 'none',
          fontSize: '0.875rem',
          py: '0.25rem',
          opacity: props.row.active ? 1 : 0.5,
          wordBreak: 'break-all',
          ...(
            columns[0].width
            ? { width: columns[0].width }
            : {}
          ),
          ...(
            columns[0].minWidth
            ? { minWidth: columns[0].minWidth }
            : {}
          ),
          ...(
            columns[0].maxWidth
            ? { maxWidth: columns[0].maxWidth }
            : {}
          )
        }}
      >
        {mapRow[0]}
      </TableCell>
      <TableCell
        sx={{
          textTransform: 'none',
          fontSize: '0.875rem',
          py: '0.25rem',
          opacity: props.row.active ? 1 : 0.5,
          ...(
            columns[1].width
            ? { width: columns[1].width }
            : {}
          ),
          ...(
            columns[1].minWidth
            ? { minWidth: columns[1].minWidth }
            : {}
          )
        }}
      >
        <Grid
          item
          container
          alignItems={'center'}
          justifyContent={'center'}
          width={'100%'}
          sx={{
            backgroundColor: '#000',
            color: '#fff',
            borderRadius: '1rem',
            px: '1.5rem',
            py: '0.125rem',
            fontWeight: 'bold',
            fontSize: '0.75rem'
          }}
        >
          {mapRow[1]}
        </Grid>
      </TableCell>
      <TableCell
        sx={{
          textTransform: 'none',
          fontSize: '0.875rem',
          py: '0.25rem',
          opacity: props.row.active ? 1 : 0.5,
          wordBreak: 'break-all',
          ...(
            columns[2].width
            ? { width: columns[2].width }
            : {}
          ),
          ...(
            columns[2].minWidth
            ? { minWidth: columns[2].minWidth }
            : {}
          )
        }}
      >
        {mapRow[2]}
      </TableCell>
      <TableCell
        sx={{
          textTransform: 'none',
          fontSize: '0.875rem',
          py: '0.25rem',
          opacity: props.row.active ? 1 : 0.5,
          wordBreak: 'break-all',
          ...(
            columns[3].width
            ? { width: columns[3].width }
            : {}
          ),
          ...(
            columns[3].minWidth
            ? { minWidth: columns[3].minWidth }
            : {}
          )
        }}
      >
        {mapRow[3]}
      </TableCell>
      <TableCell
        sx={{
          textTransform: 'none',
          fontSize: '0.875rem',
          py: '0.25rem',
          opacity: props.row.active ? 1 : 0.5,
          ...(
            columns[4].width
            ? { width: columns[4].width }
            : {}
          ),
          ...(
            columns[4].minWidth
            ? { minWidth: columns[4].minWidth }
            : {}
          )
        }}
      >
        {mapRow[4]}
      </TableCell>
      <TableCell
        sx={{
          textTransform: 'none',
          fontSize: '0.875rem',
          py: '0.25rem',
          opacity: props.row.active ? 1 : 0.5,
          ...(
            columns[5].width
            ? { width: columns[5].width }
            : {}
          ),
          ...(
            columns[5].minWidth
            ? { minWidth: columns[5].minWidth }
            : {}
          )
        }}
      >
        {mapRow[5]}
      </TableCell>
      <TableCell
        sx={{
          py: '0.25rem',
          ...(
            columns[6].width
            ? { width: columns[6].width }
            : {}
          ),
          ...(
            columns[6].minWidth
            ? { minWidth: columns[6].minWidth }
            : {}
          )
        }}
      >
        <Grid 
          item 
          sx={{marginRight: '-0.625rem'}}
        >
          <IconButton
            onClick={handleClick}
            sx={{color: '#000'}}
          >
            <MoreHorizIcon/>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{
              style: {
                width: '12rem',
              },
            }}
          >
            <MenuItem 
              onClick={() => {
                handleClose()
                setOpenAccountDetailsDialog(true)
              }}
            >
              {texts('accounts:details')}
            </MenuItem>
            <MenuItem 
              onClick={() => {
                handleClose()
                setOpenChangeActiveStateDialog(true)
              }}
            >
              {texts(props.row.active ? 'accounts:deactivate' : 'accounts:activate')}
            </MenuItem>
            {
              props.row.role === Role.EMPLOYEE
              ? <MenuItem 
                  onClick={() => {
                    handleClose()
                    setOpenChangePinDialog(true)
                  }}
                >
                  {texts('accounts:change_pin')}
                </MenuItem>
              : null
            }
          </Menu>
        </Grid>
      </TableCell>
    </TableRow>
  )
}

export default AccountRow