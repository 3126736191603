import { useTranslation } from 'react-i18next'
import { Box, Grid, Typography } from '@mui/material'
import { HiPlusCircle } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'
import PlaceListTile from '../components/Home/PlaceListTile'
import useGetPlaceList from '../hooks/place/useGetPlaceList'
import Loader from '../components/Common/Loader'
import { PlaceDetailsDto } from '../types/place'
import NetworkError from '../components/Common/NetworkError'
import { useQueryClient } from 'react-query'
import { queryNames } from '../hooks/queries'
import RoleGuard from '../components/Common/RoleGuard'
import { Role } from '../types/accounts'

const Home = () => {
  const texts = useTranslation().t
  const navigate = useNavigate()

  const queryClient = useQueryClient()
  const { isLoading, isFetching, data, isError } = useGetPlaceList()

  return (
    <>
      {
        isLoading || isFetching || isError
        ? <Grid sx={{height: 'calc(100vh - 160px)'}}>
          {
            isError
            ? <NetworkError
                withRefresh
                onRefresh={() => {
                  queryClient.resetQueries(queryNames.placeList)
                }}
              />
            : <Loader 
                width={'100%'}
                text={texts('common:loading')}
              />
          }
          </Grid>
        : <>
            <Typography variant='h2'>
              {texts('place:place_list')}
            </Typography> 
            <Box
              display={'grid'}
              gridTemplateColumns={'repeat(auto-fill, 17.188rem)'}
              gap={'1.25rem'}
              marginTop={'1.875rem'}
            >
              <RoleGuard permittedRoles={[Role.SYSTEM_ADMIN]}>
                <Grid
                  item 
                  container
                  justifyContent={'center'}
                  alignItems={'center'}
                  gap={'0.625rem'}
                  height={'19.5rem'}
                  borderRadius={'0.938rem'}
                  boxShadow={'0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.5)'}
                  color={'#000'}
                  sx={{':hover': {cursor: 'pointer'}}}
                  onClick={() => navigate('/place/create')}
                >
                  <HiPlusCircle size={25}/>
                  <Typography variant='h3' fontWeight={'bold'}>
                    {texts('place:add_place')}
                  </Typography>
                </Grid>
              </RoleGuard>
              {
                data?.map((place: PlaceDetailsDto) => (
                  <PlaceListTile 
                    placeInfo={place}
                    key={place.uuid}
                  />
                ))
              }        
            </Box>
          </>
      }
    </>
  )
}

export default Home