import { Autocomplete, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField as MuiTextField, TextFieldProps, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import useEditCmsUser from '../../hooks/accounts/useEditCmsUser'
import useEditEmployeeEditPlace from '../../hooks/accounts/useEditEmployee'
import useGetPlaceList from '../../hooks/place/useGetPlaceList'
import { AccountAllowedPlace, AccountDetailsFormData, Role } from '../../types/accounts'
import { changeAccountDetailsValidation } from '../../validations/changeAccountDetailsValidation'
import TextField from '../CustomMui/Fields/TextField'
import TextFieldWithWarning from '../CustomMui/Fields/TextFieldWithWarning'
import LoadingButton from '../CustomMui/LoadingButton'

type Props = {
  open: boolean
  onClose: () => void
  initialValues: AccountDetailsFormData
}

const AccountDetailsDialog = (props: Props) => {
  const texts = useTranslation().t
  const placeListQuery = useGetPlaceList()
  const editEmployeeMutation = useEditEmployeeEditPlace()
  const editCmsUserMutation = useEditCmsUser()

  const handleSubmit = (data: AccountDetailsFormData) => {
    if (data.role === Role.EMPLOYEE) {
      editEmployeeMutation.mutate({
        uuid: data.uuid,
        data: {
          name: data.name
        }
      })
    } else {
      editCmsUserMutation.mutate({
        uuid: data.uuid,
        data: {
          name: data.name,
          allowedPlaceUuids: data.places.map(e => e.uuid)
        }
      })

    }
    props.onClose()
  }


  return (
    <Dialog 
      open={props.open} 
      onClose={props.onClose}
      maxWidth={'xl'}
    >
      <DialogActions>
        <IconButton 
          onClick={props.onClose} 
          color={'primary'}
        >
          <AiOutlineCloseCircle size={30}/>
        </IconButton>
      </DialogActions>
      <DialogTitle
        sx={{
          fontSize: '1.5rem',
          color: 'RGBA(0, 0, 0, 0.7)'
        }}
      >
        {texts('accounts:change_details')}
      </DialogTitle>
      <DialogContent sx={{paddingBottom: 0}}>
        <Formik<AccountDetailsFormData>
          initialValues={props.initialValues}
          enableReinitialize
          validateOnBlur={true}
          validateOnChange={true}
          onSubmit={handleSubmit}
          validationSchema={changeAccountDetailsValidation(texts)}
        >
          {(formikProps) => (
            <Form>
              <Grid
                container
                columnGap={'1rem'}
                rowGap={'0.125rem'}
                mt={'1.5rem'}
                sx={{maxWidth: '40rem'}}
              >
                <Typography
                  variant={'subtitle2'}
                  mb={'0.875rem'}
                >
                  {texts('accounts:edit_details')}
                </Typography>
                <TextField
                  name='name'
                  label={texts('accounts:name')}
                />
                {
                  formikProps.values.role === Role.EMPLOYEE
                    ? null
                    : <TextFieldWithWarning
                        name='email'
                        disabled
                        label={texts('accounts:email')}
                        helperText={texts('accounts:change_email_warning')}
                      />
                }
                {
                  formikProps.values.role === Role.EMPLOYEE || formikProps.values.role === Role.PLACE_ADMIN
                  ? formikProps.values.role === Role.PLACE_ADMIN
                    ? <Autocomplete
                        multiple
                        filterSelectedOptions
                        filterOptions={(options, state) => options.filter(o => o.name.toLowerCase().includes(state.inputValue.toLowerCase()))}
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option.uuid}>
                              {option.name}
                            </li>
                          )
                        }}
                        value={formikProps.values.places}
                        isOptionEqualToValue={(option: AccountAllowedPlace, value: AccountAllowedPlace) => option.uuid === value.uuid}
                        onChange={(e: any, values: AccountAllowedPlace[]) => {
                          formikProps.setFieldTouched('places', true)
                          formikProps.setFieldValue('places', values)
                        }}
                        options={
                          placeListQuery.data?.map(e => { return { uuid: e.uuid, name: e.name }}) ?? []
                        }
                        onClose={() => {
                          formikProps.setFieldTouched('places', true)
                        }}
                        noOptionsText={texts('accounts:no_places')}
                        sx={{flexGrow: 1, maxWidth: '100%'}}
                        getOptionLabel={(option: AccountAllowedPlace) => option.name}
                        renderInput={(params: TextFieldProps) => (
                          <MuiTextField
                            {...params}
                            error={formikProps.touched.places && !!formikProps.errors.places}
                            helperText={formikProps.touched.places ? formikProps.errors.places as string : undefined}
                            label={texts('accounts:places')}
                          />
                        )}
                      />
                    : <Autocomplete
                        disabled
                        filterSelectedOptions
                        value={formikProps.values.places[0] ?? null}
                        onChange={(e: any, value: AccountAllowedPlace | null) => {
                          formikProps.setFieldTouched('places', true)
                          formikProps.setFieldValue('places', value != null ? [value] : [])
                        }}
                        isOptionEqualToValue={(option: AccountAllowedPlace, value?: AccountAllowedPlace) => option.uuid === value?.uuid}
                        options={
                          placeListQuery.data?.map(e => { return { uuid: e.uuid, name: e.name }}) ?? []
                        }
                        onClose={() => {
                          formikProps.setFieldTouched('places', true)
                        }}
                        noOptionsText={texts('accounts:no_places')}
                        sx={{flexGrow: 1, maxWidth: '100%'}}
                        getOptionLabel={(option: AccountAllowedPlace) => option.name}
                        renderInput={(params: TextFieldProps) => (
                          <MuiTextField
                            {...params}
                            error={formikProps.touched.places && !!formikProps.errors.places}
                            helperText={formikProps.touched.places ? formikProps.errors.places as string : undefined}
                            label={texts('accounts:places')}
                          />
                        )}
                      />
                  : null
                }

              </Grid>
              <DialogActions 
                sx={{
                  py: '1.25rem', 
                  px: 0,
                  mt: '1.5rem'
                }}
              >
                <LoadingButton
                  type='submit'
                  variant={'contained'}
                  size={'medium'}
                  loading={editEmployeeMutation.isLoading || editCmsUserMutation.isLoading}
                >
                  {texts('common:save')}
                </LoadingButton>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

export default AccountDetailsDialog