import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import DataApi from '../../api/data/DataApi'
import { showErrorToast } from '../../utils/error'
import { useEffect, useRef } from 'react'

const dataApi = DataApi.getInstance()

type MutationParams = {
  file: File
  thumbnailWidth: number
}

export default function useUploadPhoto(abort: boolean) {
  const texts = useTranslation().t
  const abortControllerRef = useRef<AbortController | null>(null)
  useEffect(() => {
    if (abort) {
      abortControllerRef.current?.abort()
    }
  }, [abort])

  const mutation = useMutation((params: MutationParams) => {
      abortControllerRef.current = new AbortController()
      const data = new FormData()
      data.append('file', params.file)
      data.append('thumbnailWidth', `${params.thumbnailWidth}`)
      return dataApi.uploadPhoto(data, abortControllerRef.current.signal)
    }, {
    onError: (error: any) => {
      if (error.name !== 'CanceledError') {
        showErrorToast(error, texts)
      }
    },
  })
  return mutation
}