import { Button, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import LoadingButton from '../../../CustomMui/LoadingButton'

type Props = {
  isEdit: boolean
  loading: boolean
  onBack: () => void
}

const HeaderBar = (props: Props) => {
  const texts = useTranslation().t
  
  return (
    <Grid
      item
      container 
      wrap='nowrap'
      justifyContent={'space-between'} 
      alignItems={'center'}
    >
      <Grid item> 
        <Typography variant='h2' noWrap>
          {texts(props.isEdit ? 'discountCodes:edit_title' : 'discountCodes:add_title')}
        </Typography> 
      </Grid>
      <Grid 
        item 
        container 
        justifyContent={'flex-end'}
        rowGap={'0.5rem'}
      >
        <Grid item>
          <Button
            variant={'outlined'}
            size={'medium'}
            onClick={props.onBack}
            sx={{
              width: 'fit-content',
              px: '1rem',
              whiteSpace: 'nowrap'
            }}
          >
            {texts('discountCodes:go_to_list')}
          </Button>
        </Grid>
        <Grid item>
          <LoadingButton
            type='submit'
            variant={'contained'}
            size={'medium'}
            sx={{ marginLeft: '0.625rem' }}
            loading={props.loading}
          >
            {texts('discountCodes:save')}
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default HeaderBar
