import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { TagFormData } from '../../../types/menu'
import { Form, Formik, FormikProps } from 'formik'
import { Grid } from '@mui/material'
import Dialog from '../../../components/CustomMui/Dialog'
import { addTagValidation } from '../../../validations/addTagValidation'
import EditTagHeaderBar from '../../../components/Menu/Tag/EditTagHeaderBar'
import CreateTagHeaderBar from '../../../components/Menu/Tag/CreateTagHeaderBat'
import TextField from '../../../components/CustomMui/Fields/TextField'
import useGetTagDetails from '../../../hooks/tag/useGetTagDetails'
import Loader from '../../../components/Common/Loader'
import useCreateTag from '../../../hooks/tag/useCreateTag'
import useEditTag from '../../../hooks/tag/useEditTag'
import useRemoveTag from '../../../hooks/tag/useRemoveTag'
import NetworkError from '../../../components/Common/NetworkError'
import { useQueryClient } from 'react-query'
import { queryNames } from '../../../hooks/queries'

const defaultInitValue: TagFormData = {
  name: ''
}

type Props = {
  isEditTag: boolean
}

const TagPage = (props: Props) => {
  const texts = useTranslation().t
  const navigate = useNavigate()
  const { uuid } = useParams<{ uuid: string }>()

  const goBack = () => { navigate(-1) }

  const queryClient = useQueryClient()
  const addMutation = useCreateTag()
  const editMutation = useEditTag(goBack)
  const removeMutation = useRemoveTag(goBack)

  const [openRemoveDialog, setOpenRemoveDialog] = useState<boolean>(false)
  const [openGoBackDialog, setOpenGoBackDialog] = useState<boolean>(false)
  const formRef = useRef<FormikProps<TagFormData>>(null)

  const { isFetching, data, isError } = useGetTagDetails((data) => {
    formRef?.current?.resetForm({values: data})
  }, props.isEditTag ? uuid : null, goBack)

  const handleSubmit = (data: TagFormData) => {
    if (props.isEditTag) {
      editMutation.mutate({
        uuid: uuid!,
        data: data
      })
    } else {
      addMutation.mutate(data)
    }
  }

  return (
    <>
      {
        isError || isFetching
        ? <Grid sx={{height: 'calc(100vh - 160px)'}}>
          {
            isError
            ? <NetworkError
                withRefresh
                onRefresh={() => {
                  queryClient.resetQueries([queryNames.tagDetails, uuid])
                }}
              />
            : <Loader 
                width={'100%'}
                text={texts('common:loading')}
              />
          }
          </Grid>
        : <Formik<TagFormData>
            initialValues={data ?? defaultInitValue}
            enableReinitialize
            validateOnBlur={true}
            validateOnChange={true}
            onSubmit={handleSubmit}
            validationSchema={addTagValidation(texts)}
            innerRef={formRef}
          >
            {() => (
              <Form>
                <Grid 
                  container 
                  direction={'column'}
                >
                  <Dialog
                    open={openRemoveDialog}
                    title={texts('dialogs:remove_title')}
                    description={texts('dialogs:remove_description')}
                    warning={texts('dialogs:tag_remove_warning')}
                    closeButtonLabel={texts('common:cancel')}
                    confirmButtonLabel={texts('common:remove')}
                    onCloseButtonClick={() => {
                      setOpenRemoveDialog(false)
                    }}
                    onConfirmButtonClick={() => {
                      removeMutation.mutate({ uuid: uuid! })
                      setOpenRemoveDialog(false)
                      navigate('/menu/tags', {replace: true})
                    }}
                  />
                  <Dialog
                    open={openGoBackDialog}
                    title={texts('dialogs:go_back_title')}
                    description={texts('dialogs:go_back_description')}
                    closeButtonLabel={texts('common:cancel')}
                    confirmButtonLabel={texts('common:exit')}
                    onCloseButtonClick={() => {
                      setOpenGoBackDialog(false)
                    }}
                    onConfirmButtonClick={() => {
                      setOpenGoBackDialog(false)
                      goBack()
                    }}
                  />
                  {
                    props.isEditTag
                    ? <EditTagHeaderBar
                        loading={editMutation.isLoading}
                        onRemove={() => setOpenRemoveDialog(true)}
                        onBack={() => setOpenGoBackDialog(true)}
                      />
                    : <CreateTagHeaderBar
                        loading={addMutation.isLoading}
                        onBack={() => setOpenGoBackDialog(true)}
                      />
                  }
                  <Grid 
                    item 
                    sx={{marginTop: '1.25rem'}}
                  >
                    <TextField
                      name='name'
                      label={texts('menu:tag_name')}
                    />
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
      }
    </>
  )
}

export default TagPage