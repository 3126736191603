import { AiOutlineCloseCircle } from 'react-icons/ai'
import { Button, Dialog as MuiDialog, DialogActions, DialogContent, DialogTitle, IconButton, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import { MenuFormData, MenuProduct, Variant } from '../../../../types/menu'
import moment from 'moment'
import { DropdownOption } from '../../../CustomMui/DropdownMenu'
import { useState, useRef, useEffect, useMemo } from 'react'
import DropdownMenu from '../../../CustomMui/DropdownMenu'
import SortIcon from '@mui/icons-material/Sort'
import SearchBar from '../../../CustomMui/SearchBar'
import NoDataPlaceholder from '../../../Common/NoDataPlaceholder'
import Pagination from '@mui/material/Pagination'
import NetworkError from '../../../Common/NetworkError'
import Loader from '../../../Common/Loader'
import { removeArrayElement } from '../../../../utils/array'
import useGetVariantTemplateList from '../../../../hooks/template/variant/useGetVariantTemplateList'
import VariantTemplateTile from './Tiles/VariantTemplateTile'

type Props = {
  open: boolean
  categoryCardId: string
  product: MenuProduct
  onCloseButtonClick: () => void
}

const variantsPerPage = Number.parseInt(process.env.REACT_APP_DEFAULT_PAGE_LIMIT!)

const AddVariantDialog = (props: Props) => {
  const texts = useTranslation().t
  const { values, setFieldValue } = useFormikContext<MenuFormData>()

  const sortOptions: DropdownOption[] = useMemo(() => {
    return texts('objects:variants_sort_options', {returnObjects: true}) as DropdownOption[]
  }, [texts])

  const [currentPage, setCurrentPage] = useState(1)
  const [sortOption, setSortOption] = useState<string>(sortOptions[0].value)
  const [searchValue, setSearchValue] = useState<string>('')
  const [selectedVariants, setSelectedVariants] = useState<Variant[]>([])

  const ref = useRef<HTMLDivElement | null>(null)

  const { isLoading, data, isError, maxPage } = useGetVariantTemplateList({
    limit: variantsPerPage,
    offset: (currentPage - 1) * variantsPerPage,
    sort: sortOption,
    search: searchValue,
    exclude: props.product.variants.map(e => e.templateUuid)
  })

  const onClose = () => {
    setCurrentPage(1)
    setSearchValue('')
    setSortOption(sortOptions[0].value)
    setSelectedVariants([])
    props.onCloseButtonClick()
  }

  const handleConfirm = () => {
    const catIdx = values.items.findIndex(e => e.cardId === props.categoryCardId)
    const prodIdx = values.items[catIdx].products.findIndex(e => e.cardId === props.product.cardId)
    const newItems = [...values.items]
    for (let i = 0; i < selectedVariants.length; i++) {
      newItems[catIdx].products[prodIdx].variants.push({
        cardId: `variant-${i}-${moment().unix()}`,
        templateUuid: selectedVariants[i].uuid,
        name: selectedVariants[i].name,
        description: null,
        internalNote: selectedVariants[i].note ?? '',
        active: true,
        options: selectedVariants[i].options.map((opt, idx) => {
          return {
            cardId: `variant-option-${idx}-${moment().unix()}`,
            templateUuid: opt.uuid!,
            name: opt.name,
            description: null,
            price: opt.additionalPrice,
            preparationTime: opt.additionalTime,
            customPrice: null,
            customPreparationTime: null,
            active: true,
            default: opt.defaultOption
          }
        })
      })
    }
    setFieldValue('items', newItems)
    onClose()
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(1)
    setSortOption(event.target.value)
  }

  const handlePaginationChange = (e: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page)
  }

  const handleTileClick = (variant: Variant) => {
    if (selectedVariants.findIndex(e => e.uuid === variant.uuid) > -1) {
      setSelectedVariants(removeArrayElement(selectedVariants, selectedVariants.findIndex(e => e.uuid === variant.uuid)))
    } else {
      const newItems = [...selectedVariants]
      newItems.push(variant)
      setSelectedVariants(newItems)
    }
  }

  useEffect(() => {
    ref.current?.scrollTo(0,0)
  }, [currentPage, sortOption, searchValue])

  return (
    <MuiDialog
      open={props.open}
      PaperProps={{
        ref: ref
      }}
      onClose={onClose}
      sx={{
        '.MuiDialog-paper': {
          width: '75rem',
          height: '85vh'
        }
      }}
    >
      <DialogActions>
        <IconButton onClick={onClose} color={'primary'}>
          <AiOutlineCloseCircle size={30}/>
        </IconButton>
      </DialogActions>
      <Grid
        container
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <DialogTitle sx={{paddingBottom: 0}}> 
          {texts('dialogs:add_variant_dialog_title')}
        </DialogTitle>
        <DialogActions>
          <Button
            variant={'contained'}
            sx={{
              float: 'right'
            }}
            onClick={handleConfirm}
          >
            {texts('dialogs:add_item_to_menu_dialog_button')}{selectedVariants.length > 0 ? ` (${selectedVariants.length})` : ''}
          </Button>
        </DialogActions>
      </Grid>
      <DialogContent sx={{overflowY: 'unset'}}>
        <Grid
          container
          flexDirection={'column'}
          flexWrap={'nowrap'}
          height={'100%'}
          maxWidth={'100%'}
        >
          <Grid
            item 
            container
            justifyContent={'space-between'}
            wrap={'nowrap'}
            marginBottom={'1rem'}
          >
            <Grid 
              item
              sx={{
                minWidth: '30rem',
                maxWidth: '40rem' 
              }}
            >
              <SearchBar
                onClick={(text) => {
                  setCurrentPage(1)
                  setSearchValue(text)
                }}
                placeholder={texts('variants:search')}
              />
            </Grid>
            <Grid
              item
              container
              alignItems={'center'}
              maxWidth={'fit-content'}
              gap={'0.875rem'}
              wrap={'nowrap'}
            >
              <SortIcon/>
              <Typography 
                variant='body2'
                fontWeight={'bold'}
              >
                {texts('common:sort')}
              </Typography>
              <Grid
                item
                sx={{minWidth: '12rem'}}
              >
                <DropdownMenu
                  value={sortOption}
                  onChange={handleChange}
                  options={sortOptions}
                />
              </Grid>
            </Grid>
          </Grid>
          {
            isLoading || isError
            ? <Grid 
                container 
                item
                flexGrow={1} 
                justifyContent={'center'} 
                alignItems={'center'}
              >
                {
                  isError
                  ? <NetworkError />
                  : <Loader 
                      width={'100%'}
                      text={texts('common:loading')}
                    />
                }
              </Grid>
            : <>
                {
                  data == null || data.data.length === 0 
                  ? <Grid 
                      container 
                      item
                      flexGrow={1} 
                      justifyContent={'center'} 
                      alignItems={'center'}
                    >
                      <NoDataPlaceholder />
                    </Grid>
                  : <>
                      {
                        data?.data.map((variant) => (
                          <VariantTemplateTile 
                            key={variant.uuid}
                            variant={variant}
                            selected={selectedVariants.findIndex(e => e.uuid === variant.uuid) > -1}
                            onClick={() => handleTileClick(variant)}
                          />
                        ))
                      }
                    </>
                }
                {
                  maxPage > 1
                  ? <Grid
                      item
                      container
                      justifyContent={'center'}
                      mt={'1.5rem'}
                    >
                      <Pagination 
                        page={currentPage}
                        onChange={handlePaginationChange}
                        count={maxPage} 
                      />
                    </Grid>
                  : null
                }
              </>
          }
        </Grid>
        
      </DialogContent>
      
    </MuiDialog>
  )
}

export default AddVariantDialog
