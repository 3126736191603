import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/system'
import { useFormikContext } from 'formik'
import React, { useState } from 'react'
import { DraggableProvided } from '@hello-pangea/dnd'
import { useTranslation } from 'react-i18next'
import { AiFillPlusCircle } from 'react-icons/ai'
import { MenuCategory, MenuFormData, MenuItemCardOption, MenuItemType } from '../../../types/menu'
import { removeArrayElement } from '../../../utils/array'
import Loader from '../../Common/Loader'
import DraggableItem from '../../Common/DraggableItem'
import DroppableList from '../../Common/DroppableList'
import ProductCards from './ProductCard'
import Chip from '../../Common/Chip'
import AddProductDialog from './Dialogs/AddProductDialog'
import RemoveMenuItemDialog from './Dialogs/RemoveMenuItemDialog'

type Props = {
  category: MenuCategory
  provided?: DraggableProvided
  loading?: boolean
  onEdit: () => void
}

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowDropUpIcon sx={{color: '#FFFFFF'}} />}
    {...props}
  />
))(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  flexGrow: 1,
  padding: 0,
  minHeight: '2.125rem',
  '&.Mui-expanded': {
    minHeight: '2.125rem',
  },
  '& .MuiAccordionSummary-content': {
    margin: '0',
    flexGrow: 0
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: '0',
    minHeight: '0',
  },
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(180deg)'
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(0)'
  },
}))

const Accordion = styled(MuiAccordion)(({ theme }) => ({
  background: '#F1F1F1',
  '&.Mui-expanded': {
     margin: '0'
  },
  '&:before': {
    display: 'none'
  },
  '&:last-of-type': {
    borderRadius: '0.98rem',
  }
}))

const CategoryCard = (props: Props) => {
  const texts = useTranslation().t
  const { values, setFieldValue } = useFormikContext<MenuFormData>()
  const cardOptions: MenuItemCardOption[] = texts('objects:menu_category_options', { returnObjects: true }) as MenuItemCardOption[]

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [openRemoveDialog, setOpenRemoveDialog] = useState<boolean>(false)
  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const [expandedCategory, setExpandedCategory] = useState<boolean>(false)
  const changeExpandStatus = (event: React.SyntheticEvent) => {
    setExpandedCategory(!expandedCategory)
  }

  const handleMenuItemClick = (type: MenuItemType) => {
    handleClose()
    switch (type) {
      case MenuItemType.REMOVE:
        setOpenRemoveDialog(true)
        break
      case MenuItemType.EDIT:
        setFieldValue('editCategory', props.category)
        props.onEdit()
        break
    }
  }

  return (
    <>
      <RemoveMenuItemDialog
        open={openRemoveDialog}
        onCloseButtonClick={() => {setOpenRemoveDialog(false)}}
        additionalOnFinish={() => {
          setFieldValue('items', removeArrayElement(values.items, values.items.findIndex(e => e.cardId === props.category.cardId)))
          setOpenRemoveDialog(false)
        }}
      />
      <AddProductDialog 
        open={openAddDialog}
        categoryCardId={props.category.cardId}
        onCloseButtonClick={() => {setOpenAddDialog(false)}}
      />
      <Grid
        container
        position={'relative'}
        marginTop={'0.938rem'}
        borderRadius={'0.938rem'}
        wrap={'nowrap'}
        flexDirection={'column'}
      >
        <Accordion expanded={expandedCategory} onChange={changeExpandStatus}>
          {
            props.loading
            ? <Grid
                item
                position={'absolute'}
                width={'100%'}
                height={'100%'}
                right={0}
                top={0}
                borderRadius={'0.938rem'}
                overflow={'hidden'}
                sx={{backgroundSize: 'cover'}}
              >
                <Loader
                  backgroundColor='rgba(0,0,0,0.08)'
                />
              </Grid>
            : null
          }
          <Grid
            gap={'0.938rem'}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              background: '#94C11F',
              borderRadius:'0.938rem',
              alignItems: 'center',
              paddingLeft: '0.98rem',
              color: '#FFFFFF',
              paddingTop: '0.5rem',
              paddingBottom: '0.5rem'
            }}
          >
            <Box {...props.provided?.dragHandleProps} sx={{ marginTop: '0.0625rem' }}>
              <DragHandleIcon />
            </Box>
            <AccordionSummary>
              <Grid
                item
                container
                flexDirection={'column'}
                alignItems={'center'}
                justifyContent={'space-between'}
                sx={{':hover': {cursor: 'pointer'}}}
              >
                <Grid 
                  item 
                  container
                  gap={'0.5rem'}
                  wrap='nowrap'
                >
                  <Typography 
                    variant='h2'
                    sx={{
                      fontSize: '1.125rem',
                      color: '#FFFFFF',
                      marginTop: '0.125rem'
                    }}
                  >
                    {props.category.name}
                  </Typography>
                  {
                    expandedCategory 
                    ? null 
                    : <Chip 
                        text={texts('menu:menu_value_of_products', { val: props.category.products?.length })} 
                        backgroundColor={'#FFFFFF'}
                        color={'#94C11F'}
                      />
                  }
                  <Typography
                    sx={{
                      fontSize: '1rem',
                      color: '#FFFFFF',
                      fontFamily: 'RobotoBold',
                      marginLeft: '0.625rem'
                    }}
                  >
                    {expandedCategory ? texts('menu:menu_collapse'): texts('menu:menu_expand')}
                  </Typography>
                </Grid> 
              </Grid>
            </AccordionSummary>
            <Grid 
              item
              sx={{
                marginRight: '1.125rem',
                marginLeft: 'auto'
              }}
            >
              <IconButton
                onClick={handleClick}
                sx={{color: '#FFFFFF'}}
              >
                <MoreHorizIcon/>
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                disableAutoFocusItem
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    minWidth: '12rem',
                  },
                }}
              >
                {
                  cardOptions.map((option) => (
                    <MenuItem 
                      key={option.value}                 
                      onClick={() => {
                        handleMenuItemClick(option.value)
                      }}
                    >
                      {option.label}
                    </MenuItem>
                  ))
                }
              </Menu>
            </Grid>
          </Grid>
          {expandedCategory && <DroppableList droppableId={`product:${props.category.uuid}`} boxDirection='vertical'>
            <Box
              sx={{padding:'0 1.25rem'}}
            >
              {
                props.category.products.map((product, index) => (
                  <DraggableItem id={`product:${props.category.cardId}:${product.cardId}`} index={index} key={product.cardId}>
                    <ProductCards 
                      key={product.cardId}
                      product={product}
                      categoryCardId={props.category.cardId}
                    />
                  </DraggableItem>
                ))
              }
            </Box>
          </DroppableList>}
          <Grid 
            item 
            container 
            wrap='nowrap' 
            alignItems={'center'}
            marginTop={'0.975rem'}
            paddingBottom={'0.975rem'}
            paddingLeft={'1.25rem'}
          >
            <MenuItem 
              sx={{ padding: "0" }}
              onClick={() => {
                handleClose()
                setOpenAddDialog(true)
              }}
            >
              <AiFillPlusCircle 
              size={30}
              color={'#94C11F'}/>
              <Typography
                variant='body2' 
                sx={{
                  fontFamily: 'RobotoBold',
                  marginLeft: '1.25rem',
                  paddingTop: '0.188rem',
                  color: '#94C11F'
                }}
              >
                {texts('menu:menu_add_product')}
              </Typography>
            </MenuItem>
          </Grid>
        </Accordion>
      </Grid>
    </>
  )
}

export default CategoryCard