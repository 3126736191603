import { Form, Formik, FormikHelpers } from 'formik'
import { logInValidation } from '../../validations/logInValidation'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Divider, Grid, Typography } from '@mui/material'
import TextField from '../../components/CustomMui/Fields/TextField'
import { LoginDto } from '../../api/auth/types'
import useLogIn from '../../hooks/auth/useLogIn'
import PasswordField from '../../components/CustomMui/Fields/PasswordField'

const LogInPage = () => {
  const texts = useTranslation().t

  const mutation = useLogIn()

  const handleSubmitLogin = async (data: LoginDto, formikHelpers: FormikHelpers<LoginDto>) => {
    mutation.mutate(data, {
      onError:() => {
        formikHelpers.setFieldValue('password', '')
      }
    })
	}

  return (
    <Grid 
      container 
      direction={'column'} 
      justifyContent={'center'} 
      alignItems={'center'} 
      height={'calc(100vh - 130px)'}
    >
      <Typography 
        variant='h1' 
        sx={{marginBottom: '4.5rem'}}
      > 
        {texts('auth:login_title')}
      </Typography>
      <Formik<LoginDto>
        initialValues={{ login: '', password: '' }}
        enableReinitialize
        onSubmit={handleSubmitLogin}
        validationSchema={logInValidation(texts)}
      >
        {(formikProps) => (
          <Box width={'30rem'}>
            <Form>
              <Box>
                <TextField
                  name='login'
                  type='email'
                  label={texts('auth:email_address')}
                />
              </Box>
              <Box>
                <PasswordField
                  name='password'
                  label={texts('auth:password')} 
                />
              </Box>
              <Grid 
                container 
                justifyContent={'flex-end'} 
                sx={{marginBottom: '1rem'}}
              >
                <Typography variant='h5'>
                  {texts('auth:password_forgotten')}
                </Typography>
                <Link to='/password/reset/request'>
                  <Typography 
                    variant='h5' 
                    sx={{
                      color: '#94C11F', 
                      textDecoration: 'underline', 
                      marginLeft: '0.25rem'
                    }}
                  >
                      {texts('auth:reset_password_link')}
                  </Typography>
                </Link>
              </Grid>
              <Grid 
                container 
                justifyContent={'flex-end'}
              >
                <LoadingButton
                  type='submit'
                  variant={'contained'}
                  size={'large'}
                  disabled={
                    !formikProps.isValid
                  }
                  loading={mutation.isLoading}
                >
                  {texts('auth:login_button')}
                </LoadingButton>
              </Grid>
              <Divider 
                sx={{
                  marginTop: '1.875rem', 
                  marginBottom: '0.313rem'
                }}
              />
              <Grid 
                container
                justifyContent={'center'}
              >
                <Typography variant='h5'>
                  {texts('auth:resend_activation_email_text')}
                </Typography>
                <Link to="/account/activation/resend">
                  <Typography 
                    variant='h5' 
                    sx={{
                      color: '#94C11F', 
                      textDecoration: 'underline', 
                      marginLeft: '0.25rem'
                    }}
                  >
                    {texts('auth:resend_activation_email_link')}
                  </Typography>
                </Link>
              </Grid>
            </Form>
          </Box>
        )}
      </Formik>
    </Grid>
  )
}

export default LogInPage
