import { Namespace, TFuncKey } from 'i18next'
import { AuthSubcodes, Codes, DataSubcodes, Page } from './consts'

export function mapHttpErrorMessage(e: any, page?: Page): TFuncKey<Namespace> {
    const code = e.response?.data?.code
    const subcode = e.response?.data?.subcode

    if (code === Codes.AUTH) {
      return handleAuthSubcodes(subcode, page)
    } else if (code === Codes.DATA) {
      return handleDataSubcodes(subcode, page)
    }

    return 'errors:something_went_wrong'
}

function handleAuthSubcodes(subcode: AuthSubcodes, page?: Page): TFuncKey<Namespace> {
  let errorMessage: TFuncKey<Namespace> = 'errors:something_went_wrong'

  switch(subcode) {
    case AuthSubcodes.INVALID_TOKEN:
      errorMessage = 'errors:invalid_token'
      break
    case AuthSubcodes.TOKEN_ALREADY_USED:
      errorMessage = 'errors:token_already_used'
      break
    case AuthSubcodes.USER_INACTIVE:
      errorMessage = 'errors:user_inactive'
      break
    case AuthSubcodes.UNAUTHORIZED:
      errorMessage = 'errors:unauthorized'
      break
    case AuthSubcodes.ACCOUNT_REMOVED:
      errorMessage = 'errors:account_removed'
      break
    case AuthSubcodes.INCORRECT_PASSWORD:
      errorMessage = 'errors:invalid_password'
      break
    case AuthSubcodes.SAME_PASSWORD_AS_PREVIOUS_ONE:
      errorMessage = 'errors:same_password_as_previous_one'
      break
    case AuthSubcodes.INVALID_CREDENTIALS:
      errorMessage = 'errors:invalid_credentials'
      break
    case AuthSubcodes.FORBIDDEN:
      errorMessage = 'errors:access_forbidden'
      break
  }

  return errorMessage
}

function handleDataSubcodes(subcode: DataSubcodes, page?: Page): TFuncKey<Namespace> {
  let errorMessage: TFuncKey<Namespace> = 'errors:something_went_wrong'

  switch(subcode) {
    case DataSubcodes.EMAIL_TAKEN:
      errorMessage = 'errors:email_taken'
      break
    case DataSubcodes.BAD_REQUEST:
      errorMessage = 'errors:something_went_wrong'
      break
    case DataSubcodes.DUPLICATE_ADDITION:
      errorMessage = 'errors:duplicate_addition'
      break
    case DataSubcodes.DUPLICATE_PRODUCT:
      errorMessage = 'errors:duplicate_product'
      break
    case DataSubcodes.DUPLICATE_VARIANT:
      errorMessage = 'errors:duplicate_variant'
      break
    case DataSubcodes.NON_UNIQUE_PAYU_CREDENTIALS:
      errorMessage = 'errors:not_unique_payu_credentials'
      break
    case DataSubcodes.TAG_NAME_TAKEN:
      errorMessage = 'errors:tag_name_taken'
      break
    case DataSubcodes.NAME_TAKEN:
      errorMessage = 'errors:name_taken'
      break
    case DataSubcodes.DUPLICATE_CATEGORY:
      errorMessage = 'errors:duplicate_category'
      break
    case DataSubcodes.INVALID_IMAGE_DIMENSIONS:
      errorMessage = 'errors:image_too_small'
      break
    case DataSubcodes.INCORRECT_FILE_FORMAT:
      errorMessage = 'errors:incorrect_file_type_image'
      break
    case DataSubcodes.FILE_TOO_LARGE:
      errorMessage = 'errors:image_size_too_large'
      break
    case DataSubcodes.ACTIVE_ORDERS_LEFT:
      errorMessage = 'errors:active_orders_left'
      break
    case DataSubcodes.TAG_NOT_FOUND:
      if (page === Page.PRODUCT_PAGE) {
        errorMessage = 'errors:product_page_tag_not_found'
      } else {
        errorMessage = 'errors:tag_not_found'
      }
      break
    case DataSubcodes.CATEGORY_NOT_FOUND:
      if (page === Page.CREATE_MENU) {
        errorMessage = 'errors:create_menu_category_not_found'
      } else {
        errorMessage = 'errors:category_not_found'
      }
      break  
    case DataSubcodes.PRODUCT_NOT_FOUND:
    case DataSubcodes.PRODUCT_TEMPLATE_NOT_FOUND:
      if (page === Page.CREATE_MENU) {
        errorMessage = 'errors:create_menu_product_not_found'
      } else if (page === Page.AVAILABILITYT_MANAGEMENT) {
        errorMessage = 'errors:availability_management_product_not_found'
      } else {
        errorMessage = 'errors:product_not_found'
      }
      break  
    case DataSubcodes.ADDITION_NOT_FOUND:
    case DataSubcodes.ADDITION_TEMPLATE_NOT_FOUND:
      if (page === Page.CREATE_MENU) {
        errorMessage = 'errors:create_menu_addition_not_found'
      } else if (page === Page.AVAILABILITYT_MANAGEMENT) {
        errorMessage = 'errors:availability_management_addition_not_found'
      } else {
        errorMessage = 'errors:addition_not_found'
      }
      break  
    case DataSubcodes.VARIANT_NOT_FOUND:
    case DataSubcodes.VARIANT_TEMPLATE_NOT_FOUND:
      if (page === Page.CREATE_MENU) {
        errorMessage = 'errors:create_menu_variant_not_found'
      } else {
        errorMessage = 'errors:variant_not_found'
      }
      break  
    case DataSubcodes.VARIANT_OPTION_NOT_FOUND:
    case DataSubcodes.VARIANT_OPTION_TEMPLATE_NOT_FOUND:
      if (page === Page.CREATE_MENU) {
        errorMessage = 'errors:create_menu_variant_option_not_found'
      } else if (page === Page.AVAILABILITYT_MANAGEMENT) {
        errorMessage = 'errors:availability_management_variant_option_not_found'
      } else {
        errorMessage = 'errors:variant_option_not_found'
      }
      break
    case DataSubcodes.LOYALTY_CARD_CODE_TAKEN:
      errorMessage = 'errors:loyalty_card_code_taken'
      break  
  }

  return errorMessage
}