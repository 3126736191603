import * as Yup from 'yup'

export function addProductValidation(t: (val: string) => string) {
  return Yup.object({
    name: Yup.string()
      .trim()
      .max(120, t('validations:max_120_signs_validation'))
      .required(t('validations:field_required')),
    description: Yup.string()
      .max(240, t('validations:max_240_signs_validation')),
    note: Yup.string()
      .max(120, t('validations:max_120_signs_validation')),
    // basePrice: Yup.number().nullable().required(t('validations:field_required')),
    // baseTime: Yup.number().nullable().required(t('validations:field_required'))
  })
}