import { Grid, IconButton, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ChangeState } from '../../../types/place'
import { generate } from 'short-uuid'
import React from 'react'
import Dialog from '../../CustomMui/Dialog'
import { AiFillPlusCircle } from 'react-icons/ai'
import { ArrayHelpers, Field, FieldArray, useFormikContext, FieldProps, FieldArrayRenderProps, getIn } from 'formik'
import Row from './Row'
import { VariantOptionFormData } from '../../../types/menu'
import { DragDropContext, DropResult } from '@hello-pangea/dnd'
import DroppableList from '../../Common/DroppableList'
import moment from 'moment'
import { getErrorForNestedObject, getRandomInt } from '../../../utils/common'
import DraggableItem from '../../Common/DraggableItem'
import { reorder } from '../../../utils/array'
import { useState } from 'react'

type Props = {
  name: string
}

const isError = (values: VariantOptionFormData[], value: VariantOptionFormData): string | null => {
  if (!value.name.trim()) {
    return 'validations:name_required'
  } if (value.name.length > 32) {
    return 'validations:max_32_signs_validation'
  }
  return null
}

const getEditedRowIndex = (values: VariantOptionFormData[]) => {
  return values.findIndex((change: VariantOptionFormData) => change.state !== ChangeState.SAVED)
}

const resetRowState = (values: VariantOptionFormData[], arrayHelpers: ArrayHelpers, idx: number) => {
  const currentState = values[idx].state
  if (currentState === ChangeState.EDITED) {
    values[idx].state = ChangeState.SAVED
    arrayHelpers.replace(idx, values[idx])
  } else {
    arrayHelpers.remove(idx)
  }
}

const onAdd = (arrayHelpers: ArrayHelpers) => {
  const newElement: VariantOptionFormData = {
    id: `variant-option-${moment().unix()}-${getRandomInt(0, 10000)}`,
    name: '',
    additionalPrice: null,
    additionalTime: null,
    defaultOption: false,
    key: generate(),
    state: ChangeState.INIT
  }
  arrayHelpers.push(newElement)
}

const VariantOptionsForm = (props: Props) => {
  const texts = useTranslation().t
  const [removeIndex, setRemoveIndex] = useState<number | null>(null)
  const { values, setFieldValue, setFieldError, errors } = useFormikContext<{options: VariantOptionFormData[]}>()
  const arrayError = typeof getIn(errors, props.name) === 'string' ? getIn(errors, props.name) : undefined

  const onDragEnd = (result : DropResult) => {
    if (!result.destination) {
      return
    }
    const children: VariantOptionFormData[] = reorder(
      values.options, result.source.index, result.destination.index
    )
    setFieldValue('options', children)
  }

  const onCancel = (arrayHelpers: ArrayHelpers, index: number) => {
    const value = values.options[index]
    if (value.state === ChangeState.INIT) {
      return arrayHelpers.remove(index)
    }
    value.state = ChangeState.SAVED
    arrayHelpers.replace(index, value)
    setFieldError(`${props.name}.${index}`, undefined) // only for cancel editing
  }

  const onEdit = (arrayHelpers: ArrayHelpers, index: number) => {
    const value = values.options[index]
    const editedRowId = getEditedRowIndex(values.options)
    if (editedRowId !== -1) {
      resetRowState(values.options, arrayHelpers, editedRowId)
    }
    value.state = ChangeState.EDITED
    arrayHelpers.replace(index, value)
  }

  const onSave = (value: VariantOptionFormData, index: number) => {
    const error = isError(values.options, value)
    if (error) {
      return setFieldError(`${props.name}.${index}`, error)
    }
    values.options[index] = {
      ...value, 
      state: ChangeState.SAVED,
    }
    setFieldValue(props.name, values.options)
  }

  return (
    <Grid container direction={'column'}>
      <Typography variant='subtitle2' sx={{my: '1rem'}}>{texts('variants:options')}</Typography>
        <FieldArray
          name={props.name}
          validateOnChange={true}
          render={(arrayProps: FieldArrayRenderProps) => (
            <>
              <Dialog
                open={removeIndex !== null}
                title={texts('dialogs:remove_title')}
                description={texts('dialogs:remove_description')}
                warning={texts('dialogs:variant_option_remove_warning')}
                closeButtonLabel={texts('common:cancel')}
                confirmButtonLabel={texts('common:remove')}
                onCloseButtonClick={() => {
                  setRemoveIndex(null)
                }}
                onConfirmButtonClick={() => {
                  arrayProps.remove(removeIndex!)
                  setRemoveIndex(null)
                }}
              />
              <DragDropContext onDragEnd={onDragEnd}>
                <DroppableList droppableId={'variant-options'} boxDirection='vertical'>
                  <Grid
                    container
                    flexDirection={'column'}
                    minHeight={`${values.options.length * 5}rem`}
                  >
                    {
                      values.options.map((val, index) => (
                        <Field
                          name={`${props.name}.${index}`}
                          key={`key-${index}`}
                          validate={(value?: VariantOptionFormData) => {
                            if (value) {
                              return (
                                value.state !== ChangeState.SAVED
                                  ? 'errors:save_field_before_submit'
                                  : null
                              ) // ?? checkDateRanges(values.openingHoursExceptions, value) 
                            }
                            return null
                          }}
                        >
                          {({ meta }: FieldProps) => (
                            <DraggableItem
                              id={val.id}
                              index={index}
                              key={val.id}
                            >
                              <Row
                                meta={meta}
                                key={`key-${index}`}
                                value={val}
                                error={getErrorForNestedObject(`${props.name}.${index}`, errors, meta.error)}
                                onRemove={() => {
                                  setRemoveIndex(index)
                                }}
                                onCancel={() => onCancel(arrayProps, index)}
                                onEdit={() => onEdit(arrayProps, index)}
                                onSave={(value) => onSave(value, index)}
                              />
                            </DraggableItem>
                          )}
                        </Field>
                      ))
                    }
                  </Grid>
                  {
                arrayError
                  && <Typography 
                    variant='body2'
                    sx={{
                      fontFamily: 'RobotoCondensed',
                      color: 'red',
                      marginBottom: '1rem',
                      marginLeft: '0.5rem',
                      textTransform: 'none'
                    }}
                  >
                    {texts(arrayError)}
                  </Typography>
              }
                  <Grid 
                    item 
                    container 
                    wrap='nowrap' 
                    alignItems={'center'} 
                  >
                    <IconButton
                      onClick={() => onAdd(arrayProps)}  
                      sx={{
                        minWidth: 0,
                        color: 'secondary.light'
                      }}
                      disabled={getEditedRowIndex(values.options) !== -1 || values.options.length > 24}
                      disableRipple
                    >
                      <AiFillPlusCircle size={30}/>
                      <Typography 
                        variant='body2' 
                        sx={{
                          fontFamily: 'RobotoCondensedBold',
                          marginLeft: '0.5rem',
                          color: getEditedRowIndex(values.options) !== -1 ? 'rgba(0, 0, 0, 0.26)' : 'secondary.light'
                        }}
                      >
                        {texts('variants:add_option')}
                      </Typography>
                    </IconButton>
                  </Grid>
                </DroppableList>
              </DragDropContext>
            </>
          )}
        />
    </Grid>
  )
}

export default VariantOptionsForm