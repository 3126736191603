import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import DataApi from '../../../api/data/DataApi'
import { mapVariantTemplateData } from '../../../api/data/mapping'
import { HttpError, Success } from '../../../types/common'
import { VariantFormData, VariantList, VariantTemplateDto } from '../../../types/menu'
import { showErrorToast } from '../../../utils/error'
import { mapVariantTemplateDetailsDto, mapVariantTemplateDetailsDtoToForm } from '../../../utils/mapping'
import { queryNames } from '../../queries'
import { Codes, DataSubcodes } from '../../../services/errors/consts'
import { AxiosError } from 'axios'

const dataApi = DataApi.getInstance()

type MutationParams = {
  uuid: string
  data: VariantFormData
}

export default function useEditVariantTemplate(onNotFoundError?: () => void) {
  const queryClient = useQueryClient()
  const texts = useTranslation().t
  const mutation = useMutation((params: MutationParams) => dataApi.editVariantTemplate(params.uuid, mapVariantTemplateData(params.data)), {
    onError: (e: AxiosError<HttpError>, variables: MutationParams) => {
      if (e.response?.status === 404 && e.response?.data.code === Codes.DATA && e.response?.data.subcode === DataSubcodes.VARIANT_TEMPLATE_NOT_FOUND) {
        queryClient.resetQueries(queryNames.placeMenu)
        queryClient.resetQueries(queryNames.variantList)
        queryClient.resetQueries(queryNames.variantAvailabilityList)
        queryClient.removeQueries([queryNames.variantDetails, variables.uuid])
        onNotFoundError?.()
      } else if (e.response?.status === 404 && e.response?.data.code === Codes.DATA && e.response?.data.subcode === DataSubcodes.VARIANT_OPTION_TEMPLATE_NOT_FOUND) {
        queryClient.resetQueries([queryNames.variantDetails, variables.uuid])
        queryClient.resetQueries(queryNames.placeMenu)
        queryClient.resetQueries(queryNames.variantList)
        queryClient.resetQueries(queryNames.variantAvailabilityList)
      }
      showErrorToast(e, texts)
    },
    onSuccess: (data: Success<VariantTemplateDto>, variables: MutationParams) => {
      const queriesData = queryClient.getQueriesData<VariantList>(queryNames.variantList)
      for (const queryData of queriesData) {
        if (queryData[1] != null && (queryData[1].data?.length ?? 0) > 0) {
          const index = queryData[1].data?.findIndex(p => p.uuid === variables.uuid)
          if (index !== -1) {
            queryData[1].data[index] = mapVariantTemplateDetailsDto(data.data!)
            queryClient.setQueryData(queryData[0], queryData[1])
          }
        }
      }
      queryClient.resetQueries(queryNames.placeMenu)
      queryClient.resetQueries(queryNames.variantAvailabilityList)
      queryClient.setQueryData([queryNames.variantDetails, variables.uuid], mapVariantTemplateDetailsDtoToForm(data.data!))
      toast.success(texts('successes:variant_edited'))
    }
  })
  return mutation
}