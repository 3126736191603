import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import TimerIcon from '@mui/icons-material/Timer'
import { Grid, Typography } from '@mui/material'
import currency from 'currency.js'
import { useTranslation } from 'react-i18next'
import { Product } from '../../../types/menu'
import { mapSecondsToString } from '../../../utils/mapping'
import Chip from '../../Common/Chip'
import Tag from '../../Common/Tag'

type Props = {
  product: Product
  onClick?: () => void
}

const ProductsListRowDetails = (props: Props) => {
  const texts = useTranslation().t

  return (
    <Grid
      item
      container
      gap={'0.375rem'}
      flexDirection={'column'}
      {...(
        props.onClick != null
        ? {
            sx: {':hover': {cursor: 'pointer'}},
            onClick: props.onClick
          }
        : {}
        )
      }
    >
      <Grid 
        item 
        container
        gap={'0.5rem'}
        wrap='nowrap'
      >
        <Typography 
          variant='h2'
          sx={{fontSize: '1.125rem'}}
        >
          {props.product.name}
        </Typography>
        {
          props.product.tags.map((tag) => (
            <Chip
              key={tag.uuid}
              text={tag.name}
              uppercase
            />
          ))
        }
      </Grid>
      <Grid item>
        <Typography 
          variant='body2'
          sx={{textTransform: 'none'}}
        >
          {props.product.description}
        </Typography>
      </Grid>
      <Grid 
        item 
        container
        gap={'0.5rem'}
      >
        <Tag
          text={`${mapSecondsToString(props.product.baseTime)}`}
          opacity={props.product.baseTime === 0}
        >
          <TimerIcon/>
        </Tag>
        <Tag
            text={texts('common:currency', { localValue: currency(props.product.basePrice, { fromCents: true }).value })}
            opacity={props.product.basePrice === 0}
        >
          <LocalOfferIcon/>
        </Tag>
      </Grid>
      {
        props.product.note
        ? <Grid item>
            <Typography 
              variant='body2'
              sx={{
                textTransform: 'none',
                fontStyle: 'italic'
              }}
            >
              {texts('menu:note', {val: props.product.note})}
            </Typography>
          </Grid>
        : null
      }
    </Grid>
  )
}

export default ProductsListRowDetails