import * as Yup from 'yup'


export function reportProblemValidation(t: (val: string) => string) {
  return Yup.object({
    question: Yup.string()
      .max(120, t('validations:max_120_signs_validation'))
      .required(t('validations:field_required')),
    description: Yup.string()
      .max(560, t('validations:max_560_signs_validation'))
      .required(t('validations:field_required')),
  })
}