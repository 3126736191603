import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import DataApi from '../../api/data/DataApi'
import { showErrorToast } from '../../utils/error'
import { queryNames } from '../queries'
import { DataSubcodes, Page, Codes } from '../../services/errors/consts'
import { AxiosError } from 'axios'
import { HttpError } from '../../types/common'

const dataApi = DataApi.getInstance()

type MutationParams = {
  placeUuid: string
  templateUuids: string[]
}

const errorRefreshSubcodes = [
  DataSubcodes.VARIANT_OPTION_NOT_FOUND,
  DataSubcodes.VARIANT_OPTION_TEMPLATE_NOT_FOUND
]

export default function useActivateVariantOptions(onSuccess: () => void) {
  const queryClient = useQueryClient()
  const texts = useTranslation().t
  const mutation = useMutation((params: MutationParams) => dataApi.activateVariantOptions(params.placeUuid, {
      templateUuids: params.templateUuids
    }), {
    onError: (e: AxiosError<HttpError>) => {
      if (e.response?.status === 404 && e.response?.data.code === Codes.DATA && errorRefreshSubcodes.includes(e.response?.data.subcode)) {
        queryClient.resetQueries(queryNames.variantAvailabilityList)
      }
      showErrorToast(e, texts, Page.AVAILABILITYT_MANAGEMENT)
    },
    onSuccess: () => {
      queryClient.resetQueries(queryNames.placeMenu)
      queryClient.resetQueries(queryNames.variantAvailabilityList)
      toast.success(texts('successes:variant_options_activated_successfully'))
      onSuccess()
    }
  })
  return mutation
}