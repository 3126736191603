import { Grid, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'
import Loader from '../../../components/Common/Loader'
import NetworkError from '../../../components/Common/NetworkError'
import FormikExternalAutocomplete from '../../../components/CustomMui/Fields/FormikExternalAutocomplete'
import NumericTextField from '../../../components/CustomMui/Fields/NumericTextField'
import TextField from '../../../components/CustomMui/Fields/TextField'
import HeaderBar from '../../../components/Discount/Codes/Page/HeaderBar'
import useSearchUsers from '../../../hooks/accounts/useSearchUsers'
import useCreateLoyaltyCard from '../../../hooks/loyaltyCard/useCreateLoyaltyCard'
import useEditLoyaltyCard from '../../../hooks/loyaltyCard/useEditLoyaltyCard'
import useGetLoyaltyCardDetails from '../../../hooks/loyaltyCard/useGetLoyaltyCardDetails'
import { queryNames } from '../../../hooks/queries'
import { AutocompleteBaseOption } from '../../../types/common'
import { DiscountCodeFormData } from '../../../types/discounts'
import { mapDiscountCodeDetailsToForm, mapDiscountCodeFormData, mapDiscountCodeFormDataForEdit, mapUserSearchDtoList } from '../../../utils/mapping'
import { discountCodeValidation } from '../../../validations/discountCodeValidation'

type Props = {
  isEditDiscountCode: boolean
}

const defaultInitValue: DiscountCodeFormData = {
  user: null,
  percentage: null,
  code: ''
}

const DiscountCodePage = (props: Props) => {
  const queryClient = useQueryClient()
  const texts = useTranslation().t
  const navigate = useNavigate()
  const { uuid } = useParams<{ uuid: string }>()
  const goBack = () => { navigate(-1) }

  const [userSearch, setUserSearch] = useState<string>('')

  const userList = useSearchUsers(userSearch, props.isEditDiscountCode)
  const details = useGetLoyaltyCardDetails(uuid)

  const createMutation = useCreateLoyaltyCard(() => {
    goBack()
  })
  const editMutation = useEditLoyaltyCard()

  const debounceUserSearch = useDebouncedCallback((value) => {
    setUserSearch(value)
    queryClient.resetQueries([queryNames.searchUsers, value, props.isEditDiscountCode])
  }, 300)

  const handleSubmit = (data: DiscountCodeFormData) => {
    if (props.isEditDiscountCode) {
      editMutation.mutate({ uuid: uuid!, data: mapDiscountCodeFormDataForEdit(data) })
    } else {
      createMutation.mutate({ data: mapDiscountCodeFormData(data) })
    }
  }

  return (
    <>
      {
        details.isError || details.isFetching
        ? <Grid sx={{height: 'calc(100vh - 160px)'}}>
          {
            details.isError
            ? <NetworkError
                withRefresh
                onRefresh={() => {
                  queryClient.resetQueries([queryNames.getLoyaltyCardDetails, uuid])
                }}
              />
            : <Loader 
                width={'100%'}
                text={texts('common:loading')}
              />
          }
          </Grid>
        : <Formik<DiscountCodeFormData>
            initialValues={props.isEditDiscountCode ? mapDiscountCodeDetailsToForm(details.data!) : defaultInitValue}
            enableReinitialize
            validateOnBlur={true}
            validateOnChange={true}
            onSubmit={handleSubmit}
            validationSchema={discountCodeValidation(texts)}
          >
            {(formikProps) => (
              <Form>
                <Grid 
                  container 
                  direction={'column'}
                  rowGap={'0.125rem'}
                >
                  <HeaderBar
                    isEdit={props.isEditDiscountCode}
                    loading={createMutation.isLoading || editMutation.isLoading}
                    onBack={() => { goBack() }}
                  />
                  <Grid 
                    item 
                    container
                    sx={{
                      width: '60%',
                      marginTop: '2rem'
                    }}
                    gap={'1rem'}
                    flexWrap={'nowrap'}
                  >
                    <Grid
                      item
                      width={'60%'}
                    >
                      <FormikExternalAutocomplete<AutocompleteBaseOption>
                        name='user'
                        disabled={props.isEditDiscountCode}
                        options={mapUserSearchDtoList(userList.data ?? [])}
                        label={texts('discountCodes:user_field_label')}
                        placeholder={texts('discountCodes:user_field_placeholder')}
                        shrinkLabel
                        loading={userList.isFetching}
                        onInputChange={(event, value) => {
                          debounceUserSearch(value)
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      container
                      width={'20%'}
                      flexWrap={'nowrap'}
                      alignItems={'center'}
                      gap={'0.5rem'}
                    >
                      <NumericTextField
                        name='percentage'
                        integerOnly
                        value={formikProps.values.percentage}
                        onChange={(e) => {
                          const value = e.target.value === '' ? null : Number.parseFloat(e.target.value)
                          formikProps.setFieldValue(
                            'percentage',
                            value
                          )
                        }}
                        label={texts('discountCodes:discount_value_field_label')}
                      />
                      <Typography 
                        variant='body2'
                        sx={{textTransform: 'none', marginBottom: '1.75rem'}}
                      >
                        {texts('common:percent')}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      width={'20%'}
                    >
                      <TextField
                        name='code'
                        label={texts('discountCodes:discount_code_label')}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
      }
    </>
  )
}

export default DiscountCodePage
