import { TimePicker } from '@mui/x-date-pickers-pro'
import { Button, Grid, IconButton, TextField, Typography } from '@mui/material'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { AiFillCloseCircle } from 'react-icons/ai'
import SelectField from './SelectField'
import weekDays from 'i18n-week-days'
import { ChangeState, ExtraTimeBufferForRushHours } from '../../../types/place'
import NumericTextField from '../../Common/NumericTextField'
import  { useMemo, useState, useEffect } from 'react'

const TIME_FORMAT = 'HH:mm'

type Props = {
  value: ExtraTimeBufferForRushHours
  rowError?: string
  disabledEdit?: boolean
  onRemove: () => void
  onSave: (value: ExtraTimeBufferForRushHours) => void
  onEdit: () => void
  onCancel: () => void
}

const calculateDuration = (value: ExtraTimeBufferForRushHours) => {
  const startTime = moment(value.start, TIME_FORMAT)
  const endTime = moment(value.end, TIME_FORMAT)

  let minutes: number = 0
  if (startTime.isAfter(endTime)) {
    minutes = 24 * 60 - moment(startTime).diff(endTime, 'minute')
  } else {
    minutes = moment(endTime).diff(startTime, 'minute')
  }

  return {
    hours: Math.floor(minutes / 60),
    minutes: minutes % 60
  }
}

const Row = (props: Props) => {
  const texts = useTranslation().t
  const [value, setValue] = useState<ExtraTimeBufferForRushHours>(props.value)
  const [fromError, setFromError] = useState<string | null>(null)
  const [untilError, setUntilError] = useState<string | null>(null)

  const duration = useMemo(() => calculateDuration(value), [value])

  useEffect(() => {
    setValue(props.value)
  }, [props.value, props.value.state])

  return (
    <>
      <Grid
        container 
        alignItems={'center'}
        wrap={'nowrap'}
        marginBottom={'.5rem'}
      >
        {
          props.disabledEdit
          ? null
          : <IconButton
              onClick={props.onRemove}
              color={'primary'}
              sx={{
                minWidth: 0,
                marginRight: '0.5rem',
                visibility: value.state === ChangeState.INIT ? 'hidden' : 'visible',
                paddingBottom: '1.75rem'
              }}
            >
              <AiFillCloseCircle size={30}/>
            </IconButton>
        }
        
        <Grid
          item
          container
          wrap='nowrap'
          gap='1.5rem'
        >
          <Grid 
            item
            sx={{
              minWidth: '10rem', 
              maxWidth: '12.5rem',
              paddingBottom: '1.375rem'
            }}
          > 
            <SelectField
              label={texts('place:day_of_week')}
              disabled={value.state === ChangeState.SAVED}     
              options={weekDays['pl']}
              value={value.day?.toString() ?? ''}
              handleChange={(event) => {
                setValue({
                  ...value,
                  day: +event.target.value
                })
              }}
            />
          </Grid>
          <Grid 
            item
            sx={{
              minWidth: '6.75rem',
              maxWidth: '12.5rem',
              paddingBottom: !fromError ? '1.375rem' : 0
            }}
          > 
            <TimePicker
              label={texts('common:from')}
              value={moment(value.start, TIME_FORMAT).toDate()}
              onChange={time => {
                if (time) {
                  if (!moment(time, 'HH:mm').isValid()) {
                    setFromError(texts('validations:invalid_value'))
                  } else {
                    setFromError(null)
                  }
                  setValue({
                    ...value,
                    start: moment(time).format(TIME_FORMAT)
                  })
                }
              }} 
              renderInput={({ inputProps, ...restParams }) => 
                <TextField 
                  {...restParams}
                  error={!!fromError}
                  helperText={fromError}
                  inputProps={{
                    ...inputProps,
                    placeholder: texts('common:timeInputPlaceholder'),
                  }}
                />
              }
              ampm={false}       
              disabled={value.state === ChangeState.SAVED}
            />
          </Grid>
          <Grid 
            item
            sx={{
              minWidth: '6.75rem',
              maxWidth: '12.5rem',
              paddingBottom: !untilError ? '1.375rem' : 0
            }}
          > 
            <TimePicker
              label={texts('common:until')}
              value={moment(value.end, TIME_FORMAT).toDate()}
              onChange={time => {
                if (time) {
                  if (!moment(time, 'HH:mm').isValid()) {
                    setUntilError(texts('validations:invalid_value'))
                  } else {
                    setUntilError(null)
                  }
                  setValue({
                    ...value,
                    end: moment(time).format(TIME_FORMAT)
                  })
                }
              }} 
              renderInput={({ inputProps, ...restParams }) => 
                <TextField 
                  {...restParams}
                  error={!!untilError}
                  helperText={untilError}
                  inputProps={{
                    ...inputProps,
                    placeholder: texts('common:timeInputPlaceholder'),
                  }}
                />
              }
              ampm={false}    
              disabled={value.state === ChangeState.SAVED}     
            />
          </Grid>
          <Grid 
            item
            container
            sx={{
              maxWidth: '13.5rem',
              paddingBottom: '1.375rem'
            }}
            alignItems={'center'}
          > 
            <Typography
              variant='body1'
              noWrap
            >
              {
                untilError || fromError
                ? texts('place:number_of_hours', { hours: ' - '})
                : texts(
                    duration.minutes > 0 ? 'place:number_of_hours_and_minutes' : 'place:number_of_hours', 
                    { hours: duration.hours, minutes: duration.minutes }
                  )
              } 
            </Typography>
          </Grid>
          <Grid 
            item
            container
            alignItems={'center'}
            gap={'0.25rem'}
            wrap='nowrap'
            sx={{
              minWidth: '8.125rem',
              maxWidth: '10rem',
              paddingBottom: '1.375rem'
            }}
          >
            <NumericTextField
              label={texts('place:time_extension_label')}
              placeholder={texts('common:percentage_hint')}
              value={value.value}
              noDefaultHelperText
              shrinkLabel
              onChange={(e) => {
                const percentValue = e.target.value === '' ? null : Number.parseFloat(e.target.value)
                setValue({
                  ...value,
                  value: percentValue
                })
              }}
              disabled={value.state === ChangeState.SAVED}
            />
            <Typography variant='body2'>%</Typography>
          </Grid>
          {
            props.disabledEdit
            ? null
            : <Grid 
                item
                container
                alignItems={'center'}
                sx={{
                  maxWidth: '19.25rem',
                  paddingBottom: '1.375rem'
                }}
              >  
                {
                  value.state === ChangeState.SAVED
                  ? <Grid
                      item
                      sx={{maxWidth: '19.25rem'}}
                    >
                      <Button
                        variant={'outlined'}
                        size={'small'}
                        onClick={props.onEdit}
                      >
                        {texts('common:edit')}
                      </Button>
                    </Grid> 
                  : <Grid 
                      item 
                      container
                      wrap='nowrap'
                      gap={'0.5rem'}
                      sx={{maxWidth: '19.25rem'}}
                    >
                      <Button
                        variant={'contained'}
                        size={'small'}
                        onClick={() => { 
                          if (untilError || fromError) {
                            return
                          }
                          props.onSave({
                            ...value,
                            state: ChangeState.SAVED
                          })
                        }}
                      >
                        {texts('common:save')}
                      </Button>
                      <Button
                        variant={'outlined'}
                        size={'small'}
                        sx={{
                          minWidth: '6.25rem', 
                          maxWidth: '6.25rem'
                        }}
                        onClick={() => {
                          setFromError(null)
                          setUntilError(null)
                          props.onCancel()
                        }}
                      >
                        {texts('common:cancel')}
                      </Button>
                    </Grid>
                }
              </Grid>
          }
          
        </Grid>
      </Grid>
      {
        props.rowError != null
        ? <Typography 
            variant='body2'
            sx={{
              fontFamily: 'RobotoCondensed',
              color: '#d32f2f',
              marginTop: '0.188rem',
              marginLeft: '3.75rem',
              marginBottom: '.5rem',
              textTransform: 'none'
            }}
          >
            {texts((props.rowError as any)!)}
          </Typography>
        : null
      }
    </>
  )
}


export default Row