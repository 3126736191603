import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import DataApi from '../../api/data/DataApi'
import { updateUserName } from '../../redux/storageToolkit'
import store from '../../redux/store'
import { getUserUuid } from '../../services/storage/storageService'
import { AccountListData, CmsUserDetailsDto, EditCmsUserBodyDto } from '../../types/accounts'
import { Success } from '../../types/common'
import { showErrorToast } from '../../utils/error'
import { mapCmsUserDetailsDto } from '../../utils/mapping'
import { queryNames } from '../queries'

const dataApi = DataApi.getInstance()

type MutationParams = {
  uuid: string
  data: EditCmsUserBodyDto
}

export default function useEditCmsUser() {
  const queryClient = useQueryClient()
  const texts = useTranslation().t
  const mutation = useMutation((params: MutationParams) => dataApi.editCmsUserData(params.uuid, params.data), {
    onError: (e) => {
      showErrorToast(e, texts)
    },
    onSuccess: (data: Success<CmsUserDetailsDto>, variables: MutationParams) => {
      if (variables.uuid === getUserUuid()) {
        store.dispatch(updateUserName(data.data!.name))
      }
      const queriesData = queryClient.getQueriesData<AccountListData>(queryNames.accountList)
      for (const queryData of queriesData) {
        if (queryData[1] != null && (queryData[1].data?.length ?? 0) > 0) {
          const index = queryData[1].data.findIndex(p => p.uuid === variables.uuid)
          if (index !== -1) {
            queryData[1].data[index] = mapCmsUserDetailsDto(data.data!)
            queryClient.setQueryData(queryData[0], queryData[1])
          }
        }
      }
      toast.success(texts('successes:account_updated_successfully'))
    }
  })
  return mutation
}