import { LoadingButton as MuiLoadingButton } from '@mui/lab'
import { ButtonProps } from '@mui/material'

type Props = ButtonProps & {
  loading?: boolean;
  loadingIndicator?: React.ReactNode;
  loadingPosition?: 'start' | 'end' | 'center';
  children?: React.ReactNode | React.ReactNode[]
  loadingButtonColor?: string
}

const LoadingButton = (props: Props) => {
   return (
    <MuiLoadingButton
      {...props}
      sx={{
        ...props.sx,
        '&.MuiLoadingButton-loading': {
          backgroundColor: props.loadingButtonColor ?? (props.variant === 'contained' ? '#383838' : 'transparent')
        }
      }}
    >
      {props.children}
    </MuiLoadingButton>
  )
}

export default LoadingButton
