import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { forceLogOut, LoginState } from '../redux/storageToolkit'
import store from '../redux/store'
import { AuthSubcodes, Codes, InternalSubcodes } from '../services/errors/consts'
import { SessionTokenDto } from './auth/types'
import redirectStore from '../redux/redirectStore'
import { redirectTo } from '../redux/redirectStorageToolkit'
declare module 'axios' {
  interface AxiosResponse<T = any> extends Promise<T> {}
}
export type BaseResponse = {
  success: 0 | 1
}
export type CustomAxiosRes<T = any> = {
  data: AxiosResponse<T>
  axiosRequestConfig: AxiosRequestConfig
}
export abstract class Api {
  protected api: AxiosInstance
  protected refreshingToken: Promise<SessionTokenDto> | null = null

  public constructor (config: AxiosRequestConfig) {
    this.api = axios.create({
      ...config,
      timeout: 120 * 1000,
      withCredentials: true
    })
    // this.api.defaults.timeout = 120 * 1000 
    // this middleware is been called right before the http request is made.
    //this.api.interceptors.request.use((config: AxiosRequestConfig) => {})
    
    // this middleware is been called right before the response is get it by the method that triggers the request
    this.api.interceptors.response.use(
      this.handleResponse,
      this.handleError
    )
  }
  public getUri (config?: AxiosRequestConfig): string {
    return this.api.getUri(config)
  }
  public request<T, D = any> (config: AxiosRequestConfig<D>): Promise<T> {
    return this.api.request(config)
  }
  public get<T, D = any> (url: string, config?: AxiosRequestConfig<D>, manualTimeout: boolean = false): Promise<T> {
    if (manualTimeout) {
      const abort = axios.CancelToken.source()
      const id = setTimeout(
        () => abort.cancel(`Timeout of ${(config?.timeout ?? 120 * 1000) + (30 * 1000)} ms.`),
        (config?.timeout ?? 120 * 1000) + (30 * 1000)
      )
      return this.api.get(url, {
        ...config,
        cancelToken: abort.token
      }).then(response => {
        clearTimeout(id)
        return response
      }, (error) => {
        clearTimeout(id)
        throw error
      })
    }
    return this.api.get(url, config)
  }
  public delete<T, D = any> (url: string, config?: AxiosRequestConfig<D>): Promise<T> {
    return this.api.delete(url, config)
  }
  public head<T, D = any> (url: string, config?: AxiosRequestConfig<D>): Promise<T> {
    return this.api.head(url, config)
  }
  public post<T, D = any> (url: string, data?: D, config?: AxiosRequestConfig<D>, manualTimeout: boolean = false): Promise<T> {
    if (manualTimeout) {
      const abort = axios.CancelToken.source()
      const id = setTimeout(
        () => abort.cancel(`Timeout of ${(config?.timeout ?? 120 * 1000) + (30 * 1000)} ms.`),
        (config?.timeout ?? 120 * 1000) + (30 * 1000)
      )
      return this.api.post(url, data, {
        ...config,
        cancelToken: abort.token
      }).then(response => {
        clearTimeout(id)
        return response
      }, (error) => {
        clearTimeout(id)
        throw error
      })
    }
    return this.api.post(url, data, config)
  }
  public put<T, D = any> (url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<T> {
    return this.api.put(url, data, config)
  }
  public patch<T, D = any> (url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<T> {
    return this.api.patch(url, data, config)
  }

  protected refreshToken(): Promise<SessionTokenDto> {
    return this.post<SessionTokenDto>('/user/refresh', undefined, {
      baseURL: `${process.env.REACT_APP_BASE_AUTH}/api/v2/auth/cms`,
      data: {
        _refreshing: true
      }
    })
  }

  private handleError = (error: any): Promise<Error> => {
    if (this.isForbidden(error)) {
      redirectStore.dispatch(redirectTo('/'))
    }
    if (this.isForceLogout(error)) {
      store.dispatch(forceLogOut())
      error.response.data.code = Codes.INTERNAL
      error.response.data.subcode = InternalSubcodes.FORCED_LOGOUT
      return Promise.reject(error)
    }
    if (this.shouldRefreshToken(error)) {
      return this.handleRefreshToken(error)
    }
    return Promise.reject(error)
  }
  private handleResponse = ({ data, config }: AxiosResponse) => { 
    return data 
  }

  private handleRefreshToken = async (e: any): Promise<Error> => {
    const config: AxiosRequestConfig & { _retry?: boolean } = e.config
    config._retry = true
    try {
      this.refreshingToken = this.refreshingToken ?? this.refreshToken()
      await this.refreshingToken
      return this.api(config)
    } catch(e) {
      return Promise.reject(e)
    } finally {
      this.refreshingToken = null
    }
  }

  private shouldRefreshToken = (e: any): boolean => {
    const httpStatus = e.response?.data.httpStatus
    const code = e.response?.data?.code
    const subcode = e.response?.data?.subcode
    const config: AxiosRequestConfig & { _retry: boolean } = e.config
    return httpStatus === 401 && code === Codes.AUTH 
      && ((subcode === AuthSubcodes.UNAUTHORIZED || subcode === AuthSubcodes.COOKIE_EXPIRED) && !config.url?.includes('password/reset')) 
      && !config._retry
  }

  private isForceLogout = (e: any): boolean => {
    const config: AxiosRequestConfig & { _retry?: boolean } = e.config
    const httpStatus = e.response?.data.httpStatus
    const code = e.response?.data?.code
    const subcode = e.response?.data?.subcode
    if (store.getState().storage.loginState === LoginState.LOGGED && httpStatus === 401 && code === Codes.AUTH 
      && ((subcode === AuthSubcodes.USER_INACTIVE || subcode === AuthSubcodes.ACCOUNT_REMOVED) 
        || (subcode === AuthSubcodes.UNAUTHORIZED && config.url?.includes('user/refresh'))
        || (subcode === AuthSubcodes.COOKIE_EXPIRED && config.url?.includes('user/refresh')))
    ) {
      return true
    } else {
      return false
    }
  }

  private isForbidden = (e: any): boolean => {
    const httpStatus = e.response?.data.httpStatus
    const code = e.response?.data?.code
    const subcode = e.response?.data?.subcode
    if (httpStatus === 403 && code === Codes.AUTH && subcode === AuthSubcodes.FORBIDDEN) {
      return true
    } else {
      return false
    }
  }
}