import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import DataApi from '../../api/data/DataApi'
import { AccountListData, EditEmployeeBodyDto, EmployeeDetailsDto } from '../../types/accounts'
import { showErrorToast } from '../../utils/error'
import { queryNames } from '../queries'
import { Success } from '../../types/common'
import { mapEmployeeDetailsDto } from '../../utils/mapping'

const dataApi = DataApi.getInstance()

type MutationParams = {
  uuid: string
  data: EditEmployeeBodyDto
}

export default function useEditEmployeeEditPlace() {
  const queryClient = useQueryClient()
  const texts = useTranslation().t
  const mutation = useMutation((params: MutationParams) => dataApi.editEmployeeData(params.uuid, params.data), {
    onError: (e) => {
      showErrorToast(e, texts)
    },
    onSuccess: (data: Success<EmployeeDetailsDto>, variables: MutationParams) => {
      const queriesData = queryClient.getQueriesData<AccountListData>(queryNames.accountList)
      for (const queryData of queriesData) {
        if (queryData[1] != null && (queryData[1].data?.length ?? 0) > 0) {
          const index = queryData[1].data.findIndex(p => p.uuid === variables.uuid)
          if (index !== -1) {
            queryData[1].data[index] = mapEmployeeDetailsDto(data.data!)
            queryClient.setQueryData(queryData[0], queryData[1])
          }
        }
      }
      toast.success(texts('successes:account_updated_successfully'))
    }
  })
  return mutation
}