import { Namespace, TFunction } from 'i18next'
import { mapHttpErrorMessage } from '../services/errors/errorMapping'
import toast from 'react-hot-toast'
import { Codes, InternalSubcodes, Page } from '../services/errors/consts'

export function showErrorToast(error: any, translations: TFunction<Namespace>, page?: Page) {
  const isForcedLogout = error.response?.data.code === Codes.INTERNAL && error.response?.data?.subcode === InternalSubcodes.FORCED_LOGOUT
  if (!isForcedLogout) {
    toast.error(translations(mapHttpErrorMessage(error, page)) as string)
  }
}