import { Box, Grid, SxProps, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { LoadingButton } from '@mui/lab'
import DeactivateItemsDialog from '../CreateMenu/Dialogs/DeactivateItemsDialog'
import { useState } from 'react'
import TimedDeactivateItemsDialog from '../CreateMenu/Dialogs/TimedDeactivateItemsDialog'
import ActivateItemsDialog from '../CreateMenu/Dialogs/ActivateItemsDialog'
import { AvailabilityItemType } from '../../../types/menu'

type Props = {
  selectedItems: string[]
  itemType: AvailabilityItemType
  placeUuid: string
  onSuccess: () => void
}

const buttonSx: SxProps = {
  fontSize: '20px',
  fontStyle: 'RobotoCondensedBold',
  color: 'white',
  width: 'fit-content',
  px: '1rem',
  minWidth: '190px',
  whiteSpace: 'nowrap'
  // height: '30px'
}

const activateButtonSx: SxProps = {
  ...buttonSx,
  backgroundColor: '#1BAA30',
  ':hover': {
    backgroundColor: '#2ad543',
  }
}
const deactivateButtonSx: SxProps = {
  ...buttonSx,
  backgroundColor: '#E02020',
  ':hover': {
    backgroundColor: '#f91616'
  }
}

const ButtonsSection = (props: Props) => {
  const texts = useTranslation().t
  const [activateDialogOpened, setActivateDialogOpened] = useState<boolean>(false)
  const [deactivateDialogOpened, setDeactivateDialogOpened] = useState<boolean>(false)
  const [timedDeactivateDialogOpened, setTimedDeactivateDialogOpened] = useState<boolean>(false)

  return (
    <>
      <ActivateItemsDialog
        open={activateDialogOpened}
        placeUuid={props.placeUuid}
        selectedItems={props.selectedItems}
        itemType={props.itemType}
        onClose={() => setActivateDialogOpened(false)}
        onSucces={props.onSuccess}
      />
      <DeactivateItemsDialog
        open={deactivateDialogOpened}
        placeUuid={props.placeUuid}
        selectedItems={props.selectedItems}
        itemType={props.itemType}
        onClose={() => setDeactivateDialogOpened(false)}
        onSucces={props.onSuccess}
      />
      <TimedDeactivateItemsDialog
        open={timedDeactivateDialogOpened}
        placeUuid={props.placeUuid}
        selectedItems={props.selectedItems}
        itemType={props.itemType}
        onClose={() => setTimedDeactivateDialogOpened(false)}
        onSuccess={props.onSuccess}
      />
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Typography variant='h2' noWrap>
          {texts('availability:page_title')}
        </Typography>
        {
          props.selectedItems.length > 0 
            ? <Grid
                container
                gap={'10px'}
                direction={'row'}
                alignSelf={'flex-end'}
                width={'fit-content'}
              >
                <LoadingButton
                  sx={{
                    ...activateButtonSx
                  }}
                  onClick={() => setActivateDialogOpened(true)}
                >
                  {texts('availability:activate', { count: props.selectedItems.length })}
                </LoadingButton>
                <LoadingButton
                  sx={{
                    ...deactivateButtonSx
                  }}
                  onClick={() => setTimedDeactivateDialogOpened(true)}
                >
                  {texts('availability:deactivate_until', { count: props.selectedItems.length })}
                </LoadingButton>
                <LoadingButton 
                  sx={{
                    ...deactivateButtonSx
                  }}
                  onClick={() => setDeactivateDialogOpened(true)}
                >
                  {texts('availability:deactivate', { count: props.selectedItems.length })}
                </LoadingButton>
              </Grid>
            : null
          }
      </Box>
    </>
  )
}

export default ButtonsSection