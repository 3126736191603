import { CircularProgress, SxProps, Theme } from '@mui/material'
import { LoadingButton } from "@mui/lab"

type Props = {
  text: string
  sx: SxProps<Theme>
  loading?: boolean
  onStatusChanged: () => void
}

const StatusButton = (props: Props) => {
  return (
    <LoadingButton
      variant={'contained'}
      size={'medium'}
      sx={props.sx}
      loading={props.loading}
      loadingIndicator={
       <CircularProgress
          size='1rem'
          sx={{
            color: '#fff'
          }}
       />
      }
      onClick={props.onStatusChanged}
    >
      {props.text}
    </LoadingButton>
  )
}

export default StatusButton