import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import DataApi from '../../api/data/DataApi'
import { PlaceFormData } from '../../types/place'
import { showErrorToast } from '../../utils/error'
import { queryNames } from '../queries'

const dataApi = DataApi.getInstance()

type MutationParams = {
  uuid: string
  active: boolean
}

export default function useChangePlaceActiveState() {
  const queryClient = useQueryClient()
  const texts = useTranslation().t
  const mutation = useMutation((data: MutationParams) => dataApi.editPlaceActiveState(data.uuid, data.active), {
    onError: (e) => {
      showErrorToast(e, texts)
    },
    onSuccess: (_, variables: MutationParams) => {
      const queryData = queryClient.getQueryData<PlaceFormData>([queryNames.placeDetails, variables.uuid])
      if (queryData) {
        queryData.active = variables.active
        queryClient.setQueryData([queryNames.placeDetails, variables.uuid], queryData)
      }
    }
  })
  return mutation
}