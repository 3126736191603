import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import DataApi from '../../api/data/DataApi'
import { mapSendReportDataToDto } from '../../api/data/mapping'
import { ReportFormData } from '../../types/help'
import { showErrorToast } from '../../utils/error'

const dataApi = DataApi.getInstance()

type MutationParams = {
  data: ReportFormData
}

export default function useSendProblemReport() {
  const texts = useTranslation().t
  const mutation = useMutation((params: MutationParams) => {
      const body = new FormData()
      if (params.data.file) {
        body.append('file', params.data.file as File)
      }
      body.append('data', JSON.stringify(mapSendReportDataToDto(params.data)))
      return dataApi.sendProblemReport(body)
    }, {
    onError: (e) => {
      showErrorToast(e, texts)
    },
    onSuccess: () => {
      toast.success(texts('successes:report_send_successfully'))
    }
  })
  return mutation
}

