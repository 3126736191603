import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'

type Props = {
  options: any[]
  label: string
  disabled: boolean
  value: string
  handleChange: (event: SelectChangeEvent) => void
}

const SelectField = (props: Props) => {
  return (
    <FormControl fullWidth>
      <InputLabel>{props.label}</InputLabel>
      <Select
        value={props.value}
        disabled={props.disabled}
        label={props.label}
        onChange={props.handleChange}
        sx={{textTransform: 'uppercase'}}
      >
        {Object.keys(props.options).map((val, index) => {
          return (
            <MenuItem
              key={index}
              value={val}
              sx={{textTransform: 'uppercase'}}
            >
              {props.options[index]}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

export default SelectField