import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import DataApi from '../../api/data/DataApi'
import { CreateEmployeeBodyDto, NewPinDto } from '../../types/accounts'
import { Success } from '../../types/common'
import { showErrorToast } from '../../utils/error'
import { queryNames } from '../queries'

const dataApi = DataApi.getInstance()

type MutationParams = {
  data: CreateEmployeeBodyDto
}

export default function useCreateEmployee(onSuccess: (pin: string) => void) {
  const queryClient = useQueryClient()
  const texts = useTranslation().t
  const mutation = useMutation((params: MutationParams) => dataApi.createEmployee(params.data), {
    onError: (e) => {
      showErrorToast(e, texts)
    },
    onSuccess: (data: Success<NewPinDto>) => {
      queryClient.resetQueries(queryNames.accountList)
      onSuccess(data.data!.pin)
      toast.success(texts('successes:account_created_successfully'))
    }
  })
  return mutation
}