import { FormControl, FormControlLabel, FormGroup, Checkbox as MuiCheckbox, CheckboxProps } from '@mui/material'
import { useField, useFormikContext } from 'formik'

type Props = {
  name: string
  label?: string
}

const Checkbox = (props: Props) => {
  const { setFieldValue } = useFormikContext()
  const [field, meta] = useField(props.name)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target
    setFieldValue(props.name, checked)
  }

  const configCheckbox: CheckboxProps = {
    ...field,
    onChange: handleChange
  }

  return (
    <FormControl>
      <FormGroup>
        <FormControlLabel
          control={
            <MuiCheckbox 
              {...configCheckbox} 
              {...(
                meta && meta.value != null
                ? {
                    checked: meta.value
                  }
                : {}
              )}
            />
          }
          label={props.label}
        />
      </FormGroup>
    </FormControl>
  )
}

export default Checkbox