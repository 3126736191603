import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import DataApi from '../../api/data/DataApi'
import { EditLoyaltyCardDto, LoyaltyCardDto } from '../../api/data/types'
import { Success } from '../../types/common'
import { showErrorToast } from '../../utils/error'
import { mapLoyaltyCardDto } from '../../utils/mapping'
import { queryNames } from '../queries'
import { DiscountCodeList } from '../../types/discounts'

const dataApi = DataApi.getInstance()

type MutationParams = {
  uuid: string
  data: EditLoyaltyCardDto
}

export default function useEditLoyaltyCard() {
  const queryClient = useQueryClient()
  const texts = useTranslation().t
  const mutation = useMutation((params: MutationParams) => dataApi.editLoyaltyCard(params.uuid, params.data), {
    onError: (e) => {
      showErrorToast(e, texts)
    },
    onSuccess: (data: Success<LoyaltyCardDto>, variables: MutationParams) => {
      const queriesData = queryClient.getQueriesData<DiscountCodeList>(queryNames.getLoyaltyCardList)
      for (const queryData of queriesData) {
        if (queryData[1] != null && (queryData[1].data?.length ?? 0) > 0) {
          const index = queryData[1].data.findIndex(p => p.uuid === variables.uuid)
          if (index !== -1) {
            queryData[1].data[index] = mapLoyaltyCardDto(data.data!)
            queryClient.setQueryData(queryData[0], queryData[1])
          }
        }
      }
      queryClient.setQueryData([queryNames.getLoyaltyCardDetails, variables.uuid], mapLoyaltyCardDto(data.data!))
      toast.success(texts('successes:loyalty_card_updated_successfully'))
    }
  })
  return mutation
}