import { AxiosError } from 'axios'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import AuthApi from '../../api/auth/AuthApi'
import { PasswordDto } from '../../api/auth/types'
import { AuthSubcodes, Codes } from '../../services/errors/consts'
import { HttpError } from '../../types/common'
import { showErrorToast } from '../../utils/error'

const authApi = AuthApi.getInstance()

export function useResetPassword() {
  const texts = useTranslation().t
  const navigate = useNavigate()
  const mutation = useMutation((data: Pick<PasswordDto, 'password'>) => authApi.finishPasswordReset(data), {
    onSuccess: () => {
      toast.success(texts('successes:password_changed'))
      navigate('/login', { replace: true })
    },
    onError: (error: AxiosError<HttpError>) => {
      const isCookieExpired = error.response?.data.code === Codes.AUTH && error.response?.data.subcode === AuthSubcodes.COOKIE_EXPIRED
      if (isCookieExpired) {
        navigate('/password/reset/request')
        toast.error(texts('errors:pwd_reset_cookie_expired'))
      } else {
        showErrorToast(error, texts)
      }
    },
  })
  return mutation
}