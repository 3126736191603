import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import DataApi from '../../api/data/DataApi'
import { showErrorToast } from '../../utils/error'
import { queryNames } from '../queries'
import { AxiosError } from 'axios'
import { HttpError } from '../../types/common'
import { Codes, DataSubcodes } from '../../services/errors/consts'

const dataApi = DataApi.getInstance()

type MutationParams = {
  uuid: string
}

export default function useRemoveLoyaltyCard() {
  const queryClient = useQueryClient()
  const texts = useTranslation().t
  const mutation = useMutation((params: MutationParams) => {
      return dataApi.removeLoyaltyCard(params.uuid)
    }, {
    onError: (e: AxiosError<HttpError>, variables: MutationParams) => {
      if (e.response?.data.code === Codes.DATA && e.response.data.subcode === DataSubcodes.LOYALTY_CARD_NOT_FOUND) {
        queryClient.removeQueries([queryNames.getLoyaltyCardDetails, variables.uuid])
        queryClient.resetQueries(queryNames.getLoyaltyCardList)
      }
      showErrorToast(e, texts)
    },
    onSuccess: (_, variables: MutationParams) => {
      queryClient.removeQueries([queryNames.getLoyaltyCardDetails, variables.uuid])
      queryClient.resetQueries(queryNames.getLoyaltyCardList)
      toast.success(texts('successes:loyalty_card_removed'))
    }
  })
  return mutation
}