import { useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'
import DataApi from '../../../api/data/DataApi'
import { showErrorToast } from '../../../utils/error'
import { mapVariantTemplateDetailsDtoToForm } from '../../../utils/mapping'
import { queryNames } from '../../queries'
import { AxiosError } from 'axios'
import { HttpError } from '../../../types/common'
import { Codes, DataSubcodes } from '../../../services/errors/consts'

const dataApi = DataApi.getInstance()

export default function useGetVariantTemplateDetails(uuid?: string | null, onNotFoundError?: () => void) {
  const texts = useTranslation().t
  const queryClient = useQueryClient()

  const query = useQuery([queryNames.variantDetails, uuid], async () => {
    return mapVariantTemplateDetailsDtoToForm(await dataApi.getVariantTemplateDetails(uuid!))
  }, {
    enabled: uuid != null && uuid !== '',
    staleTime: Infinity,
    refetchOnMount: 'always',
    onError: (error: AxiosError<HttpError>) => {
      if (error.response?.data.code === Codes.DATA && error.response.data.subcode === DataSubcodes.VARIANT_TEMPLATE_NOT_FOUND) {
        queryClient.removeQueries([queryNames.variantDetails, uuid])
        queryClient.resetQueries(queryNames.variantList)
        queryClient.resetQueries(queryNames.variantAvailabilityList)
        queryClient.resetQueries(queryNames.placeMenu)
        onNotFoundError?.()
      }
      showErrorToast(error, texts)
    }
  })
  return query
}

