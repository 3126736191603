import { useTranslation } from 'react-i18next'
import { Box,  Grid, TextField, Typography } from '@mui/material'
import moment from 'moment'
import { useFormikContext } from 'formik'
import Switch from '../../CustomMui/Fields/Switch'
import { TimePicker } from '@mui/x-date-pickers-pro'
import { OpeningHours } from '../../../types/place'
import { useMemo, useState } from 'react'
import { DEFAULT_START_TIME, DEFAULT_END_TIME } from '../../../utils/constants'

const TIME_FORMAT = 'HH:mm'

type Props = {
  name: string
  day: OpeningHours
  label: string
}

const calculateDuration = (value: OpeningHours) => {
  const startTime = moment(value.start, TIME_FORMAT)
  const endTime = moment(value.end, TIME_FORMAT)

  let minutes: number = 0
  if (startTime.isAfter(endTime)) {
    minutes = 24 * 60 - moment(startTime).diff(endTime, 'minute')
  } else {
    minutes = moment(endTime).diff(startTime, 'minute')
  }

  return {
    hours: Math.floor(minutes / 60),
    minutes: minutes % 60
  }
}

const Row = (props: Props) => {
  const texts = useTranslation().t

  const { setFieldValue } = useFormikContext()

  const [fromError, setFromError] = useState<string | null>(null)
  const [untilError, setUntilError] = useState<string | null>(null)
  
  const duration = useMemo(() => calculateDuration(props.day), [props.day])

  return (
    <Box 
      marginBottom={props.day.open ? '0.5rem' : '1.375rem'}
    >
      <Grid 
        container 
        alignItems={'center'} 
        columnGap={'1.375rem'}
        wrap={'nowrap'}
      >
        <Typography 
          variant='body2' 
          sx={{
            minWidth: '7.5rem',
            paddingBottom: props.day.open ? '1.375rem' : 0
          }}
        >
          {props.label}
        </Typography>
        <Grid
          item
          container
          alignItems={'center'}
          sx={{
            minWidth: '3rem',
            maxWidth: '8.5rem',
            paddingBottom: props.day.open ? '1.375rem' : 0
          }}
        > 
          <Switch
            name={`${props.name}.open`}
            label={props.day.open ? texts('place:open') : texts('place:closed')}
            additionalOnChange={(_) => {
              setFieldValue(
                `${props.name}.start`,
                DEFAULT_START_TIME
              )
              setFieldValue(
                `${props.name}.end`,
                DEFAULT_END_TIME
              )
              setFromError(null)
              setUntilError(null)
            }}
          />
        </Grid>
        {
          props.day.open
          ? <Box 
              display={'grid'}
              gridTemplateColumns={'12.5rem 12.5rem 15rem'}
              alignItems={'center'}
              columnGap={'1.375rem'}
            >
              <TimePicker
                label={texts('common:from')}
                value={
                  moment(
                    props.day.start, 
                    TIME_FORMAT
                  ).toDate()
                }
                onChange={value => {
                  if (value) {
                    if (!moment(value, 'HH:mm').isValid()) {
                      setFromError(texts('validations:invalid_value'))
                    } else {
                      setFromError(null)
                    }
                    setFieldValue(
                      `${props.name}.start`,
                      moment(value).format(TIME_FORMAT)
                    )
                  }
                }} 
                renderInput={({ inputProps, ...restParams }) => 
                  <TextField 
                    {...restParams}
                    error={!!fromError}
                    helperText={fromError}
                    sx={{
                      paddingBottom: !fromError ? '1.375rem' : 0
                    }}
                    inputProps={{
                      ...inputProps,
                      placeholder: texts('common:timeInputPlaceholder'),
                    }}
                  />
                }
                ampm={false}         
              />
              <TimePicker
                label={texts('common:until')}
                value={
                  moment(
                    props.day.end,
                    TIME_FORMAT
                  ).toDate()
                }
                onChange={value => {
                  if (value) {
                    if (!moment(value, 'HH:mm').isValid()) {
                      setUntilError(texts('validations:invalid_value'))
                    } else {
                      setUntilError(null)
                    }
                    setFieldValue(
                      `${props.name}.end`, 
                      moment(value).format(TIME_FORMAT)
                    )
                  }
                }} 
                renderInput={({ inputProps, ...restParams }) => 
                  <TextField 
                    {...restParams}
                    error={!!untilError}
                    helperText={untilError}
                    sx={{
                      paddingBottom: !untilError ? '1.375rem' : 0
                    }}
                    inputProps={{
                      ...inputProps,
                      placeholder: texts('common:timeInputPlaceholder'),
                    }}
                  />
                }
                ampm={false}         
              />
              <Typography 
                variant='body1'
                sx={{
                  paddingBottom: props.day.open ? '1.375rem' : 0
                }}
              >
                {
                  untilError || fromError
                  ? texts('place:number_of_hours', { hours: ' - '})
                  : texts(
                      props.day.open && duration.minutes > 0 ? 'place:number_of_hours_and_minutes' : 'place:number_of_hours', 
                      { hours: duration.hours, minutes: duration.minutes }
                    )
                } 
              </Typography>
            </Box> 
          : null
        }
      </Grid>
    </Box>
  )
}

export default Row