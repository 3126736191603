import { AuthDto, CheckSessionDto } from '../../api/auth/types'
import { Role } from '../../types/accounts'

export const ACCESS_TOKEN = 'depesza_cms_access_token'
export const REFRESH_TOKEN = 'depesza_cms_refresh_token'
export const USER_UUID = 'user_uuid'
export const USER_EMAIL = 'user_email'
export const USER_NAME = 'user_name'
export const USER_ROLES = 'user_roles'


export function setUserData(data: AuthDto | CheckSessionDto) {
  localStorage.setItem(USER_UUID, data.uuid)
  localStorage.setItem(USER_EMAIL, data.email)
  localStorage.setItem(USER_NAME, data.name)
  localStorage.setItem(USER_ROLES, JSON.stringify(data.roles))
}

export function clearUserData() {
  localStorage.removeItem(USER_UUID)
  localStorage.removeItem(USER_EMAIL)
  localStorage.removeItem(USER_NAME)
  localStorage.removeItem(USER_ROLES)
}

export function setUserEmail(email: string) {
  localStorage.setItem(USER_EMAIL, email)
}

export function clearUserEmail() {
  localStorage.removeItem(USER_EMAIL)
}

export function getUserUuid(): string | null {
  return localStorage.getItem(USER_UUID)
}

export function getUserName(): string | null {
  return localStorage.getItem(USER_NAME)
}

export function setUserName(name: string) {
  return localStorage.setItem(USER_NAME, name)
}

export function getUserRoles(): Role[] {
  const roleString = localStorage.getItem(USER_ROLES)
  return roleString ? JSON.parse(roleString) : []
}

export function clearUserCache() {
  clearUserData()
}