import { UploadedPhotoResponseDto } from "../api/data/types"
import { ASCENDING, DESCENDING } from "../utils/constants"

export type Success<T = any> = {
  success: true
  data?: T
}

export type HttpError<T = any> = {
  httpStatus: number
  code: number
  subcode: number
  message: string
  data?: T
}

export type Photo = {
  uuid: string
  original: string
  thumbnail: string | null
}

export type BannerFormData = {
  bannerUuid?: string
  photoUuid?: string
  photoUrl: string
}

export const AllowedImageTypes = {
  'image/jpeg': [],
  'image/png': []
}

export type ImageDimensions = {
  width: number
  height: number
}

export type Order = typeof ASCENDING | typeof DESCENDING

export type UploadedPhotoResponse = UploadedPhotoResponseDto

export type AutocompleteBaseOption = {
  value: string
  label: string
}