import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import DataApi from '../../api/data/DataApi'
import { AvailabilityListOptions } from '../../api/data/types'
import { VariantAvailabilityList } from '../../types/menu'
import { showErrorToast } from '../../utils/error'
import { mapVariantAvailabilityListDto } from '../../utils/mapping'
import { queryNames } from '../queries'

const dataApi = DataApi.getInstance()

type Options = {
  enabled?: boolean
  onSuccess: (data: VariantAvailabilityList) => void
}

export default function useGetPlaceVariantAvailabilityList(
  placeUuid: string,
  options: AvailabilityListOptions & Options
) {
  const texts = useTranslation().t

  const key = [queryNames.variantAvailabilityList, placeUuid, { 
      search: options.search,
      sort: options.sort,
      offset: options.offset 
    }
  ]
  const query = useQuery(key, async () => {
    return mapVariantAvailabilityListDto(await dataApi.getPlaceVariantAvailabilityList(placeUuid, options))

  }, {
    onError: (error) => {
      showErrorToast(error, texts)
    },
    staleTime: Infinity,
    refetchOnMount: 'always',
    enabled: options.enabled,
    onSuccess: options.onSuccess
  })

  return {
    ...query,
    maxPage: options?.limit != null ? Math.ceil((query.data?.count ?? 0) / options.limit) : 1
  }}

