import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import DataApi from '../../../api/data/DataApi'
import { mapAdditionTemplateData } from '../../../api/data/mapping'
import { AdditionFormData } from '../../../types/menu'
import { showErrorToast } from '../../../utils/error'
import { queryNames } from '../../queries'

const dataApi = DataApi.getInstance()

export default function useCreateAdditionTemplate() {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const texts = useTranslation().t
  const mutation = useMutation((data: AdditionFormData) => dataApi.createNewAdditionTemplate(mapAdditionTemplateData(data)), {
    onError: (e) => {
      showErrorToast(e, texts)
    },
    onSuccess: () => {
      queryClient.resetQueries(queryNames.additionList)
      toast.success(texts('successes:addition_added'))
      navigate('/menu/additions', {replace: true})
    }
  })
  return mutation
}