import { Box, Grid, Typography, SxProps } from '@mui/material'
import { useTranslation } from 'react-i18next'
import refreshImageSrc from '../../assets/images/refreshViewImage.webp'
import noRefreshImageSrc from '../../assets/images/noRefreshViewImage.webp'
import LoadingButton from '../CustomMui/LoadingButton'

type Props = {
  description?: string
  buttonLabel?: string
  loading?: boolean
  imageSrc?: string | null
  sx?: SxProps
  onRefresh?: () => void
  withRefresh?: boolean
}

const NetworkError = (props: Props) => {
  const texts = useTranslation().t

  return (
    <Grid 
      container
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      sx={
        props.sx ??
        {
          width: '100%',
          height: '100%',
          background: 'white'
        }
      }
    >
      <Grid
        item
        container
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        gap={'2rem'}
        sx={{
          maxWidth: '1000px'
        }}
      >
        <Box
          component={'img'}
          alt='image'
          flexShrink={0}
          src={props.imageSrc ?? (props.withRefresh ? refreshImageSrc : noRefreshImageSrc)}
          sx={{
            display: 'block',
            minHeight: '20%',
            maxHeight: '50%',
            minWidth: '20%',
            maxWidth: '50%'
          }}
        />
        <Typography 
          textAlign={'center'}
          sx={{
            maxWidth: '70%',
            textTransform: 'none'
          }}
        >
          {props.description ?? (props.withRefresh ? texts('errors:generic_query_error_with_refresh_description') : texts('errors:generic_query_error_description'))}
        </Typography>
        {
          props.withRefresh
          ? <LoadingButton
              variant={'contained'}
              size={'medium'}
              loading={props.loading}
              onClick={() => props.onRefresh?.()}
              sx={{
                width: 'max-content',
                px: '1rem'
              }}
            >
              {props.buttonLabel ?? texts('common:refresh_view_button')}
            </LoadingButton>
          : null
        }
        
      </Grid>
    </Grid>
  )
}

export default NetworkError