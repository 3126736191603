import CancelIcon from '@mui/icons-material/Cancel'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import { Grid, IconButton, Typography } from '@mui/material'
import { filesize } from 'filesize'
import { useFormikContext } from 'formik'
import { useMemo } from 'react'
import { ReportFormData } from '../../types/help'

const DEFAULT_REMOVE_HOVER_COLOR = '#E02020'
const GREY_DIM = '#DDD'

type Props = {
  file: File
}

const FileTile = (props: Props) => {
  const { setFieldValue } = useFormikContext<ReportFormData>()

  const imageSrc = useMemo(() => {
    return URL.createObjectURL(props.file)
  }, [props.file])

  return (
    <Grid 
      container
      sx={{
        background: GREY_DIM,
        ...(
          props.file.type.startsWith('image/')
          ? {
              backgroundImage: `url("${imageSrc}")`,
              backgroundSize: 'cover'
            }
          : {}
        ),
        borderRadius: '1.25rem',
        display: 'flex',
        height: '7.5rem',
        width: '7.5rem',
        position: 'relative',
        zIndex: '0.625rem',
        flexDirection: 'column',
        justifyContent: 'center'
      }}
    >
      {
        props.file.type.startsWith('image/')
        ? null
        : <>
            <Grid
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: '0.625rem',
                paddingRight: '0.625rem',
              }}
            >
              <InsertDriveFileIcon fontSize={'large'}/>
              <Typography 
                sx={{
                  backgroundColor: GREY_DIM,
                  borderRadius: 3,
                  fontSize: '12px',
                  color: '#383838',
                  marginTop: '0.2rem',
                  marginBottom: '0.2rem',
                  fontFamily: 'RobotoRegular',
                  wordBreak: 'break-all'
                }}
              >
                {props.file.name}
              </Typography>
              <Typography 
                sx={{
                  justifyContent: 'center',
                  display: 'flex',
                  fontSize: '0.625rem',
                  color: '#686868',
                  fontFamily: 'RobotoRegular'
                }}
              >
                {filesize(props.file.size) as string}
              </Typography>
            </Grid>
          </>
      }
      
      <IconButton
        onClick={(e) => {
          e.preventDefault()
          setFieldValue('file', null)
        }}
        sx={{
          height: '23px',
          position: 'absolute',
          right: 6,
          top: 6,
          width: '23px',
          color: DEFAULT_REMOVE_HOVER_COLOR
        }}
        onMouseOver={(e) => {
          e.preventDefault()
        }}
        onMouseOut={(e) => {
          e.preventDefault()
        }}
      >
        <CancelIcon />
      </IconButton>
    </Grid>
  )
}

export default FileTile