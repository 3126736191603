import { ImageDimensions } from '../types/common'

export const readFileAsDataUrl = (file: File) => {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => resolve(reader.result), false)
    reader.readAsDataURL(file)
  })
}

export async function getImageFileDimensions(file: File): Promise<ImageDimensions> {
  return new Promise(async (resolve) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.addEventListener('load', (e) => {
      const img = new Image()
      img.src = e.target?.result as string
      img.onload = function () {
        resolve({
          width: img.naturalWidth ?? img.width,
          height: img.naturalHeight ?? img.height
        })
      }
    }, false)
  })
}