import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import Home from './Home'
import PlacePage from './Place/PlacePage'
import { Grid } from '@mui/material'
import NotFound from './NotFound'
import ProductList from './Menu/Products/ProductList'
import ProductPage from './Menu/Products/ProductPage'
import VariantList from './Menu/Variants/VariantList'
import VariantPage from './Menu/Variants/VariantPage'
import TagList from './Menu/Tags/TagList'
import TagPage from './Menu/Tags/TagPage'
import AdditionList from './Menu/Additions/AdditionList'
import AdditionPage from './Menu/Additions/AdditionPage'
import CreateMenuPage from './Menu/CreateMenuPage'
import Reports from './Reports'
import Newsletter from './Marketing/Newsletter'
import Banners from './Marketing/Banners'
import Accounts from './Administration/Accounts'
import AccountSettings from './Account/AccountSettings'
import ProblemReport from './Account/ProblemReport'
import { Role } from '../types/accounts'
import { getUserRoles } from '../services/storage/storageService'
import { arrayIntersection } from '../utils/array'
import AvailabilityManagement from './Menu/Availability/AvailabilityManagement'
import DiscountCodeList from './Discount/Code/DiscountCodeList'
import DiscountCodePage from './Discount/Code/DiscountCodePage'
import FileList from './Files/FileList'

const Dashboard = () => {
  const userRoles = getUserRoles()
  const isAdmin = arrayIntersection([Role.SYSTEM_ADMIN], userRoles).length > 0
  const isPlaceAdmin = arrayIntersection([Role.PLACE_ADMIN], userRoles).length > 0

  return (
    <Grid 
      container
      sx={{padding: '1.875rem'}}
    >
      <Grid 
        item 
        flexGrow={1}
      >
        <Routes>
          <Route path='/' element={<Outlet/>}>
            <Route index element={<Home/>}/>
            <Route path='place' element={<Outlet/>}>
              <Route path='edit/:uuid' element={<PlacePage isEditPage={true}/>}/>
              {
                isAdmin
                  ? <>
                      <Route index element={<PlacePage isEditPage={false}/>} />
                      <Route path='create' element={<PlacePage isEditPage={false}/>}/>
                    </>
                  : <Route index element={<Navigate to='/'/>}/>
              }
            </Route>
            <Route path='menu' element={<Outlet/>}>
              <Route index element={<CreateMenuPage/>}/>
              <Route path='edit' element={<CreateMenuPage/>}/>
              {
                isAdmin
                ? <>
                    <Route path='products' element={<Outlet/>}>
                      <Route index element={<ProductList/>}/>
                      <Route path='add' element={<ProductPage isEditProduct={false}/>}/>
                      <Route path='edit/:uuid' element={<ProductPage isEditProduct={true}/>}/>
                    </Route>
                    <Route path='variants' element={<Outlet/>}>
                      <Route index element={<VariantList/>}/>
                      <Route path='add' element={<VariantPage isEditVariant={false}/>}/>
                      <Route path='edit/:uuid' element={<VariantPage isEditVariant={true}/>}/>
                    </Route>
                    <Route path='tags' element={<Outlet/>}>
                      <Route index element={<TagList/>}/>
                      <Route path='add' element={<TagPage isEditTag={false}/>}/>
                      <Route path='edit/:uuid' element={<TagPage isEditTag={true}/>}/>
                    </Route>
                    <Route path='additions' element={<Outlet/>}>
                      <Route index element={<AdditionList/>}/>
                      <Route path='add' element={<AdditionPage isEditAddition={false} />}/>
                      <Route path='edit/:uuid' element={<AdditionPage isEditAddition={true} />}/>
                    </Route>
                    <Route path='manage-availability' element={<AvailabilityManagement/>}/>
                  </>
                : null
              }
              {
                isPlaceAdmin
                ? <>
                    <Route path='manage-availability' element={<AvailabilityManagement/>}/>
                  </>
                : null
              }
            </Route>
            <Route path='reports' element={<Reports/>}/>
            <Route path='account' element={<Outlet/>}>
              <Route index element={<AccountSettings/>}/>
              <Route path='settings' element={<AccountSettings/>}/>
              <Route path='report' element={<ProblemReport/>}/>
            </Route>
            {
              isAdmin
                ? <>
                    <Route path='marketing/newsletter' element={<Newsletter/>} />
                    <Route path='marketing/banners' element={<Banners/>}/>
                    <Route path='administration/accounts' element={<Accounts/>}/>
                    <Route path='files' element={<FileList />}/>
                    <Route path='discount' element={<Outlet/>}>
                      <Route path='codes' element={<Outlet/>}>
                        <Route index element={<DiscountCodeList/>}/>
                        <Route path='add' element={<DiscountCodePage isEditDiscountCode={false}/>}/>
                        <Route path='edit/:uuid' element={<DiscountCodePage isEditDiscountCode={true}/>}/>
                      </Route>
                    </Route>
                  </>
                : null
            }
            <Route path='*' element={<NotFound/>}/>
          </Route>
        </Routes>
      </Grid>
    </Grid>
  )
}

export default Dashboard