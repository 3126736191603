import { Grid, IconButton, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ChangeState, OpeningHourException } from '../../../types/place'
import moment from 'moment-timezone'
import { generate } from 'short-uuid'
import React from 'react'
import Row from './Row'
import Dialog from '../../CustomMui/Dialog'
import { AiFillPlusCircle } from 'react-icons/ai'
import { ArrayHelpers, Field, FieldArray, useFormikContext, FieldProps } from 'formik'
import { DEFAULT_START_TIME, DEFAULT_END_TIME } from '../../../utils/constants'
import { useState } from 'react'

type Props = {
  name: string
}

const checkDateRanges = (values: OpeningHourException[], value: OpeningHourException): string | null => {
  const momDate = moment(value.date).startOf('day')
  for (const val of values) {
    if (val.key === value.key) {
      continue
    } else if (moment(val.date).startOf('day').isSame(momDate)) {
      return 'errors:single_exception_for_day_allowed'
    }
  }
  return null
}

const sortArray = (array: OpeningHourException[]) => {
  return array.sort((a: OpeningHourException, b: OpeningHourException) => {
    const dateA = moment(`${moment(a.date).format('YYYY-MM-DD')} ${a.open ? a.start : '00:00:00'}`, 'YYYY-MM-DD HH:mm:ss')
    const dateB = moment(`${moment(b.date).format('YYYY-MM-DD')} ${b.open ? b.start : '00:00:00'}`, 'YYYY-MM-DD HH:mm:ss')
    return dateA.isAfter(dateB) ? 1 : dateA.isBefore(dateB) ? -1 : 0
  })
}

const onAdd = (arrayHelpers: ArrayHelpers) => {
  const newElement: OpeningHourException = {
    key: generate(),
    date: moment().toDate(),
    open: false,
    start: DEFAULT_START_TIME,
    end: DEFAULT_END_TIME,
    state: ChangeState.INIT
  }
  arrayHelpers.push(newElement)
}

const getEditedRowIndex = (values: OpeningHourException[]) => {
  return values.findIndex((change: OpeningHourException) => change.state !== ChangeState.SAVED)
}

const resetRowState = (values: OpeningHourException[], arrayHelpers: ArrayHelpers, idx: number) => {
  const currentState = values[idx].state
  if (currentState === ChangeState.EDITED) {
    values[idx].state = ChangeState.SAVED
    arrayHelpers.replace(idx, values[idx])
  } else {
    arrayHelpers.remove(idx)
  }
}

const OpeningHoursExceptionsForm = (props: Props) => {
  const texts = useTranslation().t
  const [removeIndex, setRemoveIndex] = useState<number | null>(null)
  const { values, setFieldValue, setFieldError } = useFormikContext<{openingHoursExceptions: OpeningHourException[]}>()

  const onCancel = (arrayHelpers: ArrayHelpers, index: number) => {
    const value = values.openingHoursExceptions[index]
    if (value.state === ChangeState.INIT) {
      return arrayHelpers.remove(index)
    }
    value.state = ChangeState.SAVED
    arrayHelpers.replace(index, value)
    setFieldError(`${props.name}.${index}`, undefined) // only for cancel editing
  }

  const onEdit = (arrayHelpers: ArrayHelpers, index: number) => {
    const value = values.openingHoursExceptions[index]
    const editedRowId = getEditedRowIndex(values.openingHoursExceptions)
    if (editedRowId !== -1) {
      resetRowState(values.openingHoursExceptions, arrayHelpers, editedRowId)
    }
    value.state = ChangeState.EDITED
    arrayHelpers.replace(index, value)
  }

  const onSave = (value: OpeningHourException, index: number) => {
    const error = checkDateRanges(values.openingHoursExceptions, value)
    if (error) {
      return setFieldError(`${props.name}.${index}`, error)
    }
    values.openingHoursExceptions[index] = {
      ...value, 
      state: ChangeState.SAVED
    }
    setFieldValue(props.name, sortArray(values.openingHoursExceptions))
  }

  return (
    <Grid container direction={'column'}>
      <Typography variant='subtitle2' sx={{marginBottom: '1.875rem'}}>{texts('place:schedule_changes')}</Typography>
      <FieldArray
        name={props.name}
        validateOnChange={false}
        render={(arrayHelpers: ArrayHelpers) => (
          <>
            <Dialog
              open={removeIndex !== null}
              title={texts('dialogs:remove_title')}
              description={texts('dialogs:remove_description')}
              closeButtonLabel={texts('common:cancel')}
              confirmButtonLabel={texts('common:remove')}
              onCloseButtonClick={() => {
                setRemoveIndex(null)
              }}
              onConfirmButtonClick={() => {
                arrayHelpers.remove(removeIndex!)
                setRemoveIndex(null)
              }}
            />
            {
              values.openingHoursExceptions.map((val, index) => (
                <Field
                  name={`${props.name}.${index}`}
                  key={`key-${index}`}
                  validate={(value?: OpeningHourException) => {
                    if (value) {
                      return (
                        value.state !== ChangeState.SAVED
                          ? 'errors:save_field_before_submit'
                          : null
                      )
                    }
                    return null
                  }}
                >
                  {({ meta }: FieldProps) => (
                    <Row
                      key={`key-${index}`}
                      value={val}
                      rowError={meta.error}
                      onRemove={() => {
                        setRemoveIndex(index)
                      }}
                      onCancel={() => onCancel(arrayHelpers, index)}
                      onEdit={() => onEdit(arrayHelpers, index)}
                      onSave={(value) => onSave(value, index)}
                    />
                  )}
                </Field>
              ))
            }
            <Grid 
              item 
              container 
              wrap='nowrap' 
              alignItems={'center'} 
            >
              <IconButton
                onClick={() => onAdd(arrayHelpers)}  
                sx={{
                  minWidth: 0,
                  color: 'secondary.light'
                }}
                disabled={getEditedRowIndex(values.openingHoursExceptions) !== -1}
                disableRipple
              >
                <AiFillPlusCircle size={30}/>
                <Typography 
                  variant='body2' 
                  sx={{
                    fontFamily: 'RobotoCondensedBold',
                    marginLeft: '0.5rem',
                    color: getEditedRowIndex(values.openingHoursExceptions) !== -1 ? 'rgba(0, 0, 0, 0.26)' : 'secondary.light'
                  }}
                >
                  {texts('place:add_schedule_change')}
                </Typography>
              </IconButton>
            </Grid>
          </>
        )}
      />
    </Grid>
  )
}

export default OpeningHoursExceptionsForm