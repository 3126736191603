import { Autocomplete, DialogActions, Grid, Typography, TextFieldProps, TextField as MuiTextField } from '@mui/material'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import useGetPlaceList from '../../../hooks/place/useGetPlaceList'
import { AccountAllowedPlace, CreateAccessFormData, Role } from '../../../types/accounts'
import TextField from '../../CustomMui/Fields/TextField'
import LoadingButton from '../../CustomMui/LoadingButton'

type Props = {
  loading: boolean
}

const SecondStep = (props: Props) => {
  const texts = useTranslation().t
  const placeListQuery = useGetPlaceList()

  const { values, setFieldValue, errors, touched, setFieldTouched } = useFormikContext<CreateAccessFormData>()

  return (
    <>
      <Grid
        container
        columnGap={'1rem'}
        rowGap={'0.125rem'}
        mt={'1.5rem'}
        sx={{maxWidth: '40rem'}}
      >
        <Typography variant={'subtitle2'} mb={'0.825rem'}>
          {texts('accounts:fill_data')}
        </Typography>
        <TextField
          name='name'
          label={texts('accounts:name')}
        />
        {
          values.role !== Role.EMPLOYEE
          ? <TextField
              name='email'
              type='email'
              label={texts('accounts:email')}
            />
          : null
        }
        {
          values.role === Role.EMPLOYEE || values.role === Role.PLACE_ADMIN
          ? values.role === Role.PLACE_ADMIN
            ? <Autocomplete
                multiple
                filterSelectedOptions
                filterOptions={(options, state) => options.filter(o => o.name.toLowerCase().includes(state.inputValue.toLowerCase()))}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.uuid}>
                      {option.name}
                    </li>
                  )
                }}
                value={values.places}
                isOptionEqualToValue={(option: AccountAllowedPlace, value: AccountAllowedPlace) => option.uuid === value.uuid}
                onChange={(e: any, values: AccountAllowedPlace[]) => {
                  setFieldTouched('places', true, false)
                  setFieldValue('places', values)
                }}
                options={
                  placeListQuery.data?.map(e => { return { uuid: e.uuid, name: e.name }}) ?? []
                }
                noOptionsText={texts('accounts:no_places')}
                sx={{flexGrow: 1, maxWidth: '100%'}}
                getOptionLabel={(option: AccountAllowedPlace) => option.name}
                renderInput={(params: TextFieldProps) => (
                  <MuiTextField
                    {...params}
                    error={touched.places && !!errors.places}
                    helperText={touched.places ? errors.places as string : undefined}
                    label={texts('accounts:places')}
                  />
                )}
              />
            : <Autocomplete
                value={values.places[0] ?? null}
                filterOptions={(options, state) => options.filter(o => o.name.toLowerCase().includes(state.inputValue.toLowerCase()))}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.uuid}>
                      {option.name}
                    </li>
                  )
                }}
                onChange={(e: any, value: AccountAllowedPlace | null) => {
                  setFieldTouched('places', true, false)
                  setFieldValue('places', value != null ? [value] : [])
                }}
                isOptionEqualToValue={(option: AccountAllowedPlace, value: AccountAllowedPlace) => option.uuid === value.uuid}
                options={
                  placeListQuery.data?.map(e => { return { uuid: e.uuid, name: e.name }}) ?? []
                }
                noOptionsText={texts('accounts:no_places')}
                sx={{flexGrow: 1, maxWidth: '100%'}}
                getOptionLabel={(option: AccountAllowedPlace) => option.name}
                renderInput={(params: TextFieldProps) => (
                  <MuiTextField
                    {...params}
                    error={touched.places && !!errors.places}
                    helperText={touched.places ? errors.places as string : undefined}
                    label={texts('accounts:place')}
                  />
                )}
              />
          : null
        }
      </Grid>
      <DialogActions sx={{paddingTop: '1.25rem'}}>
        <LoadingButton
          type='submit'
          variant={'contained'}
          size={'medium'}
          loading={props.loading}
        >
          {texts('common:next')}
        </LoadingButton>
      </DialogActions>
    </>
  )
}

export default SecondStep