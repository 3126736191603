import { Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { VariantAvailability } from '../../../../types/menu'
import { useMemo } from 'react'
import VariantOptionItem from './VariantOptionItem'

type Props = {
  variant: VariantAvailability
  onOptionCheckboxClick: (checked: boolean, uuid: string) => void
  selectedOptions: string[]
}


const VariantItem = (props: Props) => {
  const texts = useTranslation().t

  const options = useMemo(() => {
    return props.variant.options.map((option, index) => (
      <VariantOptionItem
        key={option.uuid}
        option={option}
        checked={props.selectedOptions.includes(option.uuid!)}
        onCheckboxClick={props.onOptionCheckboxClick}
      />
    ))
  }, [props.variant.options, props.selectedOptions, props.onOptionCheckboxClick])

  return (
    <Grid
      container
      position={'relative'}
      padding={'16px 28px 16px 28px'}
      borderRadius={'0.938rem'}
      boxShadow={'0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.5)'}
      wrap={'nowrap'}
      direction={'column'}
      gap={'20px'}
      sx={{
        borderColor: '#94C11F',
        borderWidth: 3
      }}
    >
      <Typography 
        variant='h2'
        sx={{
          fontSize: '1.125rem',
          marginTop: '0.0625rem'
        }}
      >
        {props.variant.name}
      </Typography>
      <Grid
        container item
        display={props.variant.options.length > 0 ? 'auto' : 'none'}
        gap={'6px'}
        direction={'column'}
      >
        {options}
      </Grid>
      {
        props.variant.note
        ? <Grid container item>
            <Typography 
              variant='body2'
              fontStyle='italic'
              textTransform='none'
            >
              {texts('menu:note', { val: props.variant.note })}
            </Typography>
          </Grid>
        : null
      }
    </Grid>
  )
}

export default VariantItem