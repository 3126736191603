import { Collapse, Grid, IconButton, TableCell,  TableRow, Typography } from '@mui/material'
import { useMemo, useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { useTranslation } from 'react-i18next'
import { Column, OrderDetails, OrderRatingGradeType, OrderState, RejectionType } from '../../types/order'
import moment from 'moment-timezone'
import currency from 'currency.js'
import { AllowedPickupTypes } from '../../types/place'

type Props = {
  row: OrderDetails
  backgroundColor?: string
}

const CollapsingTableRow = (props: Props) => {
  const texts = useTranslation().t
  const rowSummary = props.row.summary

  const [open, setOpen] = useState(false)

  const columns: Column[] = texts('objects:reports_columns', {returnObjects: true}) as Column[]

  const mapRow: string[] = useMemo(() => {
    const entries: string[] = []
    entries.push(props.row.number)
    entries.push(props.row.employee ?? '-')
    entries.push(moment(props.row.createdAt).format('DD.MM.YYYY HH:mm'))
    entries.push(props.row.deliveryDate != null ? moment(props.row.deliveryDate).format('DD.MM.YYYY HH:mm') : '-')
    entries.push(props.row.readyDate != null ? moment(props.row.readyDate).format('DD.MM.YYYY HH:mm') : '-') 
    entries.push(props.row.pickupDate != null ? moment(props.row.pickupDate).format('DD.MM.YYYY HH:mm') : '-') 
    entries.push(currency(props.row.summary.totalCost, { fromCents: true, decimal: ',', symbol: '' }).format())
    entries.push(texts('reports:pickup_type_label', { type: props.row.pickupType }))
    entries.push(texts('order:status', { status: props.row.status }))
    entries.push(texts('order:paymentStatus', { status: props.row.paymentStatus }))
    return entries
  }, [props.row, texts])

  return (
    <>
      <TableRow 
        sx={{
          backgroundColor: props.backgroundColor ?? 'inherit',
          height: '20px'
        }}
      >
        <TableCell
          sx={{
            px: 0,
            marginInline: 'auto',
            ...(
              columns[0].width
              ? { width: columns[0].width }
              : {}
            ),
            ...(
              columns[0].minWidth
              ? { minWidth: columns[0].minWidth }
              : {}
            ),
            ...(
              columns[0].maxWidth
              ? { maxWidth: columns[0].maxWidth }
              : {}
            ),
            ...(
              columns[0]?.contentTextAlign
              ? { textAlign: columns[0]?.contentTextAlign }
              : {}
            )
          }}
        >
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {mapRow.map(((value, index) => (
          <TableCell 
            key={index}
            sx={{
              fontSize: '0.875rem',
              textTransform: 'none',
              ...(
                columns[index+1].width
                ? { width: columns[index+1].width }
                : {}
              ),
              ...(
                columns[index+1].minWidth
                ? { minWidth: columns[index+1].minWidth }
                : {}
              ),
              ...(
                columns[index+1].maxWidth
                ? { maxWidth: columns[index+1].maxWidth }
                : {}
              ),
              ...(
                columns[index+1]?.contentTextAlign
                ? { textAlign: columns[index+1]?.contentTextAlign }
                : {}
              ),
              ...(
                columns[index+1]?.wordBreak
                ? { wordBreak: columns[index+1]?.wordBreak }
                : {}
              )
            }}
          >
            {value}
          </TableCell>
        )))}
      </TableRow>
      <TableRow
        sx={{
          width: '100%',
          backgroundColor: props.backgroundColor ?? 'inherit'
        }}
      >
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Grid 
              container
              flexDirection={'column'}
              sx={{ 
                margin: '0.75rem',
                paddingLeft: '3.25rem'
              }}
              gap={'1rem'}
            >
              <Grid
                item 
                container
                flexDirection={'column'}
                gap={'0.5rem'}
              >
                <Typography
                  variant={'h5'}
                  sx={{
                    textTransform: 'none',
                    fontFamily: 'RobotoMedium',
                    fontSize: '0.875rem'
                  }}
                >
                  {texts('reports:external_payment_id')}
                </Typography>
                <Typography
                  variant={'body2'}
                  sx={{
                    fontSize: '0.875rem',
                    textTransform: 'none'
                  }}
                >
                  {props.row.externalPaymentId ?? '-'}
                </Typography>
              </Grid>
              <Grid
                item 
                container
                flexDirection={'column'}
                gap={'0.5rem'}
              >
                <Typography
                  variant={'h5'}
                  sx={{
                    textTransform: 'none',
                    fontFamily: 'RobotoMedium',
                    fontSize: '0.875rem'
                  }}
                >
                  {texts('reports:user_uuid')}
                </Typography>
                <Typography
                  variant={'body2'}
                  sx={{
                    fontSize: '0.875rem',
                    textTransform: 'none'
                  }}
                >
                  {props.row.userUuid}
                </Typography>
              </Grid>
              {
                props.row.status === OrderState.REJECTED
                ? <Grid
                    item 
                    container
                    flexDirection={'column'}
                    gap={'0.5rem'}
                  >
                    <Typography
                      variant={'h5'}
                      sx={{
                        textTransform: 'none',
                        fontFamily: 'RobotoMedium',
                        fontSize: '0.875rem'
                      }}
                    >
                      {texts('reports:rejection_reason_label')}
                    </Typography>
                    <Typography
                      variant={'body2'}
                      sx={{
                        fontSize: '0.875rem',
                        textTransform: 'none'
                      }}
                    >
                      {texts('reports:rejection_reason', { reason: props.row.rejection?.type, message: props.row.rejection?.message ?? '-' })}
                      {`${props.row.rejection?.type === RejectionType.MISSING_PRODUCTS && (props.row.rejection.missingProducts ?? [].length > 0) 
                        ? `: ${props.row.rejection.missingProducts!.map(p => p.name).join(', ')}` : ''}`}
                    </Typography>
                  </Grid>
                : null
              }
              <Grid
                item 
                container
                flexDirection={'column'}
                gap={'0.5rem'}
              >
                <Typography
                  variant={'h5'}
                  sx={{
                    textTransform: 'none',
                    fontFamily: 'RobotoMedium',
                    fontSize: '0.875rem'
                  }}
                >
                  {texts('reports:phone_number')}
                </Typography>
                <Typography
                  variant={'body2'}
                  sx={{
                    fontSize: '0.875rem',
                    textTransform: 'none'
                  }}
                >
                  {props.row.contactNumber ?? '-'}
                </Typography>
              </Grid>
              {
                props.row.pickupType === AllowedPickupTypes.DELIVERY &&
                  <Grid
                    item 
                    container
                    flexDirection={'column'}
                    gap={'0.5rem'}
                  >
                    <Typography
                      variant={'h5'}
                      sx={{
                        textTransform: 'none',
                        fontFamily: 'RobotoMedium',
                        fontSize: '0.875rem'
                      }}
                    >
                      {texts('reports:delivery_address')}
                    </Typography>
                    <Typography
                      variant={'body2'}
                      sx={{
                        fontSize: '0.875rem',
                        textTransform: 'none'
                      }}
                    >
                      {props.row.address ? texts('reports:delivery_address_value', props.row.address) : ''}
                    </Typography>
                  </Grid>
              }
              <Grid
                item 
                container
                flexDirection={'column'}
                gap={'0.5rem'}
              >
                <Typography
                  variant={'h5'}
                  sx={{
                    textTransform: 'none',
                    fontFamily: 'RobotoMedium',
                    fontSize: '0.875rem'
                  }}
                >
                  {texts('reports:ordered_products')}
                </Typography>
                {
                  rowSummary.items.map((product, idx) => (
                    <Grid
                      item
                      container
                      columnGap={'3rem'}
                      key={`${idx}-${product.product.uuid}`}
                    >
                      <Typography
                        variant={'body2'}
                        sx={{
                          fontSize: '0.875rem',
                          textTransform: 'none'
                        }}
                      >
                        {`${product.amount} x ${texts('common:currency', { localValue: currency(product.totalCost, { fromCents: true, symbol: '', decimal: ',' }).divide(product.amount).value })}`}
                      </Typography>
                      <Typography
                        variant={'body2'}
                        sx={{
                          fontSize: '0.875rem',
                          textTransform: 'none'
                        }}
                      >
                        {product.product.name}
                        {
                          product.variants && product.variants.length > 0
                          ? ': ' + product.variants.map(variant => `${variant.name} (${variant.option.name})`).join(', ')
                          : null
                        }
                      </Typography>
                      <Typography
                        variant={'body2'}
                        sx={{
                          fontSize: '0.875rem',
                          textTransform: 'none'
                        }}
                        noWrap
                      >
                        {`${texts('reports:additions')}: `}
                        {
                          product.additions != null && product.additions.length > 0
                            ? product.additions.map(add => add.name).join(', ')
                            : texts('reports:none')
                        }
                      </Typography>
                    </Grid>
                  ))
                }
              </Grid>
              {
                props.row.summary.discount != null &&
                  <>
                    <Grid
                      item 
                      container
                      gap={'0.5rem'}
                      alignItems='center'
                    >
                      <Typography
                        variant={'h5'}
                        sx={{
                          textTransform: 'none',
                          fontFamily: 'RobotoMedium',
                          fontSize: '0.875rem',
                          lineHeight: 1
                        }}
                      >
                        {texts('reports:total_cost')}
                      </Typography>
                      <Typography
                        variant={'body2'}
                        sx={{
                          fontSize: '0.875rem',
                          textTransform: 'none'
                        }}
                      >
                        {texts('common:currency', { localValue: currency(props.row.summary.noDiscountCost, { fromCents: true, decimal: ',', symbol: '' }).value })}
                      </Typography>
                    </Grid>
                    <Grid
                      item 
                      container
                      gap={'0.5rem'}
                      alignItems='center'
                    >
                      <Typography
                        variant={'h5'}
                        sx={{
                          textTransform: 'none',
                          fontFamily: 'RobotoMedium',
                          fontSize: '0.875rem',
                          lineHeight: 1
                        }}
                      >
                        {texts('reports:discount', { percentage: (props.row.summary.discount * 100).toFixed() })}
                      </Typography>
                      <Typography
                        variant={'body2'}
                        sx={{
                          fontSize: '0.875rem',
                          textTransform: 'none'
                        }}
                      >
                        {texts('common:currency', { localValue: currency(props.row.summary.discountValue!, { fromCents: true, decimal: ',', symbol: '' }).value })}
                      </Typography>
                    </Grid>
                  </>
              }
              <Grid
                item 
                container
                gap={'0.5rem'}
                alignItems='center'
              >
                <Typography
                  variant={'h5'}
                  sx={{
                    textTransform: 'none',
                    fontFamily: 'RobotoMedium',
                    fontSize: '0.875rem',
                    lineHeight: 1
                  }}
                >
                  {texts('reports:cost')}
                </Typography>
                <Typography
                  variant={'body2'}
                  sx={{
                    fontSize: '0.875rem',
                    textTransform: 'none'
                  }}
                >
                  {texts('common:currency', { localValue: currency(props.row.summary.totalCost, { fromCents: true, decimal: ',', symbol: '' }).value })}
                </Typography>
              </Grid>
              <Grid
                item 
                container
                flexDirection={'column'}
                gap={'0.5rem'}
              >
                <Typography
                  variant={'h5'}
                  sx={{
                    textTransform: 'none',
                    fontFamily: 'RobotoMedium',
                    fontSize: '0.875rem'
                  }}
                >
                  {texts('reports:comment')}
                </Typography>
                <Typography
                  variant={'body2'}
                  sx={{
                    fontSize: '0.875rem',
                    textTransform: 'none'
                  }}
                >
                  {props.row.comment ?? texts('reports:none')}
                </Typography>
              </Grid>
              <Grid
                item 
                container
                flexDirection={'column'}
                gap={'0.5rem'}
              >
                <Typography
                  variant={'h5'}
                  sx={{
                    textTransform: 'none',
                    fontFamily: 'RobotoMedium',
                    fontSize: '0.875rem'
                  }}
                >
                  {texts('reports:questionnaire')}
                </Typography>
                {
                  props.row.rating
                  ? <>
                      <Typography
                        variant={'body2'}
                        sx={{
                          fontSize: '0.875rem',
                          textTransform: 'none'
                        }}
                      >
                        {`${texts('reports:questionnaireOrder')} - `}
                        <strong>
                          {props.row.rating.grades.find(g => g.type === OrderRatingGradeType.ORDER)?.value ?? '-'}
                        </strong>
                      </Typography>
                      <Typography
                        variant={'body2'}
                        sx={{
                          fontSize: '0.875rem',
                          textTransform: 'none'
                        }}
                      >
                        {`${texts('reports:questionnaireProducts')} - `}
                        <strong>
                          {props.row.rating.grades.find(g => g.type === OrderRatingGradeType.PRODUCTS)?.value ?? '-'}
                        </strong>
                      </Typography>
                      <Typography
                        variant={'body2'}
                        sx={{
                          fontSize: '0.875rem',
                          textTransform: 'none'
                        }}
                      >
                        {`${texts('reports:questionnaireService')} - `}
                        <strong>
                          {props.row.rating.grades.find(g => g.type === OrderRatingGradeType.SERVICE)?.value ?? '-'}
                        </strong>
                      </Typography>
                      <Typography
                        variant={'body2'}
                        sx={{
                          fontSize: '0.875rem',
                          textTransform: 'none'
                        }}
                      >
                        {`${texts('reports:questionnaireApplication')} - `}
                        <strong>
                          {props.row.rating.grades.find(g => g.type === OrderRatingGradeType.APPLICATION)?.value ?? '-'}
                        </strong>
                      </Typography>
                      <Typography
                        variant={'body2'}
                        sx={{
                          fontSize: '0.875rem',
                          textTransform: 'none'
                        }}
                      >
                        {texts('reports:questionnaireComment')}
                      </Typography>
                      <Typography
                        variant={'body2'}
                        sx={{
                          fontSize: '0.875rem',
                          textTransform: 'none',
                          fontStyle: 'italic'
                        }}
                      >
                        {
                          props.row.rating.comment != null && props.row.rating.comment.trim() !== ''
                          ? props.row.rating.comment
                          : texts('reports:questionnaireNoComment')
                        }
                      </Typography>
                    </>
                  : <Typography
                      variant={'body2'}
                      sx={{
                        fontSize: '0.875rem',
                        textTransform: 'none',
                        fontStyle: 'italic'
                      }}
                    >
                      {texts('reports:questionnaireNotFilled')}
                    </Typography>
                }
              </Grid>
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default CollapsingTableRow