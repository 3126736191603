import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import DataApi from '../../../api/data/DataApi'
import { mapVariantTemplateData } from '../../../api/data/mapping'
import { VariantFormData } from '../../../types/menu'
import { showErrorToast } from '../../../utils/error'
import { queryNames } from '../../queries'

const dataApi = DataApi.getInstance()

export default function useCreateVariantTemplate() {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const texts = useTranslation().t
  const mutation = useMutation((data: VariantFormData) => dataApi.createNewVariantTemplate(mapVariantTemplateData(data)), {
    onError: (e) => {
      showErrorToast(e, texts)
    },
    onSuccess: () => {
      queryClient.resetQueries(queryNames.variantList)
      toast.success(texts('successes:variant_added'))
      navigate('/menu/variants', { replace: true })
    }
  })
  return mutation
}