import { Box, Button, Grid, Typography } from '@mui/material'
import React from 'react'
import { DragDropContext, DropResult } from '@hello-pangea/dnd'
import { useTranslation } from 'react-i18next'
import { AiFillPlusCircle } from 'react-icons/ai'
import { useQueryClient } from 'react-query'
import DraggableItem from '../../components/Common/DraggableItem'
import DroppableList from '../../components/Common/DroppableList'
import Loader from '../../components/Common/Loader'
import NetworkError from '../../components/Common/NetworkError'
import LoadingButton from '../../components/CustomMui/LoadingButton'
import ImageUploadDialog from '../../components/ImageUploadDialog/ImageUploadDialog'
import BannerCard from '../../components/Marketing/BannersCard'
import useGetBannerList from '../../hooks/banner/useGetBannerList'
import usePutBanners from '../../hooks/banner/usePutBanners'
import { queryNames } from '../../hooks/queries'
import { BannerFormData, UploadedPhotoResponse } from '../../types/common'
import { removeArrayElement, reorder } from '../../utils/array'
import { evalStringTemplate } from '../../utils/text'
import { useState } from 'react'

const maxBannerCount = Number.parseInt(process.env.REACT_APP_MAX_BANNER_COUNT!)

const Banners = () => {
  const texts = useTranslation().t
  const queryClient = useQueryClient()
  const { isFetching, isError } = useGetBannerList((data) => {
    setBanners(data)
  })
  const mutation = usePutBanners()

  const [uploadDialogOpen, setUploadDialogOpen] = useState<boolean>(false)
  const [banners, setBanners] = useState<BannerFormData[]>([])

  const onDragEnd = (result : DropResult) => {
    if (!result.destination) {
      return
    }

    const children: BannerFormData[]  = reorder(
      banners, result.source.index, result.destination.index
    )
    return setBanners(children)
  }

  const submitBanners = () => {
    mutation.mutate({
      data: banners.map(b => {
        return {
          ...(b.bannerUuid != null ? { bannerUuid: b.bannerUuid } : {}),
          ...(b.photoUuid != null ? { photoUuid: b.photoUuid } : {})
        }
      })
    })
  }

  const onUploadSuccess = (data: UploadedPhotoResponse) => {
        const newArr = [
          ...banners, 
          {
            photoUuid: data.uuid,
            photoUrl: data.url
          }
        ]
        setBanners(newArr)
      }

  return (
    <>
      <ImageUploadDialog
        aspectRatio={780/438}
        thumbnailWidth={780}
        minDimensions={{
          width: 780,
          height: 438
        }}
        croppedDimensions={{
          width: 780,
          height: 438
        }}
        open={uploadDialogOpen}
        onClose={() => { setUploadDialogOpen(false) }}
        onSuccess={onUploadSuccess}
      />
      {
        isError || isFetching
        ? <Grid sx={{height: 'calc(100vh - 160px)'}}>
          {
            isError
            ? <NetworkError
                withRefresh
                onRefresh={() => {
                  queryClient.resetQueries(queryNames.bannerList)
                }}
              />
            : <Loader 
                width={'100%'}
                text={texts('common:loading')}
              />
          }
          </Grid>
        : <Grid 
            container
            flexDirection={'row'}
          >
            <Grid 
              item
              container
              justifyContent={'space-between'}
              alignItems={'center'}
              sx={{
                marginBottom: '29px'
              }}
            > 
              <Typography variant='h2'>
                {texts('banners:title')}
              </Typography> 
              <LoadingButton
                variant={'contained'}
                size={'medium'}
                loading={mutation.isLoading}
                sx={{
                  width: 'fit-content',
                  px: '2rem',
                  whiteSpace: 'nowrap'
                }}
                onClick={submitBanners}
              >
                {texts('banners:save')}
              </LoadingButton>
            </Grid>
            <DragDropContext onDragEnd={onDragEnd}>
              <Box
                sx={{
                  backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='rgba(0,0,0,0.23)' stroke-width='2' stroke-dasharray='15%2c20' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
                  width: '100%',
                  height: '100%',
                  borderRadius: '0.3125rem',
                  margin: 'auto',
                  marginBottom: '10px',
                }}
              >
                <Box
                  sx={{
                    fontSize: '1rem',
                    marginTop: '1.375rem',
                    marginLeft: '1.625rem'
                  }}
                >
                  <Typography>
                    {evalStringTemplate(texts('banners:addedCount'), { addedCount: banners.length, maxCount: maxBannerCount})}
                  </Typography>
                </Box>
                <DroppableList droppableId={'banner'} boxDirection='horizontal'>
                  <Box
                    sx={{
                      gap:'2rem',
                      display:'flex',
                      marginLeft: '1.625rem'
                    }}
                  >
                    {
                      banners.map((photo, index) => (
                        <DraggableItem 
                          id={photo.bannerUuid ?? photo.photoUuid!}
                          index={index} 
                          key={photo.bannerUuid ?? photo.photoUuid!}
                        >
                          <BannerCard 
                            photo={photo}
                            onRemove={() => {
                              const newItems = removeArrayElement(banners, index)
                              setBanners(newItems)
                            }}
                          />
                        </DraggableItem>
                      ))
                    }
                  </Box>
                </DroppableList>
                <Button
                  disabled={banners.length >= maxBannerCount}
                  onClick={() => setUploadDialogOpen(true)}
                  sx={{
                    border: 'none',
                    float: 'right',
                    minWidth: '0',
                    marginTop: '1.625rem',
                    width: 'fit-content',
                    opacity: banners.length >= maxBannerCount ? 0.5 : 1,
                    '&:hover': {
                      backgroundColor: '#fff'
                    },
                    marginLeft: 'auto',
                    marginBottom: '10px',
                    marginRight: '20px'
                  }}
                >
                  <AiFillPlusCircle 
                    size={22}
                    color={'#000000'}
                  />
                  <Typography
                    variant='body2' 
                    sx={{
                      fontFamily: 'RobotoCondensedBold',
                      fontSize: '1.25rem',
                      marginLeft: '0.156rem',
                      textTransform: 'none',
                      color: '#000000'
                    }}
                  >
                    {texts('banners:image_placeholder')}
                  </Typography>
                </Button>
              </Box>
            </DragDropContext>
            <Grid>
              <Typography sx={{ lineHeight: 1.2 }}>
                {texts('banners:descriptions')}
              </Typography>
            </Grid>
          </Grid>
      }
    </>
  )
}

export default Banners