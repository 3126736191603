import { DraggableProvided } from '@hello-pangea/dnd'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import TimerIcon from '@mui/icons-material/Timer'
import { Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import currency from 'currency.js'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { MenuVariantOption } from '../../../types/menu'
import { mapSecondsToString } from '../../../utils/mapping'
import { evalStringTemplate } from '../../../utils/text'
import Chip from '../../Common/Chip'
import Tag from '../../Common/Tag'

type Props = {
  variantOption: MenuVariantOption
  variantCardId: string
  categoryCardId: string
  productCardId: string
  provided?: DraggableProvided
}

const VariantOptionCard = (props: Props) => {
  const texts = useTranslation().t
  
  return (
    <>
      <Grid
        container
        minHeight={'2.5rem'}
        py={'0.5rem'}
        px={'1rem'}
        borderRadius={'0.938rem'}
        gap={'0.938rem'}
        wrap={'nowrap'}
        alignItems={'center'}
        sx={{
          backgroundColor: '#f5f5f5',
          position: 'relative'
        }}
      >
        <Box
          {...props.provided?.dragHandleProps}
        >
          <DragHandleIcon /> 
        </Box>
        <Typography 
          variant='h5'
          sx={{
            fontFamily: 'RobotoRegular',
            minWidth: '10rem',
            opacity: props.variantOption.active ? 1 : 0.5
          }}
        >
          {props.variantOption.name}
        </Typography>
        <Grid
          item
          container
          sx={{
            opacity: props.variantOption.active ? 1 : 0.5
          }}
          gap={'0.938rem'}
        >
          <Tag
            text={`+${mapSecondsToString(props.variantOption.customPreparationTime ?? props.variantOption.preparationTime)}`}
            opacity={(props.variantOption.customPreparationTime ?? props.variantOption.preparationTime) === 0}
          >
            <TimerIcon/>
          </Tag>
          <Tag
            text={texts('common:currency', { localValue: currency((props.variantOption.customPrice ?? props.variantOption.price), { fromCents: true }).value })}
            opacity={(props.variantOption.customPrice ?? props.variantOption.price) === 0}
          >
            <LocalOfferIcon/>
          </Tag>
          {
            props.variantOption.default
            ? <Chip text={texts('variants:default')}/>
            : null
          }
          {
            !props.variantOption.active
            ? <Chip
                text={
                  props.variantOption.inactiveUntil 
                  ? evalStringTemplate(texts('common:inactive_until'), { date: moment(props.variantOption.inactiveUntil).format('DD.MM.YYYY, HH:mm') })
                  : texts('common:inactive')
                }
                opacity={0.3}
              />
            : null
          }
        </Grid>        
      </Grid>
    </>
  )
}

export default VariantOptionCard
