import { Box } from '@mui/material'
import React from 'react'
import { Draggable } from '@hello-pangea/dnd'

type Props= {
  id: string
  index: number
  children: React.ReactElement
}

const DraggableItem = ({ id: uuid, index, children }: Props) => {
  return (
    <Draggable draggableId={uuid} index={index}>
      {
        (provided, snapshot) => (
          <Box
            ref={provided.innerRef}
            {...provided.draggableProps}
          > 
            { React.cloneElement(children, { provided: provided }) }
          </Box>
        )
      }
    </Draggable>
  )
}

export default DraggableItem