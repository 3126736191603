import { Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { File } from '../../../types/files'
import prettyBytes from 'pretty-bytes'
import moment from 'moment'

type Props = {
  file: File
}

const FileListRow = (props: Props) => {
  const texts = useTranslation().t

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  
  return (
    <Grid
      container
      position={'relative'}
      flexDirection={'column'}
      minHeight={'3rem'}
      py={'1rem'}
      px={'1rem'}
      marginTop={'0.938rem'}
      borderRadius={'0.938rem'}
      boxShadow={'0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.5)'}
      gap={'0.5rem'}
      sx={{backgroundColor: '#FFFFFF'}}
    >
      <Grid
        container
        alignItems={'flex-start'}
        flexWrap={'nowrap'}
      >
        <Grid
          item
          container
          gap={'0.5rem'}
          alignItems={'flex-start'}
          flexDirection='column'
        >
          <Grid
            item
            mr={'0.5rem'}
          >
            <Typography 
              variant='h2'
              sx={{fontSize: '1.125rem'}}
            >
              {props.file.filename}
            </Typography>
          </Grid>
          <Grid
            item
            mr={'0.5rem'}
          >
            <Typography 
              variant='body1'
            >
              <Trans
                i18nKey='files:file_description'
                values={{
                  name: `${props.file.user.firstName}${props.file.user.lastName ? ` ${props.file.user.lastName}` : ''} (${props.file.user.contactEmail}${props.file.user.phone ? `, ${props.file.user.phone}` : ''})`,
                  date: moment(props.file.createdAt).format('DD.MM.YYYY'),
                  size: prettyBytes(props.file.size)
                }}
              />
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          width='max-content'
          height='100%'
          alignItems='flex-start'
        >
          <IconButton
            onClick={handleClick}
            sx={{color: '#000'}}
          >
            <MoreHorizIcon/>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{
              style: {
                width: '12rem',
              },
            }}
          >
            <MenuItem onClick={() => { window.open(props.file.url) }}>
              {texts('files:download')}
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default FileListRow
