import { Box, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import PasswordField from '../../components/CustomMui/Fields/PasswordField'
import { Form, Formik, FormikHelpers } from 'formik'
import { changePasswordValidation } from '../../validations/changePasswordValidation'
import useChangePassword from '../../hooks/auth/useChangePassword'
import LoadingButton from '../../components/CustomMui/LoadingButton'

type PasswordFormData = {
  oldPassword: string
  newPassword: string
  repeatPassword: string
}

const initValues: PasswordFormData = {
  oldPassword: '',
  newPassword: '',
  repeatPassword: ''
}

const AccountSettings = () => {
  const texts = useTranslation().t
  
  const changePasswordMutation = useChangePassword()

  const handleSubmit = (data: PasswordFormData, formikHelpers: FormikHelpers<PasswordFormData>) => {
    changePasswordMutation.mutate({
      oldPassword: data.oldPassword,
      newPassword: data.newPassword
    })
    formikHelpers.resetForm()
  }

  return (
    <Grid 
      container
      flexDirection={'column'}
      gap={'2.5rem'}
    >
      <Formik<PasswordFormData>
        initialValues={initValues}
        onSubmit={handleSubmit}
        validationSchema={changePasswordValidation(texts)}
      >
        {() => (
          <Form>
            <Grid
              item
              container 
              wrap='nowrap'
              justifyContent={'space-between'} 
              alignItems={'center'}
            >
              <Typography variant='h2'>
                {texts('account:settings_title')}
              </Typography>
              <LoadingButton
                type='submit'
                variant={'contained'}
                size={'medium'}
                sx={{
                  marginLeft: '0.625rem', 
                  px: '4rem',
                  gap: '0.5rem', 
                  width: 'max-content'
                }}
                loading={changePasswordMutation.isLoading}
              >
                {texts('common:save')}
              </LoadingButton>
            </Grid>
            <Grid
              item
              container
              flexDirection={'column'}
            >
              <Typography 
                variant='subtitle2'
                sx={{
                  mb: '1rem',
                  mt: '2.5rem'
                }}
              >
                {texts('account:change_password')}
              </Typography>
              <Box width={'41rem'}>
                <Box marginBottom={'0.125rem'}>
                  <PasswordField
                    name='oldPassword'
                    label={texts('account:old_password')}
                  />
                </Box>
                <Box marginBottom={'0.125rem'}>
                  <PasswordField
                    name='newPassword'
                    label={texts('account:new_password')}
                  />
                </Box>
                <Box marginBottom={'0.125rem'}>
                  <PasswordField
                    name='repeatPassword'
                    label={texts('account:repeat_password')}
                  />
                </Box>
              </Box>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  )
}

export default AccountSettings