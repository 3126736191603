import { Pagination } from '@mui/material'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import useGetPlaceVariantAvailabilityList from '../../../../hooks/place/useGetPlaceVariantAvailabilityList'
import { AvailabilityListSearchParams, AvailabilityTypeOption } from '../../../../types/menu'
import { DropdownOption } from '../../../CustomMui/DropdownMenu'
import VariantItem from '../Items/VariantItem'
import List from '../List'
import QueryParamsSection from '../QueryParamsSection'

const variantsPerPage = Number.parseInt(process.env.REACT_APP_DEFAULT_PAGE_LIMIT!)

type Props = {
  disableQueries?: boolean
  onSelectionChanged: (checked: boolean, uuid: string) => void
  selectedItems: string[]
}

const VariantAvailabilityList = (props: Props) => {
  const texts = useTranslation().t

  const typeOptions: AvailabilityTypeOption[] = useMemo(() => {
    return texts('objects:manage_availability_types', {
      returnObjects: true 
    }) as AvailabilityTypeOption[]
  }, [texts])

  const [searchParams, setSearchParams] = useQueryParams<AvailabilityListSearchParams>({
    type: withDefault(StringParam, typeOptions[0].value),
    place: withDefault(StringParam, ''),
    search: withDefault(StringParam, ''),
    order: withDefault(StringParam, 'date:desc'),
    page: withDefault(NumberParam, 1)
  })

  const sortOptions: DropdownOption[] = useMemo(() => {
    return texts('objects:variants_sort_options', {
      returnObjects: true 
    }) as DropdownOption[]
  }, [texts])

  const variantList = useGetPlaceVariantAvailabilityList(
    searchParams.place,
    {
      limit: variantsPerPage,
      offset: (searchParams.page - 1) * variantsPerPage,
      sort: searchParams.order,
      search: searchParams.search,
      enabled: !props.disableQueries,
      onSuccess: (data) => {
        if (searchParams.page > 1 && data.data.length === 0) {
          setSearchParams({
            page: 1, 
            order: 'date:desc',
            search: '',
            type: typeOptions[0].value
          }, 'replaceIn')
        }
      }
    }
  )

  if (!variantList.isFetching && searchParams.page > 1 && variantList.data!.data.length === 0) {
    return (<></>)
  }

  return (
    <>
      <QueryParamsSection 
        searchBarProps={{
          initialValue: searchParams.search,
          onClick: (text) => setSearchParams({ search: text, page: 1 }, 'replaceIn'),
          placeholder: texts('menu:availability_search_placeholder', { type: searchParams.type }) as string
        }}
        dropDownMenuProps={{
          onChange: (event) => setSearchParams({ order: event.target.value, page: 1 }, 'replaceIn'),
          value: searchParams.order.toLowerCase(),
          options: sortOptions
        }}
      />
      <List
        isLoading={variantList.isFetching}
        isError={variantList.isError}
        items={variantList.data?.data.map(variant => (
          <VariantItem
            key={variant.uuid}
            variant={variant}
            onOptionCheckboxClick={props.onSelectionChanged}
            selectedOptions={props.selectedItems}
          /> 
        )) ?? []}
      />
      { 
        !variantList.isFetching && variantList.maxPage > 1
        ? <Pagination
            count={variantList.maxPage}
            page={searchParams.page}
            onChange={(e, page) => setSearchParams({ page: page }, 'replaceIn')}
            sx={{
              position: 'absolute',
              bottom: '30px',
              alignSelf: 'center'
            }}
          />
        : null
      }
    </>
  )
}

export default VariantAvailabilityList
