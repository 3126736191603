import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type State = {
  redirectTo: string | null
}

const defaultState: State = {
  redirectTo: null
}

const slice = createSlice({
  name: 'redirectStorage',
  initialState: defaultState,
  reducers: {
    redirectTo: (state, action: PayloadAction<string>) => {
      state.redirectTo = action.payload
    },
    clearRedirect: (state) => {
      state.redirectTo = null
    }
  }
})

export const {
  redirectTo,
  clearRedirect
} = slice.actions

export default slice.reducer