import { Pagination } from '@mui/material'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import useGetPlaceProductAvailabilityList from '../../../../hooks/place/useGetPlaceProductAvailabilityList'
import { AvailabilityListSearchParams, AvailabilityTypeOption } from '../../../../types/menu'
import { DropdownOption } from '../../../CustomMui/DropdownMenu'
import ProductItem from '../Items/ProductItem'
import List from '../List'
import QueryParamsSection from '../QueryParamsSection'

const productsPerPage = Number.parseInt(process.env.REACT_APP_DEFAULT_PAGE_LIMIT!)

type Props = {
  disableQueries?: boolean
  onSelectionChanged: (checked: boolean, uuid: string) => void
  selectedItems: string[]
}

const ProductAvailabilityList = (props: Props) => {
  const texts = useTranslation().t

  const typeOptions: AvailabilityTypeOption[] = useMemo(() => {
    return texts('objects:manage_availability_types', {
      returnObjects: true 
    }) as AvailabilityTypeOption[]
  }, [texts])

  const [searchParams, setSearchParams] = useQueryParams<AvailabilityListSearchParams>({
    type: withDefault(StringParam, typeOptions[0].value),
    place: withDefault(StringParam, ''),
    search: withDefault(StringParam, ''),
    order: withDefault(StringParam, 'date:desc'),
    page: withDefault(NumberParam, 1)
  })

  const sortOptions: DropdownOption[] = useMemo(() => {
    return texts('objects:products_sort_options', {
      returnObjects: true 
    }) as DropdownOption[]
  }, [texts])

  const productList = useGetPlaceProductAvailabilityList(
    searchParams.place,
    {
      limit: productsPerPage,
      offset: (searchParams.page - 1) * productsPerPage,
      sort: searchParams.order,
      search: searchParams.search,
      enabled: !props.disableQueries,
      onSuccess: (data) => {
        if (searchParams.page > 1 && data.data.length === 0) {
          setSearchParams({
            page: 1, 
            order: 'date:desc',
            search: '',
            type: typeOptions[0].value
          }, 'replaceIn')
        }
      }
    }
  )

  if (!productList.isFetching && searchParams.page > 1 && productList.data!.data.length === 0) {
    return (<></>)
  }

  return (
    <>
      <QueryParamsSection 
        searchBarProps={{
          initialValue: searchParams.search,
          onClick: (text) => setSearchParams({ search: text, page: 1 }, 'replaceIn'),
          placeholder: texts('menu:availability_search_placeholder', { type: searchParams.type }) as string
        }}
        dropDownMenuProps={{
          onChange: (event) => setSearchParams({ order: event.target.value, page: 1 }, 'replaceIn'),
          value: searchParams.order.toLowerCase(),
          options: sortOptions
        }}
      />
      <List
        isLoading={productList.isFetching}
        isError={productList.isError}
        items={productList.data?.data.map(product => (
          <ProductItem
            key={product.uuid}
            product={product}
            onCheckboxClick={props.onSelectionChanged}
            checked={props.selectedItems.includes(product.uuid)}
          /> 
        )) ?? []}
      />
      { 
        !productList.isFetching && productList.maxPage > 1
        ? <Pagination
            count={productList.maxPage}
            page={searchParams.page}
            onChange={(e, page) => setSearchParams({ page: page }, 'replaceIn')}
            sx={{
              position: 'absolute',
              bottom: '30px',
              alignSelf: 'center'
            }}
          />
        : null
      }
    </>
  )
}

export default ProductAvailabilityList
