import * as Yup from 'yup'
import { Role } from '../types/accounts'
import { emailRegexp } from './validationRegexps'

export function createAccessSecondStepValidation(t: (val: string) => string) {
  return Yup.object({
    name: Yup.string()
      .max(120, t('validations:max_120_signs_validation'))
      .required(t('validations:field_required')),
    email: Yup.string()
      .test('email_required', t('validations:field_required'), function (val: any) {
        let role = this.resolve(Yup.ref('role')) as Role
        return !((role === Role.SYSTEM_ADMIN || role === Role.PLACE_ADMIN) && (val == null || val === ''))
      })
      .matches(emailRegexp, t('validations:invalid_email_validation')),
    places: Yup.array().test('places_required', t('validations:field_required'), function (val: any) {
      let role = this.resolve(Yup.ref('role')) as Role
      return !((role === Role.EMPLOYEE || role === Role.PLACE_ADMIN) && (val.length === 0))
    })
  })
}