import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { resetPasswordRequestValidation } from '../../validations/resetPasswordRequestValidation'
import { Box, Button, Grid, Typography } from '@mui/material'
import TextField from '../../components/CustomMui/Fields/TextField'
import LoadingButton from '../../components/CustomMui/LoadingButton'
import { useSendResetPasswordRequest } from '../../hooks/auth/useSendRestPasswordRequest'
import { EmailFormData } from '../../types/auth'

const RequestPasswordResetPage = () => {
  const texts = useTranslation().t
  const navigate = useNavigate()
  const mutation = useSendResetPasswordRequest()

  const onSubmitResetPassword = async (data: EmailFormData) => {
    mutation.mutate(data)
  }

  return (
    <Grid 
      container 
      direction={'column'} 
      justifyContent={'center'} 
      alignItems={'center'} 
      height={'calc(100vh - 130px)'}
    >
      <Typography 
        variant='h1' 
        sx={{marginBottom: '4.5rem'}}
      > 
        {texts(`auth:password_reset_request_title`)}
      </Typography>
      <Formik<EmailFormData>
        initialValues={{
          email: '',
        }}
        onSubmit={onSubmitResetPassword}
        validationSchema={resetPasswordRequestValidation(texts)}
      >
        {(formikProps) => (
          <Box width={'31.25rem'}>
            <Form>
              <Box>
                <TextField
                  name='email'
                  type='email'
                  label={texts('auth:email_address')}
                />
              </Box>
              <Grid 
                container 
                justifyContent={'space-between'}
              >
                <Button
                  variant={'outlined'}
                  size={'large'}
                  onClick={() => {navigate('/login')}}
                >
                  {texts('auth:back_to_login')}
                </Button>
                <LoadingButton
                  type='submit'
                  variant={'contained'}
                  size={'large'}
                  disabled={
                    !formikProps.isValid || !formikProps.dirty
                  }
                  loading={mutation.isLoading}
                >
                  {texts('auth:reset_password')}
                </LoadingButton>
              </Grid>
            </Form>
          </Box>
        )}
      </Formik>
    </Grid>
  )
}

export default RequestPasswordResetPage
