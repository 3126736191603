import { Button, DialogActions, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

type Props = {
  pin: string
  onClose: () => void
}

const SecondStep = (props: Props) => {
  const texts = useTranslation().t
  
  return (
    <>
      <Grid
        item 
        container
        flexDirection={'column'}
        sx={{width: '45rem'}}
        gap={'1rem'}
      >
        <Typography
          variant={'subtitle2'}
          sx={{mt: '1rem'}}
        >
          {texts('accounts:finished')}
        </Typography>
        <Typography
          variant={'body2'}
          sx={{
            textTransform: 'none',
            mt: '0.5rem'
          }}
        >
          {texts('accounts:pin_changed')}
        </Typography>
        <Typography
          variant={'body2'}
          sx={{
            textTransform: 'none',
            mb: '1rem'
          }}
        >
          {texts('accounts:pin_changed_warning')}
        </Typography>
        <Grid
          item
          container
          justifyContent={'center'}
        >
          <TextField
            value={props.pin}
            disabled
            sx={{
              maxWidth: '12rem',
              '.MuiOutlinedInput-input.Mui-disabled': {WebkitTextFillColor: '#000'}
            }}
            InputProps={{
              endAdornment: 
                <InputAdornment position='end'>
                  <CopyToClipboard text={props.pin}>
                    <IconButton edge='end'>
                      <ContentCopyIcon/>
                    </IconButton>
                  </CopyToClipboard>
                </InputAdornment>,
            }}
          /> 
        </Grid>
      </Grid>
      <DialogActions sx={{py: '1.25rem', px: 0}}>
        <Button
          variant={'contained'}
          size={'medium'}
          sx={{mt: '1rem'}}
          onClick={props.onClose}
        >
          {texts('common:close')}
        </Button>
      </DialogActions>
    </>
  )
}

export default SecondStep