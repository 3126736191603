import { Button, FormControlLabel, Grid, IconButton, Switch, TextField, Typography } from '@mui/material'
import { DatePicker, TimePicker } from '@mui/x-date-pickers-pro'
import moment from 'moment'
import { useMemo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiFillCloseCircle } from 'react-icons/ai'
import { ChangeState, OpeningHourException } from '../../../types/place'
import { DEFAULT_START_TIME, DEFAULT_END_TIME } from '../../../utils/constants'

const TIME_FORMAT = 'HH:mm:ss'

type Props = {
  value: OpeningHourException
  rowError?: string
  onRemove: () => void
  onSave: (value: OpeningHourException) => void
  onEdit: () => void
  onCancel: () => void
}

const calculateDuration = (value: OpeningHourException) => {
  const startTime = moment(value.start, TIME_FORMAT)
  const endTime = moment(value.end, TIME_FORMAT)

  let minutes: number = 0
  if (startTime.isAfter(endTime)) {
    minutes = 24 * 60 - moment(startTime).diff(endTime, 'minute')
  } else {
    minutes = moment(endTime).diff(startTime, 'minute')
  }

  return {
    hours: Math.floor(minutes / 60),
    minutes: minutes % 60
  }
}

const Row = (props: Props) => {
  const texts = useTranslation().t
  const [value, setValue] = useState<OpeningHourException>(props.value)
  const [dateError, setDateError] = useState<string | null>(null)
  const [fromError, setFromError] = useState<string | null>(null)
  const [untilError, setUntilError] = useState<string | null>(null)

  const duration = useMemo(() => calculateDuration(value), [value])

  useEffect(() => {
    setValue(props.value)
  }, [props.value.state, props.value])

  return (
    <>
      <Grid 
        container 
        alignItems={'center'}
        wrap={'nowrap'}
        marginBottom={'.5rem'}
      >
        <IconButton 
          onClick={props.onRemove}
          color={'primary'}
          sx={{
            minWidth: 0,
            marginRight: '0.5rem',
            visibility: value.state === ChangeState.INIT ? 'hidden' : 'visible',
            paddingBottom: '1.75rem'
          }}
        >
          <AiFillCloseCircle size={30}/>
        </IconButton>
        <Grid
          item
          container
          wrap='nowrap'
          gap='1.5rem'
        >
          <Grid 
            item
            sx={{
              minWidth: '9.125rem',
              maxWidth: '12.5rem',
              paddingBottom: !dateError ? '1.375rem' : 0
            }}
          > 
            <DatePicker
              label={texts('common:date')}
              value={value.date}
              onChange={val => {
                if (val) {
                  if (!moment(val).isValid()) {
                    setDateError(texts('validations:invalid_value'))
                  } else if (moment(val).startOf('day').isBefore(moment().startOf('day'))) {
                    setDateError(texts('validations:date_passed'))
                  } else {
                    setDateError(null)
                  }
                  setValue({
                    ...value,
                    date: moment(val).toDate()
                  })
                }
              }} 
              minDate={moment().startOf('day').toDate()}
              renderInput={({ inputProps, ...restParams }) => 
                <TextField 
                  {...restParams}
                  error={!!dateError}
                  helperText={dateError}
                  inputProps={{
                    ...inputProps,
                    placeholder: texts('common:dateInputPlaceholder'),
                  }}
                />
              }
              mask={'__.__.____'}
              disabled={value.state === ChangeState.SAVED}
            />
          </Grid>
          <Grid
            item
            container
            alignItems={'center'}
            sx={{
              maxWidth: '6.875rem',
              paddingBottom: '1.375rem'
            }}
          > 
            <FormControlLabel
              control={
                <Switch
                  checked={value.open}
                  onChange={(e) => {
                    setValue({
                      ...value,
                      open: e.target.checked,
                      start: DEFAULT_START_TIME,
                      end: DEFAULT_END_TIME
                    })
                    setFromError(null)
                    setUntilError(null)
                  }}
                  disabled={value.state === ChangeState.SAVED}
                />
              }
              label={value.open ? `${texts('place:open')}` : `${texts('place:closed')}`}
            />
          </Grid>
          <Grid 
            item
            sx={{
              minWidth: '6.75rem',
              maxWidth: '12.5rem',
              paddingBottom: !fromError ? '1.375rem' : 0,
              ...(!value.open ? { display: 'none' } : {})
            }}
          > 
            <TimePicker
              label={texts('common:from')}
              value={moment(
                value.open ? value.start : '00:00:00',
                TIME_FORMAT
                ).toDate()
              }
              onChange={time => {
                if (time) {
                  if (!moment(time, 'HH:mm').isValid()) {
                    setFromError(texts('validations:invalid_value'))
                  } else {
                    setFromError(null)
                  }
                  setValue({
                    ...value,
                    start: moment(time).format(TIME_FORMAT)
                  })
                }
              }} 
              renderInput={({ inputProps, ...restParams }) => 
                <TextField 
                  {...restParams}
                  error={!!fromError}
                  helperText={fromError}
                  inputProps={{
                    ...inputProps,
                    placeholder: texts('common:timeInputPlaceholder'),
                  }}
                />
              }
              ampm={false}  
              disabled={value.state === ChangeState.SAVED || !value.open}
            />
          </Grid>
          <Grid 
            item
            sx={{
              minWidth: '6.75rem',
              maxWidth: '12.5rem',
              paddingBottom: !untilError ? '1.375rem' : 0,
              ...(!value.open ? { display: 'none' } : {})
            }}
          > 
            <TimePicker
              label={texts('common:until')}
              value={moment(
                value.open ? value.end : '23:59:59',
                TIME_FORMAT
                ).toDate()
              }
              onChange={time => {
                if (time) {
                  if (!moment(time, 'HH:mm').isValid()) {
                    setUntilError(texts('validations:invalid_value'))
                  } else {
                    setUntilError(null)
                  }
                  setValue({
                    ...value,
                    end: moment(time).format(TIME_FORMAT)
                  })
                }
              }} 
              renderInput={({ inputProps, ...restParams }) => 
                <TextField 
                  {...restParams}
                  error={!!untilError}
                  helperText={untilError}
                  inputProps={{
                    ...inputProps,
                    placeholder: texts('common:timeInputPlaceholder'),
                  }}
                />
              }
              ampm={false}     
              disabled={value.state === ChangeState.SAVED || !value.open}
            />
          </Grid>
          <Grid 
            item
            container
            sx={{
              width: '13.5rem',
              paddingBottom: '1.375rem',
              ...(!value.open ? { display: 'none' } : {})
            }}
            alignItems={'center'}
          > 
            <Typography 
              variant='body1'
              noWrap
              sx={{
                opacity: value.open ? 1 : 0.5,
              ...(!value.open ? { display: 'none' } : {})
              }}
            >
              {
                untilError || fromError
                ? texts('place:number_of_hours', { hours: ' - '})
                : texts(
                    value.open && duration.minutes > 0 ? 'place:number_of_hours_and_minutes' : 'place:number_of_hours', 
                    value.open ? { hours: duration.hours, minutes: duration.minutes } : { hours: ' - ', minutes: duration.minutes }
                  )
              }         
            </Typography>
          </Grid>
          <Grid 
            item
            container
            alignItems={'center'}
            sx={{
              maxWidth: '12.5rem',
              paddingBottom: '1.375rem'
            }}
          > 
            {
              value.state === ChangeState.SAVED
              ? <Button
                  variant={'outlined'}
                  size={'small'}
                  onClick={props.onEdit}
                >
                  {texts('common:edit')}
                </Button>
              : <Grid 
                  item 
                  container
                  wrap='nowrap'
                  gap={'0.5rem'}
                >
                  <Button
                    variant={'contained'}
                    size={'small'}
                    onClick={() => {
                      if (dateError || untilError || fromError) {
                        return
                      }
                      props.onSave({
                        ...value,
                        state: ChangeState.SAVED
                      })
                    }}
                  >
                    {texts('common:save')}
                  </Button>
                   <Button
                    variant={'outlined'}
                    size={'small'}
                    sx={{minWidth: '6.25rem'}}
                    onClick={() => {
                      setDateError(null)
                      setFromError(null)
                      setUntilError(null)
                      props.onCancel()
                    }}                    >
                    {texts('common:cancel')}
                  </Button>
                </Grid>
            }
          </Grid>
        </Grid>
      </Grid>
      {
        props.rowError != null
        ? <Typography 
            variant='body2'
            sx={{
              fontFamily: 'RobotoCondensed',
              color: 'red',
              marginTop: '0.188rem',
              marginBottom: '.5rem',
              marginLeft: '3.75rem',
              textTransform: 'none'
            }}
          >
            {texts(props.rowError as any)}
          </Typography>
        : null
      }
    </>
  )
}


export default Row