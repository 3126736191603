import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import { OpeningHours } from '../../../types/place'
import { Grid, Typography } from '@mui/material'
import Row from './Row'
import weekDays from 'i18n-week-days'

const OpeningHoursForm = () => {
  const texts = useTranslation().t

  const { values } = useFormikContext<{openingHours: OpeningHours[]}>()

  return (
    <>
      <Typography variant='subtitle1'>{texts('place:opening_hours_section')}</Typography>
      <Grid 
        container
        direction={'column'}
      >
        <Typography variant='subtitle2' sx={{marginBottom: '1.875rem'}}>{texts('place:regular_schedule')}</Typography>
        {
          values.openingHours.map((val, index) => (
            <Row
              key={`day-${index}`}
              name={`openingHours.${index}`}
              day={val}
              label={weekDays['pl'][index].toLocaleLowerCase()}
            />
          ))
        }
      </Grid>
    </>
  )
}

export default OpeningHoursForm