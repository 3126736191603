import React from 'react'
import './i18n'
import './index.css'
import { createRoot } from 'react-dom/client'
import App, { ROUTER_BASE_NAME } from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { QueryParamProvider } from 'use-query-params'
import { QueryClient, QueryClientProvider } from 'react-query'
import ScrollToTop from './components/Common/ScrollToTop'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  environment: process.env.REACT_APP_ENV
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      //refetchOnWindowFocus: false,
      //retry: false,
      staleTime: Number.parseInt(process.env.REACT_APP_STALE_TIME_MS!)
    },
  },
})

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <React.StrictMode>
    <BrowserRouter basename={ROUTER_BASE_NAME}>
      {/* 
        TODO enableBatching has beed added to resolve problem with issue: https://github.com/pbeshai/use-query-params/issues/211
        This issues has been marked as closed but apparently it is still here because in this project we had encountered it
        EnableBatching puts function in setTimeout so changes are made after render not during it.
      */}
      <QueryParamProvider adapter={ReactRouter6Adapter} options={{ enableBatching: true, removeDefaultsFromUrl: true }}>
        <QueryClientProvider client={queryClient}>
          <ScrollToTop />
          <App />
        </QueryClientProvider>
      </QueryParamProvider>
    </BrowserRouter>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
