import { TextField as MuiTextField, TextFieldProps, InputAdornment, IconButton } from '@mui/material'
import { useField } from 'formik'
import { VisibilityOff, Visibility } from '@mui/icons-material'
import { useState } from 'react'

type Props = {
  name: string
  noDefaultHelperText?: boolean
} & TextFieldProps

const PasswordField = (props: Props) => {
  const [field, meta] = useField(props.name)
  const [openPassword, setOpenPassword] = useState<boolean>(false)

  const configTextField: TextFieldProps = {
    ...field,
    ...props,
    fullWidth: true,
    variant: 'outlined'
  }

  const handleClickShowPassword = () => {
    setOpenPassword(!openPassword)
  }

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  if (meta && meta.touched && meta.error) {
    configTextField.error = true
    configTextField.helperText = meta.error
  } else if (!props.noDefaultHelperText) {
    configTextField.helperText = ' '
  }

  return (
    <MuiTextField 
      {...configTextField}
      type={openPassword ? 'text' : 'password'}
      sx={{
        marginBottom: '1rem'
      }}
      InputProps={{
        endAdornment: <InputAdornment position='end'>
          <IconButton
            aria-label='toggle password visibility'
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            edge='end'
          >
            {openPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      }}
    /> 
  )
}

export default PasswordField
