import { TableRow, TableCell } from '@mui/material'
import NoDataPlaceholder from './NoDataPlaceholder'

type Props = {
  colSpan: number
}

const NoDataTableRow = (props: Props) => {
  return (
    <TableRow key={'no-data-row'}>
      <TableCell 
        key={'no-data-cell'}
        colSpan={props.colSpan}
        sx={{
          fontSize: '0.875rem',
          textAlign: 'center'
        }}
      > 
        <NoDataPlaceholder
          sx={{
            label: {
              fontSize: '0.875rem'
            },
            img: {
              width: '10.375rem',
              height: '9.375rem'
            }
          }}
        />
      </TableCell>
    </TableRow>
  )
}

export default NoDataTableRow