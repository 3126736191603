import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { Grid } from '@mui/material'
import Dialog from '../../../components/CustomMui/Dialog'
import EditVariantHeaderBar from '../../../components/Menu/Variants/EditVariantHeaderBar'
import CreateVariantHeaderBar from '../../../components/Menu/Variants/CreateVariantHeaderBar'
import TextField from '../../../components/CustomMui/Fields/TextField'
import { VariantFormData } from '../../../types/menu'
import { addVariantValidation } from '../../../validations/addVariantValidation'
import VariantOptionsForm from '../../../components/Menu/Variants/VariantOptionsForm'
import useGetVariantTemplateDetails from '../../../hooks/template/variant/useGetVariantTemplateDetails'
import Loader from '../../../components/Common/Loader'
import useCreateVariantTemplate from '../../../hooks/template/variant/useCreateVariantTemplate'
import useEditVariantTemplate from '../../../hooks/template/variant/useEditVariantTemplate'
import useRemoveVariantTemplate from '../../../hooks/template/variant/useRemoveVariantTemplate'
import NetworkError from '../../../components/Common/NetworkError'
import { useQueryClient } from 'react-query'
import { queryNames } from '../../../hooks/queries'

type Props = {
  isEditVariant: boolean
}

const defaultInitValue: VariantFormData = {
  name: '',
  note: '',
  options: []
}

const VariantPage = (props: Props) => {
  const texts = useTranslation().t
  const navigate = useNavigate()
  const { uuid } = useParams<{ uuid: string }>()
  
  const goBack = () => { navigate(-1) }

  const queryClient = useQueryClient()
  const addMutation = useCreateVariantTemplate()
  const editMutation = useEditVariantTemplate(goBack)
  const removeMutation = useRemoveVariantTemplate(goBack)

  const [openRemoveDialog, setOpenRemoveDialog] = useState<boolean>(false)
  const [openGoBackDialog, setOpenGoBackDialog] = useState<boolean>(false)

  const { isFetching, data, isError } = useGetVariantTemplateDetails(props.isEditVariant ? uuid : null, goBack)

  const handleSubmit = async (data: VariantFormData) => {
    if (props.isEditVariant) {
      editMutation.mutate({
        uuid: uuid!,
        data: data
      })
    } else {
      addMutation.mutate(data)
    }
  }

  return (
    <>
    {
      isError || isFetching
      ? <Grid sx={{height: 'calc(100vh - 160px)'}}>
        {
          isError
          ? <NetworkError
              withRefresh
              onRefresh={() => {
                queryClient.resetQueries([queryNames.variantDetails, uuid])
              }}
            />
          : <Loader 
              width={'100%'}
              text={texts('common:loading')}
            />
        }
        </Grid>
      : <Formik<VariantFormData>
          initialValues={data ?? defaultInitValue}
          enableReinitialize
          validateOnBlur={true}
          validateOnChange={true}
          onSubmit={handleSubmit}
          validationSchema={addVariantValidation(texts)}
        >
          {() => (
            <Form>
              <Grid 
                container 
                direction={'column'}
              >
                <Dialog
                  open={openRemoveDialog}
                  title={texts('dialogs:remove_title')}
                  description={texts('dialogs:remove_description')}
                  warning={texts('dialogs:variant_remove_warning')}
                  closeButtonLabel={texts('common:cancel')}
                  confirmButtonLabel={texts('common:remove')}
                  onCloseButtonClick={() => {
                    setOpenRemoveDialog(false)
                  }}
                  onConfirmButtonClick={() => {
                    setOpenRemoveDialog(false)
                    removeMutation.mutate({ uuid: uuid! })
                    navigate('/menu/variants', {replace: true})
                  }}
                />
                <Dialog
                  open={openGoBackDialog}
                  title={texts('dialogs:go_back_title')}
                  description={texts('dialogs:go_back_description')}
                  closeButtonLabel={texts('common:cancel')}
                  confirmButtonLabel={texts('common:exit')}
                  onCloseButtonClick={() => {
                    setOpenGoBackDialog(false)
                  }}
                  onConfirmButtonClick={() => {
                    setOpenGoBackDialog(false)
                    goBack()
                  }}
                />
                {
                  props.isEditVariant
                  ? <EditVariantHeaderBar
                      loading={editMutation.isLoading}
                      onRemove={() => setOpenRemoveDialog(true)}
                      onBack={() => setOpenGoBackDialog(true)}
                    />
                  : <CreateVariantHeaderBar
                      loading={addMutation.isLoading}
                      onBack={() => setOpenGoBackDialog(true)}
                    />
                }
                <Grid 
                  item 
                  sx={{
                    marginTop: '1.25rem',
                    width: '50%'
                  }}
                >
                  <TextField
                    name='name'
                    label={texts('variants:name')}
                  />
                </Grid>
                <Grid 
                  item 
                  sx={{
                    marginTop: '0.125rem',
                    width: '50%'
                  }}
                >
                  <TextField
                    name='note'
                    label={texts('variants:note_field_label')}
                  />
                </Grid>
                <Grid 
                  item 
                  sx={{ marginTop: '0.25rem'}}
                >
                  <VariantOptionsForm
                    name='options'
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      }
    </>
  )
}

export default VariantPage
