import { FormControl, FormControlLabel, Switch as MuiSwitch, SwitchProps } from '@mui/material'
import { useField, useFormikContext } from 'formik'

type Props = {
  name: string
  label?: string
  disabled?: boolean
  additionalOnChange?: (checked: boolean) => void
}

const Switch = (props: Props) => {
  const { setFieldValue } = useFormikContext()
  const [field] = useField(props.name)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target
    setFieldValue(props.name, checked)
    props.additionalOnChange?.(checked)
  }

  const configSwitch: SwitchProps = {
    ...field,
    ...props,
    checked: field.value,
    onChange: handleChange
  }

  return (
    <FormControl>
      <FormControlLabel
        control={<MuiSwitch {...configSwitch}/>}
        label={props.label ?? ''}
      />
    </FormControl>
  )
}

export default Switch