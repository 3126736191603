import { Grid, Typography } from '@mui/material'

type Props = {
  text: string
  opacity?: boolean
  children?: JSX.Element
  backgroundColor?: string
  margin?: string
}

const Tag = (props: Props) => {
  return (
    <Grid
      item
      container
      padding={'1px 8px 1px 8px'}
      sx={{
        backgroundColor: props.backgroundColor ?? '#d5d5d5',
        maxWidth: 'fit-content',
        margin: props.margin
      }}
    >
      <Grid 
        item
        container
        alignItems={'center'}
        gap={'0.188rem'}
        sx={{
          opacity: props.opacity ? 0.3 : 1
        }}
      >
        {props.children}
        <Typography 
          variant='body2'
          sx={{
            textTransform: 'none',
            marginTop: '2px'
          }}
        >
          {props.text}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default Tag