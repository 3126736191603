import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Box, Grid, IconButton, Menu, MenuItem, styled, Typography } from '@mui/material'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import { useFormikContext } from 'formik'
import moment from 'moment'
import React, { useState } from 'react'
import { DraggableProvided } from '@hello-pangea/dnd'
import { useTranslation } from 'react-i18next'
import { MenuFormData, MenuItemCardOption, MenuItemType, MenuVariant } from '../../../types/menu'
import { removeArrayElement } from '../../../utils/array'
import { evalStringTemplate } from '../../../utils/text'
import Chip from '../../Common/Chip'
import RemoveMenuItemDialog from './Dialogs/RemoveMenuItemDialog'
import DraggableItem from '../../Common/DraggableItem'
import DroppableList from '../../Common/DroppableList'
import VariantOptionCard from './VariantOptionCard'

type Props = {
  variant: MenuVariant
  provided?: DraggableProvided
  productCardId: string
  categoryCardId: string
}

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowDropUpIcon sx={{color: '#94C11F'}} />}
    {...props}
  />
))(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  flexGrow: 1,
  paddingRight: '16px',
  minHeight: '3.6875rem',
  '&.Mui-expanded': {
    minHeight: '3.6875rem',
  },
  '& .MuiAccordionSummary-content': {
    margin: '0',
    flexGrow: 0
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: '0',
    minHeight: '0',
  },
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(180deg)'
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(0)'
  },
}))

const Accordion = styled(MuiAccordion)(({ theme }) => ({
  '&.Mui-expanded': {
    margin: '0'
  },
  '&:before': {
    display: 'none'
  },
  '&:last-of-type': {
    borderRadius: '0.98rem',
  }
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: '0 1.3125rem 1.3125rem 1.3125rem'
}))

const VariantOptionListCard = (props: Props) => {
  const texts = useTranslation().t
  const cardOptions: MenuItemCardOption[] = texts('objects:menu_variant_options', { returnObjects: true }) as MenuItemCardOption[]
  const { values, setFieldValue } = useFormikContext<MenuFormData>()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [openRemoveDialog, setOpenRemoveDialog] = useState<boolean>(false)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuItemClick = (type: MenuItemType) => {
    handleClose()
    switch (type) {
      case MenuItemType.REMOVE:
        setOpenRemoveDialog(true)
        break
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  
  const [expandedVariant, setExpandedVariant] = useState<boolean>(false)
  const changeExpandStatus = (event: React.SyntheticEvent) => {
    setExpandedVariant(!expandedVariant)
  }

  return (
    <>
      <RemoveMenuItemDialog
        open={openRemoveDialog}
        onCloseButtonClick={() => {setOpenRemoveDialog(false)}}
        additionalOnFinish={() => {
          const catIdx = values.items.findIndex(e => e.cardId === props.categoryCardId)
          const prodIdx = values.items[catIdx].products.findIndex(e => e.cardId === props.productCardId)
          const varIdx = values.items[catIdx].products[prodIdx].variants!.findIndex(e => e.cardId === props.variant.cardId)
          const newItems = [...values.items]
          newItems[catIdx].products[prodIdx].variants = removeArrayElement(newItems[catIdx].products[prodIdx].variants!, varIdx)
          setFieldValue('items', newItems)
        }}
      />
      <Grid
        position={'relative'}
        flexDirection={'column'}
        marginTop={'0.938rem'}
        borderRadius={'0.938rem'}
        boxShadow={'0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.5)'}
        gap={'0.938rem'}
        sx={{
          background:'#FFFFFF'
        }}
      >
        <Accordion 
          expanded={expandedVariant} 
          onChange={changeExpandStatus} 
          sx={{
            filter: props.variant.active ? 'none' : 'grayscale(1)'
          }}
        >
          <Grid
            container
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Box {...props.provided?.dragHandleProps}
              sx={{marginLeft: '0.8125rem'}}
            >
              <DragHandleIcon />
            </Box>
            <AccordionSummary>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '0.938rem'
                }}
              >
                <Typography 
                  variant='h2'
                  sx={{
                    fontSize: '1.125rem'
                  }}
                >
                  {props.variant.name}
                </Typography>
                {
                  expandedVariant 
                  ? null 
                  : <Chip text={texts('menu:menu_value_of_options',{val: props.variant.options.length})} />
                }
                {
                  !props.variant.active
                  ? <Chip
                      text={
                        props.variant.inactiveUntil 
                        ? evalStringTemplate(texts('common:inactive_until'), { date: moment(props.variant.inactiveUntil).format('DD.MM.YYYY, HH:mm') })
                        : texts('common:inactive')
                      }
                      opacity={0.3}
                    />
                  : null
                }
                <Typography
                  sx={{
                    fontSize: '1rem',
                    color: '#94C11F',
                    fontFamily: 'RobotoCondensedBold',
                    marginLeft: '0.625rem'
                  }}
                >
                  {expandedVariant ? texts('menu:menu_collapse'): texts('menu:menu_expand')}
                </Typography>
              </Box>
            </AccordionSummary>
            <Grid item>
              <IconButton
                onClick={handleClick}
                sx={{
                  color: '#000',
                  position: 'absolute',
                  top: 0,
                  right: 0
                }}
              >
                <MoreHorizIcon/>
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    minWidth: '12rem',
                  },
                }}
              >
                {
                  cardOptions.map((option) => (
                    <MenuItem 
                      key={option.value}                 
                      onClick={() => {
                        handleMenuItemClick(option.value)
                      }}
                    >
                      {option.label}
                    </MenuItem>
                  ))
                }
              </Menu>
            </Grid>
          </Grid>
          <AccordionDetails>
            <Grid
              item
              container
              sx={{
                ':last-child': {marginBottom: '0.5rem'}
              }}
            >
              <DroppableList droppableId={`variant-option:${props.categoryCardId}:${props.productCardId}:${props.variant.cardId}`} boxDirection='vertical'>
                <Grid
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '0.5rem'
                  }}
                >
                {props.variant.options.map((item, index) => (
                  <DraggableItem id={`variant-option:${props.categoryCardId}:${props.productCardId}:${props.variant.cardId}:${item.cardId}`} index={index} key={item.cardId}>
                    <VariantOptionCard 
                      variantOption={item} 
                      categoryCardId={props.categoryCardId}
                      productCardId={props.productCardId}
                      variantCardId={props.variant.cardId}
                    />
                  </DraggableItem>
                ))}
              </Grid>
              </DroppableList>
            </Grid>
            {
              props.variant.internalNote
              ? <Grid 
                  item
                  sx={{
                    marginTop: '1.313rem'
                  }}
                >
                  <Typography 
                    variant='body2'
                    sx={{
                      textTransform: 'none',
                      fontStyle: 'italic'
                    }}
                  >
                    {`${texts('variants:note')}: ${props.variant.internalNote}`}
                  </Typography>
                </Grid>
              : null
            }
          </AccordionDetails>
        </Accordion>
      </Grid>
    </>
  )
}

export default VariantOptionListCard
