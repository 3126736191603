export const forbiddenSpecialCharacters = /^[^<>%$'?';:/|{}`()~#%()^&*\]+=*\\[]*$/
export const forbiddenSomeSpecialCharactersPassword = /^[^-<>%'?';:./|@{}`()~%()^*\]+=*\\[]*$/
export const passwordRules = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z$!&#_\d]{1,}$/
export const englishCharactersAllowedPassword = /^[A-Za-z0-9$!&#_]*$/ 
// eslint-disable-next-line
export const emailRegexp = /^[a-z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?(?:\.[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?)+$/i
export const numberPattern = /^-?(\d*(\.|,)\d*|[0-9]\d*)$/
export const integerValuePattern = /^-?\d*$/
export const monetaryValuePattern = /^-?(\d*(\.|,)\d{0,2}|[0-9]\d*)$/
export const slugAllowedCharacters = /^[a-z.\-0-9]*$/
export const postalCode = /^[0-9]{2}-[0-9]{3}$/
export const phoneRegExp = /^([+]\d{2} \d{3} \d{3} \d{3})$|^(\d{9})$|^([+]\d{11})$|^(\d{3} \d{3} \d{3})$|^(\d{2} \d{3} \d{2} \d{2})$/