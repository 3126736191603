import { Box, Grid, Typography } from '@mui/material'
import SearchBar from '../../CustomMui/SearchBar'
import { useTranslation } from 'react-i18next'
import SortIcon from '@mui/icons-material/Sort'
import DropdownMenu from '../../CustomMui/DropdownMenu'
import { DropdownOption } from '../../CustomMui/DropdownMenu'

type Props = {
  searchBarProps: {
    initialValue?: string
    onClick: (text: string) => void
    placeholder: string
  }
  dropDownMenuProps: {
    value: string
    options: DropdownOption[]
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  }
}

const QueryParamsSection = (props: Props) => {
  const texts = useTranslation().t

  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      alignItems={'center'}
    >
      <SearchBar
        initialValue={props.searchBarProps.initialValue}
        onClick={props.searchBarProps.onClick}
        placeholder={props.searchBarProps.placeholder}
        height='42px'
        width='400px'
      />

      <Grid
        container 
        alignItems={'center'}
        justifyContent={'end'}
        gap={'0.875rem'}
        wrap={'nowrap'}
      >
        <SortIcon/>
        <Typography
          variant='body2'
          fontWeight='bold'
        >
          {texts('common:sort')}
        </Typography>
        <DropdownMenu
          value={props.dropDownMenuProps.value}
          onChange={props.dropDownMenuProps.onChange}
          options={props.dropDownMenuProps.options}
          sx={{
            width: '212px',
            '.MuiOutlinedInput-root': {
              height: '42px',
              alignContent: 'center'
            }
          }}
        />
      </Grid>
    </Box>
  )
}

export default QueryParamsSection