import { Button, Dialog as MuiDialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from '@mui/material'
import { useFormikContext } from 'formik'
import moment from 'moment'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { MenuFormData } from '../../../../types/menu'

type Props = {
  open: boolean
  onCloseButtonClick: () => void
}

const AddCategoryDialog = (props: Props) => {
  const texts = useTranslation().t
  const { values, errors, setFieldValue, setFieldError } = useFormikContext<MenuFormData>()

  const ref = useRef(null) as any

  const handleConfirm = () => {
    const copyItems = [...values.items]
    if (ref.current?.value && copyItems.find(e => e.name.trim().toLowerCase() === (ref.current.value.trim().toLowerCase() ?? '')) != null) {
      setFieldError('newCategoryLabel', texts('validations:non_unique_category'))
    } else if (ref.current?.value.length > 32) {
      setFieldError('newCategoryLabel', texts('validations:max_32_signs_validation'))
    } else if (ref.current?.value) {
      setFieldError('newCategoryLabel', undefined)
      copyItems.push({
        cardId: `new-category-${moment().unix()}`,
        name: ref.current.value.trim(),
        products: []
      })
      setFieldValue('items', copyItems)
      props.onCloseButtonClick()
    } else {
      setFieldError('newCategoryLabel', texts('validations:field_required'))
    }
  }

  return (
    <MuiDialog
      open={props.open}
      onClose={() => {
        props.onCloseButtonClick()
        setFieldError('newCategoryLabel', undefined)
      }} 
    >
      <DialogActions>
        <IconButton 
          onClick={() => {
            props.onCloseButtonClick()
            setFieldError('newCategoryLabel', undefined)
          }} 
          color={'primary'}
        >
          <AiOutlineCloseCircle size={30}/>
        </IconButton>
      </DialogActions>
      <DialogTitle> 
        {texts('dialogs:add_category_title')}
      </DialogTitle>
      
      <DialogContent>
        <TextField
          inputRef={ref}
          margin="dense"
          label={texts('dialogs:category_label')}
          type="text"
          fullWidth
          onChange={() => {
            setFieldError('newCategoryLabel', undefined)
          }}
          onBlur={(e) => {
            if (e.target.value.length > 32) {
              setFieldError('newCategoryLabel', texts('validations:max_32_signs_validation'))
            } 
          }}
          error={errors.newCategoryLabel != null && errors.newCategoryLabel !== ''}
          helperText={errors.newCategoryLabel}
        />
      </DialogContent>
      
      <DialogActions>
        <Button
          variant={'contained'}
          size={'medium'}
          onClick={handleConfirm}
        >
          {texts('common:insert')}
        </Button>
      </DialogActions>
    </MuiDialog>
  )
}

export default AddCategoryDialog
