import { Box, Button, Checkbox, FormControlLabel, Grid, IconButton, TextField, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { DraggableProvided } from '@hello-pangea/dnd'
import { useTranslation } from 'react-i18next'
import { AiFillCloseCircle } from 'react-icons/ai'
import { ChangeState, VariantOptionFormData } from '../../../types/menu'
import NumericTextField from '../../Common/NumericTextField'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import currency from 'currency.js'

type Props = {
  meta: any
  value: VariantOptionFormData
  error?: string
  onRemove: () => void
  onSave: (value: VariantOptionFormData) => void
  onEdit: () => void
  onCancel: () => void
  provided?: DraggableProvided
}

const Row = (props: Props) => {
  const texts = useTranslation().t
  const [value, setValue] = useState<VariantOptionFormData>(props.value)

  useEffect(() => {
    setValue(props.value)
  }, [props.value, props.value.state])

  return (
    <Box 
      marginBottom={'1.5rem'}
    >
      <Grid 
        container 
        alignItems={'center'}
        wrap={'nowrap'}
      >
        {
          props.provided
          ? <Box 
              {...props.provided?.dragHandleProps} 
              sx={{ 
                marginTop: '0.0625rem',
                marginLeft: '0.5rem', 
                marginRight: '0.75rem' 
              }}
            >
              <DragHandleIcon />
            </Box>
          : null
        }
        <IconButton 
          onClick={props.onRemove}
          color={'primary'}
          sx={{
            minWidth: 0,
            marginRight: '0.5rem'
          }}
        >
          <AiFillCloseCircle size={30}/>
        </IconButton>
        <Grid
          item
          container
          wrap='nowrap'
          gap='1.5rem'
        >
          <Grid 
            item
            sx={{
              minWidth: '15rem',
              maxWidth: '25rem'
            }}
          > 
            <TextField
              value={value.name}
              onChange={(e) => {
                setValue({
                  ...value,
                  name: e.target.value
                })
              }}
              label={texts('variants:option_label')}
              disabled={value.state === ChangeState.SAVED}
            />
          </Grid>
          <Grid 
            item
            container
            wrap={'nowrap'}
            gap={'0.5rem'}
            alignItems={'center'}
            sx={{
              minWidth: '11rem',
              maxWidth: '13rem'
            }}
          > 
            <NumericTextField
              label={texts('variants:additional_price')}
              value={value.additionalPrice}
              placeholder={texts('common:price_hint')}
              shrinkLabel
              onChange={(e) => {
                const newVal = e.target.value === '' ? null : currency(e.target.value).value
                setValue({
                  ...value,
                  additionalPrice: newVal
                })
              }}
              noDefaultHelperText
              disabled={value.state === ChangeState.SAVED}
            />
            <Typography 
              variant='body2'
              sx={{textTransform: 'none'}}
            >
              {texts('common:PLN_label')}
            </Typography>
          </Grid>
          <Grid 
            item
            container
            wrap={'nowrap'}
            gap={'0.5rem'}
            alignItems={'center'}
            sx={{
              minWidth: '17rem',
              maxWidth: '19rem'
            }}
          > 
            <NumericTextField
              value={value.additionalTime}
              integerOnly
              placeholder={texts('common:time_hint')}
              shrinkLabel
              onChange={(e) => {
                const newVal = e.target.value === '' ? null : parseFloat(e.target.value)
                setValue({
                  ...value,
                  additionalTime: newVal
                })
              }}
              noDefaultHelperText
              label={texts('variants:additional_time')}
              disabled={value.state === ChangeState.SAVED}
            />
            <Typography 
              variant='body2'
              sx={{textTransform: 'none'}}
            >
              {texts('common:minutes')}
            </Typography>
          </Grid>
          <Grid 
            item
            container
            wrap={'nowrap'}
            gap={'0.5rem'}
            alignItems={'center'}
            sx={{
              maxWidth: 'fit-content'
            }}
          > 
            <FormControlLabel
              control={
                <Checkbox
                  checked={value.defaultOption}
                  onChange={(e) => {
                    setValue({
                      ...value,
                      defaultOption: e.target.checked
                    })
                  }}
                  disabled={value.state === ChangeState.SAVED}
                />
              }
              label={`${texts('variants:default_option')}`}
            />
          </Grid>
          <Grid 
            item
            container
            alignItems={'center'}
            sx={{maxWidth: '12.5rem'}}
          > 
            {
              value.state === ChangeState.SAVED
              ? <Button
                  variant={'outlined'}
                  size={'small'}
                  onClick={props.onEdit}
                >
                  {texts('common:edit')}
                </Button>
              : <Grid 
                  item 
                  container
                  wrap='nowrap'
                  gap={'0.5rem'}
                >
                  <Button
                    variant={'contained'}
                    size={'small'}
                    onClick={() => {
                      props.onSave({
                        ...value,
                        state: ChangeState.SAVED
                      })
                    }}
                  >
                    {texts('common:save')}
                  </Button>
                  {
                    value.state !== ChangeState.INIT
                    ? <Button
                        variant={'outlined'}
                        size={'small'}
                        sx={{minWidth: '6.25rem'}}
                        onClick={props.onCancel}
                      >
                        {texts('common:cancel')}
                      </Button>
                    : null
                  }
                </Grid>
            }
          </Grid>
        </Grid>
      </Grid>
      {
        props.error 
          && <Typography 
            variant='body2'
            sx={{
              fontFamily: 'RobotoCondensed',
              color: 'red',
              marginTop: '0.188rem',
              marginLeft: '6.875rem',
              textTransform: 'none'
            }}
          >
            {texts(props.error as any)}
          </Typography>
      }
    </Box>
  )
}

export default Row