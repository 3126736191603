import * as Yup from 'yup'

export function discountCodeValidation(t: (val: string) => string) {
  return Yup.object({
    user: Yup.object()
      .nullable()
      .required(t('validations:field_required')),
    percentage: Yup.number()
      .nullable()
      .min(1, t('validations:min_number_1'))
      .max(100, t('validations:max_number_100'))
      .required(t('validations:field_required')),
    code: Yup.string()
      .nullable()
      .required(t('validations:field_required')),
  })
}
