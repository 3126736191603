import { TextField as MuiTextField, TextFieldProps } from '@mui/material'
import { useField } from 'formik'

type Props = {
  maxLength?: number
  noDefaultHelperText?: boolean
} & TextFieldProps

const TextField = (props: Props) => {
  const [field, meta] = useField(props.name!)

  const configTextField: TextFieldProps = {
    ...field,
    ...props,
    fullWidth: true,
    variant: 'outlined'
  }

  if (meta && meta.touched && meta.error) {
    configTextField.error = true
    configTextField.helperText = meta.error
  } else if (!props.noDefaultHelperText) {
    configTextField.helperText = ' '
  }

  if (props.maxLength != null) {
    configTextField.inputProps = {
      ...configTextField.inputProps,
      maxLength: props.maxLength
    }
  }

  return (
    <MuiTextField 
      {...configTextField}
      sx={{
        marginBottom: '1rem'
      }}
    /> 
  )
}

export default TextField
