import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { AdditionFormData } from '../../../types/menu'
import { Form, Formik } from 'formik'
import { Grid, Typography } from '@mui/material'
import Dialog from '../../../components/CustomMui/Dialog'
import { addAdditionValidation } from '../../../validations/addAdditionValidation'
import TextField from '../../../components/CustomMui/Fields/TextField'
import EditAdditionHeaderBar from '../../../components/Menu/Additions/EditAdditionHeaderBar'
import CreateAdditionHeaderBar from '../../../components/Menu/Additions/CreateAdditionHeaderBar'
import useGetAdditionTemplateDetails from '../../../hooks/template/addition/useGetAdditionTemplateDetails'
import Loader from '../../../components/Common/Loader'
import NumericTextField from '../../../components/CustomMui/Fields/NumericTextField'
import useCreateAdditionTemplate from '../../../hooks/template/addition/useCreateAdditionTemplate'
import useEditAdditionTemplate from '../../../hooks/template/addition/useEditAdditionTemplate'
import useRemoveAdditionTemplate from '../../../hooks/template/addition/useRemoveAdditionTemplate'
import NetworkError from '../../../components/Common/NetworkError'
import { useQueryClient } from 'react-query'
import { queryNames } from '../../../hooks/queries'
import currency from 'currency.js'

type Props = {
  isEditAddition: boolean
}

const defaultInitValue: AdditionFormData = {
  name: '',
  note: '',
  additionalPrice: null,
  additionalTime: null
}

const AdditionPage = (props: Props) => {
  const texts = useTranslation().t
  const navigate = useNavigate()
  const { uuid } = useParams<{ uuid: string }>()

  const goBack = () => { navigate(-1) }

  const queryClient = useQueryClient()
  const addMutation = useCreateAdditionTemplate()
  const editMutation = useEditAdditionTemplate(goBack)
  const removeMutation = useRemoveAdditionTemplate(goBack)

  const [openRemoveDialog, setOpenRemoveDialog] = useState<boolean>(false)
  const [openGoBackDialog, setOpenGoBackDialog] = useState<boolean>(false)

  const { isFetching, data, isError } = useGetAdditionTemplateDetails(props.isEditAddition ? uuid : null, goBack)

  const handleSubmit = (data: AdditionFormData) => {
    if (props.isEditAddition) {
      editMutation.mutate({
        uuid: uuid!,
        data: data
      })
    } else {
      addMutation.mutate(data)
    }
  }

  return (
    <>
      {
        isError || isFetching
        ? <Grid sx={{height: 'calc(100vh - 160px)'}}>
          {
            isError
            ? <NetworkError
                withRefresh
                onRefresh={() => {
                  queryClient.resetQueries([queryNames.additionDetails, uuid])
                }}
              />
            : <Loader 
                width={'100%'}
                text={texts('common:loading')}
              />
          }
          </Grid>
        : <Formik<AdditionFormData>
            initialValues={data ?? defaultInitValue}
            enableReinitialize
            validateOnBlur={true}
            validateOnChange={true}
            onSubmit={handleSubmit}
            validationSchema={addAdditionValidation(texts)}
          >
            {(formikProps) => (
              <Form>
                <Grid 
                  container 
                  direction={'column'}
                  rowGap={'0.125rem'}
                >
                  <Dialog
                    open={openRemoveDialog}
                    title={texts('dialogs:remove_title')}
                    description={texts('dialogs:remove_description')}
                    warning={texts('dialogs:addition_remove_warning')}
                    closeButtonLabel={texts('common:cancel')}
                    confirmButtonLabel={texts('common:remove')}
                    onCloseButtonClick={() => {
                      setOpenRemoveDialog(false)
                    }}
                    onConfirmButtonClick={() => {
                      setOpenRemoveDialog(false)
                      removeMutation.mutate({ uuid: uuid! })
                      navigate('/menu/additions', {replace: true})
                    }}
                  />
                  <Dialog
                    open={openGoBackDialog}
                    title={texts('dialogs:go_back_title')}
                    description={texts('dialogs:go_back_description')}
                    closeButtonLabel={texts('common:cancel')}
                    confirmButtonLabel={texts('common:exit')}
                    onCloseButtonClick={() => {
                      setOpenGoBackDialog(false)
                    }}
                    onConfirmButtonClick={() => {
                      setOpenGoBackDialog(false)
                      goBack()
                    }}
                  />
                  {
                    props.isEditAddition
                    ? <EditAdditionHeaderBar
                        loading={editMutation.isLoading}
                        onRemove={() => setOpenRemoveDialog(true)}
                        onBack={() => setOpenGoBackDialog(true)}
                      />
                    : <CreateAdditionHeaderBar
                        loading={addMutation.isLoading}
                        onBack={() => setOpenGoBackDialog(true)}
                      />
                  }
                  <Grid 
                    item 
                    sx={{
                      marginTop: '1.125rem',
                      width: '50%'
                    }}
                  >
                    <TextField
                      name='name'
                      label={texts('additions:name')}
                    />
                  </Grid>
                  <Grid 
                    item 
                    sx={{
                      width: '50%'
                    }}
                  >
                    <TextField
                      name='note'
                      label={texts('additions:note_field_label')}
                    />
                  </Grid>
                  <Grid 
                    item 
                    container
                    sx={{
                      width: '50%'
                    }}
                    gap={'1rem'}
                    flexWrap={'nowrap'}
                  >
                    <Grid
                      item
                      container
                      width={'50%'}
                      flexWrap={'nowrap'}
                      alignItems={'center'}
                      gap={'0.5rem'}
                    >
                      <NumericTextField
                        name='additionalPrice'
                        value={formikProps.values.additionalPrice}
                        placeholder={texts('common:price_hint')}
                        shrinkLabel
                        onChange={(e) => {
                          const value = e.target.value === '' ? null : currency(e.target.value).value
                          formikProps.setFieldValue(
                            'additionalPrice',
                            value
                          )
                        }}
                        label={texts('additions:additional_price')}
                      />
                      <Typography 
                        variant='body2'
                        sx={{textTransform: 'none', marginBottom: '1.25rem'}}
                      >
                        {texts('common:PLN_label')}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      width={'50%'}
                      flexWrap={'nowrap'}
                      alignItems={'center'}
                      gap={'0.5rem'}
                    >
                      <NumericTextField
                        name='additionalTime'
                        integerOnly
                        placeholder={texts('common:time_hint')}
                        shrinkLabel
                        value={formikProps.values.additionalTime}
                        onChange={(e) => {
                          const value = e.target.value === '' ? null : Number.parseFloat(e.target.value)
                          formikProps.setFieldValue(
                            'additionalTime',
                            value
                          )
                        }}
                        label={texts('additions:additional_time')}
                      />
                      <Typography 
                        variant='body2'
                        sx={{textTransform: 'none', marginBottom: '1.25rem'}}
                      >
                        {texts('common:minutes')}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
      }
    </>
  )
}

export default AdditionPage
