
import { Grid } from '@mui/material'
import { Form, Formik } from 'formik'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import Loader from '../../components/Common/Loader'
import Dialog from '../../components/CustomMui/Dialog'
import PlaceDetails from '../../components/Place/Details/PlaceDetails'
import OpeningHoursExceptionsForm from '../../components/Place/OpeningHoursExceptionsForm/OpeningHoursExceptionsForm'
import OpeningHoursForm from '../../components/Place/OpeningHoursForm/OpeningHoursForm'
import PaymentDetails from '../../components/Place/Details/PaymentDetails'
import CreatePlaceHeaderBar from '../../components/Place/PlaceHeaderBars/CreatePlaceHeaderBar'
import EditPlaceHeaderBar from '../../components/Place/PlaceHeaderBars/EditPlaceHeaderBar'
import PlaceSettings from '../../components/Place/Settings/PlaceSettings'
import useChangePlaceActiveState from '../../hooks/place/useChangePlaceActiveState'
import useCreatePlace from '../../hooks/place/useCreatePlace'
import useEditPlace from '../../hooks/place/useEditPlace'
import useGetPlaceDetails from '../../hooks/place/useGetPlaceDetails'
import { PlaceFormData } from '../../types/place'
import { addPlaceValidation } from '../../validations/addPlaceValidation'
import { editPlaceValidation } from '../../validations/editPlaceValidation'
import NetworkError from '../../components/Common/NetworkError'
import { useQueryClient } from 'react-query'
import { queryNames } from '../../hooks/queries'
import { getUserRoles } from '../../services/storage/storageService'
import { Role } from '../../types/accounts'
import { arrayIntersection } from '../../utils/array'
import { DEFAULT_START_TIME, DEFAULT_END_TIME } from '../../utils/constants'

const defaultInitValue: PlaceFormData = {
  name: '',
  street: '',
  postalCode: '',
  phone: '',
  email: '',
  town: '',
  photoUuid: null,
  photoUrl: null,
  coordinates: {
    lng: 21.017532,
    lat: 52.237049
  },
  openingHours: [
    {
      open: false,
      start: DEFAULT_START_TIME,
      end: DEFAULT_END_TIME,
    },
    {
      open: false,
      start: DEFAULT_START_TIME,
      end: DEFAULT_END_TIME,
    },
    {
      open: false,
      start: DEFAULT_START_TIME,
      end: DEFAULT_END_TIME,
    },
    {
      open: false,
      start: DEFAULT_START_TIME,
      end: DEFAULT_END_TIME,
    },
    {
      open: false,
      start: DEFAULT_START_TIME,
      end: DEFAULT_END_TIME,
    },
    {
      open: false,
      start: DEFAULT_START_TIME,
      end: DEFAULT_END_TIME,
    },
    {
      open: false,
      start: DEFAULT_START_TIME,
      end: DEFAULT_END_TIME,
    },
  ],
  openingHoursExceptions: [],
  pickupTypes: {
    takeAway: false,
    onSite: false,
    delivery: false
  },
  extraTimeBufferForItemsInCartAmount: 3,
  extraTimeBufferForRushHours: [],
  payuCredentials: {
    posId: '',
		clientId: '',
		secondKey: '',
		clientSecret: ''
  },
  // timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  timezone: process.env.REACT_APP_TZ! // TODO! obsluzyc dobrze strefy czasowe (do ustalenia podejscie)
}

type Props = {
  isEditPage: boolean
}

const PlacePage = (props: Props) => {
  const texts = useTranslation().t
  const navigate = useNavigate()
  const userRoles = getUserRoles()
  const { uuid } = useParams<{ uuid: string }>()

  const queryClient = useQueryClient()
  const addMutation = useCreatePlace()
  const editActiveMutation = useChangePlaceActiveState()
  const editMutation = useEditPlace()

  const [openGoBackDialog, setOpenGoBackDialog] = useState<boolean>(false)
  const [openStatusChangeDialog, setOpenStatusChangeDialog] = useState<boolean>(false)

  const { isLoading, isFetching, data, isError } = useGetPlaceDetails(props.isEditPage ? uuid : null)

  const handleSubmit = (data: PlaceFormData) => {
    if (props.isEditPage) {
      editMutation.mutate({
        uuid: uuid!,
        data: data
      })
    } else {
      addMutation.mutate(data)
    }
  }

  return (
    <>
      {
        isLoading || isError || isFetching
        ? <Grid sx={{height: 'calc(100vh - 160px)'}}>
          {
            isError
            ? <NetworkError
                withRefresh
                onRefresh={() => {
                  queryClient.resetQueries([queryNames.placeDetails, uuid])
                }}
              />
            : <Loader 
                width={'100%'}
                text={texts('common:loading')}
              />
          }
          </Grid>
        : <Formik<PlaceFormData>
            initialValues={data ?? defaultInitValue}
            enableReinitialize
            validateOnBlur={true}
            validateOnChange={true}
            onSubmit={handleSubmit}
            validationSchema={props.isEditPage ? editPlaceValidation(texts) : addPlaceValidation(texts)}
          >
            {(formikProps) => (
              <Form>
                <Grid 
                  container 
                  direction={'column'}
                >
                  <Dialog
                    open={openGoBackDialog}
                    title={texts('dialogs:go_back_title')}
                    description={texts('dialogs:go_back_description')}
                    closeButtonLabel={texts('common:cancel')}
                    confirmButtonLabel={texts('common:exit')}
                    onCloseButtonClick={() => {
                      setOpenGoBackDialog(false)
                    }}
                    onConfirmButtonClick={() => {
                      setOpenGoBackDialog(false)
                      navigate('/', {replace: true})
                    }}
                  />
                  <Dialog
                    open={openStatusChangeDialog}
                    title={texts(data?.active ? 'dialogs:deactivate_place_title' : 'dialogs:activate_place_title')}
                    description={texts(data?.active ? 'dialogs:deactivate_place_description' : 'dialogs:activate_place_description')}
                    closeButtonLabel={texts('common:cancel')}
                    confirmButtonLabel={texts(data?.active ? 'common:deactivate' : 'common:activate')}
                    onCloseButtonClick={() => {setOpenStatusChangeDialog(false)}}
                    onConfirmButtonClick={() => {
                      setOpenStatusChangeDialog(false)
                      editActiveMutation.mutate({
                        uuid: uuid!,
                        active: !data?.active
                      })
                    }}
                  />
                  {
                    props.isEditPage
                    ? <EditPlaceHeaderBar
                        saving={editMutation.isLoading}
                        statusChanging={editActiveMutation.isLoading}
                        isPlaceActive={data?.active ?? false}
                        onBack={() => setOpenGoBackDialog(true)}
                        onStatusChanged={() => {setOpenStatusChangeDialog(true)}}
                      />
                    : <CreatePlaceHeaderBar
                        loading={addMutation.isLoading}
                        onBack={() => setOpenGoBackDialog(true)}
                      />
                  }
                  <Grid 
                    container 
                    sx={{marginTop: '1.25rem'}}
                  >
                    <PlaceDetails/>
                  </Grid>
                  <Grid 
                    container direction={'column'} 
                    sx={{marginTop: '1.25rem'}}
                  >
                    <Grid item marginBottom={'1rem'}>
                      <OpeningHoursForm/>
                    </Grid>
                    <Grid item marginBottom={'2rem'}>
                      <OpeningHoursExceptionsForm
                        name={'openingHoursExceptions'}
                      />
                    </Grid>
                    <Grid item marginBottom={'2rem'}>
                      <PlaceSettings/>
                    </Grid>
                    <Grid item>
                      <PaymentDetails disabledEdit={arrayIntersection([Role.SYSTEM_ADMIN], userRoles).length === 0}/>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
      }
    </>
  )
}

export default PlacePage