import { Grid, MenuItem, Select, SelectProps, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

type Props = {
  typeSelectProps: {
    options: {
      value: string
      label: string
    }[]
  } & SelectProps<string>
  premiseSelectProps: {
    options: {
      uuid: string
      name: string
    }[]
  } & SelectProps<string>
}

const ChoiceSection = (props: Props) => {
  const texts = useTranslation().t

  return (
    <Grid
      container
      item
      direction={'row'}
      flexWrap={'nowrap'}
    >
      <Grid
        container item 
        xs={4}
        direction={'row'}
        gap={'24px'}
        alignItems={'center'}
      >
        <Typography variant='body2' fontWeight='bold'>
          {texts('availability:type')}
        </Typography>
        <Select
          value={props.typeSelectProps.value}
          onChange={props.typeSelectProps.onChange}
          sx={{
            minWidth: '12rem'
          }}
        >
          {props.typeSelectProps.options.map(option => (
            <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid
        item 
        container 
        xs={8}
        justifyContent={'end'}
      >
        <Grid
          item 
          container 
          alignItems={'center'}
          width={'40rem'}
          gap={'0.875rem'}
          wrap={'nowrap'}
        >
          <Typography 
            variant='body2'
            fontWeight={'bold'}
          >
            {texts('common:premises')}
          </Typography>
          <Select
            value={props.premiseSelectProps.value}
            onChange={props.premiseSelectProps.onChange}
            sx={{
              minWidth: '12rem',
              flexGrow: 1
            }}
          >
            {props.premiseSelectProps.options.map((option) => (
              <MenuItem key={option.uuid} value={option.uuid}>{option.name}</MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ChoiceSection