import DragHandleIcon from '@mui/icons-material/DragHandle'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import TimerIcon from '@mui/icons-material/Timer'
import { Box, Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { useFormikContext } from 'formik'
import moment from 'moment'
import React, { useState } from 'react'
import { DraggableProvided } from '@hello-pangea/dnd'
import { useTranslation } from 'react-i18next'
import placeholderImage from '../../../assets/images/placeholderProduct.webp'
import { MenuFormData, MenuItemCardOption, MenuItemType, MenuProduct } from '../../../types/menu'
import { removeArrayElement } from '../../../utils/array'
import { mapSecondsToString } from '../../../utils/mapping'
import Tag from '../../Common/Tag'
import AdditionListCard from './AdditionListCard'
import VariantCard from './VariantCard'
import RemoveMenuItemDialog from './Dialogs/RemoveMenuItemDialog'
import Chip from '../../Common/Chip'
import { evalStringTemplate } from '../../../utils/text'
import currency from 'currency.js'

type Props = {
  product: MenuProduct
  provided?: DraggableProvided
  categoryCardId: string
}

const ProductCards = (props: Props) => {
  const texts = useTranslation().t
  const cardOptions: MenuItemCardOption[] = texts('objects:menu_product_options', { returnObjects: true }) as MenuItemCardOption[]
  const { values, setFieldValue } = useFormikContext<MenuFormData>()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [openRemoveDialog, setOpenRemoveDialog] = useState<boolean>(false)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItemClick = (type: MenuItemType) => {
    handleClose()
    switch (type) {
      case MenuItemType.REMOVE:
        setOpenRemoveDialog(true)
        break
    }
  }

  return (
    <>
      <RemoveMenuItemDialog
        open={openRemoveDialog}
        onCloseButtonClick={() => {setOpenRemoveDialog(false)}}
        additionalOnFinish={() => {
          const catIdx = values.items.findIndex(e => e.cardId === props.categoryCardId)
          const prodIdx = values.items[catIdx].products.findIndex(e => e.cardId === props.product.cardId)
          const newItems = [...values.items]
          newItems[catIdx].products = removeArrayElement(newItems[catIdx].products, prodIdx)
          setFieldValue('items', newItems)
        }}
      />
      <Grid
        container
        position={'relative'}
        minHeight={'9.063rem'}
        padding={'0.938rem'}
        marginTop={'0.938rem'}
        borderRadius={'0.938rem'}
        boxShadow={'0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.5)'}
        wrap={'nowrap'}
        flexDirection={'column'}
        sx={{ 
          background: '#FFFFFF'
        }}
      >
        <Grid
          gap={'0.938rem'}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '0.938rem'
          }}
        >
          <Box {...props.provided?.dragHandleProps}>
            <DragHandleIcon />
          </Box> 
          <Grid 
            item 
            width={'7.188rem'}
            height={'7.188rem'}
            flexShrink={0}
            sx={{
              backgroundColor: 'bisque',
              filter: props.product.active ? 'none' : 'grayscale(1)',
              opacity: props.product.active ? 1 : 0.5
            }}
          >
            <Box
              component={'img'}
              alt='logo'
              flexShrink={0}
              src={props.product.photo?.thumbnail ?? props.product.photo?.original ?? placeholderImage}
              sx={{
                display: 'block',
                minWidth: '100%',
                minHeight: '100%',
                maxWidth: '100%',
                maxHeight: '100%'
              }}
            />
          </Grid>
          <Grid
            item
            container
            flexDirection={'column'}
            justifyContent={'space-between'}
          >
            <Grid 
              item 
              container
              alignItems={'center'}
              gap={'0.5rem'}
              wrap='nowrap'
              sx={{
                opacity: props.product.active ? 1 : 0.5
              }}
            >
              <Typography 
                variant='h2'
                sx={{fontSize: '1.125rem'}}
              >
                {props.product.name}
              </Typography>
              {
                props.product.tags.map((tag) => (
                  <Chip
                    key={tag.uuid}
                    text={tag.name}
                    uppercase
                  />
                ))
              }
              {
                !props.product.active
                ? <Chip
                    text={
                      props.product.inactiveUntil 
                      ? evalStringTemplate(texts('common:inactive_until'), { date: moment(props.product.inactiveUntil).format('DD.MM.YYYY, HH:mm') })
                      : texts('common:inactive')
                    }
                    opacity={0.3}
                  />
                : null
              }
            </Grid>
            <Grid item>
              <Typography 
                variant='body2'
                sx={{
                  textTransform: 'none',
                  opacity: props.product.active ? 1 : 0.5
                }}
              >
                {props.product.description}
              </Typography>
            </Grid>
            <Grid 
              item 
              container
              gap={'0.5rem'}
              sx={{
                opacity: props.product.active ? 1 : 0.5
              }}
            >
              <Tag
                text={`${mapSecondsToString(props.product.customPreparationTime ?? props.product.preparationTime)}`}
                opacity={(props.product.customPreparationTime ?? props.product.preparationTime) === 0}
              >
                <TimerIcon/>
              </Tag>
              <Tag
                text={texts('common:currency', { localValue: currency((props.product.customPrice ?? props.product.price), { fromCents: true }).value })}
                opacity={(props.product.customPrice ?? props.product.price) === 0}
              >
                <LocalOfferIcon/>
              </Tag>
            </Grid>
            {
              props.product.internalNote
              ? <Grid item>
                  <Typography 
                    variant='body2'
                    sx={{
                      textTransform: 'none',
                      fontStyle: 'italic',
                      opacity: props.product.active ? 1 : 0.5
                    }}
                  >
                    {texts('menu:note', {val: props.product.internalNote})}
                  </Typography>
                </Grid>
              : <Grid item flex-grow={1} sx={{height: '1.45rem'}}> </Grid>
            }
          </Grid>
          <Grid item>
            <IconButton
              onClick={handleClick}
              sx={{
                color: '#000',
                position: 'absolute',
                top: 0,
                right: 0
              }}
            >
              <MoreHorizIcon/>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              PaperProps={{
                style: {
                  minWidth: '12rem',
                },
              }}
            >
              {
                cardOptions.map((option) => (
                  <MenuItem 
                    key={option.value}                 
                    onClick={() => {
                      handleMenuItemClick(option.value)
                    }}
                  >
                    {option.label}
                  </MenuItem>
                ))
              }
            </Menu>
          </Grid>
        </Grid>
        <VariantCard 
          product={props.product}
          categoryCardId={props.categoryCardId}
        />
        <AdditionListCard 
          product={props.product} 
          categoryCardId={props.categoryCardId}
        />
      </Grid>
    </>
  )
}

export default ProductCards
