import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import DataApi from '../../../api/data/DataApi'
import { ProductList, TemplateListOptions } from '../../../types/menu'
import { showErrorToast } from '../../../utils/error'
import { mapProductTemplatesListDto } from '../../../utils/mapping'
import { queryNames } from '../../queries'

const dataApi = DataApi.getInstance()

type Options = {
  enabled?: boolean
  onSuccess?: (data: ProductList) => void
}

export default function useGetProductTemplateList(options: TemplateListOptions & Options) {
  const texts = useTranslation().t
  const key = [queryNames.productList, { 
      search: options.search,
      sort: options.sort,
      offset: options.offset,
      exclude: options.exclude 
    }
  ]
  const query = useQuery(key, async () => {
    return mapProductTemplatesListDto(await dataApi.getProductTemplateList(options))
  }, {
    staleTime: Infinity,
    refetchOnMount: 'always',
    enabled: options.enabled,
    onSuccess: options.onSuccess,
    onError: (error) => {
      showErrorToast(error, texts)
    },
  })
  return {
    ...query,
    maxPage: options?.limit != null ? Math.ceil((query.data?.count ?? 0) / options.limit) : 1
  }
}

