import { Grid, Typography, Checkbox } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Variant } from '../../../../../types/menu'
import VariantListTileOptionRow from '../../../Variants/VariantListTileOptionRow'

type Props = {
  variant: Variant
  selected: boolean
  onClick: () => void
}

const VariantTemplateTile = (props: Props) => {
  const texts = useTranslation().t

  return (
    <Grid
      container
      position={'relative'}
      flexDirection={'column'}
      minHeight={'3rem'}
      py={'0.5rem'}
      pr={'1rem'}
      pl={'1.5rem'}
      marginTop={'0.938rem'}
      borderRadius={'0.938rem'}
      boxShadow={'0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.5)'}
      sx={{
        ':hover': {cursor: 'pointer'},
        ...(props.selected ? { border: 'solid 0.125rem #94C11F' } : { border: 'solid 0.125rem white' })
      }}
      gap={'0.938rem'}
      onClick={props.onClick}
    >
      <Grid
        container
        flexWrap={'nowrap'}
      >
        <Grid item>
          <Grid container>
            <Checkbox 
              checked={props.selected} 
              sx={{
                padding: 0, 
                marginTop: '-3px',
                marginRight: '0.5rem',
                '& .MuiSvgIcon-root': { fontSize: 30 },
              }}
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          gap={'0.5rem'}
        >
          <Grid
            container
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Grid
              item
              sx={{
                ':hover': {cursor: 'pointer'}
              }}
              flexGrow={1}
            >
              <Typography 
                variant='h2'
                sx={{fontSize: '1.125rem'}}
              >
                {props.variant.name}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            gap={'0.5rem'}
            sx={{
              ':last-child': {marginBottom: '0.5rem'}
            }}
          >
            {props.variant.options.map((option) => (
              <VariantListTileOptionRow
                variantOption={option}
                key={option.uuid}
              />
            ))}
          </Grid>
          {
            props.variant.note
            ? <Grid 
                item
                sx={{
                  marginBottom: '0.375rem'
                }}
              >
                <Typography 
                  variant='body2'
                  sx={{
                    textTransform: 'none',
                    fontStyle: 'italic'
                  }}
                >
                  {`${texts('variants:note')}: ${props.variant.note}`}
                </Typography>
              </Grid>
            : null
          }
        </Grid>
      </Grid>
    </Grid>
  )
}

export default VariantTemplateTile
