import { useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'
import DataApi from '../../../api/data/DataApi'
import { DataSubcodes, Codes } from '../../../services/errors/consts'
import { showErrorToast } from '../../../utils/error'
import { mapAdditionTemplatesDetailsDtoToForm } from '../../../utils/mapping'
import { queryNames } from '../../queries'
import { AxiosError } from 'axios'
import { HttpError } from '../../../types/common'

const dataApi = DataApi.getInstance()

export default function useGetAdditionTemplateDetails(uuid?: string | null, onNotFoundError?: () => void) {
  const texts = useTranslation().t
  const queryClient = useQueryClient()

  const query = useQuery([queryNames.additionDetails, uuid], async () => {
    return mapAdditionTemplatesDetailsDtoToForm(await dataApi.getAdditionTemplateDetails(uuid!))
  }, {
    enabled: uuid != null && uuid !== '',
    staleTime: Infinity,
    refetchOnMount: 'always',
    onError: (error: AxiosError<HttpError>) => {
      if (error.response?.data.code === Codes.DATA && error.response.data.subcode === DataSubcodes.ADDITION_TEMPLATE_NOT_FOUND) {
        queryClient.removeQueries([queryNames.additionDetails, uuid])
        queryClient.resetQueries(queryNames.additionList)
        queryClient.resetQueries(queryNames.additionAvailabilityList)
        queryClient.resetQueries(queryNames.placeMenu)
        onNotFoundError?.()
      }
      showErrorToast(error, texts)
    }
  })
  return query
}

