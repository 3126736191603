import { ASCENDING, DESCENDING } from './constants'

type OrderModel = {
  field: string
  direction: 'asc' | 'desc'
}

export function evalEnvStringTemplate(template: string, data: object): string {
  for (const [key, value] of Object.entries(data)) {
    template = template.replace(new RegExp(`::${key}::`, 'g'), value)
  }
  return template
}

export function evalStringTemplate(template: string, data: object): string {
  for (const [key, value] of Object.entries(data)) {
    template = template.replace(new RegExp(`\\\${${key}}`, 'g'), value)
  }
  return template
}

export function mapOrder(queryOrder: string): OrderModel {
  const [field, direction] = queryOrder.split(':')

  return {
    field: field,
    direction: direction && [ASCENDING, DESCENDING].includes(direction.toLowerCase()) 
      ? direction.toLowerCase() as 'asc' | 'desc'
      : ASCENDING
  }
}

export function isStringNotEmpty(str?: string | null): boolean {
  if (str) {
    return true
  }
  return false
}