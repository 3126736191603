import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import DataApi from '../../api/data/DataApi'
import { PlaceDetailsDto } from '../../types/place'
import { showErrorToast } from '../../utils/error'
import { queryNames } from '../queries'

const dataApi = DataApi.getInstance()

export default function useGetPlaceList(onSuccess?: (data: PlaceDetailsDto[]) => void) {
  const texts = useTranslation().t

  const query = useQuery(queryNames.placeList, async () => {
    return dataApi.getPlaceList()
  }, {
    onSuccess: onSuccess,
    staleTime: Infinity,
    refetchOnMount: 'always',
    onError: (error) => {
      showErrorToast(error, texts)
    }
  })
  return query
}

