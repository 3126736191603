import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import DataApi from '../../api/data/DataApi'
import { mapTagFormDataToDto } from '../../api/data/mapping'
import { Success, HttpError } from '../../types/common'
import { TagDto, TagFormData, TagListDto } from '../../types/menu'
import { showErrorToast } from '../../utils/error'
import { mapTagDetailsToForm } from '../../utils/mapping'
import { queryNames } from '../queries'
import { AxiosError } from 'axios'
import { Codes, DataSubcodes } from '../../services/errors/consts'

const dataApi = DataApi.getInstance()

type MutationParams = {
  uuid: string
  data: TagFormData
}

export default function useEditTag(onNotFoundError?: () => void) {
  const queryClient = useQueryClient()
  const texts = useTranslation().t
  const mutation = useMutation((params: MutationParams) => dataApi.editTag(params.uuid, mapTagFormDataToDto(params.data)), {
    onError: (e: AxiosError<HttpError>, variables: MutationParams) => {
      if (e.response?.status === 404 && e.response?.data.code === Codes.DATA && e.response?.data.subcode === DataSubcodes.TAG_NOT_FOUND) {
        queryClient.removeQueries([queryNames.tagDetails, variables.uuid])
        queryClient.resetQueries(queryNames.productList)
        queryClient.resetQueries(queryNames.productAvailabilityList)
        queryClient.resetQueries(queryNames.tagList)
        queryClient.resetQueries(queryNames.placeMenu)
        onNotFoundError?.()
      }
      showErrorToast(e, texts)
    },
    onSuccess: (data: Success<TagDto>, variables: MutationParams) => {
      const queriesData = queryClient.getQueriesData<TagListDto>(queryNames.tagList)
      for (const queryData of queriesData) {
        if (queryData[1] != null && (queryData[1].data?.length ?? 0) > 0) {
          const index = queryData[1].data.findIndex(p => p.uuid === variables.uuid)
          if (index !== -1) {
            queryData[1].data[index] = data.data!
            queryClient.setQueryData(queryData[0], queryData[1])
          }
        }
      }
      queryClient.resetQueries(queryNames.placeMenu)
      queryClient.resetQueries(queryNames.productAvailabilityList)
      queryClient.setQueryData([queryNames.tagDetails, variables.uuid], mapTagDetailsToForm(data.data!))
      toast.success(texts('successes:tag_edited'))
    }
  })
  return mutation
}