import { Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { VariantOption } from '../../../types/menu'
import TimerIcon from '@mui/icons-material/Timer'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import Chip from '../../Common/Chip'
import Tag from '../../Common/Tag'
import { mapSecondsToString } from '../../../utils/mapping'
import currency from 'currency.js'

type Props = {
  variantOption: VariantOption
}

const VariantListTileOptionRow = (props: Props) => {
  const texts = useTranslation().t
  
  return (
    <Grid
      container
      minHeight={'2.5rem'}
      py={'0.5rem'}
      px={'1rem'}
      borderRadius={'0.938rem'}
      gap={'0.938rem'}
      wrap={'nowrap'}
      alignItems={'center'}
      sx={{backgroundColor: '#f5f5f5'}}
    >
      <Typography 
        variant='h5'
        sx={{
          fontFamily: 'RobotoRegular',
          minWidth: '10rem'
        }}
      >
        {props.variantOption.name}
      </Typography>
      <Tag
        text={`+${mapSecondsToString(props.variantOption.additionalTime)}`}
        opacity={props.variantOption.additionalTime === 0}
      >
        <TimerIcon/>
      </Tag>
      <Tag
        text={texts('common:currency', { localValue: currency(props.variantOption.additionalPrice, { fromCents: true }).value })}
        opacity={props.variantOption.additionalPrice === 0}
      >
        <LocalOfferIcon/>
      </Tag>
      {
        props.variantOption.defaultOption
        ? <Chip
            text={texts('variants:default')}
          />
        : null
      }
    </Grid>
  )
}

export default VariantListTileOptionRow
