import { MenuCategory, Menu } from '../types/menu'

export function getProductTemplateUuidsFromMenu (categories: MenuCategory[]): string[] {
  const uuids: string[] = []
  for (const cat of categories) {
    uuids.push(...cat.products.map(e => e.templateUuid))
  }
  return uuids
}

export function checkIfInMenuCategory (cat: Menu, pathname: string): boolean {
  return cat.items.findIndex(e => e.path === pathname) > -1 
    || (cat.basePath != null && pathname.startsWith(cat.basePath) && (cat.excludedPaths == null || !cat.excludedPaths.includes(pathname))) 
    || (cat.additionalPaths != null && cat.additionalPaths.includes(pathname))
}