import { Checkbox, Grid, Typography } from '@mui/material'
import { VariantOptionAvailability } from '../../../../types/menu'
import { mapSecondsToString } from '../../../../utils/mapping'
import Tag from '../../../Common/Tag'
import TimerIcon from '@mui/icons-material/Timer'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import { useTranslation } from 'react-i18next'
import Chip from '../../../Common/Chip'
import { evalStringTemplate } from '../../../../utils/text'
import moment from 'moment'
import currency from 'currency.js'

type Props = {
  option: VariantOptionAvailability
  checked: boolean
  onCheckboxClick: (checked: boolean, uuid: string) => void
}

const VariantOptionItem = (props: Props) => {
  const texts = useTranslation().t

  return (
    <Grid
      container
      minHeight={'2.5rem'}
      py={'0.5rem'}
      px={'1rem'}
      borderRadius={'0.938rem'}
      gap={'0.938rem'}
      wrap={'nowrap'}
      alignItems={'center'}
      sx={{
        position: 'relative',
        opacity: props.option.active ? 1 : 0.5,
        borderStyle: props.checked ? 'solid' : 'none',
        backgroundColor: props.checked ? 'rgba(148,193,31, 0.2)' : '#f5f5f5',
        borderColor: '#94C11F',
        borderWidth: 3
      }}
    >
      <Checkbox
        sx={{
          padding: 0,
          alignSelf: 'flex-start'
        }}
        checked={props.checked}
        onClick={() => props.onCheckboxClick(!props.checked, props.option.uuid!)}
      />
      <Typography
        variant='h5'
        fontFamily='RobotoRegular'
        minWidth='10rem'
      >
        {props.option.name}
      </Typography>
      <Tag
        text={`+${mapSecondsToString(props.option.additionalTime)}`}
        opacity={(props.option.additionalTime) === 0}
      >
        <TimerIcon/>
      </Tag>
      <Tag
        text={texts('common:currency', { localValue: currency(props.option.additionalPrice, { fromCents: true }).value })}
        opacity={(props.option.additionalPrice) === 0}
      >
        <LocalOfferIcon/>
      </Tag>
      {
        props.option.defaultOption && <Chip text={texts('variants:default')}/>
      }
      {
        !props.option.active
        ? <Chip
            text={
              props.option.inactiveUntil 
              ? evalStringTemplate(texts('common:inactive_until'), { date: moment(props.option.inactiveUntil).format('DD.MM.YYYY, HH:mm') })
              : texts('common:inactive')
            }
            opacity={0.3}
          />
        : null
      }
    </Grid>
  )
}

export default VariantOptionItem