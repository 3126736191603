import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { DiscountCode } from '../../../../types/discounts'
import Loader from '../../../Common/Loader'
import Tag from '../../../Common/Tag'
import Dialog from '../../../CustomMui/Dialog'
import useRemoveLoyaltyCard from '../../../../hooks/loyaltyCard/useRemoveLoyaltyCard'

type Props = {
  discountCode: DiscountCode
}

const DiscountCodeListRow = (props: Props) => {
  const texts = useTranslation().t
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [openRemoveDialog, setOpenRemoveDialog] = useState<boolean>(false)

  const removeMutation = useRemoveLoyaltyCard()

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  
  return (
    <Grid
      container
      position={'relative'}
      flexDirection={'column'}
      minHeight={'3rem'}
      py={'0.5rem'}
      px={'1rem'}
      marginTop={'0.938rem'}
      borderRadius={'0.938rem'}
      boxShadow={'0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.5)'}
      gap={'0.5rem'}
      sx={{backgroundColor: '#FFFFFF'}}
    >
      <Dialog
        open={openRemoveDialog}
        title={texts('dialogs:remove_discount_code_title')}
        description={texts('dialogs:remove_discount_code_description')}
        closeButtonLabel={texts('common:cancel')}
        confirmButtonLabel={texts('common:remove')}
        onCloseButtonClick={() => {setOpenRemoveDialog(false)}}
        loading={removeMutation.isLoading}
        onConfirmButtonClick={() => {
          removeMutation.mutate({ uuid: props.discountCode.uuid })
        }}
      />
      {
        removeMutation.isLoading
        ? <Grid
            item
            position={'absolute'}
            width={'100%'}
            height={'100%'}
            right={0}
            bottom={0}
            borderRadius={'0.938rem'}
            overflow={'hidden'}
          >
            <Loader
              backgroundColor='rgba(0,0,0,0.08)'
              size={30}
            />
          </Grid>
        : null
      }
      <Grid
        container
        alignItems={'center'}
        justifyContent={'space-between'}
        flexWrap={'nowrap'}
      >
        <Grid
          item
          container
          sx={{
            ':hover': {cursor: 'pointer'}
          }}
          onClick={() => navigate(`/discount/codes/edit/${props.discountCode.uuid}`)}
          gap={'0.5rem'}
          alignItems={'center'}
        >
          <Grid
            item
            mr={'0.5rem'}
          >
            <Typography 
              variant='h2'
              sx={{fontSize: '1.125rem'}}
            >
              {texts('discountCodes:list_name', {
                name: `${props.discountCode.user.firstName}${props.discountCode.user.lastName ? ` ${props.discountCode.user.lastName}` : ''}`,
                email: props.discountCode.user.contactEmail,
                phone: props.discountCode.user.phone
              })}
            </Typography>
          </Grid>
          <Tag text={texts('discountCodes:discount_value', { percentage: (props.discountCode.discount * 100).toFixed() })} />
        </Grid>
        <Grid item>
          <IconButton
            onClick={handleClick}
            sx={{color: '#000'}}
          >
            <MoreHorizIcon/>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{
              style: {
                width: '12rem',
              },
            }}
          >
            <MenuItem 
              onClick={() => {
                handleClose()
                setOpenRemoveDialog(true)
              }}
            >
              {texts('common:remove')}
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default DiscountCodeListRow
