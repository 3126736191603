import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import DataApi from '../../api/data/DataApi'
import { TagListDto, TagListOptions } from '../../types/menu'
import { showErrorToast } from '../../utils/error'
import { queryNames } from '../queries'

const dataApi = DataApi.getInstance()

type Options = {
  enabled?: boolean
  onSuccess?: (data: TagListDto) => void
}

export default function useGetTagList(options: TagListOptions & Options) {
  const texts = useTranslation().t
  const key = [queryNames.tagList, { 
      search: options.search,
      sort: options.sort,
      offset: options.offset 
    }
  ]
  const query = useQuery(key, async () => {
    return await dataApi.getTagList(options)
  }, {
    staleTime: Infinity,
    refetchOnMount: 'always',
    enabled: options.enabled,
    onSuccess: options.onSuccess,
    onError: (error) => {
      showErrorToast(error, texts)
    }
  })
  return {
    ...query,
    maxPage: options?.limit != null ? Math.ceil((query.data?.count ?? 0) / options.limit) : 1
  }
}

