import { Photo } from './common'
import { TagDto } from './menu'

export type PlaceFormData = {
  name: string
  street: string
  postalCode: string
  phone: string
  email: string | null
  town: string
  photoUuid: string | null
  photoUrl: string | null
  coordinates: Coordinates
  openingHours: OpeningHours[]
  openingHoursExceptions: OpeningHourException[]
  pickupTypes: PickupTypes
  extraTimeBufferForItemsInCartAmount: number | null
  extraTimeBufferForRushHours: ExtraTimeBufferForRushHours[]
  payuCredentials: PayUCredentials
  timezone: string
  active?: boolean
}

export type EditPlaceFormData = PlaceFormData

export type PlaceDetailsDto = Pick<PlaceFormData, 'name' | 'street' | 'postalCode' | 'phone' | 'email' | 'town' | 'payuCredentials' > & {
  uuid: string
  active: boolean
  coordinates: CoordinatesDto
  photo?: Photo | null
  pickupMethods: AllowedPickupTypes[]
  settings: {
    openingHours: OpeningHoursDto
    openingHoursException: OpeningHourDetailsExceptionDto[]
    extraTimeBufferForRushHours: ExtraTimeBufferForRushHoursDto | null
    extraTimeBufferForItemsInCartAmount: ExtraTimeBufferForItemsInCartAmountDto | null
  }
}

export type ExtraTimeBufferForItemsInCartAmountDto = {
  amount: number
  value: number
}

export type OpeningHourDetailsExceptionDto = {
  uuid?: string
  open: boolean
  from: string
  to: string
}

export type ExtraDetailsTimeBufferForRushHours = {
  day: number
  start: string
  end: string
  value: number
} & FieldValue

export const enum AllowedPickupTypes {
  ON_SITE = 1,
  TAKEAWAY = 2,
  DELIVERY = 3
}

export type Coordinates = {
  lng: number
  lat: number
}



export type CoordinatesDto = {
  type: 'Point'
  coordinates: number[]
}


export type OpeningHours = {
  open: boolean
  start?: string
  end?: string
} 

export type PickupTypes = {
  takeAway: boolean
  onSite: boolean
  delivery: boolean
}

export type OpeningHourException = {
  date: Date
  open: boolean
  start: string
  end: string
} & FieldValue

export type ExtraTimeBufferForRushHours = {
  day: number
  start: string
  end: string
  value: number | null
} & FieldValue

export enum ChangeState {
  INIT, 
  EDITED,
  SAVED
}

export type PayUCredentials = {
  posId: string
  secondKey: string
  clientId: string
  clientSecret: string
}

type FieldValue = {
  state: ChangeState
  key?: string
}


export type OpeningHoursDto = {
  tz: string
  monday: DayOpeningHoursDto
  tuesday: DayOpeningHoursDto
  wednesday: DayOpeningHoursDto
  thursday: DayOpeningHoursDto
  friday: DayOpeningHoursDto
  saturday: DayOpeningHoursDto
  sunday: DayOpeningHoursDto
}

export type DayOpeningHoursDto = {
  open: boolean
  start?: string | null
  end?: string | null
}

export type ExtraTimeBufferForRushHoursDto = {
  tz: string
  monday: TimeBufferDto[] | null
  tuesday: TimeBufferDto[] | null
  wednesday: TimeBufferDto[] | null
  thursday: TimeBufferDto[] | null
  friday: TimeBufferDto[] | null
  saturday: TimeBufferDto[] | null
  sunday: TimeBufferDto[] | null
}

export type TimeBufferDto = {
  start: string // 00:00:00
  end: string // 00:00:00
  value: number // from 0.0 to x.y *%)
}

export type MenuCategoryDto = {
  uuid: string
  name: string
  products: MenuProductDto[]
}

export type MenuProductDto = {
  uuid: string
  templateUuid: string
  name: string
  description: string
  internalNote: string
  price: number
  preparationTime: number
  customPrice: number | null
  customPreparationTime: number | null
  photo: Photo | null
  tags: TagDto[]
  active: boolean
  inactiveUntil?: string
  variants: MenuVariantDto[]
  additions: MenuAdditionDto[]
}

export type MenuAdditionDto = {
  uuid: string
  templateUuid: string
  name: string
  description?: string
  internalNote: string
  price: number
  active: boolean
  inactiveUntil?: string
  preparationTime: number
  customPrice: number | null
  customPreparationTime: number | null
}

export type MenuVariantDto = {
  uuid: string
  templateUuid: string
  name: string
  description: string | null
  active: boolean
  inactiveUntil?: string
  internalNote: string
  options: MenuVariantOptionDto[]
}

export type MenuVariantOptionDto = {
  uuid: string
  templateUuid: string
  name: string
  description?: string | null
  price: number
  preparationTime: number
  active: boolean
  inactiveUntil?: string
  default: boolean
  customPrice: number | null
  customPreparationTime: number | null
}

export type MenuRequestCategoryBodyDto = {
  uuid?: string
  name: string
  products: MenuRequestProductDto[]
}

export type MenuRequestProductDto = {
  uuid?: string
  templateUuid: string
  price: number | null
  preparationTime: number | null
  additions: MenuRequestAdditionDto[]
  variants: MenuRequestVariantDto[]
}

export type MenuRequestAdditionDto = {
  uuid?: string
  templateUuid: string
  price: number | null
  preparationTime: number | null
}

export type MenuRequestVariantDto = {
  uuid?: string
  templateUuid: string
  options: MenuRequestVariantOptionDto[]
}

export type MenuRequestVariantOptionDto = {
  uuid?: string
  templateUuid: string
  price: number | null
  preparationTime: number | null
}

export type ProductAvailabilityListDto = {
  count: number
  data: ProductAvailabilityEntryDto[]
}

export type ProductAvailabilityEntryDto = {
  templateUuid: string
  name: string
  description: string
  internalNote: string
  price: number
  preparationTime: number
  customPrice: number | null
  customPreparationTime: number | null
  photo: Photo | null
  tags: {
    uuid: string
    name: string
  }[]
  active: boolean
  inactiveUntil?: string
  createdAt: string
  updatedAt?: string
}

export type VariantAvailabilityListDto = {
  count: number
  data: VariantAvailabilityEntryDto[]
}

export type VariantAvailabilityEntryDto = {
  templateUuid: string
  name: string
  description: string
  internalNote: string
  options: VariantOptionAvailabilityEntryDto[]
  createdAt: string
  updatedAt?: string
}

export type VariantOptionAvailabilityEntryDto = {
  templateUuid: string
  name: string
  description: string
  default: boolean
  price: number
  preparationTime: number
  customPrice: number | null
  customPreparationTime: number | null
  active: boolean
  inactiveUntil?: string
  createdAt: string
  updatedAt?: string
}

export type AdditionAvailabilityListDto = {
  count: number
  data: AdditionAvailabilityEntryDto[]
}


export type AdditionAvailabilityEntryDto = {
  templateUuid: string
  name: string
  description: string
  internalNote: string
  price: number
  preparationTime: number
  customPrice: number | null
  customPreparationTime: number | null
  active: boolean
  inactiveUntil?: string
  createdAt: string
  updatedAt?: string
}