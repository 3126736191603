import { Button, DialogActions, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { CreateAccessFormData, Role } from '../../../types/accounts'
import { useFormikContext } from 'formik'

type Props = {
  onClose: () => void
  //role: Role
  pin: string
}

const ThirdStep = (props: Props) => {
  const texts = useTranslation().t

  const { values } = useFormikContext<CreateAccessFormData>()

  return (
    <>
      <Grid
        container
        sx={{maxWidth: '45rem'}}
        gap={'1rem'}
        mt={'1rem'}
      >
        <Typography
          variant={'subtitle2'}
        >
          {texts('accounts:finished')}
        </Typography>
        {
          values.role === Role.EMPLOYEE
            ? <Grid
                item 
                container
                gap={'1rem'}
              >
                <Typography
                  variant={'body2'}
                  sx={{
                    textTransform: 'none'
                  }}
                >
                  {texts('accounts:user_finish_description')}
                </Typography>
                <Typography
                  variant={'body2'}
                  sx={{
                    textTransform: 'none'
                  }}
                >
                  {texts('accounts:user_finish_warning')}
                </Typography>
                <Grid
                  item
                  container
                  justifyContent={'center'}
                >
                  <TextField
                    value={props.pin}
                    disabled
                    sx={{
                      maxWidth: '12rem',
                      '.MuiOutlinedInput-input.Mui-disabled': {WebkitTextFillColor: '#000'}
                    }}
                    InputProps={{
                      endAdornment: 
                        <InputAdornment position='end'>
                          <CopyToClipboard text={props.pin}>
                            <IconButton edge='end'>
                              <ContentCopyIcon/>
                            </IconButton>
                          </CopyToClipboard>
                        </InputAdornment>,
                    }}
                  /> 
                </Grid>
              </Grid>
            : <Typography
                variant={'body2'}
                sx={{
                  textTransform: 'none'
                }}
              >
                {texts('accounts:admin_finish_description')}
              </Typography>
        }
      </Grid>
      <DialogActions sx={{paddingTop: '1.25rem'}}>
        <Button
          variant={'contained'}
          size={'medium'}
          onClick={props.onClose}
        >
          {texts('common:next')}
        </Button>
      </DialogActions>
    </>
  )
}

export default ThirdStep