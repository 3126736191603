import {
  Button,
  Grid,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography
} from '@mui/material'
import Box from '@mui/material/Box'
import { visuallyHidden } from '@mui/utils'
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'
import moment from 'moment'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { NumberParam, StringParam, useQueryParams, withDefault, DecodedValueMap } from 'use-query-params'
import Loader from '../components/Common/Loader'
import LoadingTableRow from '../components/Common/LoadingTableRow'
import NetworkError from '../components/Common/NetworkError'
import NoDataTableRow from '../components/Common/NoDataTableRow'
import CollapsingTableRow from '../components/Reports/CollapsingTableRow'
import useGetPlaceList from '../hooks/place/useGetPlaceList'
import useGetPlaceOrderList from '../hooks/place/useGetPlaceOrderList'
import { queryNames } from '../hooks/queries'
import { Column } from '../types/order'
import { ReportSearchParams } from '../types/reports'
import { ASCENDING, DESCENDING } from '../utils/constants'
import { evalEnvStringTemplate, mapOrder } from '../utils/text'

const rowsPerPageOptions = [12,24,48,96]

function isSearchParamsValid(params: DecodedValueMap<ReportSearchParams>, columns: Column[]): boolean {
  return Number.isInteger(params.page) // must be a number, not a NaN
    && params.page >= 1  // page cannot be lower than 1
    && Number.isInteger(params.size)  // must be a number, not a NaN
    && rowsPerPageOptions.includes(params.size) // size should be from defined list
    && (!params.from || moment(params.from).isValid()) // from should be empty or valid date string
    && (!params.to || moment(params.to).isValid()) // to should be empty or valid date string
    && (
      params.order == null || params.order === '' // no sort order defined
      || (
        columns.find(c => c.id === params.order.split(':')[0]) != null // first part of order string matches column
        && columns.find(c => c.id === params.order.split(':')[0])?.sortable === true // first part of order string must match sortable column
        && ['asc', 'desc'].includes(params.order.split(':')[1]?.toLowerCase()) // second part of sort string must be a sort order string
      ) 
    )
}

const Reports = () => {
  const texts = useTranslation().t
  const queryClient = useQueryClient()

  const columns: Column[] = texts('objects:reports_columns', {returnObjects: true}) as Column[]

  const [searchParams, setSearchParams] = useQueryParams<ReportSearchParams>({ 
    page: withDefault(NumberParam, 1), 
    size: withDefault(NumberParam, rowsPerPageOptions[1]),
    place: withDefault(StringParam, null),
    order: withDefault(StringParam, 'createdAt:desc'),
    from: withDefault(StringParam, ''), 
    to: withDefault(StringParam, '')
  })

  const orderListQuery = useGetPlaceOrderList(searchParams.place ?? '', {
    limit: searchParams.size,
    offset: searchParams.size * (searchParams.page - 1),
    ...(searchParams.from ? { from: moment(searchParams.from).toDate() } : {}),
    ...(searchParams.to ? { to: moment(searchParams.to).toDate() } : {}),
    sort: searchParams.order,
    enabled: searchParams.place != null && searchParams.place !== '' && isSearchParamsValid(searchParams, columns),
    onSuccess: (data) => {
      if (searchParams.page > 1 && data.data.length === 0) {
        setSearchParams({
          page: 1, 
          size: rowsPerPageOptions[1],
          place: null,
          order: 'createdAt:desc',
          from: '', 
          to: ''
        }, 'replaceIn')
      }
    }
  })

  const placeListQuery = useGetPlaceList((data) => {
    const isNotEmpty = data.length > 0
    const isInsideArray = data.find(d => d.uuid === searchParams.place)
    if (isNotEmpty && !isInsideArray) {
      queryClient.resetQueries([queryNames.placeOrderList])
      setSearchParams({
        page: 1, 
        size: rowsPerPageOptions[1],
        place: data[0].uuid,
        order: 'createdAt:desc',
        from: '', 
        to: ''
      }, 'replace')
    }
  })

  useEffect(() => {
    if (!searchParams.place) {
      queryClient.resetQueries([queryNames.placeList])
      return
    }

    if (searchParams.place != null && searchParams.place !== '' && queryClient.getQueryData([queryNames.placeOrderList, searchParams.place, {
      limit: searchParams.size, 
      offset: searchParams.size * searchParams.page, 
      sort: searchParams.order,     
      ...(searchParams.from ? { from: moment(searchParams.from).toDate() } : {}),
      ...(searchParams.to ? { to: moment(searchParams.to).toDate() } : {}),
    }])) {
      queryClient.resetQueries([queryNames.placeOrderList, searchParams.place, {
        limit: searchParams.size, 
        offset: searchParams.size * searchParams.page, 
        sort: searchParams.order,     
        ...(searchParams.from ? { from: moment(searchParams.from).toDate() } : {}),
        ...(searchParams.to ? { to: moment(searchParams.to).toDate() } : {}),
      }])
      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.place])

  useEffect(() => {
    window.scrollTo(0,0)
  }, [searchParams.page])

  useEffect(() => {
    if (!isSearchParamsValid(searchParams, columns)) {
      setSearchParams({
        page: 1, 
        size: rowsPerPageOptions[1],
        place: null,
        order: 'createdAt:desc',
        from: '', 
        to: ''
      }, 'replaceIn')
    }
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, orderListQuery.data?.count, orderListQuery.isFetching, setSearchParams, placeListQuery.data, columns])

  const order = useMemo(() => mapOrder(searchParams.order), [searchParams.order])

  const onClick = () => {
    const params: string[] = [`tz=${process.env.REACT_APP_TZ}`]
    if (searchParams.from) {
      params.push(`from=${encodeURIComponent(searchParams.from)}`)
    }
    if (searchParams.to) {
      params.push(`to=${encodeURIComponent(searchParams.to)}`)
    }
    window.open(`${process.env.REACT_APP_BASE_DATA}${evalEnvStringTemplate(process.env.REACT_APP_ORDER_XLSX_PATH ?? '', { uuid: searchParams.place })}${params.length > 0 ? `?${params.join('&')}` : ''}`)
  }

  if (!isSearchParamsValid(searchParams, columns)) {
    return <></>
  }
  
  return (
    <>              
      {
        placeListQuery.isFetching || placeListQuery.isError
        ? <Grid sx={{height: 'calc(100vh - 160px)'}}>
            {
              placeListQuery.isError
              ? <NetworkError
                  withRefresh
                  onRefresh={() => {
                    queryClient.resetQueries(queryNames.placeList)
                  }}
                />
              : <Loader 
                  width={'100%'}
                  text={texts('common:loading')}
                />
            }
          </Grid>
        : <Grid 
            container
            flexDirection={'column'}
            gap={'2rem'}
            sx={{
              minHeight: 'calc(100vh - 130px)'
            }}
          >
            <Grid 
              item
              container
              justifyContent={'space-between'}
              alignItems={'center'}
            > 
              <Typography variant='h2'>
                {texts('reports:title')}
              </Typography> 
              <Button
                variant={'contained'}
                size={'medium'}
                onClick={onClick}
                sx={{
                  width: 'fit-content',
                  px: '1rem',
                  whiteSpace: 'nowrap'
                }}
              >
                {texts('reports:export')}
              </Button>
            </Grid>
            <Grid
              item
              container
              justifyContent={'space-between'}
              flexWrap={'nowrap'}
            >
              <Grid
                item
                sx={{
                  minWidth: '15rem',
                  maxWidth: '20rem'
                }}
              >
                <DateRangePicker
                  mask={'__.__.____'}
                  maxDate={new Date()}
                  value={
                    [
                      searchParams.from ? moment(searchParams.from).toDate() : null,
                      searchParams.to ? moment(searchParams.to).toDate() : null
                    ]
                  }
                  onChange={(newValue) => {
                    setSearchParams({
                      from: newValue[0] != null ? moment(newValue[0]).format() : '',
                      to: newValue[1] != null ? moment(newValue[1]).endOf('day').format() : '',
                      page: 1
                    }, 'replaceIn')
                  }}
                  renderInput={(startProps, endProps) => (
                    <Box>
                      <Typography 
                        variant={'clear'}
                        sx={{
                          float: 'right'
                        }}
                        onClick={() => {
                          setSearchParams({
                            from: '',
                            to: '',
                            page: 1
                          }, 'replaceIn')
                        }}
                      >
                        {texts('common:clear')}
                      </Typography>
                      <Grid 
                        container
                        flexWrap={'nowrap'}
                        alignItems={'center'}
                      >
                        <TextField 
                          {...startProps}
                          inputProps={{
                            ...startProps.inputProps,
                            placeholder: texts('common:dateInputPlaceholder'),
                            onKeyDown: (event) => {
                              event.preventDefault()
                            }
                          }}
                          label={texts('reports:date_range_from')}
                        />
                        <Box sx={{ mx: 2, fontFamily: 'RobotoRegular' }}> - </Box>
                        <TextField
                          {...endProps}
                          inputProps={{
                            ...endProps.inputProps,
                            placeholder: texts('common:dateInputPlaceholder'),
                            onKeyDown: (event) => {
                              event.preventDefault()
                            }
                          }}
                          label={texts('reports:date_range_to')}
                        />
                      </Grid>
                    </Box>
                  )}
                />
              </Grid>
              <Grid
                item
                container
                sx={{
                  minWidth: '20rem',
                  maxWidth: '40rem'
                }}
                flexWrap={'nowrap'}
                alignItems={'center'}
                gap={'1.5rem'}
              >
                <Typography 
                  variant='body2'
                  fontWeight={'bold'}
                >
                  {texts('reports:place')}
                </Typography> 
                <TextField
                  select
                  value={searchParams.place ?? ''}
                  onChange={(e) => {
                    if (searchParams.place !== e.target.value) {
                        setSearchParams({ 
                        place: e.target.value,
                        page: 1,
                        order: 'createdAt:desc',
                        from: '',
                        to: ''
                      }, 'replaceIn')
                    }
                  }}
                  variant='outlined'
                >
                  {placeListQuery.data?.map((place) => (
                    <MenuItem key={place.name} value={place.uuid}>
                      {place.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            {
              orderListQuery.isError 
              ? <Grid 
                  container 
                  item
                  flexGrow={1} 
                  justifyContent={'center'} 
                  alignItems={'center'}
                >
                  <NetworkError />
                </Grid>
              : <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                  <TableContainer >
                    <Table 
                      stickyHeader 
                      aria-label='sticky table' 
                      sx={{ 
                        tableLayout: 'fixed'
                      }}
                    >
                      <TableHead>
                        <TableRow>
                          {
                            columns.map((column) => (
                              <TableCell
                                key={column.id}
                                sx={{
                                  textTransform: 'none',
                                  fontFamily: 'RobotoMedium',
                                  fontSize: '0.875rem',
                                  wordBreak: 'break-word',
                                  ...(
                                    column.width
                                    ? { width: column.width }
                                    : {}
                                  ),
                                  ...(
                                    column.minWidth
                                    ? { minWidth: column.minWidth }
                                    : {}
                                  ),
                                  ...(
                                    column.maxWidth
                                    ? { maxWidth: column.maxWidth }
                                    : {}
                                  )
                                }}
                              >
                                {
                                  column.sortable
                                  ? <TableSortLabel
                                      active={order.field === column.id}
                                      hideSortIcon={order.field !== column.id}
                                      direction={order.field === column.id ? order.direction : ASCENDING}
                                      onClick={() => {
                                        const newDirection = column.id === order.field
                                          ? order.direction === ASCENDING ? DESCENDING : ASCENDING
                                          : ASCENDING

                                        setSearchParams({ order: `${column.id}:${newDirection}`, page: 1 }, 'replaceIn')
                                      }}
                                      sx={{
                                        flexDirection: 'row-reverse',
                                        justifyContent: 'flex-end'
                                      }}
                                    >
                                      {order.field === column.id ? (
                                        <Box component='span' sx={visuallyHidden}>
                                          {order.direction === DESCENDING ? 'sorted descending' : 'sorted ascending'}
                                        </Box>
                                      ) : null}
                                      {column.label}
                                    </TableSortLabel>
                                  : column.label
                                }
                              </TableCell>
                            ))
                          }
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          orderListQuery.isFetching || (searchParams.page > 1 && orderListQuery.data!.data.length === 0)
                          ? <LoadingTableRow colSpan={columns.length + 1} />
                          : orderListQuery.data == null || orderListQuery.data.data.length === 0
                            ? <NoDataTableRow colSpan={columns.length + 1} />
                            : <>
                                {
                                  orderListQuery.data?.data.map((row, index) => (
                                    <CollapsingTableRow
                                      key={row.number}
                                      row={row}
                                      backgroundColor={index % 2 === 0 ? '#fff' : 'rgba(0, 0, 0, 0.04)'}
                                    />
                                  ))
                                }
                              </>
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions}
                    component='div'
                    count={orderListQuery.data?.count ?? 0}
                    rowsPerPage={searchParams.size}
                    page={searchParams.page - 1}
                    onPageChange={(e, page) => setSearchParams({ page: page + 1 }, 'replaceIn' )}
                    onRowsPerPageChange={(event) => 
                      setSearchParams({
                        size: Number.parseInt(event.target.value), 
                        page: 1
                      }, 'replaceIn' )
                    }
                    sx={{
                      '.MuiTablePagination-selectLabel' : {
                        fontSize: '0.75rem',
                        textTransform: 'none'
                      },
                      '.MuiTablePagination-displayedRows' : {
                        fontSize: '0.75rem',
                        textTransform: 'none'
                      },
                    }}
                  />
                </Paper>
            }
          </Grid>
      }
    </>
  )
}

export default Reports