import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import DataApi from '../../api/data/DataApi'
import { DiscountCodeList } from '../../types/discounts'
import { LoyaltyCardListOptions } from '../../types/menu'
import { showErrorToast } from '../../utils/error'
import { mapLoyaltyCardListDto } from '../../utils/mapping'
import { queryNames } from '../queries'

const dataApi = DataApi.getInstance()

type Options = {
  enabled?: boolean
  onSuccess?: (data: DiscountCodeList) => void
}

export default function useGetLoyaltyCardList(options: LoyaltyCardListOptions & Options) {
  const texts = useTranslation().t
  const key = [queryNames.getLoyaltyCardList, { 
      search: options.search,
      limit: options.limit,
      offset: options.offset,
    }
  ]
  const query = useQuery(key, async () => {
    return mapLoyaltyCardListDto(await dataApi.getLoyaltyCardList(options))
  }, {
    staleTime: Infinity,
    refetchOnMount: 'always',
    enabled: options.enabled,
    onSuccess: options.onSuccess,
    onError: (error) => {
      showErrorToast(error, texts)
    }
  })
  return {
    ...query,
    maxPage: options?.limit != null ? Math.ceil((query.data?.count ?? 0) / options.limit) : 1
  }
}

