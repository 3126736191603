import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import DataApi from '../../api/data/DataApi'
import { NewPinDto } from '../../types/accounts'
import { Success } from '../../types/common'
import { showErrorToast } from '../../utils/error'

const dataApi = DataApi.getInstance()

type MutationParams = {
  uuid: string
}

export default function useResetEmployeePin(onSuccess: (newPin: string) => void) {
  const texts = useTranslation().t
  const mutation = useMutation((params: MutationParams) => dataApi.resetEmployeePin(params.uuid), {
    onError: (e) => {
      showErrorToast(e, texts)
    },
    onSuccess: (data: Success<NewPinDto>) => {
      onSuccess(data.data!.pin)
    }
  })
  return mutation
}