
import { styled } from '@mui/material/styles'
import { DataGrid as MuiDataGrid, DataGridProps } from '@mui/x-data-grid'

const StyledDataGrid = styled(MuiDataGrid)(() => ({
  '& .MuiDataGrid-root': {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0
  },
  '& .MuiDataGrid-selectedRowCount': {
    display: 'none',
  },
  '& .MuiDataGrid-footerContainer': {
    display: 'none'
  },
  '& .MuiDataGrid-cell': {
    fontSize: '1rem',
    textTransform: 'none'
  },
  '& .MuiDataGrid-columnHeaderTitleContainer': {
    fontSize: '1rem',
    fontFamily: 'RobotoMedium',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end'
  },
  '& .MuiDataGrid-menuIcon': {
    display: 'none'
  },
  '.MuiTablePagination-selectLabel': {
    fontSize: '0.875rem'
  },
  '.MuiTablePagination-displayedRows': {
    fontSize: '0.875rem'
  },
  '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
    display: 'none'
  }
}))

export const DataGrid = (props: Omit<DataGridProps, 'disableSelectionOnClick'>) => {
  return (
    <StyledDataGrid
      {...props}
      disableSelectionOnClick={!props.checkboxSelection}
    />
  )
}
