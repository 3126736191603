import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import DataApi from '../../api/data/DataApi'
import { BannerFormData } from '../../types/common'
import { showErrorToast } from '../../utils/error'
import { mapBannerList } from '../../utils/mapping'
import { queryNames } from '../queries'

const dataApi = DataApi.getInstance()

export default function useGetBannerList(onSuccess: (data: BannerFormData[]) => void) {
  const texts = useTranslation().t

  const query = useQuery(queryNames.bannerList, async () => {
    return mapBannerList(await dataApi.getBanners())
  }, {
    staleTime: Infinity,
    refetchOnMount: 'always',
    onError: (error) => {
      showErrorToast(error, texts)
    },
    onSuccess: onSuccess
  })
  return query
}

