import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import TimerIcon from '@mui/icons-material/Timer'
import { Grid, Typography, Checkbox } from '@mui/material'
import currency from 'currency.js'
import { useTranslation } from 'react-i18next'
import { Addition } from '../../../../../types/menu'
import { mapSecondsToString } from '../../../../../utils/mapping'
import Tag from '../../../../Common/Tag'

type Props = {
  addition: Addition
  selected: boolean
  onClick: () => void
}

const AdditionsTemplateTile = (props: Props) => {
  const texts = useTranslation().t

  return (
    <Grid
      container
      position={'relative'}
      flexDirection={'column'}
      minHeight={'3rem'}
      py={'0.5rem'}
      px={'1rem'}
      marginTop={'0.938rem'}
      borderRadius={'0.938rem'}
      boxShadow={'0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.5)'}
      gap={'0.5rem'}
      sx={{
        ':hover': {cursor: 'pointer'},
        ...(props.selected ? { border: 'solid 0.125rem #94C11F' } : { border: 'solid 0.125rem white' })
      }}
      onClick={props.onClick}
    >
      <Grid
        container
        alignItems={'center'}
        justifyContent={'space-between'}
        flexWrap={'nowrap'}
      >
        <Grid item>
          <Checkbox 
            checked={props.selected} 
            sx={{
              padding: 0, 
              marginRight: '0.5rem',
              // marginTop: '-3px',
              '& .MuiSvgIcon-root': { fontSize: 30 },
            }}
          />
        </Grid>
        <Grid
          item
          container
          gap={'0.5rem'}
          alignItems={'center'}
        >
          <Grid
            item
            mr={'0.5rem'}
            mt={'0.25rem'}
          >
            <Typography 
              variant='h2'
              sx={{fontSize: '1.125rem'}}
            >
              {props.addition.name}
            </Typography>
          </Grid>
          <Tag
            text={`+${mapSecondsToString(props.addition.additionalTime)}`}
            opacity={props.addition.additionalTime === 0}
          >
            <TimerIcon/>
          </Tag>
          <Tag
            text={texts('common:currency', { localValue: currency(props.addition.additionalPrice, { fromCents: true }).value })}
            opacity={props.addition.additionalPrice === 0}
          >
            <LocalOfferIcon/>
          </Tag>
        </Grid>
      </Grid>
      {
        props.addition.note
        ? <Grid 
            item
            sx={{
              marginBottom: '0.375rem'
            }}
          >
            <Typography 
              variant='body2'
              sx={{
                textTransform: 'none',
                fontStyle: 'italic'
              }}
            >
              {`${texts('additions:note')}: ${props.addition.note}`}
            </Typography>
          </Grid>
        : null
      }
    </Grid>
  )
}

export default AdditionsTemplateTile
