import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import DataApi from '../../../api/data/DataApi'
import { mapAdditionTemplateData } from '../../../api/data/mapping'
import { Success, HttpError } from '../../../types/common'
import { AdditionFormData, AdditionList, AdditionTemplateDto } from '../../../types/menu'
import { showErrorToast } from '../../../utils/error'
import { mapAdditionTemplatesDetailsDto, mapAdditionTemplatesDetailsDtoToForm } from '../../../utils/mapping'
import { queryNames } from '../../queries'
import { AxiosError } from 'axios'
import { Codes, DataSubcodes } from '../../../services/errors/consts'

const dataApi = DataApi.getInstance()

type MutationParams = {
  uuid: string
  data: AdditionFormData
}

export default function useEditAdditionTemplate(onNotFoundError?: () => void) {
  const queryClient = useQueryClient()
  const texts = useTranslation().t
  const mutation = useMutation((params: MutationParams) => dataApi.editAdditionTemplate(params.uuid, mapAdditionTemplateData(params.data)), {
    onError: (e: AxiosError<HttpError>, variables: MutationParams) => {
      if (e.response?.status === 404 && e.response?.data.code === Codes.DATA && e.response?.data.subcode === DataSubcodes.ADDITION_TEMPLATE_NOT_FOUND) {
        queryClient.resetQueries(queryNames.placeMenu)
        queryClient.resetQueries(queryNames.additionList)
        queryClient.resetQueries(queryNames.additionAvailabilityList)
        queryClient.removeQueries([queryNames.additionDetails, variables.uuid])
        onNotFoundError?.()
      }
      showErrorToast(e, texts)
    },
    onSuccess: (data: Success<AdditionTemplateDto>, variables: MutationParams) => {
      const queriesData = queryClient.getQueriesData<AdditionList>(queryNames.additionList)
      for (const queryData of queriesData) {
        if (queryData[1] != null && (queryData[1].data?.length ?? 0) > 0) {
          const index = queryData[1].data.findIndex(p => p.uuid === variables.uuid)
          if (index !== -1) {
            queryData[1].data[index] = mapAdditionTemplatesDetailsDto(data.data!)
            queryClient.setQueryData(queryData[0], queryData[1])
          }
        }
      }
      queryClient.resetQueries(queryNames.placeMenu)
      queryClient.resetQueries(queryNames.additionAvailabilityList)
      queryClient.setQueryData([queryNames.additionDetails, variables.uuid], mapAdditionTemplatesDetailsDtoToForm(data.data!))
      toast.success(texts('successes:addition_edited'))
    }
  })
  return mutation
}