import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import DataApi from '../../../api/data/DataApi'
import { mapProductTemplateData } from '../../../api/data/mapping'
import { Success, HttpError } from '../../../types/common'
import { ProductFormData, ProductList, ProductTemplateDto } from '../../../types/menu'
import { showErrorToast } from '../../../utils/error'
import { mapProductTemplateDetailsDto, mapProductTemplateDetailsDtoToForm } from '../../../utils/mapping'
import { queryNames } from '../../queries'
import { AxiosError } from 'axios'
import { Codes, DataSubcodes, Page } from '../../../services/errors/consts'

const dataApi = DataApi.getInstance()

type MutationParams = {
  uuid: string
  data: ProductFormData
  onTagNotFoundError: (uuids: string[]) => void
}

export default function useEditProductTemplate(onNotFoundError?: () => void) {
  const queryClient = useQueryClient()
  const texts = useTranslation().t
  const mutation = useMutation((params: MutationParams) => dataApi.editProductTemplate(params.uuid, mapProductTemplateData(params.data)), {
    onError: (e: AxiosError<HttpError>, variables: MutationParams) => {
      if (e.response?.status === 404 && e.response?.data.code === Codes.DATA && e.response?.data.subcode === DataSubcodes.PRODUCT_TEMPLATE_NOT_FOUND) {
        queryClient.resetQueries(queryNames.placeMenu)
        queryClient.resetQueries(queryNames.productList)
        queryClient.resetQueries(queryNames.productAvailabilityList)
        queryClient.removeQueries([queryNames.productDetails, variables.uuid])
        onNotFoundError?.()
      } else if (e.response?.status === 404 && e.response?.data.code === Codes.DATA && e.response?.data.subcode === DataSubcodes.TAG_NOT_FOUND) {
        queryClient.resetQueries(queryNames.tagList)
        queryClient.resetQueries(queryNames.placeMenu)
        queryClient.resetQueries(queryNames.productList)
        queryClient.resetQueries(queryNames.productAvailabilityList)
        variables.onTagNotFoundError(e.response.data.data!)
      }
      showErrorToast(e, texts, Page.PRODUCT_PAGE)
    },
    onSuccess: (data: Success<ProductTemplateDto>, variables: MutationParams) => {
      const queriesData = queryClient.getQueriesData<ProductList>(queryNames.productList)
      for (const queryData of queriesData) {
        if (queryData[1] != null && (queryData[1].data?.length ?? 0) > 0) {
          const index = queryData[1].data.findIndex(p => p.uuid === variables.uuid)
          if (index !== -1) {
            queryData[1].data[index] = mapProductTemplateDetailsDto(data.data!)
            queryClient.setQueryData(queryData[0], queryData[1])
          }
        }
      }
      queryClient.resetQueries(queryNames.placeMenu)
      queryClient.resetQueries(queryNames.productAvailabilityList)
      queryClient.setQueryData([queryNames.productDetails, variables.uuid], mapProductTemplateDetailsDtoToForm(data.data!))
      toast.success(texts('successes:product_edited'))
    }
  })
  return mutation
}