import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { resendActivationEmailValidation } from '../../validations/resendActivationEmailValidation'
import { Box, Button, Grid, Typography } from '@mui/material'
import TextField from '../../components/CustomMui/Fields/TextField'
import useResendActivationEmail from '../../hooks/auth/useResendActivationEmail'
import LoadingButton from '../../components/CustomMui/LoadingButton'
import { EmailFormData } from '../../types/auth'


const ResendActivationEmailPage = () => {
  const texts = useTranslation().t
  const navigate = useNavigate()
  const mutation = useResendActivationEmail()

  const onSubmitResendActivationEmail = async (data: EmailFormData) => {
    mutation.mutate(data)
  }

  return (
    <Grid 
      container 
      direction={'column'} 
      justifyContent={'center'} 
      alignItems={'center'} 
      height={'calc(100vh - 130px)'}
    >
      <Typography 
        variant='h1' 
        sx={{
          marginBottom: '4.5rem',
          maxWidth: '43.75rem'
        }}
      > 
        {texts('auth:resend_activation_email_title')}
      </Typography>
      <Formik<EmailFormData> 
        initialValues={{
          email: '',
        }}
        onSubmit={onSubmitResendActivationEmail}
        validationSchema={resendActivationEmailValidation(texts)}
      >
        {(formikProps) => (
          <Box width={'30rem'}>
            <Form>
            <Box>
              <TextField
                name='email'
                type='email'
                label={texts('auth:email_address')}
              />
            </Box>
            <Grid 
              container 
              justifyContent={'space-between'}
            >
              <Button
                variant={'outlined'}
                size={'large'}
                onClick={() => {navigate('/login')}}
              >
                {texts('auth:back_to_login')}
              </Button>
              <LoadingButton
                variant={'contained'}
                type='submit'
                size={'large'}
                disabled={
                  !formikProps.isValid || !formikProps.dirty
                }
                loading={mutation.isLoading}
              >
                {texts('auth:resend_activation_email_label')}
              </LoadingButton>
            </Grid>
            </Form>
          </Box>
        )}
      </Formik>    
    </Grid>
  )
}

export default ResendActivationEmailPage
