import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import DataApi from '../../api/data/DataApi'
import { showErrorToast } from '../../utils/error'
import { queryNames } from '../queries'
import { mapLoyaltyCardDto } from '../../utils/mapping'

const dataApi = DataApi.getInstance()

export default function useGetLoyaltyCardDetails(uuid?: string | null) {
  const texts = useTranslation().t

  const query = useQuery([queryNames.getLoyaltyCardDetails, uuid], async () => {
    return mapLoyaltyCardDto(await dataApi.getLoyaltyCardDetails(uuid!))
  }, {
    enabled: uuid != null && uuid !== '',
    staleTime: Infinity,
    refetchOnMount: 'always',
    onError: (error) => {
      showErrorToast(error, texts)
    }
  })
  return query
}

