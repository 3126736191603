import { Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import notFoundImage from '../assets/images/notFoundImage.webp'
import Box from '@mui/material/Box'

const NotFound = () => {
  const texts = useTranslation().t

  return (
    <Grid 
      container
      minHeight={'calc(100vh - 130px)'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Grid
        item
        container
        flexDirection={'column'}
        alignItems={'center'}
        gap={'1rem'}
      >
        <Box
          component={'img'}
          alt='image'
          flexShrink={0}
          src={notFoundImage}
          sx={{
            width: '22.8125rem',
            height: '21.875rem',
            marginBottom: '3.125rem'
          }}
        />
        <Typography variant='h1' color={'rgba(0, 0, 0, 0.7)'}>
          {texts('errors:page_not_found_label')}
        </Typography>
        <Typography color={'#202020'}>
          {texts('errors:page_not_found_sub_label')}
        </Typography>
      </Grid>
    </Grid>
  )
} 

export default NotFound