import * as Yup from 'yup'
import moment from 'moment'

export function timedDeactivationValidation(t: (val: string) => string) {
  return Yup.object({
    date: Yup.date()
      .typeError(t('validations:invalid_value'))
      .required(t('validations:field_required'))
      .test('places_required', t('validations:date_in_past'), function (val: any) {
        const momDate = moment(val)
        return momDate.isAfter(moment())
      }),
  })
}