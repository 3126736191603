import { Grid, GridProps } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Loader from '../../Common/Loader'
import NetworkError from '../../Common/NetworkError'
import NoDataPlaceholder from '../../Common/NoDataPlaceholder'

type Props = {
  isLoading: boolean
  isError: boolean
  items: JSX.Element[]
} & GridProps

const List = (props: Props) => {
  const texts = useTranslation().t

  return (
    <>
    {
      props.isLoading || props.isError
        ? <Grid 
            container 
            item
            flexGrow={1} 
            justifyContent={'center'} 
            alignItems={'center'}
          >
            {
              props.isError
                ? <NetworkError/>
                : <Loader
                    width={'100%'}
                    text={texts('common:loading')}
                  />
            }
          </Grid>
        : <Grid 
            container item 
            gap={'10px'} 
            height={'fit-content'} 
            marginBottom={'60px'}
          >
            {props.items.length > 0 
              ? props.items
              : <NoDataPlaceholder/>
            }
          </Grid>
      }
    </>
  )
}

export default List