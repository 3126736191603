import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import DataApi from '../../../api/data/DataApi'
import { mapProductTemplateData } from '../../../api/data/mapping'
import { Page, Codes, DataSubcodes } from '../../../services/errors/consts'
import { HttpError } from '../../../types/common'
import { ProductFormData } from '../../../types/menu'
import { showErrorToast } from '../../../utils/error'
import { queryNames } from '../../queries'
import { AxiosError } from 'axios'

const dataApi = DataApi.getInstance()

type MutationParams = {
  data: ProductFormData
  onTagNotFoundError: (uuids: string[]) => void
}

export default function useCreateProductTemplate() {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const texts = useTranslation().t
  const mutation = useMutation((params: MutationParams) => dataApi.createNewProductTemplate(mapProductTemplateData(params.data)), {
    onError: (e: AxiosError<HttpError>, variables: MutationParams) => {
      if (e.response?.status === 404 && e.response?.data.code === Codes.DATA && e.response?.data.subcode === DataSubcodes.TAG_NOT_FOUND) {
        queryClient.resetQueries(queryNames.tagList)
        queryClient.resetQueries(queryNames.placeMenu)
        queryClient.resetQueries(queryNames.productList)
        queryClient.resetQueries(queryNames.productAvailabilityList)
        variables.onTagNotFoundError(e.response.data.data!)
      }
      showErrorToast(e, texts, Page.PRODUCT_PAGE)
    },
    onSuccess: () => {
      queryClient.resetQueries(queryNames.productList)
      toast.success(texts('successes:product_added'))
      navigate('/menu/products', {replace: true})
    }
  })
  return mutation
}