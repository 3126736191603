import { useEffect } from 'react'
import { NavigationType, useLocation, useNavigationType } from 'react-router-dom'

type Props = {
  children?: JSX.Element | JSX.Element[]
}

function useBackButton() {
  const navType = useNavigationType()
  return navType === NavigationType.Pop
}

export default function ScrollToTop({ children }: Props) {
  const { pathname } = useLocation()

  const isPop = useBackButton()
  
  useEffect(() => {
    if (isPop) {
      return
    }
    
    window.scrollTo(0, 0)
  }, [pathname, isPop])
  
  return (
    <>
      {
        children
      }
    </>
  )
}
