import { Grid, MenuItem, TextField, Typography } from '@mui/material'
import { Form, Formik, FormikProps } from 'formik'
import { useState } from 'react'
import { DragDropContext, DropResult } from '@hello-pangea/dnd'
import { useTranslation } from 'react-i18next'
import { AiFillPlusCircle } from 'react-icons/ai'
import CategoryCard from '../../components/Menu/CreateMenu/CategoryCard'
import AddCategoryDialog from '../../components/Menu/CreateMenu/Dialogs/AddCategoryDialog'
import DraggableItem from '../../components/Common/DraggableItem'
import DroppableList from '../../components/Common/DroppableList'
import { MenuCategory, MenuAddition, MenuProduct, MenuVariant, MenuVariantOption, MenuFormData } from '../../types/menu'
import { reorder } from '../../utils/array'
import { useRef, useEffect } from 'react'
import useGetPlaceList from '../../hooks/place/useGetPlaceList'
import NetworkError from '../../components/Common/NetworkError'
import Loader from '../../components/Common/Loader'
import { useQueryClient } from 'react-query'
import { queryNames } from '../../hooks/queries'
import useGetPlaceMenu from '../../hooks/place/useGetPlaceMenu'
import usePutPlaceMenu from '../../hooks/place/usePutPlaceMenu'
import { putPlaceMenuValidation } from '../../validations/putPlaceMenuValidation'
import toast from 'react-hot-toast'
import LoadingButton from '@mui/lab/LoadingButton'
import { useQueryParams, withDefault, StringParam } from 'use-query-params'
import EditCategoryDialog from '../../components/Menu/CreateMenu/Dialogs/EditCategoryDialog'
import Dialog from '../../components/CustomMui/Dialog'

const CreateMenuPage = () => {
  const texts = useTranslation().t
  const queryClient = useQueryClient()
  const formRef = useRef<FormikProps<MenuFormData>>(null)
  const selectValue = useRef<string | null>(null)

  const [searchParams, setSearchParams] = useQueryParams({
    place: withDefault(StringParam, null)
  })

  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false)
  const [openEditDialog, setOpenEditDialog] = useState<boolean>(false)
  const [openConfirmChangePlaceDialog, setOpenConfirmChangePlaceDialog] = useState<boolean>(false)

  const putMutation = usePutPlaceMenu()
  const placeListQuery = useGetPlaceList((data) => {
    const isNotEmpty = data.length > 0
    const isInsideArray = data.find(d => d.uuid === searchParams.place)

    if (isNotEmpty && !isInsideArray) {
      setSearchParams({ place: data[0].uuid }, 'replaceIn')
    }
  })
 
  const placeMenuQuery = useGetPlaceMenu(
    placeListQuery.data != null && searchParams.place != null,
    searchParams.place!
  )

  useEffect(() => {
    if (searchParams.place != null && queryClient.getQueryData([queryNames.placeMenu, searchParams.place])) {
      queryClient.resetQueries([queryNames.placeMenu, searchParams.place])
      return
    }

    if ((placeListQuery.data?.length ?? 0) > 0 && searchParams.place == null) {     
      setSearchParams({ place: placeListQuery.data![0].uuid }, 'replaceIn')    
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.place])

  const onDragEnd = (result : DropResult) => {
    if (!result.destination || formRef.current == null) {
      return
    }

    let children: MenuCategory[] | MenuProduct[] | MenuVariant[] | MenuAddition[] | MenuVariantOption[]

    if (result.type === 'category') { //CategoryCard
      children = reorder(formRef.current.values.items, result.source.index, result.destination.index)
      formRef.current?.setFieldValue('items', children)
    } else if (result.type.includes('variant-option')) { //VariantContainer
      const copiedItems = [...formRef.current.values.items]
      const categoryIndex = copiedItems.findIndex(c => c.cardId === result.destination?.droppableId.split(':')[1])!
      const productIndex = copiedItems[categoryIndex].products.findIndex(p => p.cardId === result.destination?.droppableId.split(':')[2])!
      const variantIndex = copiedItems[categoryIndex].products[productIndex].variants?.findIndex(v => v.cardId === result.destination?.droppableId.split(':')[3])!
      children = reorder(copiedItems[categoryIndex].products[productIndex].variants![variantIndex].options, result.source.index, result.destination.index)
      copiedItems[categoryIndex].products[productIndex].variants![variantIndex].options = children as MenuVariantOption[]
      formRef.current?.setFieldValue('items', copiedItems)
    } else if (result.type.includes('variant')) { //VariantOptionCard
      const copiedItems = [...formRef.current.values.items]
      const categoryIndex = copiedItems.findIndex(c => c.cardId === result.destination?.droppableId.split(':')[1])!
      const productIndex = copiedItems[categoryIndex].products.findIndex(p => p.cardId === result.destination?.droppableId.split(':')[2])!
      children = reorder(copiedItems[categoryIndex].products[productIndex].variants!, result.source.index, result.destination.index)
      copiedItems[categoryIndex].products[productIndex].variants = children as MenuVariant[]
      formRef.current?.setFieldValue('items', copiedItems)
    } else if (result.type.includes('addition')) { //AdditionCard
      const copiedItems = [...formRef.current.values.items]
      const categoryIndex = copiedItems.findIndex(c => c.cardId === result.destination?.droppableId.split(':')[1])!
      const productIndex = copiedItems[categoryIndex].products.findIndex(p => p.cardId === result.destination?.droppableId.split(':')[2])!
      children = reorder(copiedItems[categoryIndex].products[productIndex].additions!, result.source.index, result.destination.index)
      copiedItems[categoryIndex].products[productIndex].additions = children as MenuAddition[]
      formRef.current?.setFieldValue('items', copiedItems)
    } else if (result.type.includes('product')) { //ProductCard
      const copiedItems = [...formRef.current.values.items]
      const categoryIndex = copiedItems.findIndex(c => c.cardId === result.destination?.droppableId.split(':')[1])!
      children = reorder(copiedItems[categoryIndex].products, result.source.index, result.destination.index)
      copiedItems[categoryIndex].products = children as MenuProduct[]
      formRef.current?.setFieldValue('items', copiedItems)
    }
  }

  const handleSubmit = (data: MenuFormData) => {
    putMutation.mutate({
      uuid: searchParams.place!,
      data: data.items
    })
  }

  return (
    <>
      <Dialog
        open={openConfirmChangePlaceDialog}
        title={texts('dialogs:go_back_title')}
        description={texts('dialogs:go_back_description')}
        closeButtonLabel={texts('common:cancel')}
        confirmButtonLabel={texts('common:exit')}
        onCloseButtonClick={() => {
          selectValue.current = ''
          setOpenConfirmChangePlaceDialog(false)
        }}
        onConfirmButtonClick={() => {
          setSearchParams({ place: selectValue.current ?? null }, 'replaceIn')
          selectValue.current = ''
          setOpenConfirmChangePlaceDialog(false)
        }}
      />
      {
        placeListQuery.isError || placeListQuery.isFetching
        ? <Grid sx={{height: 'calc(100vh - 160px)'}}>
            {
              placeListQuery.isError
              ? <NetworkError
                  withRefresh
                  onRefresh={() => {
                    queryClient.resetQueries(queryNames.placeList)
                  }}
                />
              : <Loader 
                  width={'100%'}
                  text={texts('common:loading')}
                />
            }
          </Grid>
        : <Grid 
            container
            flexDirection={'column'}
          >
            <Grid
              item
              container 
              wrap='nowrap'
              justifyContent={'space-between'} 
              alignItems={'center'}
            >
              <Grid item> 
                <Typography variant='h2' noWrap>
                  {texts('menu:menu_title')}
                </Typography> 
              </Grid>
              <Grid 
                item 
                container 
                justifyContent={'flex-end'}
              >
                <Grid item>
                  <LoadingButton
                    variant={'contained'}
                    size={'medium'}
                    loading={putMutation.isLoading}
                    sx={{
                      marginLeft: '0.625rem', 
                      gap: '0.5rem', 
                      width: 'max-content',
                      px: '0.8rem'
                    }}
                    onClick={() => formRef.current?.submitForm()}
                  >
                    {texts('menu:save_menu')}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item 
              container
              justifyContent={'right'}
              wrap={'nowrap'}
              marginTop={'1.5rem'}
              marginBottom={'1rem'}
            >
              <Grid
                item
                container
                alignItems={'center'}
                width={'40rem'}
                gap={'0.875rem'}
                wrap={'nowrap'}
              >
                <Typography 
                  variant='body2'
                  fontWeight={'bold'}
                >
                  {texts('common:premises')}
                </Typography>
                <TextField
                  select
                  value={searchParams.place ?? ''}
                  onChange={(event) => {
                    if (selectValue.current !== event.target.value) {
                      selectValue.current = event.target.value
                      setOpenConfirmChangePlaceDialog(true)
                    }
                  }}
                  variant='outlined'
                  sx={{
                    minWidth: '12rem',
                    flexGrow: 1
                  }}
                >
                  {
                    placeListQuery.data?.map((place) => (
                      <MenuItem key={place.name} value={place.uuid}>
                        {place.name}
                      </MenuItem>
                    ))
                  }
                </TextField>
              </Grid>
            </Grid>
            <Formik<MenuFormData>
              initialValues={{items: placeMenuQuery.data ?? []}}
              onSubmit={handleSubmit}
              enableReinitialize
              validateOnChange={false}
              validateOnBlur={false}
              innerRef={formRef}
              validate={async (data: MenuFormData) => {
                try {
                  await putPlaceMenuValidation(texts).validate(data)
                  return {}
                } catch (error) {
                  toast.error(error.message)
                  return { menu: error.message }
                }
              }}
            >
              {(formikProps) => (
                <Form>
                  <AddCategoryDialog
                    open={openAddDialog}
                    onCloseButtonClick={() => {setOpenAddDialog(false)}} 
                  />
                  <EditCategoryDialog
                    open={openEditDialog}
                    onCloseButtonClick={() => {setOpenEditDialog(false)}} 
                  />
                  {
                    placeMenuQuery.isFetching || placeMenuQuery.isError
                    ? <Grid 
                        container 
                        item
                        flexGrow={1} 
                        justifyContent={'center'} 
                        alignItems={'center'}
                      >
                        {
                          placeMenuQuery.isError
                          ? <NetworkError />
                          : <Loader 
                              width={'100%'}
                              text={texts('common:loading')}
                            />
                        }
                      </Grid>
                    : <>
                        <DragDropContext onDragEnd={onDragEnd}>
                          <DroppableList droppableId={'category'} boxDirection='vertical'>
                            {
                              formikProps.values.items.map((category, index) => (
                                <DraggableItem id={`category:${category.cardId}`} index={index} key={category.cardId}>
                                  <CategoryCard
                                    key={category.cardId}     
                                    category={category}
                                    onEdit={() => { setOpenEditDialog(true) }}
                                  />
                                </DraggableItem>
                              ))
                            }
                          </DroppableList>
                        </DragDropContext>
                        <Grid 
                          item 
                          container 
                          wrap='nowrap' 
                          alignItems={'center'}
                          marginTop={'0.975rem'}
                          paddingBottom={'2.875rem'}
                        >
                          <MenuItem 
                            sx={{ padding: "0" }}
                            onClick={() => {
                              setOpenAddDialog(true)
                            }}
                            disabled={formikProps.values.items.length >= 25}
                          >
                            <AiFillPlusCircle 
                              size={30}
                              color={'#94C11F'}
                            />
                            <Typography
                              variant='body2'
                              sx={{
                                fontFamily: 'RobotoBold',
                                marginLeft: '1.25rem',
                                paddingTop: '0.188rem',
                                color: '#94C11F'
                              }}
                            >
                              {texts('menu:menu_add_category')}
                            </Typography>
                          </MenuItem>
                        </Grid>
                      </>
                  }
                </Form>
              )}
            </Formik>
          </Grid>
      }
    </>
  )
}

export default CreateMenuPage