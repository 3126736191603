import * as Yup from 'yup'

export function addAdditionValidation(t: (val: string) => string) {
  return Yup.object({
    name: Yup.string()
      .trim()
      .max(32, t('validations:max_32_signs_validation'))
      .required(t('validations:field_required')),
    note: Yup.string()
      .max(120, t('validations:max_120_signs_validation')),
  })
}
