import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import DataApi from '../../api/data/DataApi'
import { mapPlaceDataToDto } from '../../api/data/mapping'
import { PlaceFormData } from '../../types/place'
import { showErrorToast } from '../../utils/error'
import { queryNames } from '../queries'

const dataApi = DataApi.getInstance()

export default function useCreatePlace() {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const texts = useTranslation().t
  const mutation = useMutation((data: PlaceFormData) => dataApi.createNewPlace(mapPlaceDataToDto(data)), {
    onError: (e) => {
      showErrorToast(e, texts)
    },
    onSuccess: () => {
      queryClient.resetQueries(queryNames.placeList)
      toast.success(texts('successes:place_created_successfully'))
      navigate('/', {replace: true})
    }
  })
  return mutation
}