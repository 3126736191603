import { IconButton, InputBase, Paper } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { useRef, useEffect } from 'react'

type Props = {
  onChange?: (text: string) => void 
  onClick?: (text: string) => void
  placeholder: string
  width?: number | string
  height?: number | string
  initialValue?: string
}

const SearchBar = (props: Props) => {
  const ref = useRef(null) as any

  useEffect(() => {
    if (props.initialValue != null && ref.current) {
      ref.current.value = props.initialValue
    }
  }, [props.initialValue])

  return (
    <Paper
      component='form'
      sx={{ 
        py: '0.125rem',
        paddingLeft: '0.625rem',
        display: 'flex', 
        alignItems: 'center',
        width: props.width,
        height: props.height
      }}
    >
      <InputBase
        inputRef={ref}
        onChange={(e) => props.onChange?.(e.target.value ?? '')} 
        sx={{
          minWidth: '15rem',
          flexGrow: '1'
        }}
        placeholder={props.placeholder}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault()
            props.onClick?.(ref.current?.value ?? '')
          }
        }}
      />
      <IconButton 
        sx={{ p: '0.625rem' }} 
        aria-label='search'
        onClick={() => props.onClick?.(ref.current?.value ?? '')}
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  )
}

export default SearchBar