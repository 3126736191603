import { Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import TimerIcon from '@mui/icons-material/Timer'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import { DraggableProvided } from '@hello-pangea/dnd'
import { Box } from '@mui/system'
import { MenuAddition, MenuItemCardOption, MenuItemType, MenuFormData } from '../../../types/menu'
import Tag from '../../Common/Tag'
import { mapSecondsToString } from '../../../utils/mapping'
import moment from 'moment'
import Chip from '../../Common/Chip'
import { evalStringTemplate } from '../../../utils/text'
import RemoveMenuItemDialog from './Dialogs/RemoveMenuItemDialog'
import { useFormikContext } from 'formik'
import { removeArrayElement } from '../../../utils/array'
import currency from 'currency.js'

type Props = {
  addition: MenuAddition
  categoryCardId: string
  productCardId: string
  provided?: DraggableProvided
}

const AdditionCard = (props: Props) => {
  const texts = useTranslation().t
  const cardOptions: MenuItemCardOption[] = texts('objects:menu_addition_options', { returnObjects: true }) as MenuItemCardOption[]
  const { values, setFieldValue } = useFormikContext<MenuFormData>()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [openRemoveDialog, setOpenRemoveDialog] = useState<boolean>(false)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItemClick = (type: MenuItemType) => {
    handleClose()
    switch (type) {
      case MenuItemType.REMOVE:
        setOpenRemoveDialog(true)
        break
    }
  }
  
  return (
    <>
      <RemoveMenuItemDialog
        open={openRemoveDialog}
        onCloseButtonClick={() => {setOpenRemoveDialog(false)}}
        additionalOnFinish={() => {
          const catIdx = values.items.findIndex(e => e.cardId === props.categoryCardId)
          const prodIdx = values.items[catIdx].products.findIndex(e => e.cardId === props.productCardId)
          const addIdx = values.items[catIdx].products[prodIdx].additions!.findIndex(e => e.cardId === props.addition.cardId)
          const newItems = [...values.items]
          newItems[catIdx].products[prodIdx].additions = removeArrayElement(newItems[catIdx].products[prodIdx].additions!, addIdx)
          setFieldValue('items', newItems)
        }}
      />
      <Grid
        container
        position={'relative'}
        flexDirection={'column'}
        minHeight={'3.6875rem'}
        py={'0.5rem'}
        px={'1rem'}
        marginTop={'0.938rem'}
        borderRadius={'0.938rem'}
        boxShadow={'0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.5)'}
        justifyContent={'center'}
        gap={'0.5rem'}
        sx={{ 
          backgroundColor: '#FFFFFF'
        }}
      >
        <Grid
          container
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Grid
            item
            container
            gap={'0.5rem'}
            alignItems={'center'}
          >
            {
              props.provided
                ? <Box
                  {...props.provided?.dragHandleProps}>
                  <DragHandleIcon /> 
                </Box>
                : null
            }
            <Grid
              item
              mr={'0.5rem'}
            >
              <Typography 
                variant='h2'
                sx={{
                  fontSize: '1.125rem',
                  opacity: props.addition.active ? 1 : 0.5
                }}
              >
                {props.addition.name}
              </Typography>
            </Grid>
            <Grid
              item
              container
              gap={'0.5rem'}
              sx={{
                width: 'max-content',
                opacity: props.addition.active ? 1 : 0.5
              }}
            >
              <Tag
                text={`+${mapSecondsToString(props.addition.customPreparationTime ?? props.addition.preparationTime)}`}
                opacity={(props.addition.customPreparationTime ?? props.addition.preparationTime) === 0}
              >
                <TimerIcon/>
              </Tag>
              <Tag
                text={texts('common:currency', { localValue: currency((props.addition.customPrice ?? props.addition.price), { fromCents: true }).value })}
                opacity={(props.addition.customPrice ?? props.addition.price) === 0}
              >
                <LocalOfferIcon/>
              </Tag>
              {
                !props.addition.active
                ? <Chip
                    text={
                      props.addition.inactiveUntil 
                      ? evalStringTemplate(texts('common:inactive_until'), { date: moment(props.addition.inactiveUntil).format('DD.MM.YYYY, HH:mm') })
                      : texts('common:inactive')
                    }
                    opacity={0.3}
                  />
                : null
              }
            </Grid>
          </Grid>
          <Grid item>
            <IconButton
              onClick={handleClick}
              sx={{
                color: '#000',
                position: 'absolute',
                right: 0,
                ...(
                  props.addition.internalNote
                  ? {
                      top: 0
                    }
                  : {
                      top: '50%',
                      transform: 'translateY(-50%)',
                    } 
                )
              }}
            >
              <MoreHorizIcon/>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              PaperProps={{
                style: {
                  minWidth: '12rem',
                },
              }}
            >
              {
                cardOptions.map((option) => (
                  <MenuItem 
                    key={option.value}                 
                    onClick={() => {
                      handleMenuItemClick(option.value)
                    }}
                  >
                    {option.label}
                  </MenuItem>
                ))
              }
            </Menu>
          </Grid>
        </Grid>
        {
          props.addition.internalNote
          ? <Grid 
              item
              sx={{
                marginBottom: '0.375rem'
              }}
            >
              <Typography 
                variant='body2'
                sx={{
                  textTransform: 'none',
                  fontStyle: 'italic'
                }}
              >
                {`${texts('additions:note')}: ${props.addition.internalNote}`}
              </Typography>
            </Grid>
          : null
        }
      </Grid>
    </>
  )
}

export default AdditionCard
