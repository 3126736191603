import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import AuthApi from '../../api/auth/AuthApi'
import { EmailDto } from '../../api/auth/types'
import { showErrorToast } from '../../utils/error'

const authApi = AuthApi.getInstance()

export default function useResendActivationEmail() {
  const texts = useTranslation().t
  const navigate = useNavigate()
  const mutation = useMutation((data: EmailDto) => authApi.resendActivationEmail(data), {
    onSuccess: () => {
      toast.success(texts('successes:panel_email_mail_sent'))
      navigate('/login', { replace: true })
    },
    onError: (error) => {
      showErrorToast(error, texts)
    },
  })
  return mutation
}