import { QueryParamConfig } from "use-query-params"

export type Column = {
  id: ColumnId
  label: string
  sortable?: boolean
  width?: string
  minWidth?: string
  maxWidth?: string
}

export type ColumnId = 'name' | 'role' | 'email' | 'places' | 'joinDate' | 'lastLogin' 

export type AccountListRowDto = {
  uuid: string
  name: string
  role: Role
  allowedPlaces: AccountAllowedPlace[]
  email?: string
  createdAt: string
  active: boolean
  emailVerified?: string
  lastLoginAt?: string
}

export type AccountListDto = {
  count: number
  data: AccountListRowDto[]
}

export type AccountListData = {
  count: number
  data: AccountDataRow[]
}

export type AccountDataRow = {
  uuid: string
  name: string
  role: Role
  places: AccountAllowedPlace[]
  email?: string
  joinDate: Date
  active: boolean
  lastLogin: Date | null
}

export type AccountAllowedPlace = {
  uuid: string
  name: string
}

export enum Role {
  SYSTEM_ADMIN,
  PLACE_ADMIN,
  EMPLOYEE
}

export type AccountDetailsFormData = {
  uuid: string
  role: Role
  name: string
  email: string
  places: AccountAllowedPlace[]
}

export type CreateAccessFormData = {
  role: Role
  name: string
  email: string
  places: AccountAllowedPlace[]
}

export type NewPinDto = {
  pin: string
}

export type EmployeeDetailsDto = {
  uuid: string
  name: string
  createdAt: string
  lastLoginAt: string | null
  allowedPlaces: AccountAllowedPlace[]
  active: boolean
}

export type EditEmployeeBodyDto = {
  name: string
}

export type CmsUserDetailsDto = {
  uuid: string
  name: string
  email: string
  role: Role
  createdAt: string
  lastLoginAt: string | null
  allowedPlaces: AccountAllowedPlace[]
  active: boolean
  emailVerified: boolean
}

export type EditCmsUserBodyDto = {
  name: string
  // email: string
  allowedPlaceUuids: string[]
}

export type CreateEmployeeBodyDto = {
  name: string
  placeUuid: string
}

export type CreateCmsUserBodyDto = {
  name: string
  email: string
  allowedPlaceUuids: string[]
  role: Role
}

export type AccountListSearchParams = {
  search: QueryParamConfig<string | null | undefined, string>
  page: QueryParamConfig<number | null | undefined, number>
  size: QueryParamConfig<number | null | undefined, number>
  order: QueryParamConfig<string | null | undefined, string>
}