import { Box, Grid, IconButton, Typography } from '@mui/material'
import { useFormikContext } from 'formik'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaTrash } from 'react-icons/fa'
import { HiPlusCircle } from 'react-icons/hi'
import { MdSwapVert } from 'react-icons/md'
import { UploadedPhotoResponse } from '../../../types/common'
import { PlaceFormData } from '../../../types/place'
import ImageUploadDialog from '../../ImageUploadDialog/ImageUploadDialog'

const AddImage = () => {
  const texts = useTranslation().t

  const [uploadDialogOpen, setUploadDialogOpen] = useState<boolean>(false)
  const { setFieldValue, errors, touched, values } = useFormikContext<PlaceFormData>()

  const onDelete = () => {
    setFieldValue('photoUuid', null)
    setFieldValue('photoUrl', null)
  }

  const onUploadSuccess = (data: UploadedPhotoResponse) => {
    setFieldValue('photoUuid', data.uuid)
    setFieldValue('photoUrl', data.url)
  }

  return (
    <>
      <ImageUploadDialog
        aspectRatio={4/3}
        thumbnailWidth={200}
        minDimensions={{
          width: 200,
          height: 150
        }}
        croppedDimensions={{
          width: 800,
          height: 600
        }}
        open={uploadDialogOpen}
        onClose={() => { setUploadDialogOpen(false) }}
        onSuccess={onUploadSuccess}
      />
      <Box marginRight={'1.875rem'}>
        <Typography variant='subtitle2' sx={{marginBottom: '0.313rem'}}>{texts('place:image_label')}</Typography>
        {
          values.photoUuid && values.photoUrl
          ? <Grid item container 
              justifyContent={'flex-end'}
              width={'20rem'} 
              height={'15rem'} 
              sx={{
                position: 'relative',
                float: 'right',
                backgroundColor: '#efefef'
              }}
            >
              <Grid 
                item 
                display={'flex'}
                justifyContent={'center'}
                overflow={'hidden'}
                sx={{
                  display: 'block',
                  width: '100%',
                  height: 'auto',
                  position: 'relative',
                  overflow: 'hidden',
                  padding: '75% 0 0 0',
                }}
              >
                <Box
                  component={'img'}
                  src={values.photoUrl}
                  alt={'img'}
                  style={{
                    display: 'block',
                    minWidth: '100%',
                    maxWidth: '100%',
                    maxHeight: '100%',
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                  }}
                />
              </Grid>
              <Grid
                item container
                justifyContent={'center'}
                alignItems={'center'}
                position={'absolute'}
                sx={{
                  width: '2.5rem',
                  height: '2.5rem',
                  backgroundColor: 'white',
                  borderRadius: '0.5rem',
                  margin: '0.813rem'
                }}
              >
                <IconButton 
                  onClick={onDelete} 
                  color={'error'}
                >
                  <FaTrash size={20}/>
                </IconButton>
              </Grid>
              <Grid
                item container
                justifyContent={'center'}
                alignItems={'center'}
                position={'absolute'}
                sx={{
                  width: '2.5rem',
                  height: '2.5rem',
                  backgroundColor: 'white',
                  borderRadius: '0.5rem',
                  margin: '0.813rem',
                  right: '3.125rem',
                  cursor: 'pointer'
                }}
                onClick={() => setUploadDialogOpen(true)}
              >
                <MdSwapVert size={25}/>
              </Grid>
            </Grid>
          : <Box>
              <Grid item container 
                justifyContent={'center'} 
                alignItems={'center'} 
                width={'20rem'} 
                height={'15rem'} 
                sx={{
                  backgroundColor: '#efefef',
                  cursor: 'pointer'
                }}
                onClick={() => setUploadDialogOpen(true)}
              >
                <Grid item alignItems={'center'}>
                  <HiPlusCircle size={25}/>
                </Grid>
                <Grid item marginLeft={'0.625rem'} marginBottom={'0.313rem'}>
                  <Typography variant='subtitle2'>{texts('place:image_placeholder')}</Typography>
                </Grid>
              </Grid>
            </Box>
        }
        {
          errors.photoUuid && touched.photoUuid
          ? <Typography 
              variant='body2'
              sx={{
                fontFamily: 'RobotoCondensed',
                color: '#d32f2f',
                marginTop: '0.188rem',
                textTransform: 'none'
              }}
            >
              {errors.photoUuid}
            </Typography>
          : null
        }
      </Box>
    </>
  )
}

export default AddImage