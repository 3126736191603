import { Moment } from "moment";
import moment from 'moment'


export function convertMomentTimezone(dateStr: string, originalTz: string, targetTz: string): Moment {
  return moment.tz(dateStr, originalTz).tz(targetTz)
}

export function convertMomentTimezoneCustomFormat(dateStr: string, format: string, originalTz: string, targetTz: string): Moment {
  return moment.tz(dateStr, format, originalTz).tz(targetTz)
}