import { Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { AiFillInfoCircle } from 'react-icons/ai'
import Checkbox from '../../CustomMui/Fields/Checkbox'
import ExtraTimeBufferForRushHoursForm from './ExtraTimeBufferForRushHoursForm'
import { useFormikContext } from 'formik'
import { PickupTypes } from '../../../types/place'
import NumericTextField from '../../CustomMui/Fields/NumericTextField'
import { getUserRoles } from '../../../services/storage/storageService'
import { arrayIntersection } from '../../../utils/array'
import { Role } from '../../../types/accounts'

const PlaceSettings = () => {
  const texts = useTranslation().t
  const userRoles = getUserRoles()

  const { values, errors, touched, setFieldValue } = useFormikContext<{pickupTypes: PickupTypes, extraTimeBufferForItemsInCartAmount: number}>()

  return (
    <Grid 
      container 
      direction={'column'}
    >
      <Typography variant='subtitle1'>{texts('place:place_settings_title')}</Typography>
      <Typography variant='subtitle2'>{texts('place:pickup_option')}</Typography>
      <Grid 
        item 
        container 
        wrap={'nowrap'}
      >
        <Checkbox
          name={'pickupTypes.takeAway'}
          label={texts('place:takeaway')}
        />
        <Checkbox
          name={'pickupTypes.onSite'}
          label={texts('place:on_site')}
        />
        <Checkbox
          name={'pickupTypes.delivery'}
          label={texts('place:delivery')}
        />
      </Grid>
      {
        errors.pickupTypes && touched.pickupTypes
        ? <Typography 
            variant='body2'
            sx={{
              fontFamily: 'RobotoCondensed',
              color: '#d32f2f',
              textTransform: 'none'
            }}
          >
            {texts('validations:at_least_one_required')}
          </Typography>
        : null
      }
      <Typography 
        variant='subtitle2' 
        sx={{
          marginTop: '1rem', marginBottom: '0.5rem'
        }}
      >
        {texts('place:order_time_parameters')}
      </Typography>
      <Grid 
        item
        container
        direction={'column'}
        gap={'0.25rem'}
        wrap='nowrap'
      >
        <Typography 
          variant='body1'
          sx={{
            marginBottom: '0.5rem',
            fontFamily: 'RobotoCondensedBold'
          }}
        >
          {texts('place:cart_length_time_extension')}
        </Typography>
        <Grid 
          item
          container
          alignItems={'center'}
          gap={'0.25rem'}
          wrap='nowrap'
          sx={{width: '10rem'}}
        >
          <NumericTextField
            name='extraTimeBufferForItemsInCartAmount'
            label={texts('place:time_extension_label')}
            placeholder={texts('common:percentage_hint')}
            shrinkLabel
            disabled={arrayIntersection([Role.SYSTEM_ADMIN], userRoles).length === 0}
            value={values.extraTimeBufferForItemsInCartAmount}
            noDefaultHelperText
            onChange={(e) => {
              const percentValue = e.target.value === '' ? null : Number.parseFloat(e.target.value)
              setFieldValue(
                'extraTimeBufferForItemsInCartAmount',
                percentValue
              )
            }}
          />
          <Typography variant='body2'>%</Typography>
        </Grid>
      </Grid>
      <Grid 
        item
        container
        gap={'0.625rem'}
        mt={'0.5rem'}
        wrap={'nowrap'}
      >
        <Grid item flexShrink={0}>
          <AiFillInfoCircle 
            size={20} 
            color={'#fa6400'}
          />
        </Grid>
        <Typography variant='body1'>{texts('place:time_extension_warning')}</Typography>
      </Grid>
      <Grid
        item 
        mt={'1.5rem'}
      >
        <ExtraTimeBufferForRushHoursForm
          name={'extraTimeBufferForRushHours'}
          disabledEdit={arrayIntersection([Role.SYSTEM_ADMIN], userRoles).length === 0}
        />
      </Grid>
    </Grid>
  )
}

export default PlaceSettings