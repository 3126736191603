import currency from 'currency.js'
import moment from 'moment-timezone'
import { ReportFormData } from '../../types/help'
import {
  AdditionFormData,
  BulkProductTemplateEntry,
  ProductFormData,
  TagFormData,
  VariantFormData,
  VariantOptionFormData,
  VariantTemplateOptionDto,
} from '../../types/menu'
import {
  AllowedPickupTypes,
  ExtraTimeBufferForRushHours,
  OpeningHourException,
  OpeningHours,
  PickupTypes,
  PlaceFormData,
} from '../../types/place'
import {
  BulkProductTemplateEntryDto, ExtraTimeBufferForRushHoursDto,
  MutateAdditionTemplateDto, MutatePlaceDto, MutateProductTemplateDto, MutateSendReportDto, MutateTagDto, MutateVariantTemplateDto,
  OpeningHoursDto,
  TimeBufferDto
} from './types'

export function mapPlaceDataToDto(data: PlaceFormData): MutatePlaceDto {
  return {
    name: data.name.trim(),
    street: data.street,
    postalCode: data.postalCode,
    town: data.town,
    coordinates: {
      type: 'Point',
      coordinates: [data.coordinates.lng, data.coordinates.lat]
    },
    phone: data.phone ? data.phone.trim() : null,
    email: data.email ? data.email.trim() : null,
    settings: {
      openingHours: mapOpeningHours(data.timezone, data.openingHours),
      openingHoursException: data.openingHoursExceptions.map((val: OpeningHourException) => {
        return {
          open: val.open,
          from: moment(`${moment(val.date).format('YYYY-MM-DD')} ${val.open ? val.start : '00:00:00'}`, 'YYYY-MM-DD HH:mm:ss').toISOString(),
          to: moment(`${moment(val.date).format('YYYY-MM-DD')} ${val.open ? val.end : '23:59:59'}`, 'YYYY-MM-DD HH:mm:ss').toISOString()
        }
      }),
      extraTimeBufferForRushHours: mapDayTimeBuffer(data.timezone, data.extraTimeBufferForRushHours),
      extraTimeBufferForItemsInCartAmount: {
        amount: 5,
        value: (data.extraTimeBufferForItemsInCartAmount ?? 0) / 100
      }
    },
    payuCredentials: {
      posId: data.payuCredentials.posId.trim(),
      clientId: data.payuCredentials.clientId.trim(),
      secondKey: data.payuCredentials.secondKey.trim(),
      clientSecret: data.payuCredentials.clientSecret.trim()
    },
    pickupMethods: mapPickupMethods(data.pickupTypes),
    photoUuid: data.photoUuid
  }
}

function mapPickupMethods(values: PickupTypes): number[] {
  const array: number[] = []
  if (values.onSite) {
    array.push(AllowedPickupTypes.ON_SITE)
  }
  if (values.takeAway) {
    array.push(AllowedPickupTypes.TAKEAWAY)
  }
  if (values.delivery) {
    array.push(AllowedPickupTypes.DELIVERY)
  }
  return array
}

function mapDayTimeBuffer(tz: string, dayTimeBuffers: ExtraTimeBufferForRushHours[]): ExtraTimeBufferForRushHoursDto {
  return {
    tz: tz,
    monday: dayTimeBuffers.filter(v => v.day === 0).map(mapTimeBuffer),
    tuesday: dayTimeBuffers.filter(v => v.day === 1).map(mapTimeBuffer),
    wednesday: dayTimeBuffers.filter(v => v.day === 2).map(mapTimeBuffer),
    thursday: dayTimeBuffers.filter(v => v.day === 3).map(mapTimeBuffer),
    friday: dayTimeBuffers.filter(v => v.day === 4).map(mapTimeBuffer),
    saturday: dayTimeBuffers.filter(v => v.day === 5).map(mapTimeBuffer),
    sunday: dayTimeBuffers.filter(v => v.day === 6).map(mapTimeBuffer),
  }
}

function mapTimeBuffer(timeBuffer: ExtraTimeBufferForRushHours) : TimeBufferDto {
  return {
    start: timeBuffer.start,
    end: timeBuffer.end,
    value: (timeBuffer.value ?? 0) / 100
  }
}

function mapOpeningHours(tz: string, openingHours: OpeningHours[]): OpeningHoursDto {
  return {
    tz: tz,
    monday: {
      open: openingHours[0].open,
      start: openingHours[0].open ? openingHours[0].start : null,
      end: openingHours[0].open ? openingHours[0].end : null
    },
    tuesday: {
      open: openingHours[1].open,
      start: openingHours[1].open ? openingHours[1].start : null,
      end: openingHours[1].open ? openingHours[1].end : null
    },
    wednesday: {
      open: openingHours[2].open,
      start: openingHours[2].open ? openingHours[2].start : null,
      end: openingHours[2].open ? openingHours[2].end : null
    },
    thursday: {
      open: openingHours[3].open,
      start: openingHours[3].open ? openingHours[3].start : null,
      end: openingHours[3].open ? openingHours[3].end : null
    },
    friday: {
      open: openingHours[4].open,
      start: openingHours[4].open ? openingHours[4].start : null,
      end: openingHours[4].open ? openingHours[4].end : null
    },
    saturday: {
      open: openingHours[5].open,
      start: openingHours[5].open ? openingHours[5].start : null,
      end: openingHours[5].open ? openingHours[5].end : null
    },
    sunday: {
      open: openingHours[6].open,
      start: openingHours[6].open ? openingHours[6].start : null,
      end: openingHours[6].open ? openingHours[6].end : null
    },
  }
}

export function mapAdditionTemplateData(data: AdditionFormData): MutateAdditionTemplateDto {
  return {
    name: data.name.trim(),
    internalNote: data.note,
    price: currency(data.additionalPrice ?? 0).intValue,
    preparationTime: (data.additionalTime ?? 0) * 60
  }
}

export function mapVariantTemplateData(data: VariantFormData): MutateVariantTemplateDto {
  return {
    name: data.name.trim(),
    internalNote: data.note,
    options: data.options.map(mapVariantTemplateOptionData)
  }
}

function mapVariantTemplateOptionData(data: VariantOptionFormData): VariantTemplateOptionDto {
  return {
    uuid: data.uuid,
    name: data.name.trim(),
    default: data.defaultOption,
    price: currency(data.additionalPrice ?? 0).intValue,
    preparationTime: (data.additionalTime ?? 0) * 60
  }
}

export function mapProductTemplateData(data: ProductFormData): MutateProductTemplateDto {
  return {
    name: data.name.trim(),
    description: data.description,
    internalNote: data.note,
    price: currency(data.basePrice ?? 0).intValue,
    preparationTime: (data.baseTime ?? 0) * 60,
    tagUuids: data.tags.map(e => e.uuid),
    photoUuid: data.photoUuid
  }
}

export function mapSendReportDataToDto(data: ReportFormData): MutateSendReportDto {
  return {
    question: data.question,
    description: data.description
  }
}

export function mapBulkProductTemplateEntriesToDto(entries: BulkProductTemplateEntry[]): BulkProductTemplateEntryDto[] {
  return entries.map(mapBulkProductTemplateEntryToDto)
}

export function mapBulkProductTemplateEntryToDto(entry: BulkProductTemplateEntry): BulkProductTemplateEntryDto {
  return {
    name: entry.name,
    description: entry.description,
    price: entry.price,
    preparationTime: entry.preparationTime,
    internalNote: entry.internalNote
  }
}

export function mapTagFormDataToDto(tag: TagFormData): MutateTagDto {
  return {
    name: tag.name.trim()
  }
}