import { useTranslation } from 'react-i18next'
import Dialog from '../../../CustomMui/Dialog'
import useActivateProducts from '../../../../hooks/place/useActivateProducts'
import useActivateVariantOptions from '../../../../hooks/place/useActivateVariantOptions'
import useActivateAdditions from '../../../../hooks/place/useActivateAdditions'
import { AvailabilityItemType } from '../../../../types/menu'

type Props = {
  open: boolean
  itemType: AvailabilityItemType
  placeUuid: string
  selectedItems: string[]
  onClose: () => void
  onSucces: () => void
}

const ActivateItemsDialog = (props: Props) => {
  const texts = useTranslation().t

  const activateProductsMutation = useActivateProducts(() => {
    props.onSucces()
  })
  const activateVariantOptionsMutation = useActivateVariantOptions(() => {
    props.onSucces()
  })
  const activateAdditionsMutation = useActivateAdditions(() => {
    props.onSucces()
  })

  return (
    <Dialog
      open={props.open}
      title={texts('dialogs:activate_items_title')}
      description={texts('dialogs:activate_items_description')}
      closeButtonLabel={texts('common:cancel')}
      confirmButtonLabel={texts('common:activate')}
      onCloseButtonClick={props.onClose}
      loading={
        activateProductsMutation.isLoading 
        || activateVariantOptionsMutation.isLoading 
        || activateAdditionsMutation.isLoading
      }
      onConfirmButtonClick={() => {
        switch (props.itemType) {
          case AvailabilityItemType.ADDITIONS:
            activateAdditionsMutation.mutate({
              placeUuid: props.placeUuid,
              templateUuids: props.selectedItems
            })
            break
          case AvailabilityItemType.PRODUCTS:
            activateProductsMutation.mutate({
              placeUuid: props.placeUuid,
              templateUuids: props.selectedItems
            })
            break
          case AvailabilityItemType.VARIANTS:
            activateVariantOptionsMutation.mutate({
              placeUuid: props.placeUuid,
              templateUuids: props.selectedItems
            })
            break
        }
        props.onSucces()
        props.onClose()
      }}
    />
  )
}

export default ActivateItemsDialog
