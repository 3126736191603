import { AiOutlineCloseCircle } from 'react-icons/ai'
import { Button, Dialog as MuiDialog, DialogActions, DialogContent, DialogContentText, DialogTitle,  IconButton } from '@mui/material'
import LoadingButton from './LoadingButton'

type Props = {
  open: boolean
  title: string
  description: string
  warning?: string
  closeButtonLabel: string
  confirmButtonLabel: string
  loading?: boolean
  onCloseButtonClick?: () => void
  onConfirmButtonClick?: () => void
}

const Dialog = (props: Props) => {
   return (
    <MuiDialog open={props.open} onClose={props.onCloseButtonClick}>
      <DialogActions>
        <IconButton onClick={props.onCloseButtonClick} color={'primary'}>
          <AiOutlineCloseCircle size={30}/>
        </IconButton>
      </DialogActions>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.description}</DialogContentText>
      </DialogContent>
      {
        props.warning
        ? <DialogContent
            sx={{paddingTop: 0}}
          >
            <DialogContentText
              sx={{
                color: '#d32f2f',
                fontFamily: 'RobotoCondensedBold'
              }}
            >
              {props.warning}
            </DialogContentText>
          </DialogContent>
        : null
      }
      <DialogActions>
        <Button
          variant={'outlined'}
          size={'medium'}
          onClick={props.onCloseButtonClick}
        >
          {props.closeButtonLabel}
        </Button>
        <LoadingButton
          variant={'contained'}
          size={'medium'}
          loading={props.loading}
          onClick={props.onConfirmButtonClick}
        >
          {props.confirmButtonLabel}
        </LoadingButton>
      </DialogActions>
    </MuiDialog>
  )
}

export default Dialog
