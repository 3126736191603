import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import DataApi from '../../api/data/DataApi'
import { OrderListOptions } from '../../api/data/types'
import { queryNames } from '../queries'
import { showErrorToast } from '../../utils/error'
import { mapOrderListDto } from '../../utils/mapping'
import { OrderList } from '../../types/order'

const dataApi = DataApi.getInstance()

type Options = {
  enabled?: boolean
  onSuccess: (data: OrderList) => void
}

export default function useGetPlaceOrderList(uuid: string, options: OrderListOptions & Options) {
  const texts = useTranslation().t
  const key = [queryNames.placeOrderList, uuid, { 
      limit: options?.limit,
      offset: options?.offset,
      from: options?.from,
      to: options?.to,
      sort: options?.sort
    }
  ]
  const query = useQuery(key, async () => {
    const res = await dataApi.getPlaceOrders(uuid!, options)
    return mapOrderListDto(res)
  }, {
    enabled: options.enabled,
    staleTime: Infinity,
    refetchOnMount: 'always',
    onSuccess: options.onSuccess,
    onError: (error) => {
      showErrorToast(error, texts)
    }
  })
  return {
    ...query,
    isEnabled: uuid != null && uuid !== ''
  }
}

