/* eslint-disable no-fallthrough */
import { ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LicenseInfo } from '@mui/x-license-pro'
import { pl } from 'date-fns/locale'
import { useEffect, useRef, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Navigate, Outlet, Route, Routes, useNavigate } from 'react-router-dom'
import AuthApi from './api/auth/AuthApi'
import './App.css'
import AppBar from './components/CustomMui/AppBar'
import Dialog from './components/CustomMui/Dialog'
import MainTheme from './components/themes/MainTheme'
import useLogOut from './hooks/auth/useLogOut'
import AccountActivationPage from './pages/Auth/AccountActivationPage'
import LogInPage from './pages/Auth/LogInPage'
import RequestPasswordResetPage from './pages/Auth/RequestPasswordResetPage'
import ResendActivationEmailPage from './pages/Auth/ResendActivationEmailPage'
import ResetPasswordPage from './pages/Auth/ResetPasswordPage'
import VerifyEmailChangePage from './pages/Auth/VerifyEmailChangePage'
import Dashboard from './pages/Dashboard'
import { clearRedirect } from './redux/redirectStorageToolkit'
import redirectStore from './redux/redirectStore'
import { forceLogOut, LoginState } from './redux/storageToolkit'
import store from './redux/store'
import { getUserUuid, setUserData, getUserName } from './services/storage/storageService'

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY ?? '')

export const ROUTER_BASE_NAME = '/'

const authApi = AuthApi.getInstance()

function App() {
  const navigate = useNavigate()
  const texts = useTranslation().t

  const logout = useLogOut()
  const loginStateRef = useRef<LoginState | null>(null)

  const [openLogoutDialog, setOpenLogoutDialog] = useState<boolean>(false)
  const [userName, setUserName] = useState<string | null>(getUserName())

  useEffect(() => {
    console.log(`Cofity CMS v. ${process.env.REACT_APP_VERSION}`)
    const unsubscribe = store.subscribe(() => {
      const previousValue = loginStateRef.current
      loginStateRef.current = store.getState().storage.loginState
      setUserName(store.getState().storage.userName)
      if (previousValue === loginStateRef.current) { //stan dla nas sie nie zmienil
        return
      }

      switch (store.getState().storage.loginState) {
        case LoginState.LOGGED:  
          authApi.checkSession().then(res => {
            setUserData(res)
          })
          break
        case LoginState.FORCED_LOGGED_OUT:
          toast.success(texts('successes:automatically_logged_out'))
        case LoginState.LOGGED_OUT:
          navigate('/login')
          break
      }
    })
    const redirectUnsubscribe = redirectStore.subscribe(() => {
      if (redirectStore.getState().storage.redirectTo) {
        navigate(redirectStore.getState().storage.redirectTo!)
        redirectStore.dispatch(clearRedirect())
      }
    })

    const handleCrossTabLogout = () => {
      if (store.getState().storage.loginState === LoginState.LOGGED && !getUserUuid()) {
        store.dispatch(forceLogOut())
      }
    }
    window.addEventListener('storage', handleCrossTabLogout) 

    return () => {
      window.removeEventListener('storage', handleCrossTabLogout)
      redirectUnsubscribe()
      unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loggedRoutes = () => {
    return (
      <>
        <Route path='*' element={<Dashboard/>}/>
        
        <Route path='/login' element={<Navigate to={ROUTER_BASE_NAME}/>}/>
        <Route path='/password/reset' element={<Outlet/>}>
          <Route index element={<Navigate to={ROUTER_BASE_NAME}/>}/>
          <Route path='request' element={<Navigate to={ROUTER_BASE_NAME}/>}/>
          <Route path='finish' element={<Navigate to={ROUTER_BASE_NAME}/>}/>
        </Route>
        <Route path='/account' element={<Outlet/>}>
          <Route index element={<Navigate to={ROUTER_BASE_NAME}/>}/>
          <Route path='activate' element={<Navigate to={ROUTER_BASE_NAME}/>}/>
          <Route path='activation/resend' element={<Navigate to={ROUTER_BASE_NAME}/>}/>
          <Route path='email/verify' element={<Navigate to={ROUTER_BASE_NAME}/>}/>
        </Route>
      </>
    )
  }
  
  const unLoggedRoutes = () => {
    return (
      <>
        <Route path={'/' } element={<Outlet/>}>
          <Route index element={<LogInPage/>}/>
          <Route path='*' element={<LogInPage/>}/>
          <Route path='password/reset' element={<Outlet/>}>
            <Route index element={<RequestPasswordResetPage/>}/>
            <Route path='request' element={<RequestPasswordResetPage/>}/>
            <Route path='finish' element={<ResetPasswordPage/>}/>
          </Route>
          <Route path='account' element={<Outlet/>}>
            <Route index element={<AccountActivationPage/>}/>
            <Route path='activate' element={<AccountActivationPage/>}/>
            <Route path='activation/resend' element={<ResendActivationEmailPage/>}/>
            <Route path='email/verify' element={<VerifyEmailChangePage/>}/>
          </Route>
        </Route>
      </>
    )
  }

  return (
    <>
      <ThemeProvider theme={MainTheme}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pl}>
          <Toaster
            position='top-right'
            containerStyle={{
              fontFamily:'RobotoCondensed',
              top:'5rem'
            }}
            toastOptions={{
              success: {duration: 4000},
              error: {duration: 4000}
            }}
          />
          <Dialog
            open={openLogoutDialog}
            title={texts('dashboard:logout_dialog_title')}
            description={texts('dashboard:logout_dialog_description')}
            closeButtonLabel={texts('common:cancel')}
            confirmButtonLabel={texts('dashboard:logout_button_label')}
            onCloseButtonClick={() => {
              setOpenLogoutDialog(false)
            }}
            onConfirmButtonClick={() => {
              setOpenLogoutDialog(false)
              logout.mutate()
            }}
          />
          <AppBar
            showContent={store.getState().storage.loginState === LoginState.LOGGED}
            userName={userName}
            onLogoutClick={() => {
              setOpenLogoutDialog(true)
            }}
          />
          <Routes>
            {
              store.getState().storage.loginState === LoginState.LOGGED
                ? loggedRoutes()
                : unLoggedRoutes()
            }
          </Routes>
        </LocalizationProvider> 
      </ThemeProvider>
    </>
  )
}

export default App
