export function getRandomInt(min: number, max: number): number {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min)) + min;
}

// fix error https://github.com/jaredpalmer/formik/issues/3104
export function getErrorForNestedObject(name: string, formErrors: any, fieldError?: string) {
  if (name.match(/\.|\[/)) {
    const outerFieldName = name.split(/\.|\[/)[0]
    const outerFieldError = formErrors[outerFieldName]
    // When no error messages for inner fields
    if (!Array.isArray(outerFieldError) || (fieldError?.length ?? 0) === 1) {
      return undefined
    }
  }

  return fieldError
}

// fix error https://github.com/jaredpalmer/formik/issues/3104
export function getErrorFoArray(name: string, formErrors: any, fieldError?: string) {
  if (name.match(/\.|\[/)) {
   //console.log(name, formErrors, fieldError)
    const outerFieldName = name.split(/\.|\[/)[0]
    const outerFieldError = formErrors[outerFieldName]
    // console.log(formErrors)
    // When no error messages for inner fields
    if (!Array.isArray(outerFieldError)) {
      return undefined
    }
  }

  return fieldError
}