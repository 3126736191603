import { useTranslation } from 'react-i18next'
import { Grid, SxProps, Typography } from '@mui/material'
import noDataImage from '../../assets/images/noDataImage.webp'
import Box from '@mui/material/Box'

type Props = {
  sx?: {
    container?: SxProps
    img?: SxProps
    label?: SxProps
  }
}

const NoDataPlaceholder = (props: Props) => {
  const texts = useTranslation().t
  return (
    <Grid
      container
      justifyContent={'center'}
      flexDirection={'column'}
      alignItems={'center'}
      gap={'1rem'}
      sx={props.sx?.container}
    >
      <Box
        component={'img'}
        alt='image'
        flexShrink={0}
        src={noDataImage}
        sx={props.sx?.img ?? {
          width: '17.1875rem',
          height: '15.625rem'
        }}
      />
      <Typography
        sx={props.sx?.label ?? {
          fontSize: '1rem'
        }}
      >
        {texts('common:no_data')}
      </Typography>
    </Grid>
  )
}

export default NoDataPlaceholder