import * as Yup from 'yup'
import { passwordRules, englishCharactersAllowedPassword } from './validationRegexps'

export function passwordValidation(t: (val: string) => string) {
  return Yup.object({
    password: Yup.string()
      .matches(englishCharactersAllowedPassword, t('validations:special_characters_password_validation'))
      .min(8, t('validations:min_8_signs_validation'))
      .matches(passwordRules, t('validations:password_rules_validation'))
      .max(32, t('validations:max_32_signs_validation'))
      .required(t('validations:field_required')),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], t('validations:different_passwords_validation'))
      .required(t('validations:field_required'))
  })
}

