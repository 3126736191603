import { useTranslation } from 'react-i18next'
import Dialog from '../../../CustomMui/Dialog'

type Props = {
  open: boolean
  onCloseButtonClick: () => void
  additionalOnFinish?: () => void
}

const RemoveMenuItemDialog = (props: Props) => {
  const texts = useTranslation().t

  return (
    <Dialog
      open={props.open}
      title={texts('dialogs:remove_title')}
      description={texts('dialogs:remove_description')}
      closeButtonLabel={texts('common:cancel')}
      confirmButtonLabel={texts('common:delete')}
      onCloseButtonClick={props.onCloseButtonClick}
      onConfirmButtonClick={() => {
        props.additionalOnFinish?.()
        props.onCloseButtonClick()
      }}
    />
  )
}

export default RemoveMenuItemDialog
