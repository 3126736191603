import { Button, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import LoadingButton from '../../CustomMui/LoadingButton'

type Props = {
  loading: boolean
  onBack: () => void
  onRemove: () => void
}

const EditAdditionHeaderBar = (props: Props) => {
  const texts = useTranslation().t
  
  return (
    <Grid
      item
      container 
      wrap='nowrap'
      justifyContent={'space-between'} 
      alignItems={'center'}
    >
      <Grid item> 
        <Typography variant='h2' noWrap>
          {texts('additions:edit_title')}
        </Typography> 
      </Grid>
      <Grid 
        item 
        container 
        justifyContent={'flex-end'}
        rowGap={'0.5rem'}
      >
        <Grid item>
          <Button
            variant={'outlined'}
            size={'medium'}
            onClick={props.onBack}
            sx={{
              width: 'fit-content',
              px: '1rem',
              whiteSpace: 'nowrap'
            }}
          >
            {texts('additions:go_to_list')}
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant={'contained'}
            size={'medium'}
            onClick={props.onRemove}
            sx={{
              border: 'none',
              color: '#fff',
              '&.MuiButton-root': 
                {
                  backgroundColor: '#d32f2f',
                ':hover': {backgroundColor: '#c62828'}
                },
              marginLeft: '0.625rem'
            }}
          >
            {texts('additions:remove')}
          </Button>
        </Grid>
        <Grid item>
          <LoadingButton
            type='submit'
            variant={'contained'}
            size={'medium'}
            sx={{marginLeft: '0.625rem'}}
            loading={props.loading}
          >
            {texts('additions:save')}
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default EditAdditionHeaderBar