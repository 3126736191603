import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import Loader from '../../components/Common/Loader'
import { useVerifyEmailChange } from '../../hooks/auth/useVerifyEmailChange'
import { useEffect } from 'react'

const VerifyEmailChangePage = () => {
  const texts = useTranslation().t
  const locations = useLocation()

  const query = new URLSearchParams(locations.search)
  const token = query.get('token')
  const mutation = useVerifyEmailChange()

  useEffect(() => {
    mutation.mutate({token})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid 
      container 
      direction={'column'} 
      justifyContent={'center'} 
      alignItems={'center'} 
      height={'calc(100vh - 130px)'}
    >
      <Grid item>
        <Loader 
          width={'100%'}
          text={texts('common:loading')}
        />
      </Grid>
    </Grid>
  )
}

export default VerifyEmailChangePage
