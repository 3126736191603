import { Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import Dialog from '../../CustomMui/Dialog'
import { useNavigate } from 'react-router-dom'
import { Variant } from '../../../types/menu'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import VariantListTileOptionRow from './VariantListTileOptionRow'
import Loader from '../../Common/Loader'
import useRemoveVariantTemplate from '../../../hooks/template/variant/useRemoveVariantTemplate'

type Props = {
  variant: Variant
}

const VariantListTile = (props: Props) => {
  const texts = useTranslation().t
  const navigate = useNavigate()

  const removeMutation = useRemoveVariantTemplate()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [openRemoveDialog, setOpenRemoveDialog] = useState<boolean>(false)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Grid
      container
      position={'relative'}
      flexDirection={'column'}
      minHeight={'3rem'}
      py={'0.5rem'}
      pr={'1rem'}
      pl={'1.5rem'}
      marginTop={'0.938rem'}
      borderRadius={'0.938rem'}
      boxShadow={'0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.5)'}
      gap={'0.938rem'}
    >
      <Dialog
        open={openRemoveDialog}
        title={texts('dialogs:remove_title')}
        description={texts('dialogs:remove_description')}
        warning={texts('dialogs:variant_remove_warning')}
        closeButtonLabel={texts('common:cancel')}
        confirmButtonLabel={texts('common:remove')}
        onCloseButtonClick={() => {setOpenRemoveDialog(false)}}
        onConfirmButtonClick={() => {
          setOpenRemoveDialog(false)
          removeMutation.mutate({ uuid: props.variant.uuid })
        }}
      />
      {
        removeMutation.isLoading
        ? <Grid
            item
            position={'absolute'}
            width={'100%'}
            height={'100%'}
            right={0}
            bottom={0}
            borderRadius={'0.938rem'}
            overflow={'hidden'}
          >
            <Loader
              backgroundColor='rgba(0,0,0,0.08)'
            />
          </Grid>
        : null
      }
      <Grid
        container
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Grid
          item
          sx={{
            ':hover': {cursor: 'pointer'}
          }}
          onClick={() => navigate(`/menu/variants/edit/${props.variant.uuid}`)}
          flexGrow={1}
        >
          <Typography 
            variant='h2'
            sx={{
              fontSize: '1.125rem',
              minWidth: '1rem',
              minHeight: '1.25rem'
            }}
          >
            {props.variant.name}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton
            onClick={handleClick}
            sx={{color: '#000'}}
          >
            <MoreHorizIcon/>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{
              style: {
                width: '12rem',
              },
            }}
          >
            <MenuItem 
              onClick={() => {
                handleClose()
                setOpenRemoveDialog(true)
              }}
            >
              {texts('common:remove')}
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
      <Grid
        item
        container
        gap={'0.5rem'}
        sx={{
          ':hover': {cursor: 'pointer'},
          ':last-child': {marginBottom: '0.5rem'}
        }}
        onClick={() => navigate(`/menu/variants/edit/${props.variant.uuid}`)}
      >
        {props.variant.options.map((option) => (
          <VariantListTileOptionRow
            variantOption={option}
            key={option.uuid}
          />
        ))}
      </Grid>
      {
        props.variant.note
        ? <Grid 
            item
            sx={{
              ':hover': {cursor: 'pointer'},
              marginBottom: '0.375rem'
            }}
            onClick={() => navigate(`/menu/variants/edit/${props.variant.uuid}`)}
          >
            <Typography 
              variant='body2'
              sx={{
                textTransform: 'none',
                fontStyle: 'italic'
              }}
            >
              {`${texts('variants:note')}: ${props.variant.note}`}
            </Typography>
          </Grid>
        : null
      }
    </Grid>
  )
}

export default VariantListTile
