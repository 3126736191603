import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Step, StepLabel, Stepper } from '@mui/material'
import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import FirstStep from './FirstStep'
import SecondStep from './SecondStep'
import ThirdStep from './ThirdStep'
import { Form, Formik, FormikProps } from 'formik'
import { createAccessSecondStepValidation } from '../../../validations/createAccessSecondStepValidation'
import { CreateAccessFormData, Role } from '../../../types/accounts'
import { createAccessFirstStepValidation } from '../../../validations/createAccessFirstStepValidation'
import useCreateEmployee from '../../../hooks/accounts/useCreateEmployee';
import useCreateCmsUser from '../../../hooks/accounts/useCreateCmsUser'

export type RoleObject = {
  label: string
  value: Role
}

type Props = {
  open: boolean
  onClose: () => void
  onSuccess: () => void
}

const initialFormValues = {
  role: Role.SYSTEM_ADMIN,
  name: '', 
  email: '', 
  places: []
}

const CreateAccessDialog = (props: Props) => {
  const texts = useTranslation().t

  const steps: string[] = texts('objects:create_access_steps', {returnObjects: true}) as string[]

  const [step, setStep] = useState<number>(0)
  const [newPin, setNewPin] = useState<string>('')

  const createEmployeeMutation = useCreateEmployee((pin) => { 
    setNewPin(pin)
    setStep(2)
    props.onSuccess()
  })
  const createCmsUserMutation = useCreateCmsUser(() => { 
    setStep(2) 
    props.onSuccess()
  })

  useEffect(() => {
    if (props.open) {
      ref.current?.resetForm()
      setStep(0)
    }
  }, [props.open])

  const ref = useRef<FormikProps<CreateAccessFormData>>(null)

  const handleSubmit = (data: CreateAccessFormData) => {
    if (data.role === Role.EMPLOYEE) {
      createEmployeeMutation.mutate({
        data: {
          name: data.name,
          placeUuid: data.places![0].uuid
        }
      })
    } else {
      createCmsUserMutation.mutate({
        data: {
          name: data.name,
          role: data.role,
          email: data.email!,
          allowedPlaceUuids: (data.places ?? []).map(e => e.uuid )
        }
      })
    }
  }

  return (
    <Dialog 
      open={props.open} 
      onClose={props.onClose}
      sx={{
        '.MuiPaper-root': {
          width: '44.875rem'
        }
      }}
    >
      <Formik<CreateAccessFormData>
        initialValues={initialFormValues}
        enableReinitialize
        validateOnBlur={true}
        validateOnChange={true}
        onSubmit={(data) => {
          if (step === 0) {
            ref.current?.resetForm({
              values: data
            })
            setStep(1)
          } else {
            handleSubmit(data)
          }
        }}
        validationSchema={
          step === 0 
            ? createAccessFirstStepValidation(texts) 
            : createAccessSecondStepValidation(texts)
        }
        innerRef={ref}
      >
        {(formikProps) => (
          <Form>
            <DialogActions>
              <IconButton 
                onClick={props.onClose} 
                color={'primary'}
              >
                <AiOutlineCloseCircle size={30}/>
              </IconButton>
            </DialogActions>
            <DialogTitle
              sx={{
                mb: '1.5rem',
                fontSize: '1.5rem',
                color: 'RGBA(0, 0, 0, 0.7)'
              }}
            >
              {texts('accounts:create_access')}
            </DialogTitle>
            <DialogContent sx={{paddingBottom: 0}}>
              <Stepper activeStep={step} alternativeLabel>
                {steps.map((label) => {
                  return (
                    <Step key={label}>
                      <StepLabel>
                        {label}
                      </StepLabel>
                    </Step>
                  )
                })}
              </Stepper>
              {
                step === 0
                  ? <FirstStep/>
                  : step === 1
                      ? <SecondStep
                          loading={createEmployeeMutation.isLoading || createCmsUserMutation.isLoading}
                        />
                      : <ThirdStep
                          pin={newPin}
                          onClose={props.onClose}
                        />
              }
            </DialogContent>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

export default CreateAccessDialog