import { AppBar as MuiAppBar, Divider, Grid } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiPlusCircle, HiUserCircle } from 'react-icons/hi'
import { IoMdPower } from 'react-icons/io'
import { RiArrowDownSFill } from 'react-icons/ri'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import logo from '../../assets/images/logo.webp'
import { getUserRoles } from '../../services/storage/storageService'
import { Menu as MenuType, MenuItem as MenuItemType } from '../../types/menu'
import { arrayIntersection } from '../../utils/array'
import { checkIfInMenuCategory } from '../../utils/menu'

type Props = {
  showContent: boolean
  userName: string | null
  onLogoutClick: () => void 
}

const AppBar = (props: Props) => {
  const texts = useTranslation().t
  const navigate = useNavigate()
  const location = useLocation()

  const userRoles = getUserRoles()

  const pages: MenuType[] = texts('objects:appbar_menu_items', { returnObjects: true }) as MenuType[]
  const accountItems: MenuItemType[] = texts('objects:appbar_account_items', { returnObjects: true }) as MenuItemType[]

  const [anchorElNav, setAnchorElNav] = useState<EventTarget & HTMLButtonElement | null>(null)
  const [anchorElUser, setAnchorElUser] = useState<EventTarget & HTMLButtonElement | null>(null)

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElNav(event.currentTarget)
  }
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  return (
    <MuiAppBar 
      position='static' 
      color='secondary'
    >
      <Toolbar disableGutters>
        <Grid 
          container 
          wrap='nowrap' 
          justifyContent={'space-between'} 
          alignItems={'center'}
          height={'4.375rem'}
        >
          <Grid item sx={{
            padding: '0.625rem 0 0.625rem 1.875rem',
            height: '100%'}}
          >
            <Link to={'/'}>
              <Box
                component={'img'}
                alt='logo'
                src={logo}
                sx={{
                  height: '100%'
                }}
              />
            </Link>
          </Grid>
          {
            props.showContent
            ? <>
                <Grid 
                  item 
                  flexGrow={1} 
                  container 
                  wrap='nowrap' 
                  justifyContent={'flex-end'}
                >
                  {
                    pages.filter(e => e.permittedRoles == null || arrayIntersection(userRoles, e.permittedRoles).length > 0).map((page) => (
                      <Box key={page.label}>
                        <Button
                          id={`menu-appbar-${page.label}`}
                          onClick={handleOpenNavMenu}
                          disableRipple
                          sx={{
                            // color: anchorElNav?.id === `menu-appbar-${page.label}` ? 'secondary.light' : 'primary',
                            color: checkIfInMenuCategory(page, location.pathname) ? 'secondary.light' : 'primary',
                            display: 'block',
                            border: 'none',
                            minWidth: '0',
                            width: 'fit-content',
                            px: '1.875rem',
                            '&:hover': {
                              backgroundColor: '#fff'
                            }
                          }}
                        >
                          {page.label}
                        </Button>
                        <Menu
                          sx={{mt: '2.813rem'}}
                          anchorEl={anchorElNav}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                          keepMounted
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                          open={anchorElNav?.id === `menu-appbar-${page.label}`}
                          onClose={handleCloseNavMenu}
                        >
                          {
                            page.items
                            .filter(e => e.permittedRoles == null || arrayIntersection(userRoles, e.permittedRoles).length > 0)
                            .map((val) => (
                              <Grid
                                key={val.label}
                              >
                                <MenuItem 
                                  onClick={() => {
                                    handleCloseNavMenu()
                                    navigate(val.path)
                                  }}
                                  sx={{minWidth: '11rem'}}
                                >
                                  <Grid
                                    item
                                    container
                                    flexDirection={'column'}
                                  >
                                    <Grid
                                      item
                                      container
                                      alignItems={'center'}
                                      gap={'0.375rem'}
                                    >
                                      {
                                        val.addIcon
                                        ? <HiPlusCircle size={20}/>
                                        : null
                                      }
                                      <Typography textAlign='center'>{val.label}</Typography>
                                    </Grid>
                                  </Grid>
                                </MenuItem>
                                {
                                  val.bottomDivider 
                                  ? <Grid 
                                      item
                                      mx={'1rem'}
                                    >
                                      <Divider/>
                                    </Grid>
                                  : null
                                }
                              </Grid>

                            ))
                          }
                        </Menu>
                      </Box>
                    ))
                  }
                </Grid>
                <Box height={'100%'} marginLeft={'1.875rem'}>
                  <Button
                    onClick={handleOpenUserMenu}
                    disableRipple 
                    sx={{
                      display: 'block',
                      height: '100%',
                      border: 'none',
                      minWidth: '0',
                      width: 'fit-content',
                      borderRadius: '0 !important'
                    }}
                  >
                    <Grid 
                      container
                      alignItems={'center'}
                      columnGap={'0.938rem'}
                      px={'1.563rem'}
                      wrap='nowrap'
                      sx={{
                        backgroundColor: '#000',
                        color: '#fff',
                        height: '100%',
                        borderRadius: '0 !important'
                      }}
                    >
                      <HiUserCircle
                        size={'24px'}
                      />
                      <Typography 
                        noWrap 
                        sx={{
                          fontFamily: 'RobotoCondensedBold',
                          textTransform: 'none',
                          fontSize: '1.25rem'
                        }}
                      >
                        {/* {getUserName()} */}
                        {props.userName}
                      </Typography>
                      <RiArrowDownSFill/>
                    </Grid>
                  </Button>
                  <Menu
                    sx={{ mt: '2.813rem' }}
                    id='menu-appbar-settings'
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    {
                      accountItems.map((value) => (
                        <MenuItem 
                          key={value.label}
                          onClick={() => {
                            navigate(value.path)
                            handleCloseUserMenu()
                          }}
                          sx={{minWidth: '14rem'}}
                        >
                          <Typography>
                            {value.label}
                          </Typography>
                        </MenuItem>
                      ))
                    }
                    <Divider sx={{mx: '0.625rem'}}/>
                    <MenuItem onClick={() => {
                      props.onLogoutClick()
                      handleCloseUserMenu()
                    }}>
                      <IoMdPower size={'15px'}/>
                      <Typography 
                        marginLeft={'0.313rem'}
                      >
                        {texts('common:logout')}
                      </Typography>
                    </MenuItem>
                    <Grid
                      container
                      flexDirection={'column'}
                      justifyContent={'center'}
                      alignItems={'center'}
                      sx={{
                        color: '#bfbfbf',
                        paddingBlock: '.25rem'
                      }}
                    >
                      <Typography>
                        {texts('common:version', { version: process.env.REACT_APP_VERSION })}
                      </Typography>
                      <Typography>
                        {texts('common:poweredBy')}
                      </Typography>
                    </Grid>
                  </Menu>
                </Box>
              </>
            : null
          }
        </Grid>
      </Toolbar>
    </MuiAppBar>
  )
}
export default AppBar
