import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import DataApi from '../../api/data/DataApi'
import { Page, Codes, DataSubcodes } from '../../services/errors/consts'
import { MenuCategory } from '../../types/menu'
import { showErrorToast } from '../../utils/error'
import { mapMenuFormDataToDto } from '../../utils/mapping'
import { queryNames } from '../queries'
import { AxiosError } from 'axios'
import { HttpError } from '../../types/common'

const dataApi = DataApi.getInstance()

type MutationParams = {
  uuid: string
  data: MenuCategory[]
}

const errorRefreshSubcodes = [
  DataSubcodes.CATEGORY_NOT_FOUND,
  DataSubcodes.PRODUCT_NOT_FOUND,
  DataSubcodes.PRODUCT_TEMPLATE_NOT_FOUND,
  DataSubcodes.ADDITION_NOT_FOUND,
  DataSubcodes.ADDITION_TEMPLATE_NOT_FOUND,
  DataSubcodes.VARIANT_NOT_FOUND,
  DataSubcodes.VARIANT_TEMPLATE_NOT_FOUND,
  DataSubcodes.VARIANT_OPTION_NOT_FOUND,
  DataSubcodes.VARIANT_OPTION_TEMPLATE_NOT_FOUND
]

export default function usePutPlaceMenu() {
  const queryClient = useQueryClient()
  const texts = useTranslation().t
  const mutation = useMutation((params: MutationParams) => dataApi.putPlaceMenu(params.uuid, mapMenuFormDataToDto(params.data)), {
    onError: (e: AxiosError<HttpError>, variables: MutationParams) => {
      if (e.response?.status === 404 && e.response?.data.code === Codes.DATA && errorRefreshSubcodes.includes(e.response?.data.subcode)) {
        queryClient.resetQueries([queryNames.placeMenu, variables.uuid])
      }
      showErrorToast(e, texts, Page.CREATE_MENU)
    },
    onSuccess: (_, variables: MutationParams) => {
      queryClient.resetQueries([queryNames.placeMenu, variables.uuid])
      queryClient.resetQueries(queryNames.productAvailabilityList)
      queryClient.resetQueries(queryNames.variantAvailabilityList)
      queryClient.resetQueries(queryNames.additionAvailabilityList)
      toast.success(texts('successes:menu_updated_successfully'))
    }
  })
  return mutation
}

