import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import DataApi from '../../api/data/DataApi'
import { showErrorToast } from '../../utils/error'
import { queryNames } from '../queries'

const dataApi = DataApi.getInstance()

export default function useSearchUsers(search: string, withCard: boolean) {
  const texts = useTranslation().t
  const query = useQuery([queryNames.searchUsers, search, withCard], async () => {
    return await dataApi.searchUsers(search, withCard)
  }, {
    staleTime: Infinity,
    refetchOnMount: 'always',
    onError: (error) => {
      showErrorToast(error, texts)
    }
  })
  return query
}

