import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import DataApi from '../../api/data/DataApi'
import { showErrorToast } from '../../utils/error'
import { queryNames } from '../queries'
import { AccountListData } from '../../types/accounts'

const dataApi = DataApi.getInstance()

type MutationParams = {
  uuid: string
  active: boolean
}

export default function useEditCmsUserActiveState() {
  const queryClient = useQueryClient()
  const texts = useTranslation().t
  const mutation = useMutation((params: MutationParams) => dataApi.editCmsUserActiveState(params.uuid, params.active), {
    onError: (e) => {
      showErrorToast(e, texts)
    },
    onSuccess: (_, variables: MutationParams) => {
      const queriesData = queryClient.getQueriesData<AccountListData>(queryNames.accountList)
      for (const queryData of queriesData) {
        if (queryData[1] != null && (queryData[1].data?.length ?? 0) > 0) {
          const index = queryData[1].data.findIndex(p => p.uuid === variables.uuid)
          if (index !== -1) {
            queryData[1].data[index].active = variables.active
            queryClient.setQueryData(queryData[0], queryData[1])
          }
        }
      }
      toast.success(
        texts(
          variables.active 
          ? 'successes:account_activated' 
          : 'successes:account_deactivated' 
        )
      )
    }
  })
  return mutation
}