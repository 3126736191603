import { Grid, IconButton, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { AiFillPlusCircle } from 'react-icons/ai'
import moment from 'moment-timezone'
import { ExtraTimeBufferForRushHours, ChangeState } from '../../../types/place'
import { generate } from 'short-uuid'
import React from 'react'
import Dialog from '../../CustomMui/Dialog'
import { ArrayHelpers, Field, FieldArray, FieldProps, useFormikContext } from 'formik'
import Row from './Row'
import { DEFAULT_END_TIME, DEFAULT_START_TIME } from '../../../utils/constants'
import { useState } from 'react'

type Props = {
  name: string
  disabledEdit?: boolean
}

const TIME_FORMAT = 'HH:mm'

const sortArray = (array: ExtraTimeBufferForRushHours[]) => {
  return array.sort((a: ExtraTimeBufferForRushHours, b: ExtraTimeBufferForRushHours) => {
    const aStartTime = (Number.parseInt(a.start.split(':')[0]) * 60) + Number.parseInt(a.start.split(':')[1])
    const bStartTime = (Number.parseInt(b.start.split(':')[0]) * 60) + Number.parseInt(a.start.split(':')[1])

    if (a.day === b.day) {
      return aStartTime === bStartTime ? 0 : (aStartTime > bStartTime ? 1 : -1)
    } else {
      return a.day > b.day ? 1 : -1
    }
  })
}

const checkDateRanges = (values: ExtraTimeBufferForRushHours[], value: ExtraTimeBufferForRushHours): string | null => {
  if (!value.value) {
    return 'errors:invalid_time_extension'
  }

  const startTime = moment(value.start, TIME_FORMAT).add(value.day, 'day')
  const endTime = moment(value.end, TIME_FORMAT).add(value.day, 'day')
  if (moment(startTime).isAfter(endTime)) {
    endTime.add(1, 'day')
  }

  let comparedStartTime: moment.Moment
  let comparedEndTime: moment.Moment
  for (const val of values) {
    comparedStartTime = moment(val.start, TIME_FORMAT).add(val.day, 'day')
    comparedEndTime = moment(val.end, TIME_FORMAT).add(val.day, 'day')
    if (val.key === value.key || startTime.day !== comparedStartTime.day) {
      continue
    }

    if (moment(comparedStartTime).isAfter(comparedEndTime)) {
      comparedEndTime.add(1, 'day')
    }
    if (startTime.isBetween(comparedStartTime, comparedEndTime, null, '[]') || 
        endTime.isBetween(comparedStartTime, comparedEndTime, null, '[]') ||
        comparedStartTime.isBetween(startTime, endTime, null, '[]') ||
        comparedEndTime.isBetween(startTime, endTime, null, '[]') 
    ) {
      return 'errors:time_period_coincidence'
    }
  }
  return null
}

const onAdd = (arrayHelpers: ArrayHelpers) => {
  const newElement: ExtraTimeBufferForRushHours = {
    key: generate(),
    day: 0,
    start: DEFAULT_START_TIME,
    end: DEFAULT_END_TIME,
    value: null,
    state: ChangeState.INIT
  }
  arrayHelpers.push(newElement)
}

const getEditedRowIndex = (values: ExtraTimeBufferForRushHours[]) => {
  return values.findIndex((change: ExtraTimeBufferForRushHours) => change.state !== ChangeState.SAVED)
}

const resetRowState = (values: ExtraTimeBufferForRushHours[], arrayHelpers: ArrayHelpers, idx: number) => {
  const currentState = values[idx].state
  if (currentState === ChangeState.EDITED) {
    values[idx].state = ChangeState.SAVED
    arrayHelpers.replace(idx, values[idx])
  } else {
    arrayHelpers.remove(idx)
  }
}

const ExtraTimeBufferForRushHoursForm = (props: Props) => {
  const texts = useTranslation().t
  const { values, setFieldValue, setFieldError } = useFormikContext<{extraTimeBufferForRushHours: ExtraTimeBufferForRushHours[]}>()
  const [removeIndex, setRemoveIndex] = useState<number | null>(null)

  const onCancel = (arrayHelpers: ArrayHelpers, index: number) => {
    const value = values.extraTimeBufferForRushHours[index]
    if (value.state === ChangeState.INIT) {
      return arrayHelpers.remove(index)
    }
    value.state = ChangeState.SAVED
    arrayHelpers.replace(index, value)
    setFieldError(`${props.name}.${index}`, undefined) // only for cancel editing
  }

  const onEdit = (arrayHelpers: ArrayHelpers, index: number) => {
    const value = values.extraTimeBufferForRushHours[index]
    const editedRowId = getEditedRowIndex(values.extraTimeBufferForRushHours)
    if (editedRowId !== -1) {
      resetRowState(values.extraTimeBufferForRushHours, arrayHelpers, editedRowId)
    }
    value.state = ChangeState.EDITED
    arrayHelpers.replace(index, value)
  }

  const onSave = (value: ExtraTimeBufferForRushHours, index: number) => {
    const error = checkDateRanges(values.extraTimeBufferForRushHours, value)
    if (error) {
      return setFieldError(`${props.name}.${index}`, error)
    }
    values.extraTimeBufferForRushHours[index] = {
      ...value, 
      state: ChangeState.SAVED
    }
    setFieldValue(props.name, sortArray(values.extraTimeBufferForRushHours))
  }

  return (
    <Grid container direction={'column'}>
      <Typography 
        variant='body1'
        sx={{
          marginBottom: '1.5rem',
          fontFamily: 'RobotoCondensedBold'
        }}
      >
        {texts('place:traffic_time_extension')}
      </Typography>
      <FieldArray
        name={props.name}
        render={(arrayHelpers: ArrayHelpers ) => (
          <>
            <Dialog
              open={removeIndex !== null}
              title={texts('dialogs:remove_title')}
              description={texts('dialogs:remove_description')}
              closeButtonLabel={texts('common:cancel')}
              confirmButtonLabel={texts('common:remove')}
              onCloseButtonClick={() => {
                setRemoveIndex(null)
              }}
              onConfirmButtonClick={() => {
                arrayHelpers.remove(removeIndex!)
                setRemoveIndex(null)
              }}
            />
            {
              values.extraTimeBufferForRushHours.map((val: ExtraTimeBufferForRushHours, index: number) => (
                <Field 
                  name={`${props.name}.${index}`}
                  key={`key-${index}`}
                  validate={(value?: ExtraTimeBufferForRushHours) => {
                    if (value) {
                      return (
                        value.state !== ChangeState.SAVED
                          ? 'errors:save_field_before_submit'
                          : null
                      )
                    }
                    return null
                  }}
                >
                  {({ meta }: FieldProps) => (
                    <Row
                      value={val}
                      disabledEdit={props.disabledEdit}
                      rowError={meta.error}
                      onRemove={() => {
                        setRemoveIndex(index)
                      }}
                      onCancel={() => onCancel(arrayHelpers, index)}
                      onEdit={() => onEdit(arrayHelpers, index)}
                      onSave={(value) => onSave(value, index)}
                    />
                  )}
                </Field>
              ))
            }
            {
              props.disabledEdit
              ? null
              : <Grid 
                  item 
                  container 
                  wrap='nowrap' 
                  alignItems={'center'} 
                >
                  <IconButton 
                    onClick={() => {onAdd(arrayHelpers)}} 
                    sx={{
                      minWidth: 0,
                      color: 'secondary.light'
                    }}
                    disabled={getEditedRowIndex(values.extraTimeBufferForRushHours) !== -1}
                    disableRipple
                  >
                    <AiFillPlusCircle size={30}/>
                    <Typography 
                      variant='body2' 
                      sx={{
                        fontFamily: 'RobotoCondensedBold',
                        marginLeft: '0.5rem',
                        color: getEditedRowIndex(values.extraTimeBufferForRushHours) !== -1 ? 'rgba(0, 0, 0, 0.26)' : 'secondary.light'
                      }}
                    >
                      {texts('place:add_period')}
                    </Typography>
                  </IconButton>
                </Grid>
            }
            
          </>
        )}
      />
    </Grid>
  )
}

export default ExtraTimeBufferForRushHoursForm