import { Box,  Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { AiFillInfoCircle } from 'react-icons/ai'
import TextField from '../../CustomMui/Fields/TextField'

type Props = {
  disabledEdit?: boolean
}

const PaymentDetails = (props: Props) => {
  const texts = useTranslation().t

  return (
    <Grid container direction={'column'}>
      <Typography variant='subtitle1'>{texts('place:payment_details_title')}</Typography>
      <Grid item container>
        <Grid 
          item
          container
          gap={'0.625rem'}
          mb={'1rem'}
          wrap={'nowrap'}
        >
          <Grid item flexShrink={0}>
            <AiFillInfoCircle 
              size={20} 
              color={'#fa6400'}
            />
          </Grid>
          <Typography variant='body1'>{texts('place:payment_info_label')}</Typography>
        </Grid>
        <Box 
          flexGrow={1} 
          display='grid' 
          gridTemplateColumns='repeat(2, 1fr)' 
          gridTemplateRows='repeat(2, 1fr)' 
          columnGap='1.875rem'
          rowGap='0.125rem'
          maxWidth={'60%'}
        >
          <TextField
            name='payuCredentials.posId'
            disabled={props.disabledEdit}
            label={texts('place:payment_id')}
          />
          <TextField
            name='payuCredentials.clientId'
            disabled={props.disabledEdit}
            label={texts('place:payment_client_id_oauth')}
          />
          <TextField
            name='payuCredentials.secondKey'
            disabled={props.disabledEdit}
            label={texts('place:payment_second_key')}
          />
          <TextField
            name='payuCredentials.clientSecret'
            disabled={props.disabledEdit}
            label={texts('place:payment_client_secret_oauth')}
          />
        </Box>
      </Grid>
    </Grid>
  )
}

export default PaymentDetails