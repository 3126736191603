import { Photo } from './common'
import { Role } from './accounts'
import { Namespace, TFuncKey } from 'i18next'
import { QueryParamConfig } from 'use-query-params'

export enum AvailabilityItemType {
  PRODUCTS = 'products',
  VARIANTS = 'variants',
  ADDITIONS = 'additions'
}

export type AvailabilityTypeOption = {
  label: string
  value: AvailabilityItemType
  sortReference: TFuncKey<Namespace>
  searchReference: TFuncKey<Namespace>
}

export type ProductFormData = {
  photoUuid: string | null
  photoUrl: string | null
  name: string
  description: string
  basePrice: number | null
  baseTime: number | null
  tags: TagDto[]
  note: string
}

export type BulkProductTemplateEntry = {
  name: string
  description: string
  price: number
  preparationTime: number
  internalNote: string
}

export type Product = {
  uuid: string
  photo: Photo | null
  name: string
  description: string
  basePrice: number
  baseTime: number
  tags: TagDto[]
  note: string
}

export type ProductAvailability = Product & {
  active: boolean
  inactiveUntil: Date | null
  internalNote?: string | null
}

export type Menu = {
  label: string
  permittedRoles?: Role[]
  items: MenuItem[]
  additionalPaths?: string[]
  basePath?: string
  excludedPaths?: string[]
}

export type MenuItem = {
  path: string
  label: string
  permittedRoles?: Role[]
  bottomDivider?: boolean
  addIcon?: boolean
}

export type VariantFormData = {
  name: string
  note?: string
  options: VariantOptionFormData[]
}

export type Variant = {
  name: string
  note?: string
  options: VariantOption[]
  uuid: string
}

export type VariantOptionFormData = {
  id: string
  uuid?: string
  name: string
  additionalPrice: number | null
  additionalTime: number | null
  defaultOption: boolean
  active?: boolean
} & FieldValue

export type VariantOption = {
  id: string
  uuid?: string
  name: string
  additionalPrice: number
  additionalTime: number
  defaultOption: boolean
  active?: boolean
} & FieldValue

export type VariantAvailability = {
  uuid: string
  name: string
  note?: string
  options: VariantOptionAvailability[]
}

export type VariantOptionAvailability = VariantOption & {
  inactiveUntil: Date | null
}

type FieldValue = {
  state: ChangeState
  key?: string
}

export enum ChangeState {
  INIT, 
  EDITED,
  SAVED
}

export type AdditionFormData = {
  name: string
  note?: string
  additionalPrice: number | null
  additionalTime: number | null
}

export type Addition = {
  name: string
  note?: string
  additionalPrice: number
  additionalTime: number
  uuid: string
}

export type AdditionAvailability = Addition & {
  active: boolean
  inactiveUntil: Date | null
  internalNote?: string | null
}

export type TagFormData = {
  name: string
}

export type TagDto = TagFormData & {
  uuid: string
}

export type AdditionList = {
  count: number
  data: Addition[]
}

export type AdditionAvailabilityList = {
  count: number
  data: AdditionAvailability[]
}

export type VariantList = {
  count: number
  data: Variant[]
}

export type VariantAvailabilityList = {
  count: number
  data: VariantAvailability[]
}

export type ProductList = {
  count: number
  data: Product[]
}

export type ProductAvailabilityList = {
  count: number
  data: ProductAvailability[]
}

export type AdditionTemplateListDto = {
  count: number
  data: AdditionTemplateDto[]
}

export type AdditionTemplateDto = {
  uuid: string
  name: string
  internalNote?: string
  price: number
  preparationTime: number
}

export type ProductTemplateListDto = {
  count: number
  data: ProductTemplateDto[]
}

export type ProductTemplateDto = {
  uuid: string
  name: string
  description: string
  internalNote?: string
  price: number
  preparationTime: number
  tags: TagDto[]
  photo: Photo | null
}

export type VariantTemplateListDto = {
  count: number
  data: VariantTemplateDto[]
}

export type VariantTemplateDto = {
  uuid: string
  name: string
  internalNote?: string
  options: VariantTemplateOptionDto[]
}

export type VariantTemplateOptionDto = {
  uuid?: string
  name: string
  description?: string
  default?: boolean
  active?: boolean
  internalNote?: string
  price: number
  preparationTime: number
}

export type TagListDto = {
  count: number
  data: TagDto[]
}

export type MenuCategory = {
  cardId: string
  uuid?: string
  name: string
  products: MenuProduct[]
}

export type MenuProduct = {
  cardId: string
  uuid?: string
  templateUuid: string
  name: string
  description: string
  internalNote: string
  price: number
  preparationTime: number
  customPrice: number | null
  customPreparationTime: number | null
  photo: Photo | null
  tags: TagDto[]
  active: boolean
  inactiveUntil?: string
  variants: MenuVariant[]
  additions: MenuAddition[]
}

export type MenuAddition = {
  cardId: string
  uuid?: string
  templateUuid: string
  name: string
  description?: string
  internalNote: string
  price: number
  active: boolean
  inactiveUntil?: string
  preparationTime: number
  customPrice: number | null
  customPreparationTime: number | null
}

export type MenuVariant = {
  cardId: string
  uuid?: string
  templateUuid: string
  name: string
  description: string | null
  active: boolean
  inactiveUntil?: string
  internalNote: string
  options: MenuVariantOption[]
}

export type MenuVariantOption = {
  cardId: string
  uuid?: string
  templateUuid: string
  name: string
  description?: string | null
  price: number
  preparationTime: number
  active: boolean
  inactiveUntil?: string
  default: boolean
  customPrice: number | null
  customPreparationTime: number | null
}

export type MenuFormData = {
  items: MenuCategory[]
  newCategoryLabel?: string
  editCategory?: MenuCategory
}

export type MenuItemCardOption = {
  label: string
  value: MenuItemType
}

export enum MenuItemType {
  REMOVE = 'remove',
  SET_AS_DEFAULT = 'setAsDefault',
  ADD = 'add',
  EDIT = 'edit'
}

export type TemplateListOptions = {
  search?: string
  sort?: string
  limit?: number
  offset?: number
  exclude?: string[]
}

export type TagListOptions = {
  search?: string
  sort?: string
  limit?: number
  offset?: number
}

export type LoyaltyCardListOptions = {
  search?: string
  limit?: number
  offset?: number
}

export type FileListOptions = LoyaltyCardListOptions

export type SearchListSearchParams = {
  page: QueryParamConfig<number | null | undefined, number>
  search: QueryParamConfig<string | null | undefined, string>
}

export type SearchOrderedListSearchParams = {
  page: QueryParamConfig<number | null | undefined, number>
  order: QueryParamConfig<string | null | undefined, string>
  search: QueryParamConfig<string | null | undefined, string>
}

export type AvailabilityListSearchParams = {
  type: QueryParamConfig<string | null | undefined, string>
  place: QueryParamConfig<string | null | undefined, string>
  search: QueryParamConfig<string | null | undefined, string>
  order: QueryParamConfig<string | null | undefined, string>
  page: QueryParamConfig<number | null | undefined, number>
}