import { MenuItem, TextField, SxProps } from '@mui/material'

export type DropdownOption = {
  label: string
  value: string
}

type Props = {
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  options: DropdownOption[]
  sx?: SxProps
}

const DropdownMenu = (props: Props) => {
  return (
    <TextField
      select
      value={props.value}
      onChange={props.onChange}
      variant='outlined'
      sx={props.sx}
    >
      {props.options.map((option) => (
        <MenuItem key={option.label} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  )
}

export default DropdownMenu