import { Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import Dialog from '../../CustomMui/Dialog'
import { useNavigate } from 'react-router-dom'
import { TagDto } from '../../../types/menu'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import Loader from '../../Common/Loader'
import useRemoveTag from '../../../hooks/tag/useRemoveTag'

type Props = {
  tag: TagDto
}

const TagListRow = (props: Props) => {
  const texts = useTranslation().t
  const navigate = useNavigate()

  const removeMutation = useRemoveTag()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [openRemoveDialog, setOpenRemoveDialog] = useState<boolean>(false)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Grid
      container
      position={'relative'}
      minHeight={'3rem'}
      py={'0.5rem'}
      px={'1rem'}
      marginTop={'0.938rem'}
      borderRadius={'0.938rem'}
      boxShadow={'0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.5)'}
      gap={'0.938rem'}
      wrap={'nowrap'}
      alignItems={'center'}
      justifyContent={'space-between'}
    >
      <Dialog
        open={openRemoveDialog}
        title={texts('dialogs:remove_title')}
        description={texts('dialogs:remove_description')}
        warning={texts('dialogs:tag_remove_warning')}
        closeButtonLabel={texts('common:cancel')}
        confirmButtonLabel={texts('common:remove')}
        onCloseButtonClick={() => {setOpenRemoveDialog(false)}}
        onConfirmButtonClick={() => {
          setOpenRemoveDialog(false)
          removeMutation.mutate({ uuid: props.tag.uuid })
        }}
      />
      {
        removeMutation.isLoading
        ? <Grid
            item
            position={'absolute'}
            width={'100%'}
            height={'100%'}
            right={0}
            borderRadius={'0.938rem'}
            overflow={'hidden'}
            sx={{backgroundSize: 'cover'}}
          >
            <Loader
              backgroundColor='rgba(0,0,0,0.08)'
              size={30}
            />
          </Grid>
        : null
      }
      <Grid
        item
        sx={{
          ':hover': {cursor: 'pointer'},
          minWidth: '1rem'
        }}
        onClick={() => navigate(`/menu/tags/edit/${props.tag.uuid}`)}
        flexGrow={1}
      >
        <Typography 
          variant='h2'
          sx={{
            fontSize: '1.125rem',
            minWidth: '1rem',
            minHeight: '1.25rem'
          }}
        >
          {props.tag.name}
        </Typography>        
      </Grid>
      <Grid item>
        <IconButton
          onClick={handleClick}
          sx={{color: '#000'}}
        >
          <MoreHorizIcon/>
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: '12rem',
            },
          }}
        >
          <MenuItem 
            onClick={() => {
              handleClose()
              setOpenRemoveDialog(true)
            }}
          >
            {texts('common:remove')}
          </MenuItem>
        </Menu>
      </Grid>
    </Grid>
  )
}

export default TagListRow