import React from 'react'
import { Checkbox, Grid } from '@mui/material'
import { Product } from '../../../../../types/menu'
import ProductsListRowDetails from '../../../Product/ProductsListRowDetails'
import ProductsListRowPhoto from '../../../Product/ProductsListRowPhoto'

type Props = {
  product: Product
  selected: boolean
  onClick: () => void
}

const ProductTemplateTile = (props: Props) => {
  return (
    <Grid
      container
      position={'relative'}
      minHeight={'9.063rem'}
      padding={'0.938rem'}
      marginTop={'0.938rem'}
      borderRadius={'0.938rem'}
      boxShadow={'0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.5)'}
      gap={'0.938rem'}
      wrap={'nowrap'}
      sx={{
        ':hover': {cursor: 'pointer'},
        ...(props.selected ? { border: 'solid 0.125rem #94C11F' } : { border: 'solid 0.125rem white' })
      }}
      onClick={props.onClick}
    >
      <Grid item>
        <Grid
          container
          flexDirection={'column'}
          justifyContent={'flex-start'}
          sx={{
            height: '100%'
          }}
        >
          <Checkbox 
            checked={props.selected} 
            sx={{
              padding: 0, 
              marginTop: '-3px',
              '& .MuiSvgIcon-root': { fontSize: 30 },
            }}
          />
        </Grid>
      </Grid>
      <ProductsListRowPhoto 
        product={props.product}
      />
      <ProductsListRowDetails
        product={props.product}
      />
    </Grid>
  )
}

export default ProductTemplateTile