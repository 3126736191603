import { Box, Checkbox, Grid, Typography } from '@mui/material'
import Tag from '../../../Common/Tag'
import TimerIcon from '@mui/icons-material/Timer'
import { useTranslation } from 'react-i18next'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import { ProductAvailability } from '../../../../types/menu'
import { mapSecondsToString } from '../../../../utils/mapping'
import Chip from '../../../Common/Chip'
import moment from 'moment'
import placeholderImage from '../.../../../../../assets/images/placeholderProduct.webp'
import { evalStringTemplate } from '../../../../utils/text'
import currency from 'currency.js'

type Props = {
  product: ProductAvailability
  onCheckboxClick: (checked: boolean, uuid: string) => void
  checked: boolean
}

const ProductItem = (props: Props) => {
  const texts = useTranslation().t

  return (
    <Grid
      container
      position={'relative'}
      padding={'1rem'}
      borderRadius={'0.938rem'}
      boxShadow={'0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.5)'}
      wrap={'nowrap'}
      direction={'row'}
      gap={'15px'}
      sx={{
        borderStyle: props.checked ? 'solid' : 'none',
        backgroundColor: props.checked ? 'rgba(148,193,31, 0.2)' : '#FFFFFF',
        borderColor: '#94C11F',
        borderWidth: 3
      }}
    >
      <Checkbox
        sx={{
          padding: 0,
          alignSelf: 'flex-start'
        }}
        checked={props.checked}
        onClick={() => props.onCheckboxClick(!props.checked, props.product.uuid)}
      />
      <Box
        component={'img'}
        alt='logo'
        flexShrink={0}
        width={'7.188rem'}
        height={'7.188rem'}
        display={'block'}
        src={props.product.photo?.thumbnail ?? placeholderImage}
        sx={{
          filter: props.product.active ? 'none' : 'grayscale(1)' 
        }}
      />
      <Grid
        item
        container
        direction={'column'}
        gap={'0.375rem'}
        display={'flex'}
        sx={{
          opacity: props.product.active ? 1 : 0.5
        }}
      >
        {
          !props.product.active &&
          <Chip
            text={
              props.product.inactiveUntil 
              ? evalStringTemplate(texts('common:inactive_until'), { date: moment(props.product.inactiveUntil).format('DD.MM.YYYY, HH:mm') })
              : texts('common:inactive')
            }
            opacity={0.3}
            paddingInline={'3rem'}
          />
        }
        <Grid
          container item
          direction={'row'}
          gap={'0.5rem'}
        >
          <Typography 
            variant='h2'
            fontSize={'1.125rem'}
            paddingTop={'1px'}
          >
            {props.product.name}
          </Typography>
          {
            props.product.tags.map((tag) => (
              <Chip
                key={tag.uuid}
                text={tag.name}
                uppercase
              />
            ))
          }
        </Grid>
        <Typography 
          variant='body2'
          sx={{textTransform: 'none'}}
        >
          {props.product.description}
        </Typography>
        <Grid 
          item 
          container
          gap={'0.5rem'}
        >
          <Tag
            text={`${mapSecondsToString(props.product.baseTime)}`}
            opacity={(props.product.baseTime) === 0}
          >
            <TimerIcon/>
          </Tag>
          <Tag
            text={texts('common:currency', { localValue: currency(props.product.basePrice, { fromCents: true }).value })}
            opacity={(props.product.basePrice) === 0}
          >
            <LocalOfferIcon/>
          </Tag>
        </Grid>
        {
          props.product.internalNote
          ? <Grid 
              container item 
              marginTop={'5px'} 
              flexGrow={1}
              alignItems={'end'}
            >
              <Typography 
                variant='body2'
                fontStyle='italic'
                textTransform='none'
              >
                {texts('menu:note', { val: props.product.internalNote })}
              </Typography>
            </Grid>
          : null
        }
      </Grid>
    </Grid>
  )
}

export default ProductItem