import { Button, Dialog as MuiDialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from '@mui/material'
import { useFormikContext } from 'formik'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { MenuFormData } from '../../../../types/menu'
// import TextField from '../../../CustomMui/Fields/TextField'

type Props = {
  open: boolean
  onCloseButtonClick: () => void
}

const EditCategoryDialog = (props: Props) => {
  const texts = useTranslation().t
  const { values, errors, setFieldValue, setFieldError } = useFormikContext<MenuFormData>()

  const ref = useRef(null) as any

  const handleConfirm = () => {
    const copyItems = [...values.items]
    if (
      ref.current?.value 
      && copyItems.find(e => e.cardId !== values.editCategory!.cardId && e.name.trim().toLowerCase() === (ref.current.value.trim().toLowerCase() ?? '')) != null
    ) {
      setFieldError('editCategory', texts('validations:non_unique_category'))
    } else if (ref.current?.value.length > 32) {
      setFieldError('editCategory', texts('validations:max_32_signs_validation'))
    } else if (ref.current?.value) {
      setFieldError('editCategory', undefined)
      const idx = copyItems.findIndex(e => e.cardId === values.editCategory!.cardId)
      copyItems[idx].name = ref.current.value 
      setFieldValue('items', copyItems)
      setFieldValue('editCategory', undefined)
      props.onCloseButtonClick()
    } else {
      setFieldError('editCategory', texts('validations:field_required'))
    }
  }

  return (
    <MuiDialog
      open={props.open}
      onClose={() => {
        props.onCloseButtonClick()
        setFieldError('editCategory', undefined)
      }} 
    >
      <DialogActions>
        <IconButton 
          onClick={() => {
            props.onCloseButtonClick()
            setFieldError('editCategory', undefined)
          }} 
          color={'primary'}
        >
          <AiOutlineCloseCircle size={30}/>
        </IconButton>
      </DialogActions>
      <DialogTitle> 
        {texts('dialogs:edit_category_title')}
      </DialogTitle>
      
      <DialogContent>
        <TextField
          inputRef={ref}
          margin="dense"
          label={texts('dialogs:category_label')}
          type="text"
          value={values.editCategory?.name}
          fullWidth
          onChange={(e) => {
            setFieldValue('editCategory.name', e.target.value)
            setFieldError('editCategory', undefined)
          }}
          onBlur={(e) => {
            if (e.target.value.length > 32) {
              setFieldError('editCategory', texts('validations:max_32_signs_validation'))
            } 
          }}
          error={errors.editCategory != null && errors.editCategory !== ''}
          helperText={errors.editCategory}
        />
      </DialogContent>
      
      <DialogActions>
        <Button
          variant={'contained'}
          size={'medium'}
          onClick={handleConfirm}
        >
          {texts('common:save')}
        </Button>
      </DialogActions>
    </MuiDialog>
  )
}

export default EditCategoryDialog
