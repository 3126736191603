import { Box, Grid } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { DecodedValueMap, NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import Loader from '../../../components/Common/Loader'
import NetworkError from '../../../components/Common/NetworkError'
import { DropdownOption } from '../../../components/CustomMui/DropdownMenu'
import ButtonsSection from '../../../components/Menu/Availability/ButtonsSection'
import ChoiceSection from '../../../components/Menu/Availability/ChoiceSection'
import AdditionAvailabilityList from '../../../components/Menu/Availability/Lists/AdditionAvailabilityList'
import ProductAvailabilityList from '../../../components/Menu/Availability/Lists/ProductAvailabilityList'
import VariantAvailabilityList from '../../../components/Menu/Availability/Lists/VariantAvailabilityList'
import useGetPlaceList from '../../../hooks/place/useGetPlaceList'
import { queryNames } from '../../../hooks/queries'
import { AvailabilityItemType, AvailabilityTypeOption, AvailabilityListSearchParams } from '../../../types/menu'
import { isStringNotEmpty } from '../../../utils/text'

function isSearchParamsValid(
  params: DecodedValueMap<AvailabilityListSearchParams>, 
  sortOptions: DropdownOption[],
  typeOptions: AvailabilityTypeOption[]
): boolean {
  return Number.isInteger(params.page) // must be a number, not a NaN
    && params.page >= 1  // page cannot be lower than 1
    && sortOptions.some(option => option.value === params.order.toLowerCase()) // order must be from given list
    && typeOptions.some(option => option.value === params.type.toLowerCase()) // type must be from given list
}

const AvailabilityManagement = () => {
  const texts = useTranslation().t
  const queryClient = useQueryClient()

  const typeOptions: AvailabilityTypeOption[] = useMemo(() => {
    return texts('objects:manage_availability_types', {
      returnObjects: true 
    }) as AvailabilityTypeOption[]
  }, [texts])

  const [searchParams, setSearchParams] = useQueryParams<AvailabilityListSearchParams>({
    type: withDefault(StringParam, typeOptions[0].value),
    place: withDefault(StringParam, ''),
    search: withDefault(StringParam, ''),
    order: withDefault(StringParam, 'date:desc'),
    page: withDefault(NumberParam, 1)
  })

  const sortOptions: DropdownOption[] = useMemo(() => {
    const option = typeOptions.find(e => e.value === searchParams.type)
    if (option) {
      return texts(option!.sortReference, {
        returnObjects: true 
      }) as DropdownOption[]
    } else {
      return []
    }
  }, [typeOptions, texts, searchParams.type])

  const placeListQuery = useGetPlaceList((data) => {
    const isNotEmpty = data.length > 0
    const isInsideArray = data.find(d => d.uuid === searchParams.place)
    if (isNotEmpty && !isInsideArray) {
      queryClient.resetQueries([queryNames.additionAvailabilityList])
      queryClient.resetQueries([queryNames.productAvailabilityList])
      queryClient.resetQueries([queryNames.variantAvailabilityList])
      setSearchParams({ 
        place: data[0].uuid,
        order: 'date:desc',
        search: '',
        page: 1,
        type: typeOptions[0].value
      }, 'replace')
    }
  })

  const [selectedItems, setSelectedItems] = useState<string[]>([])

  useEffect(() => {
    if (!searchParams.place) {
      queryClient.resetQueries([queryNames.placeList])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.place])

  useEffect(() => {
    window.scrollTo(0,0)
  }, [searchParams.page])

  useEffect(() => {
    if (!isSearchParamsValid(searchParams, sortOptions, typeOptions)) {
      setSearchParams({ 
        place: placeListQuery.data?.[0].uuid,
        order: 'date:desc',
        search: '',
        page: 1,
        type: typeOptions[0].value
      }, 'replace')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.page, searchParams.type, searchParams.order, sortOptions, typeOptions]) 

  const onSelectionChange = useCallback((checked: boolean, uuid: string) => {
    if (checked) {
      setSelectedItems(selectedItems.concat(uuid))
    } else {
      setSelectedItems(selectedItems.filter(entry => entry !== uuid))
    }
  }, [selectedItems])

  const listQueryEnabled = !placeListQuery.isFetching && isStringNotEmpty(searchParams.place) && isSearchParamsValid(searchParams, sortOptions, typeOptions)

  if (!isSearchParamsValid(searchParams, sortOptions, typeOptions)) {
    return <></>
  }

  return (
    <>
      {
        placeListQuery.isError || placeListQuery.isFetching
          ? <Grid sx={{height: 'calc(100vh - 160px)'}}>
            {
              placeListQuery.isError
              ? <NetworkError
                  withRefresh
                  onRefresh={() => {
                    queryClient.resetQueries(queryNames.placeList)
                  }}
                />
              : <Loader 
                  width={'100%'}
                  text={texts('common:loading')}
                />
            }
            </Grid>
          : <Grid
              container
              direction={'column'}
              gap={'34px'}
              padding={'0 4px 0 4px'}
              sx={{
                minHeight: 'calc(100% - 130px)',
                position: 'absolute',
                width: 'max(calc(100% - 60px), 980px)',
                overflow: 'auto'
              }}
              paddingBottom={'30px'}
            >
              <ButtonsSection
                itemType={searchParams.type as AvailabilityItemType}
                placeUuid={searchParams.place}
                selectedItems={selectedItems}
                onSuccess={() => {
                  setSelectedItems([])
                }}
              />

              <ChoiceSection
                typeSelectProps={{
                  value: searchParams.type,
                  options: typeOptions,
                  onChange: (event) => {
                    setSearchParams({ type: event.target.value, page: 1, search : '', order: 'date:desc' }, 'replaceIn')
                    setSelectedItems([])
                  }
                }}
                premiseSelectProps={{
                  value: searchParams.place,
                  options: placeListQuery.data ?? [],
                  onChange: (event) => {
                    setSearchParams({ place: event.target.value, page: 1, search : '', order: 'date:desc' }, 'replaceIn')
                    setSelectedItems([])
                  }
                }}
              />

              <Box 
                sx={{
                  backgroundColor: '#979797',
                  height: '1px',
                  width: '100%'
                }}
              />
              {
                searchParams.type === AvailabilityItemType.PRODUCTS
                ? <ProductAvailabilityList 
                    disableQueries={!listQueryEnabled}
                    onSelectionChanged={onSelectionChange}
                    selectedItems={selectedItems}
                  />
                : null
              }
              {
                searchParams.type === AvailabilityItemType.ADDITIONS
                ? <AdditionAvailabilityList 
                    disableQueries={!listQueryEnabled}
                    onSelectionChanged={onSelectionChange}
                    selectedItems={selectedItems}
                  />
                : null
              }
              {
                searchParams.type === AvailabilityItemType.VARIANTS
                ? <VariantAvailabilityList 
                    disableQueries={!listQueryEnabled}
                    onSelectionChanged={onSelectionChange}
                    selectedItems={selectedItems}
                  />
                : null
              }
            </Grid>
      }
    </>
  )
}

export default AvailabilityManagement