import { useEffect } from 'react'
import { useGeolocated } from 'react-geolocated'

export default function useGetCurrentLocation(suppressLocationOnMount: boolean, onSuccess: (coords: GeolocationCoordinates) => void) {
  const { coords } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    suppressLocationOnMount: suppressLocationOnMount,
    userDecisionTimeout: 5000
  })

  useEffect(() => {
    if (coords) {
      onSuccess(coords)
    }
  }, [coords, onSuccess])

  return { coords }
}