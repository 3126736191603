import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import CreateProductHeaderBar from '../../../components/Menu/Product/CreateProductHeaderBar'
import EditProductHeaderBar from '../../../components/Menu/Product/EditProductHeaderBar'
import { ProductFormData } from '../../../types/menu'
import { Form, Formik, FormikHelpers } from 'formik'
import { addProductValidation } from '../../../validations/addProductValidation'
import { Grid } from '@mui/material'
import Dialog from '../../../components/CustomMui/Dialog'
import ProductDetails from '../../../components/Menu/Product/ProductDetails'
import useGetProductTemplateDetails from '../../../hooks/template/product/useGetProductTemplateDetails'
import Loader from '../../../components/Common/Loader'
import useCreateProductTemplate from '../../../hooks/template/product/useCreateProductTemplate'
import useEditProductTemplate from '../../../hooks/template/product/useEditProductTemplate'
import useRemoveProductTemplate from '../../../hooks/template/product/useRemoveProductTemplate'
import NetworkError from '../../../components/Common/NetworkError'
import { useQueryClient } from 'react-query'
import { queryNames } from '../../../hooks/queries'

type Props = {
  isEditProduct: boolean
}

const defaultInitValue: ProductFormData = {
  photoUuid: null,
  photoUrl: null,
  name: '',
  description: '',
  basePrice: null,
  baseTime: null,
  tags: [],
  note: ''
}

const ProductPage = (props: Props) => {
  const texts = useTranslation().t
  const navigate = useNavigate()
  const { uuid } = useParams<{ uuid: string }>()

  const goBack = () => { navigate(-1) }

  const queryClient = useQueryClient()
  const addMutation = useCreateProductTemplate()
  const editMutation = useEditProductTemplate(goBack)
  const removeMutation = useRemoveProductTemplate(goBack)

  const [openRemoveDialog, setOpenRemoveDialog] = useState<boolean>(false)
  const [openGoBackDialog, setOpenGoBackDialog] = useState<boolean>(false)

  const { isFetching, data, isError } = useGetProductTemplateDetails(props.isEditProduct ? uuid : null, goBack)

  const handleSubmit = (data: ProductFormData, formikHelpers: FormikHelpers<ProductFormData>) => {
    if (props.isEditProduct) {
      editMutation.mutate({
        uuid: uuid!,
        data: data,
        onTagNotFoundError: (uuids: string[]) => {
          formikHelpers.setFieldValue('tags', data.tags.filter(e => !uuids.includes(e.uuid)))
        }
      })
    } else {
      addMutation.mutate({
        data: data,
        onTagNotFoundError: (uuids: string[]) => {
          formikHelpers.setFieldValue('tags', data.tags.filter(e => !uuids.includes(e.uuid)))
        }
      })
    }
  }

  return (
    <>
      {
        isError || isFetching
        ? <Grid sx={{height: 'calc(100vh - 160px)'}}>
          {
            isError
            ? <NetworkError
                withRefresh
                onRefresh={() => {
                  queryClient.resetQueries([queryNames.productDetails, uuid])
                }}
              />
            : <Loader 
                width={'100%'}
                text={texts('common:loading')}
              />
          }
          </Grid>
        : <Formik<ProductFormData>
            initialValues={data ?? defaultInitValue}
            enableReinitialize
            validateOnBlur={true}
            validateOnChange={true}
            onSubmit={handleSubmit}
            validationSchema={addProductValidation(texts)}
          >
            {() => (
              <Form>
                <Grid 
                  container 
                  direction={'column'}
                >
                  <Dialog
                    open={openRemoveDialog}
                    title={texts('dialogs:remove_title')}
                    description={texts('dialogs:remove_description')}
                    warning={texts('dialogs:product_remove_warning')}
                    closeButtonLabel={texts('common:cancel')}
                    confirmButtonLabel={texts('common:delete')}
                    onCloseButtonClick={() => {
                      setOpenRemoveDialog(false)
                    }}
                    onConfirmButtonClick={() => {
                      setOpenRemoveDialog(false)
                      removeMutation.mutate({ uuid: uuid! })
                      navigate('/menu/products', {replace: true})
                    }}
                  />
                  <Dialog
                    open={openGoBackDialog}
                    title={texts('dialogs:go_back_title')}
                    description={texts('dialogs:go_back_description')}
                    closeButtonLabel={texts('common:cancel')}
                    confirmButtonLabel={texts('common:exit')}
                    onCloseButtonClick={() => {
                      setOpenGoBackDialog(false)
                    }}
                    onConfirmButtonClick={() => {
                      setOpenGoBackDialog(false)
                      goBack()
                    }}
                  />
                  {
                    props.isEditProduct
                    ? <EditProductHeaderBar
                        loading={editMutation.isLoading}
                        onRemove={() => setOpenRemoveDialog(true)}
                        onBack={() => setOpenGoBackDialog(true)}
                      />
                    : <CreateProductHeaderBar
                        loading={addMutation.isLoading}
                        onBack={() => setOpenGoBackDialog(true)}
                      />
                  }
                  <Grid 
                    item 
                    sx={{marginTop: '1.25rem'}}
                  >
                    <ProductDetails/>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
      }
    </>
  )
}

export default ProductPage