import { Button, Grid, Typography } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import CreateAccessDialog from '../../components/Accounts/CreateAccessDialog/CreateAccessDialog'
import AccountsTable from '../../components/Administration/AccountsTable'
import SearchBar from '../../components/CustomMui/SearchBar'
import { AccountListSearchParams } from '../../types/accounts'

const Accounts = () => {
  const texts = useTranslation().t

  const [openCreateAccessDialog, setOpenCreateAccessDialog] = useState<boolean>(false)
  const [searchParams, setSearchParams] = useQueryParams<AccountListSearchParams>({ 
    search: withDefault(StringParam, ''),
    page: withDefault(NumberParam, 1), 
    size: withDefault(NumberParam, 24),
    order: withDefault(StringParam, 'joinDate:desc'),
  })
  
  return (
    <Grid 
      container
      flexDirection={'column'}
      gap={'2rem'}
      sx={{
        minHeight: 'calc(100vh - 130px)'
      }}
    >
      <CreateAccessDialog
        open={openCreateAccessDialog}
        onClose={() => setOpenCreateAccessDialog(false)}
        onSuccess={() => {
          setSearchParams({
            search: '',
            page: 1,
            order: 'joinDate:desc'
          }, 'replaceIn')
        }}
      />
      <Grid 
        item
        container
        justifyContent={'space-between'}
        alignItems={'center'}
      > 
        <Typography variant='h2'>
          {texts('accounts:title')}
        </Typography> 
        <Button
          variant={'contained'}
          size={'medium'}
          onClick={() => setOpenCreateAccessDialog(true)}
          sx={{
            width: 'fit-content',
            px: '2rem',
            whiteSpace: 'nowrap'
          }}
        >
          {texts('accounts:create_access')}
        </Button>
      </Grid>
      <Grid
        item
        sx={{
          maxWidth: '35rem'
        }}
      >
        <SearchBar
          initialValue={searchParams.search}
          onClick={(text) => setSearchParams({ search: text, page: 1 }, 'replaceIn')}
          placeholder={texts('accounts:search')}
        />
      </Grid>
      <AccountsTable />
    </Grid>
  )
}

export default Accounts
