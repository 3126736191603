import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import DataApi from '../../../api/data/DataApi'
import { mapBulkProductTemplateEntriesToDto } from '../../../api/data/mapping'
import { BulkProductTemplateEntry } from '../../../types/menu'
import { showErrorToast } from '../../../utils/error'
import { queryNames } from '../../queries'

const dataApi = DataApi.getInstance()

export default function useCreateBulkProductTemplates() {
  const queryClient = useQueryClient()
  const texts = useTranslation().t
  const mutation = useMutation((data: BulkProductTemplateEntry[]) => dataApi.createBulkProductTemplates(mapBulkProductTemplateEntriesToDto(data)), {
    onError: (e) => {
      showErrorToast(e, texts)
    },
    onSuccess: () => {
      queryClient.resetQueries(queryNames.productList)
      toast.success(texts('successes:bulk_product_added'))
    }
  })
  return mutation
}