import { Checkbox, Grid, Typography } from '@mui/material'
import Tag from '../../../Common/Tag'
import TimerIcon from '@mui/icons-material/Timer'
import { useTranslation } from 'react-i18next'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import { AdditionAvailability } from '../../../../types/menu'
import { mapSecondsToString } from '../../../../utils/mapping'
import Chip from '../../../Common/Chip'
import moment from 'moment'
import { evalStringTemplate } from '../../../../utils/text'
import currency from 'currency.js'

type Props = {
  addition: AdditionAvailability
  onCheckboxClick: (checked: boolean, uuid: string) => void
  checked: boolean
}


const AdditionItem = (props: Props) => {
  const texts = useTranslation().t

  return (
    <Grid
      container
      position={'relative'}
      borderRadius={'0.938rem'}
      boxShadow={'0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.5)'}
      gap={'20px'}
      padding={'16px 22px 16px 22px'}
      direction={'row'}
      wrap={'nowrap'}
      sx={{ 
        borderStyle: props.checked ? 'solid' : 'none',
        backgroundColor: props.checked ? 'rgba(148,193,31, 0.2)' : '#FFFFFF',
        borderColor: '#94C11F',
        borderWidth: 3
      }}
    >
      <Checkbox
        sx={{
          padding: 0,
          alignSelf: 'flex-start'
        }}
        checked={props.checked}
        onClick={() => props.onCheckboxClick(!props.checked, props.addition.uuid)}
      />
      
      <Grid
        container item
        gap={'16px'}
        wrap={'nowrap'}
        direction={'column'}
        sx={{
          opacity: props.addition.active ? 1 : 0.5 
        }}
      >
        <Grid
          item
          container
          gap={'0.5rem'}
          alignItems={'center'}
          direction={'row'}
        >
          <Typography 
            variant='h2'
            fontSize={'1.125rem'}
            noWrap
          >
            {props.addition.name}
          </Typography>
          <Tag
            text={`+${mapSecondsToString(props.addition.additionalTime)}`}
            opacity={props.addition.additionalTime === 0}
            margin={'0 0 0 26px'}
          >
            <TimerIcon/>
          </Tag>
          <Tag
            text={texts('common:currency', { localValue: currency(props.addition.additionalPrice, { fromCents: true }).value })}
            opacity={props.addition.additionalPrice === 0}
          >
            <LocalOfferIcon/>
          </Tag>
          {
            !props.addition.active &&
             <Chip
                text={
                  props.addition.inactiveUntil 
                    ? evalStringTemplate(texts('common:inactive_until'), { date: moment(props.addition.inactiveUntil).format('DD.MM.YYYY, HH:mm') })
                    : texts('common:inactive')
                }
                opacity={0.3}
              />  
          }
        </Grid>
        {
          props.addition.internalNote
          ? <Typography 
              variant='body2'
              sx={{
                textTransform: 'none',
                fontStyle: 'italic'
              }}
            >
              {`${texts('additions:note')}: ${props.addition.note}`}
            </Typography>
          : null
        }
      </Grid>
    </Grid>
  )
}

export default AdditionItem