import { Button, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { HiPlusCircle } from 'react-icons/hi'
import ProductsListRow from '../../../components/Menu/Product/ProductsListRow'
import SortIcon from '@mui/icons-material/Sort'
import { useEffect, useMemo, useState } from 'react'
import Pagination from '@mui/material/Pagination'
import { useNavigate } from 'react-router-dom'
import SearchBar from '../../../components/CustomMui/SearchBar'
import DropdownMenu, { DropdownOption } from '../../../components/CustomMui/DropdownMenu'
import useGetProductTemplateList from '../../../hooks/template/product/useGetProductTemplateList'
import Loader from '../../../components/Common/Loader'
import useCreateBulkProductTemplates from '../../../hooks/template/product/useCreateProductTemplatesFromCsv'
import NetworkError from '../../../components/Common/NetworkError'
import { UploadProductCsvDialog } from '../../../components/Menu/Product/UploadProductCsvDialog'
import NoDataPlaceholder from '../../../components/Common/NoDataPlaceholder'
import { DecodedValueMap, NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import { SearchOrderedListSearchParams } from '../../../types/menu'

const productsPerPage = Number.parseInt(process.env.REACT_APP_DEFAULT_PAGE_LIMIT!)

function isSearchParamsValid(params: DecodedValueMap<SearchOrderedListSearchParams>, sortOptions: DropdownOption[]): boolean {
  return Number.isInteger(params.page) // must be a number, not a NaN
    && params.page >= 1  // page cannot be lower than 1
    && sortOptions.some(option => option.value === params.order.toLowerCase()) // order must be from given list
}

const ProductList = () => {
  const texts = useTranslation().t
  const navigate = useNavigate()

  const sortOptions: DropdownOption[] = useMemo(() => {
    return texts('objects:products_sort_options', { returnObjects: true }) as DropdownOption[]
  }, [texts])

  const addFromCsvMutation = useCreateBulkProductTemplates()

  const [searchParams, setSearchParams] = useQueryParams<SearchOrderedListSearchParams>({ 
    page: withDefault(NumberParam, 1), 
    order: withDefault(StringParam, sortOptions[0].value),
    search: withDefault(StringParam, '')
  })

  const [showFromCsvDialog, setShowFromCsvDialog] = useState<boolean>(false)

  const { isFetching, data, isError, maxPage } = useGetProductTemplateList({
    limit: productsPerPage,
    offset: (searchParams.page - 1) * productsPerPage,
    sort: searchParams.order,
    search: searchParams.search,
    enabled: isSearchParamsValid(searchParams, sortOptions),
    onSuccess: (data) => {
      if (searchParams.page > 1 && data.data.length === 0) {
        setSearchParams({
          page: 1,
          order: sortOptions[0].value,
          search: ''
        }, 'replaceIn')
      }
    }
  })

  useEffect(() => {
    window.scrollTo(0,0)
  }, [searchParams.page])

  useEffect(() => {    
    if (!isSearchParamsValid(searchParams, sortOptions)) {
      setSearchParams({
        page: 1,
        order: sortOptions[0].value,
        search: ''
      }, 'replaceIn')
      return
    }

    if (searchParams.page === 1) {
      setSearchParams({
        page: 1
      }, 'replaceIn')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.page, searchParams.order, sortOptions])

  if (!isSearchParamsValid(searchParams, sortOptions) || (!isFetching && searchParams.page > 1 && data!.data.length === 0)) {
    return <></>
  }

  return (
    <>
      <UploadProductCsvDialog
        open={showFromCsvDialog}
        loading={addFromCsvMutation.isLoading}
        onCloseButtonClick={() => { setShowFromCsvDialog(false) }}
        onConfirmButtonClick={(data) => {
          addFromCsvMutation.mutate(data)
          setShowFromCsvDialog(false)
        }}
      />
      <Grid 
        container
        flexDirection={'column'}
        sx={{
          minHeight: 'calc(100vh - 130px)'
        }}
      >
        <Grid
          item
          container 
          wrap='nowrap'
          justifyContent={'space-between'} 
          alignItems={'center'}
        >
          <Grid item> 
            <Typography variant='h2' noWrap>
              {texts('menu:product_list_title')}
            </Typography> 
          </Grid>
          <Grid 
            item 
            container 
            justifyContent={'flex-end'}
          >
            <Grid item>
              <Button
                variant={'contained'}
                size={'medium'}
                component={'span'}
                onClick={() => { setShowFromCsvDialog(true) }}
                sx={{
                  gap: '0.5rem', 
                  width: 'max-content',
                  px: '1rem'
                }}
              >
                <HiPlusCircle size={25}/>
                {texts('menu:read_products_from_file')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant={'contained'}
                size={'medium'}
                sx={{
                  marginLeft: '0.625rem', 
                  gap: '0.5rem', 
                  width: 'max-content',
                  px: '1rem'
                }}
                onClick={() => navigate('/menu/products/add')}
              >
                <HiPlusCircle size={25}/>
                  {texts('menu:add_product')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item 
          container
          justifyContent={'space-between'}
          wrap={'nowrap'}
          marginTop={'1.5rem'}
          marginBottom={'1rem'}
        >
          <Grid 
            item
            sx={{
              minWidth: '30rem',
              maxWidth: '40rem' 
            }}
          >
            <SearchBar
              initialValue={searchParams.search}
              onClick={(text) => setSearchParams({ search: text, page: 1 }, 'replaceIn')}
              placeholder={texts('menu:search_product')}
            />
          </Grid>
          <Grid
            item
            container
            alignItems={'center'}
            maxWidth={'fit-content'}
            gap={'0.875rem'}
            wrap={'nowrap'}
          >
            <SortIcon/>
            <Typography 
              variant='body2'
              fontWeight={'bold'}
            >
              {texts('common:sort')}
            </Typography>
            <Grid
              item
              sx={{minWidth: '12rem'}}
            >
              <DropdownMenu
                value={searchParams.order.toLowerCase()}
                onChange={(event) => {console.log('WAT'); setSearchParams({ order: event.target.value, page: 1 }, 'replaceIn')}}
                options={sortOptions}
              />
            </Grid>
          </Grid>
        </Grid>
        {
          isError || isFetching
          ? <Grid 
              container 
              item
              flexGrow={1} 
              justifyContent={'center'} 
              alignItems={'center'}
            >
              {
                isError
                ? <NetworkError />
                : <Loader 
                    width={'100%'}
                    text={texts('common:loading')}
                  />
              }
            </Grid>
          : <>
              {
                data == null || data.data.length === 0 
                ? <NoDataPlaceholder />
                : <>
                    {
                      data?.data.map((product) => (
                        <ProductsListRow 
                          key={product.uuid}
                          product={product}
                        />
                      ))
                    }
                  </>
              }
              {
                maxPage > 1
                ? <Grid
                    item
                    container
                    justifyContent={'center'}
                    mt={'1.5rem'}
                  >
                    <Pagination 
                      page={searchParams.page}
                      onChange={(e, page) => setSearchParams({ page: page }, 'replaceIn')}
                      count={maxPage} 
                    />
                  </Grid>
                : null
              }
            </>
        }
      </Grid>
    </>
  )
}

export default ProductList