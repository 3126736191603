import { DialogActions, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import LoadingButton from '../../CustomMui/LoadingButton'

type Props = {
  loading: boolean
  onConfirm: () => void
}

const FirstStep = (props: Props) => {
  const texts = useTranslation().t

  return (
    <>
      <Grid
        container
        flexDirection={'column'}
        sx={{width: '45rem'}}
        gap={'1rem'}
        mt={'1rem'}
      >
        <Typography
          variant={'subtitle2'}
          sx={{mt: '1rem'}}
        >
          {texts('accounts:confirm')}
        </Typography>
        <Typography
          variant={'body2'}
          sx={{
            textTransform: 'none',
          }}
        >
          {texts('accounts:change_pin_warning')}
        </Typography>
      </Grid>
      <DialogActions sx={{py: '1.25rem', px: 0}}>
        <LoadingButton
          variant={'contained'}
          size={'medium'}
          sx={{mt: '1rem'}}
          onClick={props.onConfirm}
          loading={props.loading}
        >
          {texts('common:confirm')}
        </LoadingButton>
      </DialogActions>
    </>
  )
}

export default FirstStep