import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import DataApi from '../../api/data/DataApi'
import { FileList } from '../../types/files'
import { FileListOptions } from '../../types/menu'
import { showErrorToast } from '../../utils/error'
import { mapFileListDto } from '../../utils/mapping'
import { queryNames } from '../queries'

const dataApi = DataApi.getInstance()

type Options = {
  enabled?: boolean
  onSuccess?: (data: FileList) => void
}

export default function useGetFileList(options: FileListOptions & Options) {
  const texts = useTranslation().t
  const key = [queryNames.getFileList, { 
      search: options.search,
      limit: options.limit,
      offset: options.offset,
    }
  ]
  const query = useQuery(key, async () => {
    return mapFileListDto(await dataApi.getFileList(options))
  }, {
    staleTime: Infinity,
    refetchOnMount: 'always',
    enabled: options.enabled,
    onSuccess: options.onSuccess,
    onError: (error) => {
      showErrorToast(error, texts)
    }
  })
  return {
    ...query,
    maxPage: options?.limit != null ? Math.ceil((query.data?.count ?? 0) / options.limit) : 1
  }
}

