import LoadingButton from '@mui/lab/LoadingButton'
import { Button, Dialog, DialogActions, DialogContent, Input, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useCallback, useState, useEffect } from 'react'
import Dropzone from 'react-dropzone'
import Cropper from 'react-easy-crop'
import { Area } from 'react-easy-crop/types'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import useUploadPhoto from '../../hooks/photo/useUploadPhoto'
import { UploadedPhotoResponse, AllowedImageTypes } from '../../types/common'
import { readFileAsDataUrl } from '../../utils/file'
import { getCroppedImg, getImageFileDimensions } from './utils'

const GREY = '#CCC'
const GREY_DIM = '#DDD'

type FileObject = {
  src: any
  file: File
  name: string
  type: string
}

type Props = {
  open: boolean
  aspectRatio: number
  minDimensions: { width: number; height: number }
  croppedDimensions: { width: number; height: number }
  thumbnailWidth: number
  onClose: () => void
  onSuccess: (data: UploadedPhotoResponse) => void
}

const ImageUploadDialog = (props: Props) => {
  const texts = useTranslation().t
  const uploadMutation = useUploadPhoto(!props.open)

  const [image, setImage] = useState<FileObject | null>(null)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null)
  const [zoneHover, setZoneHover] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const resetStates = () => {
    setErrorMessage(null)
    setImage(null)
    setZoom(1)
    setCrop({ x: 0, y: 0 })
    setCroppedAreaPixels(null)
    setZoneHover(false)
  }

  useEffect(() => {
    resetStates()
  }, [props.open])

  const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const createCroppedImage = useCallback(async () => {
    try {
      const croppedImage: Blob | null = await getCroppedImg(
        image!.src,
        croppedAreaPixels as Area,
        { horizontal: false, vertical: false},
        { width: props.croppedDimensions.width, height: props.croppedDimensions.height }
      )
      if (croppedImage) {
        const file = new File([croppedImage], image!.name, { type: image!.type })
        uploadMutation.mutate({
          file: file,
          thumbnailWidth: props.thumbnailWidth
        }, {
          onSuccess: (data) => {
            props.onSuccess(data)
            props.onClose()
          }
        })
      }
      setImage(null)
    } catch (e) {
      toast.error(texts('errors:image_crop_failed'))
      console.error(e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [croppedAreaPixels, image, props, texts])

  return (
    <Dialog 
      open={props.open}
      sx={{width: '100%'}}
    >
      <DialogContent
        sx={{ 
          position: 'relative',
          width: '50rem', height: '37.5rem' ,
          overflow: 'hidden'
        }}
      >
        {
          image != null
          ? <Cropper
              image={image.src}
              zoom={zoom}
              crop={crop}
              aspect={props.aspectRatio}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
              zoomSpeed={0.1}
            />
          : <Dropzone
              maxFiles={1}
              onDropAccepted={async (acceptedFiles) => {
                const dim = await getImageFileDimensions(acceptedFiles[0])
                if (dim.height < props.minDimensions.height || dim.width < props.minDimensions.width) {
                  setErrorMessage(texts('imageUpload:image_too_small'))
                  return
                }
                setErrorMessage(null)
                setImage({
                  file: acceptedFiles[0],
                  name: acceptedFiles[0].name,
                  src: await readFileAsDataUrl(acceptedFiles[0]),
                  type: acceptedFiles[0].type
                })
                setZoneHover(false)
              }} 
              onDropRejected={(fileRejections) => {
                if (fileRejections[0].errors[0].code === 'too-many-files') {
                  setErrorMessage(texts('imageUpload:too_many_files'))
                } else if (fileRejections[0].errors[0].code === 'file-invalid-type') {
                  setErrorMessage(texts('imageUpload:invalid_file_format'))
                } else if (fileRejections[0].errors[0].code === 'file-too-large') {
                  setErrorMessage(texts('imageUpload:file_too_large'))
                } else {
                  setErrorMessage(texts('imageUpload:file_error'))
                }
                setZoneHover(false)
              }} 
              accept={AllowedImageTypes}
              multiple={false}
              maxSize={parseInt(process.env.REACT_APP_MAX_IMAGE_SIZE!)}
              onDragOver={() => setZoneHover(true)}
              onDragLeave={() => setZoneHover(false)}
            >
              {({getRootProps, getInputProps}) => (
                <>
                  <Box
                    sx={{
                      fontFamily: 'RobotoRegular',
                      alignItems: 'center',
                      width: '100%',
                      backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='rgba(0,0,0,0.23)' stroke-width='2' stroke-dasharray='15%2c20' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
                      borderRadius: '0.625rem',
                      borderColor: zoneHover ? GREY_DIM : GREY,
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                      justifyContent: 'center',
                      py: '0.5rem'
                    }}
                    {...getRootProps()}
                  >
                    <Input 
                      inputProps={getInputProps()} 
                      sx={{ display: 'none' }}
                      type={'file'}
                    />
                    {
                      <Typography
                        sx={{
                          ':hover': {cursor: 'pointer'}
                        }}
                      >
                        {texts('imageUpload:report_file_placeholder')}
                      </Typography>
                    }
                  </Box>
                  {
                    errorMessage
                    ? <Typography
                        sx={{
                          fontFamily: 'RobotoCondensed',
                          fontSize: '0.75rem',
                          margin: '0.125rem 0 0 0.875rem',
                          color: 'error.main'
                        }}
                      >
                        {errorMessage}
                      </Typography>
                    : null
                  }
                </>
              )}
            </Dropzone>
        }
        
      </DialogContent>
      <DialogActions>
        <Button
          variant={'outlined'}
          size={'medium'}
          onClick={() => {
            setImage(null)
            props.onClose()
          }}
          sx={{marginTop: '0.938rem'}}
        >
          {texts('imageUpload:cancel')}
        </Button>
        {
          image != null
          ? <LoadingButton
              variant={'contained'}
              size={'medium'}
              onClick={createCroppedImage}
              sx={{marginTop: '0.938rem'}}
              loading={uploadMutation.isLoading}
            >
              {texts('imageUpload:upload')}
            </LoadingButton>
          : null
        }
        
      </DialogActions>
    </Dialog>
  )
}

export default ImageUploadDialog