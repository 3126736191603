import { Grid } from '@mui/material'
import Box from '@mui/material/Box'
import React from 'react'
import placeholderImage from '../../../assets/images/placeholderProduct.webp'
import { Product } from '../../../types/menu'

type Props = {
  product: Product
  onClick?: () => void
}

const ProductsListRowPhoto = (props: Props) => {
  return (
    <Grid 
      item 
      width={'7.188rem'}
      height={'7.188rem'}
      flexShrink={0}
      {...(
        props.onClick != null
        ? {
            sx: {':hover': {cursor: 'pointer'}},
            onClick: props.onClick
          }
        : {}
        )
      }
    >
      <Box
        component={'img'}
        alt='logo'
        flexShrink={0}
        src={props.product.photo?.thumbnail ?? props.product.photo?.original ?? placeholderImage}
        sx={{
          display: 'block',
          minWidth: '100%',
          minHeight: '100%',
          maxWidth: '100%',
          maxHeight: '100%'
        }}
      />
    </Grid>
  )
}

export default ProductsListRowPhoto