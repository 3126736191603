import { Box,  Grid, Typography } from '@mui/material'
import { useFormikContext } from 'formik'
import L, { LatLng } from 'leaflet'
import { useTranslation } from 'react-i18next'
import { AiFillInfoCircle } from 'react-icons/ai'
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet'
import { Coordinates } from '../../../types/place'
import TextField from '../../CustomMui/Fields/TextField'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import useGetCurrentLocation from '../../../hooks/location/useGetCurrentLocation'

const MapTile = () => {
  const texts = useTranslation().t
  const [map, setMap] = useState<L.Map | null>(null)

  const { uuid } = useParams<{ uuid: string }>()

  const { values, setFieldValue, setFieldTouched, touched } = useFormikContext<{coordinates: Coordinates}>()

  useGetCurrentLocation(uuid != null, (coords) => {
    if (uuid == null && !touched.coordinates?.lat && !touched.coordinates?.lng) {
      setFieldValue('coordinates', {
        lng: coords.longitude,
        lat: coords.latitude
      })
      setFieldTouched('coordinates.lat', true)
      setFieldTouched('coordinates.lng', true)
    }
  })
  
  useEffect(() => {
    if (Number.isNaN(+values.coordinates.lat)  || Number.isNaN(+values.coordinates.lng)) {
      return
    }
    map?.panTo({
      lat: values.coordinates.lat, 
      lng: values.coordinates.lng
    })
  }, [map, values.coordinates])

  function MapHandleComponent() {
    useMapEvents({
      click: (e: {latlng: LatLng}) => {
        setFieldValue('coordinates', {
          lng: e.latlng.lng,
          lat: e.latlng.lat
        })
        setFieldTouched('coordinates.lat', true)
        setFieldTouched('coordinates.lng', true)
      },
    })
    return null
  }

  return (
    <Grid container direction={'column'}>
      <Typography variant='subtitle2'>{texts('place:map_title_label')}</Typography>
      <Grid
        item
        container
        wrap='nowrap'
        justifyContent={'flex-end'}
        gap={'0.5rem'}
        mb={'1rem'}
      >
        <Box sx={{maxWidth: '12rem'}}>
          <TextField
            name='coordinates.lat'
            label={texts('place:lat_coordinate')}
          />
        </Box>
        <Box sx={{maxWidth: '12rem'}}>
          <TextField
            name='coordinates.lng'
            label={texts('place:long_coordinate')}
          />
        </Box>
      </Grid>
      <MapContainer 
        center={[
          Number.isNaN(+values.coordinates.lat) ? 0 : values.coordinates.lat, 
          Number.isNaN(+values.coordinates.lng) ? 0 : values.coordinates.lng,
        ]} 
        scrollWheelZoom={false}
        zoom={13}
        ref={(map: L.Map | null) => {
          if (map) {
            setMap(map)
          }
        }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker 
          position={[
            Number.isNaN(+values.coordinates.lat) ? 0 : values.coordinates.lat, 
            Number.isNaN(+values.coordinates.lng) ? 0 : values.coordinates.lng,
          ]}
          icon={new L.Icon({
            iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-black.png',
            shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
            shadowSize: [41, 41]
          })}
        />
        <MapHandleComponent/>
      </MapContainer>
      <Grid 
        item
        container
        gap={'0.625rem'}
        mt={'1rem'}
        wrap={'nowrap'}
      >
        <Grid item flexShrink={0}>
          <AiFillInfoCircle 
            size={20} 
            color={'#fa6400'}
          />
        </Grid>
        <Typography variant='body1'>{texts('place:map_info_label')}</Typography>
      </Grid>
    </Grid>
  )
}

export default MapTile