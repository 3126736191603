import moment from 'moment-timezone'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import DataApi from '../../api/data/DataApi'
import { showErrorToast } from '../../utils/error'
import { queryNames } from '../queries'
import { DataSubcodes, Page, Codes } from '../../services/errors/consts'
import { AxiosError } from 'axios'
import { HttpError } from '../../types/common'

const dataApi = DataApi.getInstance()

type MutationParams = {
  placeUuid: string
  templateUuids: string[]
  deactivateUntil?: Date | null
}

const errorRefreshSubcodes = [
  DataSubcodes.PRODUCT_NOT_FOUND,
  DataSubcodes.PRODUCT_TEMPLATE_NOT_FOUND
]

export default function useDeactivateProducts(onSuccess: () => void) {
  const queryClient = useQueryClient()
  const texts = useTranslation().t
  const mutation = useMutation((params: MutationParams) => dataApi.deactivateProducts(params.placeUuid, {
    templateUuids: params.templateUuids,
    deactivateUntil: params.deactivateUntil != null ? moment(params.deactivateUntil).format() : null
  }), {
    onError: (e: AxiosError<HttpError>) => {
      if (e.response?.status === 404 && e.response?.data.code === Codes.DATA && errorRefreshSubcodes.includes(e.response?.data.subcode)) {
        queryClient.resetQueries(queryNames.productAvailabilityList)
      }
      showErrorToast(e, texts, Page.AVAILABILITYT_MANAGEMENT)
    },
    onSuccess: () => {
      queryClient.resetQueries(queryNames.placeMenu)
      queryClient.resetQueries(queryNames.productAvailabilityList)
      toast.success(texts('successes:products_deactivated_successfully'))
      onSuccess()
    }
  })
  return mutation
}