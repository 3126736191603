import { TableRow, TableCell } from '@mui/material'
import Loader from './Loader'
import { useTranslation } from 'react-i18next'

type Props = {
  colSpan: number
}

const LoadingTableRow = (props: Props) => {
  const texts = useTranslation().t
  return (
    <TableRow key={'no-data-row'}>
      <TableCell 
        key={'no-data-cell'}
        colSpan={props.colSpan}
        sx={{
          fontSize: '0.875rem',
          textAlign: 'center'
        }}
      > 
        <Loader 
          width={'100%'}
          text={texts('common:loading')}
        />
      </TableCell>
    </TableRow>
  )
}

export default LoadingTableRow