import { TextField as MuiTextField, TextFieldProps } from '@mui/material'
import { forwardRef, useState } from 'react'
import NumberFormat from 'react-number-format'
import { useField } from 'formik'

type CustomProps = {
  onChange: (event: { target: { name: string, value: string } }) => void
  onBlur?: React.FocusEventHandler<Element>
  onFocus?: React.FocusEventHandler<HTMLInputElement>
  value?: string | number
  name: string
  integerOnly?: boolean
}

const NumberFormatCustom = forwardRef<NumberFormat<string>, CustomProps>(
  function NumberFormatCustom(props, ref) {
    const [isFocus, setIsFocus] = useState<boolean>(true)
    const { onChange, integerOnly, ...other } = props
    return (
      <NumberFormat
        {...other}
        fixedDecimalScale={isFocus && other.value != null && other.value !== ''}
        allowEmptyFormatting
        onBlur={(ev: any) => {
          setIsFocus(true)
          other.onBlur?.(ev)
        }}
        onFocus={(ev: any) => {
          setIsFocus(false)
          other.onFocus?.(ev)
        }}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          })
        }}
        {
          ...integerOnly
          ? {
              decimalScale: 0
            }
          : {
              decimalSeparator: ',',
              decimalScale: 2
            }
        }
        isNumericString
        allowNegative={false}
      />
    )
  },
)

type Props = {
  name: string
  integerOnly?: boolean
  noDefaultHelperText?: boolean
  shrinkLabel?: boolean
} & TextFieldProps

const NumericTextField = (props: Props) => {
  const [field, meta] = useField(props.name!)
  const { noDefaultHelperText, integerOnly, ...textFieldProps } = props

  const configTextField: TextFieldProps = {
    ...field,
    ...textFieldProps,
    fullWidth: true,
    variant: 'outlined',
    InputProps: {inputComponent: NumberFormatCustom as any, inputProps: { integerOnly: integerOnly }},
    ...(props.shrinkLabel != null ? { InputLabelProps: { shrink: props.shrinkLabel } } : {})
  }

  if (props.name && meta && meta.touched && meta.error) {
    configTextField.error = true
    configTextField.helperText = meta.error
  } else if (!noDefaultHelperText) {
    configTextField.helperText = ' '
  }

  return (
    <MuiTextField 
      {...configTextField}
      sx={{
        marginBottom: '1rem'
      }}
    /> 
  )
}

export default NumericTextField
