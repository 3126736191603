import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import DataApi from '../../api/data/DataApi'
import { BannerDto, BannerRequestDto } from '../../types/banner'
import { Success } from '../../types/common'
import { showErrorToast } from '../../utils/error'
import { mapBannerList } from '../../utils/mapping'
import { queryNames } from '../queries'

const dataApi = DataApi.getInstance()

type MutationParams = {
  data: BannerRequestDto[]
}

export default function usePutBanners() {
  const queryClient = useQueryClient()
  const texts = useTranslation().t

  const mutation = useMutation((params: MutationParams) => dataApi.putBanners(params.data), {
  onError: (e) => {
    showErrorToast(e, texts)
  },
  onSuccess: (data: Success<BannerDto[]>) => {
    queryClient.setQueryData(queryNames.bannerList, mapBannerList(data.data!))
    toast.success(texts('successes:banners_updated_successfully'))
  }
})
return mutation
}

