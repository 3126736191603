import moment from 'moment-timezone'
import { CreateLoyaltyCardDto, EditLoyaltyCardDto, FileListDto, LoyaltyCardDto, LoyaltyCardListDto, TimeBufferDto, UserSearchDto } from '../api/data/types'
import { BannerDto } from '../types/banner'
import { AutocompleteBaseOption, BannerFormData } from '../types/common'
import {
  Addition,
  AdditionFormData,
  AdditionList,
  AdditionTemplateDto,
  AdditionTemplateListDto,
  ChangeState,
  MenuCategory,
  MenuProduct,
  Product,
  ProductFormData,
  ProductList,
  ProductTemplateDto,
  ProductTemplateListDto,
  TagDto,
  TagFormData,
  Variant,
  VariantFormData,
  VariantList,
  VariantOption,
  VariantTemplateDto,
  VariantTemplateListDto,
  VariantTemplateOptionDto,
} from '../types/menu'
import {
  AdditionAvailabilityListDto,
  AllowedPickupTypes,
  ChangeState as PlaceChangeState,
  DayOpeningHoursDto,
  ExtraTimeBufferForRushHours,
  ExtraTimeBufferForRushHoursDto,
  MenuCategoryDto,
  MenuRequestVariantOptionDto,
  OpeningHourDetailsExceptionDto,
  OpeningHourException,
  OpeningHoursDto,
  PlaceDetailsDto,
  PlaceFormData,
} from '../types/place'
import { convertMomentTimezoneCustomFormat } from './time'
import { MenuProductDto, MenuAdditionDto, MenuVariantDto, MenuVariantOptionDto, MenuRequestAdditionDto, MenuRequestVariantDto, MenuRequestProductDto, MenuRequestCategoryBodyDto, AdditionAvailabilityEntryDto, VariantAvailabilityListDto, VariantAvailabilityEntryDto, VariantOptionAvailabilityEntryDto, ProductAvailabilityEntryDto, ProductAvailabilityListDto } from '../types/place'
import { MenuAddition, MenuVariantOption, MenuVariant, ProductAvailabilityList, ProductAvailability, AdditionAvailability, AdditionAvailabilityList, VariantAvailabilityList, VariantAvailability, VariantOptionAvailability } from '../types/menu'
import { AccountListRowDto, AccountDataRow, AccountListData, AccountListDto, CmsUserDetailsDto, EmployeeDetailsDto, Role } from '../types/accounts'
import currency from 'currency.js'
import { DEFAULT_START_TIME, DEFAULT_END_TIME } from './constants'
import { OrderDetails, OrderDetailsDto, OrderList, OrderListDto } from '../types/order'
import { DiscountCode, DiscountCodeFormData, DiscountCodeList, User } from '../types/discounts'
import { FileList } from '../types/files'

export function mapPlaceDetailsToForm(data: PlaceDetailsDto): PlaceFormData {
  return {
    name: data.name,
    street: data.street,
    postalCode: data.postalCode,
    phone: data.phone ?? '',
    email: data.email ?? '',
    town: data.town,
    photoUuid: data.photo?.uuid ?? null,
    photoUrl: data.photo?.original ?? null,
    coordinates: {
      lng: data.coordinates.coordinates[0],
      lat: data.coordinates.coordinates[1]
    },
    openingHours: mapOpeningHoursToForm(data.settings.openingHours),
    openingHoursExceptions: data.settings.openingHoursException
      .map((e: OpeningHourDetailsExceptionDto, idx: number) => mapOpeningHourException(e, idx))
      .sort((a: OpeningHourException, b: OpeningHourException) => {
        const dateA = moment(`${moment(a.date).format('YYYY-MM-DD')} ${a.open ? a.start : '00:00:00'}`, 'YYYY-MM-DD HH:mm:ss')
        const dateB = moment(`${moment(b.date).format('YYYY-MM-DD')} ${b.open ? b.start : '00:00:00'}`, 'YYYY-MM-DD HH:mm:ss')
        return dateA.isAfter(dateB) ? 1 : dateA.isBefore(dateB) ? -1 : 0
      }),
    pickupTypes: {
      takeAway: data.pickupMethods.includes(AllowedPickupTypes.TAKEAWAY),
      onSite: data.pickupMethods.includes(AllowedPickupTypes.ON_SITE),
      delivery: data.pickupMethods.includes(AllowedPickupTypes.DELIVERY)
    },
    extraTimeBufferForItemsInCartAmount: data.settings.extraTimeBufferForItemsInCartAmount != null ? data.settings.extraTimeBufferForItemsInCartAmount?.value * 100 : 0,
    extraTimeBufferForRushHours: mapExtraTimeBufferForRushHoursToForm(data.settings.extraTimeBufferForRushHours),
    payuCredentials: data.payuCredentials,
    // timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    timezone: process.env.REACT_APP_TZ!, // TODO! obsluzyc dobrze strefy czasowe (do ustalenia podejscie)
    active: data.active
  }
}

export function mapOpeningHourException (data: OpeningHourDetailsExceptionDto, idx: number): OpeningHourException {
  return {
    date: moment(data.from).toDate(),
    open: data.open,
    // start: moment(data.from).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('HH:mm'),
    // end: moment(data.to).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('HH:mm'),
    start: moment(data.from).tz(process.env.REACT_APP_TZ!).format('HH:mm'), // TODO! obsluzyc dobrze strefy czasowe (do ustalenia podejscie)
    end: moment(data.to).tz(process.env.REACT_APP_TZ!).format('HH:mm'), // TODO! obsluzyc dobrze strefy czasowe (do ustalenia podejscie)
    state: PlaceChangeState.SAVED,
    key: `opening-hour-exception-${idx}`
  }
}

export function mapExtraTimeBufferForRushHoursToForm(data?: ExtraTimeBufferForRushHoursDto | null): ExtraTimeBufferForRushHours[] {
  return [
    ...(data?.monday != null ? data.monday?.map((e: any, idx: number) => mapSingleExtraTimeBufferForRushHourToForm(e, 0, data.tz, idx)) : []),
    ...(data?.tuesday != null ? data.tuesday?.map((e: any, idx: number) => mapSingleExtraTimeBufferForRushHourToForm(e, 1, data.tz, idx)) : []),
    ...(data?.wednesday != null ? data.wednesday?.map((e: any, idx: number) => mapSingleExtraTimeBufferForRushHourToForm(e, 2, data.tz, idx)) : []),
    ...(data?.thursday != null ? data.thursday?.map((e: any, idx: number) => mapSingleExtraTimeBufferForRushHourToForm(e, 3, data.tz, idx)) : []),
    ...(data?.friday != null ? data.friday?.map((e: any, idx: number) => mapSingleExtraTimeBufferForRushHourToForm(e, 4, data.tz, idx)) : []),
    ...(data?.saturday != null ? data.saturday?.map((e: any, idx: number) => mapSingleExtraTimeBufferForRushHourToForm(e, 5, data.tz, idx)) : []),
    ...(data?.sunday != null ? data.sunday?.map((e: any, idx: number) => mapSingleExtraTimeBufferForRushHourToForm(e, 6, data.tz, idx)) : [])
  ]
}

export function mapSingleExtraTimeBufferForRushHourToForm(data: TimeBufferDto, day: number, tz: string, idx: number) {
  return {
    day: day,
    value: data.value * 100,
    // start: convertMomentTimezoneCustomFormat(data.start, 'HH:mm', tz, Intl.DateTimeFormat().resolvedOptions().timeZone).format('HH:mm'),
    // end: convertMomentTimezoneCustomFormat(data.end, 'HH:mm', tz, Intl.DateTimeFormat().resolvedOptions().timeZone).format('HH:mm'),
    start: convertMomentTimezoneCustomFormat(data.start, 'HH:mm', tz, process.env.REACT_APP_TZ!).format('HH:mm'), // TODO! obsluzyc dobrze strefy czasowe (do ustalenia podejscie)
    end: convertMomentTimezoneCustomFormat(data.end, 'HH:mm', tz, process.env.REACT_APP_TZ!).format('HH:mm'), // TODO! obsluzyc dobrze strefy czasowe (do ustalenia podejscie)
    state: PlaceChangeState.SAVED,
    key: `rush-hour-key-${day}-${idx}`
  }
}

export function mapOpeningHoursToForm(data: OpeningHoursDto) {
  return [
    mapDayOpeningHourToForm(data.monday, data.tz),
    mapDayOpeningHourToForm(data.tuesday, data.tz),
    mapDayOpeningHourToForm(data.wednesday, data.tz),
    mapDayOpeningHourToForm(data.thursday, data.tz),
    mapDayOpeningHourToForm(data.friday, data.tz),
    mapDayOpeningHourToForm(data.saturday, data.tz),
    mapDayOpeningHourToForm(data.sunday, data.tz)
  ]
}

export function mapDayOpeningHourToForm(data: DayOpeningHoursDto, tz: string) {
  if (data.open) {
    return {
      open: data.open,
      // start: convertMomentTimezoneCustomFormat(data.start!, 'HH:mm', tz, Intl.DateTimeFormat().resolvedOptions().timeZone).format('HH:mm'),
      // end: convertMomentTimezoneCustomFormat(data.end!, 'HH:mm', tz, Intl.DateTimeFormat().resolvedOptions().timeZone).format('HH:mm')
      start: convertMomentTimezoneCustomFormat(data.start!, 'HH:mm', tz, process.env.REACT_APP_TZ!).format('HH:mm'), // TODO! obsluzyc dobrze strefy czasowe (do ustalenia podejscie)
      end: convertMomentTimezoneCustomFormat(data.end!, 'HH:mm', tz, process.env.REACT_APP_TZ!).format('HH:mm') // TODO! obsluzyc dobrze strefy czasowe (do ustalenia podejscie)
    }
  }
  return {
    open: false,
    start: DEFAULT_START_TIME,
    end: DEFAULT_END_TIME,
  }
}

export function mapAdditionTemplatesListDto(data: AdditionTemplateListDto): AdditionList {
  return {
    count: data.count,
    data: data.data.map(mapAdditionTemplatesDetailsDto)
  }
}

export function mapAdditionTemplatesDetailsDto(data: AdditionTemplateDto): Addition {
  return {
    uuid: data.uuid,
    name: data.name,
    note: data.internalNote,
    additionalPrice: data.price,
    additionalTime: data.preparationTime
  }
}

export function mapAdditionAvailabilityListDto(data: AdditionAvailabilityListDto): AdditionAvailabilityList {
  return {
    count: data.count,
    data: data.data.map(mapAdditionAvailabilityEntryDto)
  }
}

export function mapAdditionAvailabilityEntryDto(data: AdditionAvailabilityEntryDto): AdditionAvailability {
  return {
    uuid: data.templateUuid,
    name: data.name,
    note: data.internalNote,
    additionalPrice: data.price,
    additionalTime: data.preparationTime,
    internalNote: data.internalNote,
    active: data.active,
    inactiveUntil: data.inactiveUntil != null ? moment(data.inactiveUntil).toDate() : null
  }
}

export function mapVariantTemplatesListDto(data: VariantTemplateListDto): VariantList {
  return {
    count: data.count,
    data: data.data.map(mapVariantTemplateDetailsDto)
  }
}

export function mapVariantTemplateDetailsDto(data: VariantTemplateDto): Variant {
  return {
    uuid: data.uuid,
    name: data.name,
    note: data.internalNote,
    options: data.options.map(mapVariantTemplateOptionDetailsDto),
  }
}

export function mapVariantTemplateOptionDetailsDto(data: VariantTemplateOptionDto): VariantOption {
  return {
    id: data.uuid!,
    name: data.name,
    uuid: data.uuid,
    additionalPrice: data.price,
    defaultOption: data.default ?? false,
    additionalTime: data.preparationTime,
    active: data.active,
    state: ChangeState.SAVED
  }
}

export function mapVariantAvailabilityListDto(data: VariantAvailabilityListDto): VariantAvailabilityList {
  return {
    count: data.count,
    data: data.data.map(mapVariantAvailabilityEntryDto)
  }
}

export function mapVariantAvailabilityEntryDto(data: VariantAvailabilityEntryDto): VariantAvailability {
  return {
    uuid: data.templateUuid,
    name: data.name,
    note: data.internalNote,
    options: data.options.map(mapVariantTemplateOptionAvailabilityEntryDto),
  }
}

export function mapVariantTemplateOptionAvailabilityEntryDto(data: VariantOptionAvailabilityEntryDto): VariantOptionAvailability {
  return {
    id: data.templateUuid!,
    name: data.name,
    uuid: data.templateUuid,
    additionalPrice: data.price,
    defaultOption: data.default ?? false,
    additionalTime: data.preparationTime,
    state: ChangeState.SAVED,
    active: data.active,
    inactiveUntil: data.inactiveUntil != null ? moment(data.inactiveUntil).toDate() : null
  }
}

export function mapProductTemplatesListDto(data: ProductTemplateListDto): ProductList {
  return {
    count: data.count,
    data: data.data.map(mapProductTemplateDetailsDto)
  }
}

export function mapProductTemplateDetailsDto(data: ProductTemplateDto): Product {
  return {
    uuid: data.uuid,
    name: data.name,
    note: data.internalNote ?? '',
    basePrice: data.price,
    baseTime: data.preparationTime,
    photo: data.photo ?? null,
    tags: data.tags,
    description: data.description
  }
}

export function mapProductAvailabilityListDto(data: ProductAvailabilityListDto): ProductAvailabilityList {
  return {
    count: data.count,
    data: data.data.map(mapProductAvailabilityEntryDto)
  }
}

export function mapProductAvailabilityEntryDto(data: ProductAvailabilityEntryDto): ProductAvailability {
  return {
    uuid: data.templateUuid,
    name: data.name,
    note: data.internalNote ?? '',
    basePrice: data.price,
    baseTime: data.preparationTime,
    photo: data.photo ?? null,
    tags: data.tags,
    internalNote: data.internalNote,
    description: data.description,
    active: data.active,
    inactiveUntil: data.inactiveUntil != null ? moment(data.inactiveUntil).toDate() : null
  }
}

export function mapSecondsToString (seconds: number): string {
  const min = Math.floor(seconds / 60)
  const leftSeconds = seconds - min * 60
  let str = `${min}`
  if (leftSeconds > 0) {
    str += `:${leftSeconds < 10 ? '0' : ''}${leftSeconds}`
  }
  str += ' min.'
  return str
}

export function mapPreparationTime (minutes: number): string {
  return `${minutes} min.`
}

export function mapPriceToString (price: number): string {
  return new Intl.NumberFormat('pl-PL',{ style: 'currency', currency: 'PLN' }).format(price)
}

export function mapTagDetailsToForm(data: TagDto): TagFormData {
  return {
    name: data.name
  }
}

export function mapVariantTemplateDetailsDtoToForm(data: VariantTemplateDto): VariantFormData {
  return {
    name: data.name,
    note: data.internalNote,
    options: data.options.map(mapVariantTemplateOptionDetailsDtoToForm),
  }
}

export function mapVariantTemplateOptionDetailsDtoToForm(data: VariantTemplateOptionDto): VariantOption {
  return {
    id: data.uuid!,
    uuid: data.uuid,
    name: data.name,
    additionalPrice: currency(data.price, { fromCents: true }).value,
    defaultOption: data.default ?? false,
    additionalTime: data.preparationTime / 60,
    active: data.active,
    state: ChangeState.SAVED
  }
}

export function mapAdditionTemplatesDetailsDtoToForm(data: AdditionTemplateDto): AdditionFormData {
  return {
    name: data.name,
    note: data.internalNote,
    additionalPrice: currency(data.price, { fromCents: true }).value,
    additionalTime: data.preparationTime / 60
  }
}

export function mapProductTemplateDetailsDtoToForm(data: ProductTemplateDto): ProductFormData {
  return {
    name: data.name,
    note: data.internalNote ?? '',
    basePrice: currency(data.price, { fromCents: true }).value,
    baseTime: data.preparationTime / 60,
    photoUuid: data.photo?.uuid ?? null,
    photoUrl: data.photo?.original ?? null,
    tags: data.tags,
    description: data.description
  }
}

export function mapBannerList(banners: BannerDto[]): BannerFormData[] {
  return banners.map(mapBanner)
}

export function mapBanner(banner: BannerDto): BannerFormData {
  return {
    bannerUuid: banner.uuid,
    photoUrl: banner.photo.original
  }
}

export function mapPlaceMenu(data: MenuCategoryDto[]): MenuCategory[] {
  return data.map(mapPlaceMenuCategory)
}

export function mapPlaceMenuCategory(data: MenuCategoryDto): MenuCategory {
  return {
    cardId: data.uuid,
    ...data,
    products: data.products.map(mapPlaceMenuProduct)
  }
}

export function mapPlaceMenuProduct(data: MenuProductDto): MenuProduct {
  return {
    cardId: data.uuid,
    ...data,
    variants: data.variants.map(mapPlaceMenuVariant),
    additions: data.additions.map(mapPlaceMenuAddition)
  }
}

export function mapPlaceMenuVariant(data: MenuVariantDto): MenuVariant {
  return {
    cardId: data.uuid,
    ...data,
    options: data.options.map(mapPlaceMenuVariantOption)
  }
}

export function mapPlaceMenuVariantOption(data: MenuVariantOptionDto): MenuVariantOption {
  return {
    cardId: data.uuid,
    ...data
  }
}

export function mapPlaceMenuAddition(data: MenuAdditionDto): MenuAddition {
  return {
    cardId: data.uuid,
    ...data
  }
}


export function mapMenuFormDataToDto (data: MenuCategory[]): MenuRequestCategoryBodyDto[] {
  return data.map(mapMenuFormDataCategoryToDto)
}

export function mapMenuFormDataCategoryToDto (data: MenuCategory): MenuRequestCategoryBodyDto {
  return {
    uuid: data.uuid,
    name: data.name,
    products: data.products.map(mapMenuFormDataProductToDto),
  }
}

export function mapMenuFormDataProductToDto (data: MenuProduct): MenuRequestProductDto {
  return {
    uuid: data.uuid,
    templateUuid: data.templateUuid,
    price: data.customPrice,
    preparationTime: data.customPreparationTime,
    variants: data.variants.map(mapMenuFormDataVariantToDto),
    additions: data.additions.map(mapMenuFormDataAdditionToDto)
  }
}

export function mapMenuFormDataVariantToDto(data: MenuVariant): MenuRequestVariantDto {
  return {
    uuid: data.uuid,
    templateUuid: data.templateUuid,
    options: data.options.map(mapMenuFormDataVariantOptionToDto)
  }
}

export function mapMenuFormDataVariantOptionToDto(data: MenuVariantOption): MenuRequestVariantOptionDto {
  return {
    uuid: data.uuid,
    templateUuid: data.templateUuid,
    price: data.customPrice,
    preparationTime: data.customPreparationTime
  }
}

export function mapMenuFormDataAdditionToDto(data: MenuAddition): MenuRequestAdditionDto {
  return {
    uuid: data.uuid,
    templateUuid: data.templateUuid,
    price: data.customPrice,
    preparationTime: data.customPreparationTime
  }
}

export function mapAccountListData(data: AccountListDto): AccountListData {
  return {
    count: data.count,
    data: data.data.map(mapAccountDetailsData)
  }
}

export function mapAccountDetailsData(data: AccountListRowDto): AccountDataRow {
  return {
    uuid: data.uuid,
    name: data.name,
    role: data.role,
    places: data.allowedPlaces ?? [],
    email: data.email,
    joinDate: moment(data.createdAt).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).toDate(),
    active: data.active,
    lastLogin: data.lastLoginAt ? moment(data.lastLoginAt).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).toDate() : null
  }
}

export function mapCmsUserDetailsDto(data: CmsUserDetailsDto): AccountDataRow {
  return {
    uuid: data.uuid,
    name: data.name,
    role: data.role,
    places: data.allowedPlaces ?? [],
    email: data.email,
    joinDate: moment(data.createdAt).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).toDate(),
    active: data.active,
    lastLogin: data.lastLoginAt ? moment(data.lastLoginAt).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).toDate() : null
  }
}

export function mapEmployeeDetailsDto(data: EmployeeDetailsDto): AccountDataRow {
  return {
    uuid: data.uuid,
    name: data.name,
    role: Role.EMPLOYEE,
    places: data.allowedPlaces ?? [],
    joinDate: moment(data.createdAt).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).toDate(),
    active: data.active,
    lastLogin: data.lastLoginAt ? moment(data.lastLoginAt).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).toDate() : null
  }
}

export function mapOrderListDto(list: OrderListDto): OrderList {
  return {
    count: list.count,
    data: list.data.map(mapOrderDetailsDto)
  }
}

export function mapOrderDetailsDto(order: OrderDetailsDto): OrderDetails {
  const noDiscountCost = order.summary.items.reduce((acc, cur) => acc + (cur.amount * cur.totalCost), 0)
  return {
    ...order,
    summary: {
      totalCost: order.summary.totalCost,
      noDiscountCost: noDiscountCost,
      ...(
        order.summary.discount != null 
          ? { 
              discount: order.summary.discount,
              discountValue: noDiscountCost - order.summary.totalCost
            } 
          : {}
      ),
      items: order.summary.items
    }
  }
}

export function mapLoyaltyCardDto(card: LoyaltyCardDto): DiscountCode {
  return {
    ...card
  }
}

export function mapDiscountCodeFormData(form: DiscountCodeFormData): CreateLoyaltyCardDto {
  return {
    userUuid: form.user!.value,
    discount: parseFloat((form.percentage! / 100).toFixed(2)),
    code: form.code!
  }
}

export function mapDiscountCodeFormDataForEdit(form: DiscountCodeFormData): EditLoyaltyCardDto {
  return {
    discount: parseFloat((form.percentage! / 100).toFixed(2)),
    code: form.code!
  }
}

export function mapUserSearchDtoList(users: UserSearchDto[]): AutocompleteBaseOption[] {
  return users.map(mapUserToOption)
}

export function mapUserToOption(user: User | UserSearchDto): AutocompleteBaseOption {
  return {
    value: user.uuid,
    label: `${user.firstName}${user.lastName ? ` ${user.lastName}` : ''} (${user.contactEmail}${user.phone ? `, ${user.phone}` : ''})`
  }
}

export function mapDiscountCodeDetailsToForm(data: DiscountCode): DiscountCodeFormData {
  return {
    user: mapUserToOption(data.user),
    percentage: parseInt((data.discount * 100).toFixed()),
    code: data.code
  }
}

export function mapLoyaltyCardListDto(list: LoyaltyCardListDto): DiscountCodeList {
  return {
    ...list
  }
}

export function mapFileListDto(list: FileListDto): FileList {
  return {
    ...list
  }
}