import { TextField as MuiTextField, TextFieldProps } from '@mui/material'
import { useField } from 'formik'
import { useState } from 'react'

type Props = {
  name: string
  noDefaultHelperText?: boolean
} & TextFieldProps

const TextFieldWithWarning = (props: Props) => {
  const [field, meta] = useField(props.name)
  const [focus, setFocus] = useState<boolean>(false)

  const configTextField: TextFieldProps = {
    ...field,
    ...props,
    fullWidth: true,
    color: focus ? 'warning' : undefined,
    helperText: focus ? props.helperText : undefined,
    variant: 'outlined',
    onFocus: () => setFocus(true),
    sx: focus
      ? {
          "& .MuiInputLabel-root": {color: 'warning.main'},
          "& .MuiFormHelperText-root": {color: 'warning.main'},
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'warning.main',
            },
            '&:hover fieldset': {
              borderColor: 'warning.main',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'warning.main',
            },
          }
        }
      : undefined
  }

  if (meta && meta.touched && meta.error) {
    configTextField.error = true
    configTextField.helperText = meta.error
    delete configTextField.sx
  } else if (!props.noDefaultHelperText) {
    configTextField.helperText = ' '
  }

  return (
    <MuiTextField 
      {...configTextField}
      sx={{
        marginBottom: '1rem'
      }}
    /> 
  )
}

export default TextFieldWithWarning
