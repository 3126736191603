import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import DataApi from '../../api/data/DataApi'
import { AvailabilityListOptions } from '../../api/data/types'
import { showErrorToast } from '../../utils/error'
import { mapProductAvailabilityListDto } from '../../utils/mapping'
import { queryNames } from '../queries'
import { ProductAvailabilityList } from '../../types/menu'

const dataApi = DataApi.getInstance()

type Options = {
  enabled?: boolean
  onSuccess: (data: ProductAvailabilityList) => void
}

export default function useGetPlaceProductAvailabilityList(
  placeUuid: string,
  options: AvailabilityListOptions & Options
) {
  const texts = useTranslation().t
  const key = [
    queryNames.productAvailabilityList,
    placeUuid, 
    { 
      search: options.search,
      sort: options.sort,
      offset: options.offset 
    }
  ]
  const query = useQuery(key, async () => {
    return mapProductAvailabilityListDto(await dataApi.getPlaceProductAvailabilityList(placeUuid, options))
  }, {
    onError: (error) => {
      showErrorToast(error, texts)
    },
    staleTime: Infinity,
    refetchOnMount: 'always',
    enabled: options.enabled,
    onSuccess: options.onSuccess
  })

  return {
    ...query,
    maxPage: options?.limit != null ? Math.ceil((query.data?.count ?? 0) / options.limit) : 1
  }}

