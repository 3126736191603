import { AiOutlineCloseCircle } from 'react-icons/ai'
import { Button, Dialog as MuiDialog, DialogActions, DialogContent, DialogTitle, IconButton, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import { MenuFormData, Product } from '../../../../types/menu'
import moment from 'moment'
import useGetProductTemplateList from '../../../../hooks/template/product/useGetProductTemplateList'
import { DropdownOption } from '../../../CustomMui/DropdownMenu'
import { useState, useRef, useEffect, useMemo } from 'react'
import DropdownMenu from '../../../CustomMui/DropdownMenu'
import SortIcon from '@mui/icons-material/Sort'
import SearchBar from '../../../CustomMui/SearchBar'
import ProductTemplateTile from './Tiles/ProductTemplateTile'
import NoDataPlaceholder from '../../../Common/NoDataPlaceholder'
import Pagination from '@mui/material/Pagination'
import NetworkError from '../../../Common/NetworkError'
import Loader from '../../../Common/Loader'
import { removeArrayElement } from '../../../../utils/array'
import { getProductTemplateUuidsFromMenu } from '../../../../utils/menu'

type Props = {
  open: boolean
  categoryCardId: string
  onCloseButtonClick: () => void
}

const productsPerPage = Number.parseInt(process.env.REACT_APP_DEFAULT_PAGE_LIMIT!)

const AddProductDialog = (props: Props) => {
  const texts = useTranslation().t
  const { values, setFieldValue } = useFormikContext<MenuFormData>()

  const sortOptions: DropdownOption[] = useMemo(() => {
    return texts('objects:products_sort_options', {returnObjects: true}) as DropdownOption[]
  }, [texts])

  const [currentPage, setCurrentPage] = useState(1)
  const [sortOption, setSortOption] = useState<string>(sortOptions[0].value)
  const [searchValue, setSearchValue] = useState<string>('')
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([])

  const ref = useRef<HTMLDivElement | null>(null)

  const { isLoading, data, isError, maxPage } = useGetProductTemplateList({
    limit: productsPerPage,
    offset: (currentPage - 1) * productsPerPage,
    sort: sortOption,
    search: searchValue,
    exclude: getProductTemplateUuidsFromMenu(values.items)
  })

  const onClose = () => {
    setCurrentPage(1)
    setSearchValue('')
    setSortOption(sortOptions[0].value)
    setSelectedProducts([])
    props.onCloseButtonClick()
  }


  const handleConfirm = () => {
    const catIdx = values.items.findIndex(e => e.cardId === props.categoryCardId)
    const newItems = [...values.items]
    for (let i = 0; i < selectedProducts.length; i++) {
      newItems[catIdx].products.push({
        cardId: `product-${i}-${moment().unix()}`,
        templateUuid: selectedProducts[i].uuid,
        variants: [],
        additions: [],
        photo: selectedProducts[i].photo,
        name: selectedProducts[i].name,
        description: selectedProducts[i].description,
        price: selectedProducts[i].basePrice,
        preparationTime: selectedProducts[i].baseTime,
        internalNote: selectedProducts[i].note,
        customPrice: null,
        customPreparationTime: null,
        active: true,
        tags: selectedProducts[i].tags
      })
    }
    setFieldValue('items', newItems)
    onClose()
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(1)
    setSortOption(event.target.value)
  }

  const handlePaginationChange = (e: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page)
  }

  const handleTileClick = (product: Product) => {
    if (selectedProducts.findIndex(e => e.uuid === product.uuid) > -1) {
      setSelectedProducts(removeArrayElement(selectedProducts, selectedProducts.findIndex(e => e.uuid === product.uuid)))
    } else {
      const newItems = [...selectedProducts]
      newItems.push(product)
      setSelectedProducts(newItems)
    }
  }

  useEffect(() => {
    ref.current?.scrollTo(0,0)
  }, [currentPage, sortOption, searchValue])

  return (
    <MuiDialog
      open={props.open}
      PaperProps={{
        ref: ref
      }}
      onClose={onClose}
      sx={{
        '.MuiDialog-paper': {
          width: '75rem',
          height: '85vh'
        }
      }}
    >
      <DialogActions>
        <IconButton onClick={onClose} color={'primary'}>
          <AiOutlineCloseCircle size={30}/>
        </IconButton>
      </DialogActions>
      <Grid
        container
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <DialogTitle sx={{paddingBottom: 0}}> 
          {texts('dialogs:add_product_dialog_title')}
        </DialogTitle>
        <DialogActions>
          <Button
            variant={'contained'}
            sx={{
              float: 'right'
            }}
            onClick={handleConfirm}
          >
            {texts('dialogs:add_item_to_menu_dialog_button')}{selectedProducts.length > 0 ? ` (${selectedProducts.length})` : ''}
          </Button>
        </DialogActions>
      </Grid>
      <DialogContent sx={{overflowY: 'unset'}}>
        <Grid
          container
          flexDirection={'column'}
          flexWrap={'nowrap'}
          height={'100%'}
          maxWidth={'100%'}
        >
          <Grid
            item 
            container
            justifyContent={'space-between'}
            wrap={'nowrap'}
            marginBottom={'1rem'}
          >
            <Grid 
              item
              sx={{
                minWidth: '30rem',
                maxWidth: '40rem' 
              }}
            >
              <SearchBar
                onClick={(text) => {
                  setCurrentPage(1)
                  setSearchValue(text)
                }}
                placeholder={texts('menu:search_product')}
              />
            </Grid>
            <Grid
              item
              container
              alignItems={'center'}
              maxWidth={'fit-content'}
              gap={'0.875rem'}
              wrap={'nowrap'}
            >
              <SortIcon/>
              <Typography 
                variant='body2'
                fontWeight={'bold'}
              >
                {texts('common:sort')}
              </Typography>
              <Grid
                item
                sx={{minWidth: '12rem'}}
              >
                <DropdownMenu
                  value={sortOption}
                  onChange={handleChange}
                  options={sortOptions}
                />
              </Grid>
            </Grid>
          </Grid>
          {
            isLoading || isError
            ? <Grid 
                container 
                item
                flexGrow={1} 
                justifyContent={'center'} 
                alignItems={'center'}
              >
                {
                  isError
                  ? <NetworkError />
                  : <Loader 
                      width={'100%'}
                      text={texts('common:loading')}
                    />
                }
              </Grid>
            : <>
                {
                  data == null || data.data.length === 0 
                  ? <Grid 
                      container 
                      item
                      flexGrow={1} 
                      justifyContent={'center'} 
                      alignItems={'center'}
                    >
                      <NoDataPlaceholder />
                    </Grid>
                  : <>
                      {
                        data?.data.map((product) => (
                          <ProductTemplateTile 
                            key={product.uuid}
                            product={product}
                            selected={selectedProducts.findIndex(e => e.uuid === product.uuid) > -1}
                            onClick={() => handleTileClick(product)}
                          />
                        ))
                      }
                    </>
                }
                {
                  maxPage > 1
                  ? <Grid
                      item
                      container
                      justifyContent={'center'}
                      mt={'1.5rem'}
                    >
                      <Pagination 
                        page={currentPage}
                        onChange={handlePaginationChange}
                        count={maxPage} 
                      />
                    </Grid>
                  : null
                }
              </>
          }
        </Grid>
        
      </DialogContent>
      
    </MuiDialog>
  )
}

export default AddProductDialog
