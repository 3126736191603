import i18n from 'i18next'
import ICU from 'i18next-icu'
import { initReactI18next } from 'react-i18next'
import auth from './translations/auth.json'
import validations from './translations/validations.json'
import dashboard from './translations/dashboard.json'
import place from './translations/place.json'
import errors from './translations/errors.json'
import successes from './translations/successes.json'
import common from './translations/common.json'
import dialogs from './translations/dialogs.json'
import menu from './translations/menu.json'
import objects from './translations/objects.json'
import variants from './translations/variants.json'
import additions from './translations/additions.json'
import reports from './translations/reports.json'
import newsletter from './translations/newsletter.json'
import order from './translations/order.json'
import banners from './translations/banners.json'
import accounts from './translations/accounts.json'
import account from './translations/account.json'
import availability from './translations/availability.json'
import imageUpload from './translations/imageUpload.json'
import discountCodes from './translations/discountCodes.json'
import files from './translations/files.json'

export const defaultNS = 'common'

export const resources = {
  pl: {
    auth,
    validations,
    dashboard,
    place,
    errors,
    successes,
    common,
    dialogs,
    menu,
    objects,
    variants,
    additions,
    reports,
    newsletter,
    order,
    banners,
    accounts,
    account,
    availability,
    imageUpload,
    discountCodes,
    files
  },
} as const

i18n
  .use(ICU)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'pl',
    fallbackLng: 'pl',
    keySeparator: false,
    ns: [
      'auth',
      'validations',
      'dashboard',
      'place',
      'errors',
      'successes',
      'common',
      'dialogs',
      'menu',
      'objects',
      'variants',
      'additions',
      'reports',
      'newsletter',
      'order',
      'banners',
      'accounts',
      'account',
      'availability',
      'imageUpload',
      'discountCodes',
      'files'
    ],
    defaultNS,
    interpolation: {
      escapeValue: false,
    },
    returnNull: false
  })

export default i18n