import * as Yup from 'yup'
import { emailRegexp } from './validationRegexps'

export function resetPasswordRequestValidation(t: (val: string) => string) {
  return Yup.object({
    email: Yup.string()
      .required(t('validations:field_required'))
      .matches(emailRegexp, t('validations:invalid_email_validation')),
  })
}

