import { Grid, Typography } from '@mui/material'
import Pagination from '@mui/material/Pagination'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { DecodedValueMap, NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import Loader from '../../components/Common/Loader'
import NetworkError from '../../components/Common/NetworkError'
import NoDataPlaceholder from '../../components/Common/NoDataPlaceholder'
import SearchBar from '../../components/CustomMui/SearchBar'
import FileListRow from '../../components/Files/List/FileListRow'
import useGetFileList from '../../hooks/file/useGetFileList'
import { SearchListSearchParams } from '../../types/menu'

const filesPerPage = Number.parseInt(process.env.REACT_APP_FILES_PER_PAGE!)

function isSearchParamsValid(params: DecodedValueMap<SearchListSearchParams>): boolean {
  return Number.isInteger(params.page) // must be a number, not a NaN
    && params.page >= 1  // page cannot be lower than 1
}

const FileList = () => {
  const texts = useTranslation().t

  const [searchParams, setSearchParams] = useQueryParams<SearchListSearchParams>({ 
    page: withDefault(NumberParam, 1), 
    search: withDefault(StringParam, '')
  })

  const { isFetching, data, isError, maxPage } = useGetFileList({
    limit: filesPerPage,
    offset: (searchParams.page - 1) * filesPerPage,
    search: searchParams.search,
    enabled: isSearchParamsValid(searchParams),
    onSuccess: (data) => {
      if (searchParams.page > 1 && data.data.length === 0) {
        setSearchParams({
          page: 1,
          search: ''
        }, 'replaceIn')
      }
    }
  })

  useEffect(() => {
    window.scrollTo(0,0)
  }, [searchParams.page])

  useEffect(() => {    
    if (!isSearchParamsValid(searchParams)) {
      setSearchParams({
        page: 1,
        search: ''
      }, 'replaceIn')
      return
    }

    if (searchParams.page === 1) {
      setSearchParams({
        page: 1
      }, 'replaceIn')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.page])

  if (!isSearchParamsValid(searchParams) || (!isFetching && searchParams.page > 1 && data!.data.length === 0)) {
    return <></>
  }

  return (
    <Grid 
      container
      flexDirection={'column'}
      sx={{
        minHeight: 'calc(100vh - 130px)'
      }}
    >
      <Grid
        item
        container 
        wrap='nowrap'
        justifyContent={'space-between'} 
        alignItems={'center'}
      >
        <Grid item> 
          <Typography variant='h2' noWrap>
            {texts('files:list_title')}
          </Typography> 
        </Grid>
      </Grid>
      <Grid
        item 
        container
        justifyContent={'space-between'}
        wrap={'nowrap'}
        marginTop={'1.5rem'}
        marginBottom={'1rem'}
      >
        <Grid 
          item
          sx={{
            minWidth: '30rem',
            maxWidth: '40rem' 
          }}
        >
          <SearchBar
            initialValue={searchParams.search}
            onClick={(text) => setSearchParams({ search: text, page: 1 }, 'replaceIn')}
            placeholder={texts('files:search')}
          />
        </Grid>
      </Grid>
      {
        isError || isFetching
        ? <Grid 
            container 
            item
            flexGrow={1} 
            justifyContent={'center'} 
            alignItems={'center'}
          >
            {
              isError
              ? <NetworkError />
              : <Loader 
                  width={'100%'}
                  text={texts('common:loading')}
                />
            }
          </Grid>
        : <>
            {
              data == null || data.data.length === 0 
              ? <NoDataPlaceholder />
              : <>
                  {
                    data?.data.map((file) => (
                      <FileListRow 
                        key={file.uuid}
                        file={file}
                      />
                    ))
                  }
                </>
            }
            {
              maxPage > 1
              ? <Grid
                  item
                  container
                  justifyContent={'center'}
                  mt={'1.5rem'}
                >
                  <Pagination 
                    page={searchParams.page}
                    onChange={(e, page) => setSearchParams({ page: page }, 'replaceIn')}
                    count={maxPage} 
                  />
                </Grid>
              : null
            }
          </>
      }   
    </Grid>
  )
}

export default FileList