import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import DataApi from '../../api/data/DataApi'
import { showErrorToast } from '../../utils/error'
import { mapPlaceMenu } from '../../utils/mapping'
import { queryNames } from '../queries'

const dataApi = DataApi.getInstance()

export default function useGetPlaceMenu(
  enabled: boolean,
  uuid: string
) {
  const texts = useTranslation().t

  const query = useQuery([queryNames.placeMenu, uuid], async () => {
    return mapPlaceMenu(await dataApi.getPlaceMenu(uuid!))
  }, {
    enabled: enabled,
    staleTime: Infinity,
    refetchOnMount: 'always',
    onError: (error) => {
      showErrorToast(error, texts)
    }
  })
  return query
}

