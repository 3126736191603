import { useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'
import DataApi from '../../api/data/DataApi'
import { TagFormData } from '../../types/menu'
import { showErrorToast } from '../../utils/error'
import { mapTagDetailsToForm } from '../../utils/mapping'
import { queryNames } from '../queries'
import { AxiosError } from 'axios'
import { HttpError } from '../../types/common'
import { Codes, DataSubcodes } from '../../services/errors/consts'

const dataApi = DataApi.getInstance()

export default function useGetTagDetails(
  onSuccess: (data: TagFormData) => void,
  uuid?: string | null,
 onNotFoundError?: () => void
) {
  const texts = useTranslation().t
  const queryClient = useQueryClient()

  const query = useQuery([queryNames.tagDetails, uuid], async () => {
    return mapTagDetailsToForm(await dataApi.getTagDetails(uuid!))
  }, {
    enabled: uuid != null && uuid !== '',
    staleTime: Infinity,
    refetchOnMount: 'always',
    onError: (error: AxiosError<HttpError>) => {
      if (error.response?.data.code === Codes.DATA && error.response.data.subcode === DataSubcodes.TAG_NOT_FOUND) {
        queryClient.removeQueries([queryNames.tagDetails, uuid])
        queryClient.resetQueries(queryNames.productList)
        queryClient.resetQueries(queryNames.productAvailabilityList)
        queryClient.resetQueries(queryNames.tagList)
        queryClient.resetQueries(queryNames.placeMenu)
        onNotFoundError?.()
      }
      showErrorToast(error, texts)
    },
    onSuccess: onSuccess
  })
  return query
}

