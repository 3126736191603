import CancelIcon from '@mui/icons-material/Cancel'
import { Autocomplete, Chip, Grid, TextField as MuiTextField, Typography } from '@mui/material'
import currency from 'currency.js'
import { useFormikContext } from 'formik'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import useGetTagList from '../../../hooks/tag/useGetTagList'
import { TagDto } from '../../../types/menu'
import NumericTextField from '../../CustomMui/Fields/NumericTextField'
import TextField from '../../CustomMui/Fields/TextField'
import AddImage from './AddImage'
import { useState } from 'react'

const ProductDetails = () => {
  const texts = useTranslation().t

  const [tagSearch, setTagSearch] = useState<string>('')

  const { values, setFieldValue } = useFormikContext<{tags: TagDto[], basePrice: number, baseTime: number}>()
  const { isLoading, data } = useGetTagList({
    limit: 20,
    offset: 0,
    sort: 'name:asc',
    search: tagSearch,
  })

  const handleChange = (event: React.SyntheticEvent, value: TagDto[]) => {
    setFieldValue('tags', value)
    setTagSearch('')
  }

  const handleDelete = (tagUuid: string) => {
    const newValues = [...values.tags]
    newValues.splice(newValues.findIndex(e => e.uuid === tagUuid), 1)
    setFieldValue('tags', newValues)
  }

  return (
    <Grid container direction={'column'}>
      <Grid 
        item 
        container
        marginBottom={'1rem'}
        wrap={'nowrap'}
      >
        <AddImage/>
        <Grid 
          item
          container
          flexGrow={1} 
          columnGap='1.875rem'
          rowGap='0.125rem'
          marginTop='1.875rem'
        >
          <TextField
            name='name'
            label={texts('menu:name')}
          />
          <TextField
            name='description'
            label={texts('menu:description')}
          />
          <Grid
            item
            container
            wrap='nowrap'
            gap={'2rem'}
          >
            <Grid
              item
              container
              wrap={'nowrap'}
              gap={'1rem'}
              alignItems={'center'}
              maxWidth={'16rem'}
            >
              <NumericTextField
                name='basePrice'
                label={texts('menu:basePrice')}
                placeholder={texts('common:price_hint')}
                shrinkLabel
                value={values.basePrice}
                onChange={(e) => {
                  const value = e.target.value === '' ? null : currency(e.target.value).value
                  setFieldValue(
                    'basePrice',
                    value
                  )
                }}
              />
              <Typography 
                variant='body2'
                sx={{textTransform: 'none', marginBottom: '1.25rem'}}
              >
                {texts('common:PLN_label')}
              </Typography>
            </Grid>
            <Grid
              item
              container
              wrap={'nowrap'}
              gap={'1rem'}
              alignItems={'center'}
              maxWidth={'16rem'}
            >
              <NumericTextField
                name='baseTime'
                integerOnly
                placeholder={texts('common:time_hint')}
                shrinkLabel
                label={texts('menu:baseTime')}
                value={values.baseTime}
                onChange={(e) => {
                  const value = e.target.value === '' ? null : Number.parseFloat(e.target.value)
                  setFieldValue(
                    'baseTime',
                    value
                  )
                }}
              />
              <Typography 
                variant='body2'
                sx={{textTransform: 'none', marginBottom: '1.25rem'}}
              >
                {texts('common:minutes')}
              </Typography>
            </Grid>
          </Grid>
          <Grid 
            item
            sx={{width: '100%'}}
          >
            <Autocomplete
              multiple
              loading={isLoading}
              filterSelectedOptions
              value={values.tags}
              onChange={handleChange}
              sx={{
                marginBottom: '1rem'
              }}
              isOptionEqualToValue={(option: TagDto, value: TagDto) => {
                return option.uuid === value.uuid
              }}
              filterOptions={(x) => {
                return x
              }}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.uuid}>
                    {option.name}
                  </li>
                )
              }}
              renderTags={(value: TagDto[], getTagProps: any) => 
                value.map((val: TagDto, index) => (
                  <Chip
                    key={val.uuid}
                    label={val.name}
                    sx={{
                      margin: '0 0.5rem'
                    }}
                    clickable
                    deleteIcon={
                      <CancelIcon
                        onMouseDown={(e) => e.stopPropagation()}
                      />
                    }
                    onDelete={() => handleDelete(val.uuid)}
                    {...getTagProps({ index })}
                  />
                ))
              }
              options={data?.data ?? []}
              noOptionsText={texts('menu:no_tags')}
              loadingText={texts('common:downloading')}
              getOptionLabel={(option: TagDto) => option.name}
              onClose={() => {
                setTagSearch('')
              }}
              renderInput={(params) => (
                <MuiTextField
                  {...params}
                  helperText={' '}
                  onChange={(e) => {
                    setTagSearch(e.target.value)
                  }}
                  label={texts('menu:tags')}
                />
              )}
            />
          </Grid>
          <TextField
            name='note'
            label={texts('menu:note_field_label', {val: null})}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ProductDetails