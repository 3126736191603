import { Button, DialogActions, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { RoleObject } from './CreateAccessDialog'
import SelectTextField from './SelectTextField'

const FirstStep = () => {
  const texts = useTranslation().t
  const roles: RoleObject[] = texts('objects:create_access_roles', { returnObjects: true }) as RoleObject[]

  return (
    <>
      <Typography
        variant={'subtitle2'}
        sx={{
          mt: '1.5rem',
          mb: '0.75rem'
        }}
      >
        {texts('accounts:pick_role')}
      </Typography>
      <SelectTextField
        name={'role'}
        label={texts('accounts:role')}
        options={roles}
      />
      <DialogActions sx={{paddingTop: '1.25rem'}}>
        <Button
          type='submit'
          variant={'contained'}
          size={'medium'}
        >
          {texts('common:next')}
        </Button>
      </DialogActions>
    </>
  )
}

export default FirstStep