import { ReactNode } from 'react'
import { Role } from '../../types/accounts'
import { arrayIntersection } from '../../utils/array'
import { getUserRoles } from '../../services/storage/storageService'

type Props = {
  permittedRoles: Role[]
  children?: ReactNode | ReactNode[]
}

const RoleGuard = (props: Props) => {
  const userRoles = getUserRoles()

  return (
    <>
      {
        arrayIntersection(props.permittedRoles, userRoles).length > 0
        ? props.children
        : null
      }
    </>
  )
}

export default RoleGuard