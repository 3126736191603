import * as Yup from 'yup'
import { VariantOption } from '../types/menu'
import { ChangeState } from '../types/place'

export function addVariantValidation(t: (val: string) => string) {
  return Yup.object({
    name: Yup.string()
      .trim()
      .max(32, t('validations:max_32_signs_validation'))
      .required(t('validations:field_required')),
    note: Yup.string()
      .max(120, t('validations:max_120_signs_validation')),
    options: Yup.array()
      .min(2, t('validations:min_two_options_variant'))
      .test(function (val: any, ctx: any) {
        if (val.some((v: VariantOption) => v.state !== ChangeState.SAVED)) {
          return this.createError({
            message: t('errors:save_field_before_submit'),
            path: ctx.path
          })
        }
        const options = val.filter((v: VariantOption) => v.defaultOption)
        if (options.length === 1) {
          return true
        }
        if (options.length === 0) {
          return this.createError({
            message: t('validations:zero_default_options_variant'),
            path: ctx.path
          })
        }
        return this.createError({
          message: t('validations:too_many_default_options_variant'),
          path: ctx.path
        })
      }),
  })
}
