import { useTranslation } from 'react-i18next'
import useDeactivateAdditions from '../../../../hooks/place/useDeactivateAdditions'
import useDeactivateProducts from '../../../../hooks/place/useDeactivateProducts'
import useDeactivateVariantOptions from '../../../../hooks/place/useDeactivateVariantOptions'
import { AvailabilityItemType } from '../../../../types/menu'
import Dialog from '../../../CustomMui/Dialog'

type Props = {
  open: boolean
  itemType: AvailabilityItemType
  placeUuid: string
  selectedItems: string[]
  onClose: () => void
  onSucces: () => void
}

const DeactivateItemsDialog = (props: Props) => {
  const texts = useTranslation().t
  const deactivateProductsMutation = useDeactivateProducts(() => {
    props.onSucces()
  })
  const deactivateVariantOptionsMutation = useDeactivateVariantOptions(() => {
    props.onSucces()
  })
  const deactivateAdditionsMutation = useDeactivateAdditions(() => {
    props.onSucces()
  })

  return (
    <Dialog
      open={props.open}
      title={texts('dialogs:deactivate_items_title')}
      description={texts('dialogs:deactivate_items_description')}
      closeButtonLabel={texts('common:cancel')}
      confirmButtonLabel={texts('common:deactivate')}
      onCloseButtonClick={props.onClose}
      loading={
        deactivateProductsMutation.isLoading 
        || deactivateVariantOptionsMutation.isLoading 
        || deactivateAdditionsMutation.isLoading
      }
      onConfirmButtonClick={() => {
        switch (props.itemType) {
          case AvailabilityItemType.ADDITIONS:
            deactivateAdditionsMutation.mutate({
              placeUuid: props.placeUuid,
              templateUuids: props.selectedItems
            })
            break
          case AvailabilityItemType.PRODUCTS:
            deactivateProductsMutation.mutate({
              placeUuid: props.placeUuid,
              templateUuids: props.selectedItems
            })
            break
          case AvailabilityItemType.VARIANTS:
            deactivateVariantOptionsMutation.mutate({
              placeUuid: props.placeUuid,
              templateUuids: props.selectedItems
            })
            break
        }
        props.onSucces()
        props.onClose()
      }}
    />
  )
}

export default DeactivateItemsDialog
