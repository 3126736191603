import { Box } from '@mui/system'
import React from 'react'
import { Direction, Droppable } from '@hello-pangea/dnd'

type Props = {
  children?: React.ReactNode
  droppableId: string
  boxDirection: Direction
}

const DroppableList = React.memo(({ children, droppableId, boxDirection }: Props)  => {
  return (
    <Box
      sx={{
        width: '100%'
      }}
    >
      <Droppable 
        droppableId={droppableId}
        type={droppableId}
        direction={boxDirection}
      >
        {(provided) => (
          <Box 
            ref={provided.innerRef} 
            {...provided.droppableProps} 
            display={boxDirection === 'horizontal' ? 'flex' : 'block'}
          >
            { children }
            { provided.placeholder }
          </Box>
        )}
      </Droppable>
    </Box>
  )
})

export default DroppableList