import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import DataApi from '../../../api/data/DataApi'
import { showErrorToast } from '../../../utils/error'
import { queryNames } from '../../queries'
import { AxiosError } from 'axios'
import { HttpError } from '../../../types/common'
import { Codes, DataSubcodes } from '../../../services/errors/consts'

const dataApi = DataApi.getInstance()

type MutationParams = {
  uuid: string
}

export default function useRemoveProductTemplate(onNotFoundError?: () => void) {
  const queryClient = useQueryClient()
  const texts = useTranslation().t
  const mutation = useMutation((params: MutationParams) => dataApi.removeProductTemplate(params.uuid), {
    onError: (e: AxiosError<HttpError>, variables: MutationParams) => {
      if (e.response?.data.code === Codes.DATA && e.response.data.subcode === DataSubcodes.PRODUCT_TEMPLATE_NOT_FOUND) {
        queryClient.removeQueries([queryNames.productDetails, variables.uuid])
        queryClient.resetQueries(queryNames.productList)
        queryClient.resetQueries(queryNames.productAvailabilityList)
        queryClient.resetQueries(queryNames.placeMenu)
        onNotFoundError?.()
      }
      showErrorToast(e, texts)
    },
    onSuccess: (_, variables: MutationParams) => {
      queryClient.removeQueries([queryNames.productDetails, variables.uuid])
      queryClient.resetQueries(queryNames.productList)
      queryClient.resetQueries(queryNames.productAvailabilityList)
      queryClient.resetQueries(queryNames.placeMenu)
      toast.success(texts('successes:product_removed'))
    }
  })
  return mutation
}