import { Grid, Typography, Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { PlaceDetailsDto } from '../../types/place'
import placeholderImage from '../../assets/images/placeholderPlace.webp'

type Props = {
  placeInfo: PlaceDetailsDto
}

const PlaceListTile = (props: Props) => {
  const navigate = useNavigate()

  return (
    <Grid
      container
      direction={'column'}
      height={'19.5rem'}
      padding={'0.938rem'}
      flexWrap={'nowrap'}
      borderRadius={'0.938rem'}
      boxShadow={'0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.5)'}
      sx={{
        ':hover': {cursor: 'pointer'},
        opacity: props.placeInfo.active ? 1 : 0.5
      }}
      onClick={() => navigate(`/place/edit/${props.placeInfo.uuid}`)}
    >
      <Grid 
        item 
        sx={{
          filter: props.placeInfo.active ? 'none' : 'grayscale(100%)',
          display: 'block',
          width: '100%',
          height: 'auto',
          position: 'relative',
          overflow: 'hidden',
          padding: '75% 0 0 0'
        }}
      >
        <Box
          component={'img'}
          alt='logo'
          flexShrink={0}
          src={props.placeInfo.photo?.thumbnail ?? props.placeInfo.photo?.original ?? placeholderImage}
          sx={{
            display: 'block',
            minWidth: '100%',
            maxWidth: '100%',
            maxHeight: '100%',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
        />
      </Grid>
      <Typography 
        variant='h4'
        sx={{
          marginTop: '0.625rem',
          display: '-webkit-box',
          '-webkit-line-clamp': '2',
          '-webkit-box-orient': 'vertical',
          overflow: 'hidden'
        }}
      >
        {props.placeInfo.name}
      </Typography>
      <Typography 
        variant='h5'
        sx={{
          marginTop: '0.313rem',
          display: '-webkit-box',
          '-webkit-line-clamp': '2',
          '-webkit-box-orient': 'vertical',
          overflow: 'hidden'
        }}
      >
        {`${props.placeInfo.street}, ${props.placeInfo.postalCode} ${props.placeInfo.town}`}
      </Typography>
    </Grid>
  )
}

export default PlaceListTile