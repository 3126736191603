import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Step, StepLabel, Stepper } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import FirstStep from './FirstStep'
import SecondStep from './SecondStep'
import useResetEmployeePin from '../../../hooks/accounts/useResetEmployeePin'

type Props = {
  open: boolean
  uuid: string
  onClose: () => void
}

const ChangePinDialog = (props: Props) => {
  const texts = useTranslation().t

  const steps: string[] = texts('objects:change_pin_steps', {returnObjects: true}) as string[]

  const [step, setStep] = useState<number>(0)
  const [newPin, setNewPin] = useState<string>('')

  const onClose = () => {
    props.onClose()
    setNewPin('')
    setStep(0)
  }

  const resetPinMutation = useResetEmployeePin((newPin) => {
    setNewPin(newPin)
    setStep(1)
  })
  
  return (
    <Dialog 
      open={props.open} 
      onClose={onClose}
      maxWidth={'xl'}
    >
      <DialogActions>
        <IconButton 
          onClick={onClose} 
          color={'primary'}
        >
          <AiOutlineCloseCircle size={30}/>
        </IconButton>
      </DialogActions>
      <DialogTitle
        sx={{
          mb: '1.5rem',
          fontSize: '1.5rem',
          color: 'RGBA(0, 0, 0, 0.7)'
        }}
      >
        {texts('accounts:change_pin')}
      </DialogTitle>
      <DialogContent sx={{paddingBottom: 0}}>
        <Stepper activeStep={step} alternativeLabel>
          {steps.map((label) => {
            return (
              <Step key={label}>
                <StepLabel>
                  {label}
                </StepLabel>
              </Step>
            )
          })}
        </Stepper>
        {
          step === 0
          ? <FirstStep
              onConfirm={() => {
                resetPinMutation.mutate({ uuid: props.uuid })
              }}
              loading={resetPinMutation.isLoading}
            />
          : <SecondStep
              onClose={() => {
                onClose()
              }}
              pin={newPin}
            />
        }
      </DialogContent>
    </Dialog>
  )
}

export default ChangePinDialog