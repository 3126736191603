export const reorder = <T>(
  list: T[],
  startIndex: number,
  endIndex: number
): T[] => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const removeArrayElement = <T>(
  list: T[],
  index: number
): T[] => {
  const result = Array.from(list)
  result.splice(index, 1)
  return result
}

export const arrayIntersection = <T>(arr1: T[], arr2: T[]) => {
  return arr1.filter(value => arr2.includes(value))
}