import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Grid, IconButton, Menu, MenuItem } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import useRemoveProductTemplate from '../../../hooks/template/product/useRemoveProductTemplate'
import { Product } from '../../../types/menu'
import Loader from '../../Common/Loader'
import Dialog from '../../CustomMui/Dialog'
import ProductsListRowDetails from './ProductsListRowDetails'
import ProductsListRowPhoto from './ProductsListRowPhoto'

type Props = {
  product: Product
}

const ProductsListRow = (props: Props) => {
  const texts = useTranslation().t
  const navigate = useNavigate()

  const removeMutation = useRemoveProductTemplate()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [openRemoveDialog, setOpenRemoveDialog] = useState<boolean>(false)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Grid
      container
      position={'relative'}
      minHeight={'9.063rem'}
      padding={'0.938rem'}
      marginTop={'0.938rem'}
      borderRadius={'0.938rem'}
      boxShadow={'0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.5)'}
      gap={'0.938rem'}
      wrap={'nowrap'}
    >
      <Dialog
        open={openRemoveDialog}
        title={texts('dialogs:remove_title')}
        description={texts('dialogs:remove_description')}
        warning={texts('dialogs:product_remove_warning')}
        closeButtonLabel={texts('common:cancel')}
        confirmButtonLabel={texts('common:delete')}
        onCloseButtonClick={() => {setOpenRemoveDialog(false)}}
        onConfirmButtonClick={() => {
          setOpenRemoveDialog(false)
          removeMutation.mutate({ uuid: props.product.uuid })
        }}
      />
      {
        removeMutation.isLoading
        ? <Grid
            item
            position={'absolute'}
            width={'100%'}
            height={'100%'}
            right={0}
            top={0}
            borderRadius={'0.938rem'}
            overflow={'hidden'}
            sx={{backgroundSize: 'cover'}}
          >
            <Loader
              backgroundColor='rgba(0,0,0,0.08)'
            />
          </Grid>
        : null
      }
      <ProductsListRowPhoto 
        product={props.product}
        onClick={() => navigate(`/menu/products/edit/${props.product.uuid}`)}
      />
      <ProductsListRowDetails
        product={props.product}
        onClick={() => navigate(`/menu/products/edit/${props.product.uuid}`)}
      />
      <Grid item>
        <IconButton
          onClick={handleClick}
          sx={{color: '#000'}}
        >
          <MoreHorizIcon/>
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: '12rem',
            },
          }}
        >
          <MenuItem 
            onClick={() => {
              handleClose()
              setOpenRemoveDialog(true)
            }}
          >
            {texts('common:remove')}
          </MenuItem>
        </Menu>
      </Grid>
    </Grid>
  )
}

export default ProductsListRow