import { ComponentsOverrides, ComponentsProps, ComponentsVariants, createTheme } from '@mui/material/styles'
import { plPL } from '@mui/material/locale'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    clear: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    clear?: React.CSSProperties;
  }

  interface Components {
    MuiLoadingButton?: {
      defaultProps?: ComponentsProps['MuiButton']
      styleOverrides?: ComponentsOverrides['MuiButton'] & { 
        loadingIndicator?: React.CSSProperties
        '.MuiLoadingButton-loading'?: React.CSSProperties 
      }
      variants?: ComponentsVariants['MuiButton']
    };
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    clear: true;
  }
}

const MainTheme = createTheme(
  {
    palette: {
      primary: {
        main: '#383838'
      },
      secondary: {
        main: '#fff',
        light: '#94C11F'
      },
      success: {
        main: '#94C11F'
      }
    },
    typography: {
      fontFamily: 'RobotoRegular',
      clear: {
        color: '#38383877',
        fontSize: '0.75rem',
        cursor: 'pointer',
        fontFamily: 'RobotoRegular',
        paddingTop: '0.125rem',
        paddingBottom: '0.25rem'

      },
      subtitle1: {
        fontFamily: 'RobotoCondensedBold',
        fontSize: '1.375rem',
        letterSpacing: '0.114rem',
        marginBottom: '1.25rem',
        borderBottom: 'solid 0.063rem #383838'
      },
      subtitle2: {
        fontFamily: 'RobotoCondensedBold',
        fontSize: '1rem',
        textTransform: 'uppercase',
        letterSpacing: '0.094rem'
      },
      body2: {
        fontSize: '1rem',
        textTransform: 'uppercase'
      },
      h1: {
        fontFamily: 'RobotoCondensedBold',
        fontSize: '2rem',
        textTransform: 'uppercase',
        letterSpacing: '0.167rem',
        textAlign: 'center'
      },
      h2: {
        fontFamily: 'RobotoCondensedBold',
        fontSize: '1.5rem',
        textTransform: 'uppercase',
        letterSpacing: '0.125rem',
        color: 'rgba(0, 0, 0, 0.7)'
      },
      h3: {
        fontFamily: 'RobotoCondensed',
        fontSize: '1.5rem',
        textTransform: 'uppercase',
        letterSpacing: '0.125rem'
      },
      h4: {
        fontFamily: 'RobotoCondensedBold',
        fontSize: '1.125rem',
        letterSpacing: '0.063rem'
      },
      h5: {
        fontFamily: 'RobotoCondensed',
        fontSize: '1rem'
      }
    },
    components: {
      MuiMenu: {
        defaultProps: {
          disableAutoFocusItem: true
        }
      },
      MuiLoadingButton: {
        styleOverrides: {
          loadingIndicator: {
            color: 'white'
          },
          root: {
            '&.MuiLoadingButton-loading': {
              backgroundColor: '#383838'
            }
          }
        }
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            textTransform: 'none'
          }
        }
      },
      MuiButton: { 
        defaultProps: {
          disableElevation: true,
        },
        styleOverrides: {
          root: {
            fontFamily: 'RobotoCondensedBold',
            padding: '0 1.25rem',
            borderRadius: '1.563rem',
            minWidth: '9.375rem'
          },
          contained: {
            border: 0
          },
          outlined: {
            border: 'solid 0.063rem #383838'
          }
        },
        variants: [
          {
            props: { size: 'large' },
            style: { fontSize: '1.5rem', width: '14.375rem', padding: 0}
          },
          {
            props: { size: 'medium' },
            style: { fontSize: '1.25rem', width: '12.5rem', height: '2.5rem', padding: '0', textTransform: 'none' }
          },
          {
            props: { size: 'small' },
            style: { fontSize: '1.125rem', width: '8.5rem', height: '1.875rem', padding: '0', textTransform: 'none' }
          }
        ]
      },
      MuiTextField: {
        defaultProps: {
          fullWidth: true,
          InputLabelProps: {
          },
          sx: {
            borderRadius: '3.125rem',
            // paddingBottom: '0.25rem'
          }
        }
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontFamily: 'RobotoCondensed',
            color: '#d32f2f'
          }
        }
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            minWidth: '37.5rem',
            maxWidth: 'none',
            padding: '0.938rem'
          }
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            fontFamily: 'RobotoCondensedBold',
            fontSize: '1.125rem',
            textTransform: 'uppercase',
            letterSpacing: '0.093rem',
            paddingTop: '0'
          }
        }
      },
      MuiDialogContentText: {
        styleOverrides: {
          root: {
            fontFamily: 'RobotoCondensed',
            fontSize: '1rem',
            textTransform: 'none'
          }
        }
      },
      MuiSwitch: {
        defaultProps: {
          color: 'success',
        }
      },
      MuiCheckbox: {
        defaultProps: {
          color: 'success'
        }
      },
      MuiStepLabel: {
        styleOverrides: {
          label: {
            textTransform: 'none'
          }
        }
      }
    }
  },
  plPL
)

MainTheme.transitions.create('transform', {
  duration: 10,
  delay: 0
})

export default MainTheme