import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import AuthApi from '../../api/auth/AuthApi'
import { logOut } from '../../redux/storageToolkit'
import store from '../../redux/store'
import { showErrorToast } from '../../utils/error'

const authApi = AuthApi.getInstance()

export default function useLogOut() {
  const texts = useTranslation().t
  const mutation = useMutation(() => authApi.logout(), {
    onSuccess: () => {
      store.dispatch(logOut())
    },
    onError: (error) => {
      showErrorToast(error, texts)
    },
  })
  return mutation
}