import { AiOutlineCloseCircle } from 'react-icons/ai'
import { Dialog as MuiDialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton,TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { DateTimePicker } from '@mui/x-date-pickers-pro'
import { AvailabilityItemType } from '../../../../types/menu'
import useDeactivateProducts from '../../../../hooks/place/useDeactivateProducts'
import useDeactivateVariantOptions from '../../../../hooks/place/useDeactivateVariantOptions'
import useDeactivateAdditions from '../../../../hooks/place/useDeactivateAdditions'
import { LoadingButton } from '@mui/lab'
import { Form, Formik } from 'formik'
import { timedDeactivationValidation } from '../../../../validations/timedDeactivationValidation'

type InitialData = {
  date: Date | null
}

type Props = {
  open: boolean
  itemType: AvailabilityItemType
  placeUuid: string
  selectedItems: string[]
  onClose: () => void
  onSuccess: () => void
}

const TimedDeactivateItemsDialog = (props: Props) => {
  const texts = useTranslation().t
  const deactivateProductsMutation = useDeactivateProducts(() => {
    props.onSuccess()
  })
  const deactivateVariantOptionsMutation = useDeactivateVariantOptions(() => {
    props.onSuccess()
  })
  const deactivateAdditionsMutation = useDeactivateAdditions(() => {
    props.onSuccess()
  })

  const handleSubmit = (data: InitialData) => {
    switch (props.itemType) {
      case AvailabilityItemType.ADDITIONS:
        deactivateAdditionsMutation.mutate({
          placeUuid: props.placeUuid,
          templateUuids: props.selectedItems,
          deactivateUntil: data.date
        })
        break
      case AvailabilityItemType.PRODUCTS:
        deactivateProductsMutation.mutate({
          placeUuid: props.placeUuid,
          templateUuids: props.selectedItems,
          deactivateUntil: data.date
        })
        break
      case AvailabilityItemType.VARIANTS:
        deactivateVariantOptionsMutation.mutate({
          placeUuid: props.placeUuid,
          templateUuids: props.selectedItems,
          deactivateUntil: data.date
        })
        break
    }
    props.onSuccess()
    props.onClose()
  }

  return (
    <MuiDialog open={props.open} onClose={props.onClose}>
      <DialogActions>
        <IconButton onClick={props.onClose} color={'primary'}>
          <AiOutlineCloseCircle size={30}/>
        </IconButton>
      </DialogActions>
      <DialogTitle>{texts('dialogs:items_timed_deactivation_title')}</DialogTitle>
      <Formik<InitialData>
        initialValues={{
          date: null
        }}
        onSubmit={handleSubmit}
        validationSchema={timedDeactivationValidation(texts)}
      >
        {(formikProps) => (
          <Form>
            <DialogContent>
              <Grid sx={{ paddingTop: '0.3125rem'}}>
                <DateTimePicker
                  label={texts('common:dateAndTime')}
                  renderInput={({ inputProps, ...restParams }) => 
                    <TextField 
                      {...restParams} 
                      inputProps={{
                        ...inputProps,
                        placeholder: texts('common:dateTimeInputPlaceholder'),
                      }}
                      error={!!formikProps.errors.date} 
                      helperText={formikProps.errors.date}
                    />
                  }
                  value={formikProps.values.date}
                  minDateTime={new Date()}
                  onChange={(date: Date | null) => {
                    formikProps.setFieldValue('date', date)
                  }}
                />
              </Grid>
            </DialogContent>
            <DialogActions>
              <LoadingButton
                variant={'contained'}
                size={'medium'}
                type={'submit'}
                loading={
                  deactivateProductsMutation.isLoading 
                  || deactivateVariantOptionsMutation.isLoading 
                  || deactivateAdditionsMutation.isLoading
                }
                disabled={!formikProps.dirty || !formikProps.isValid}
              >
                {texts('common:save')}
              </LoadingButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </MuiDialog>
  )
}

export default TimedDeactivateItemsDialog
