export enum Codes {
  AUTH = 100,
  DATA = 200,
  INTERNAL = 999
}

export enum AuthSubcodes {
  INVALID_CREDENTIALS = 100,
  UNAUTHORIZED = 102,
  USER_INACTIVE = 103,
  ACCOUNT_REMOVED = 104,
  INVALID_TOKEN = 105,
  INCORRECT_PASSWORD = 106,
  SAME_PASSWORD_AS_PREVIOUS_ONE = 107,
  TOKEN_ALREADY_USED = 108,
  FORBIDDEN = 109,
  COOKIE_EXPIRED = 114
}

export enum DataSubcodes {
  NAME_TAKEN = 124,
  INCORRECT_FILE_FORMAT = 125,
  NON_UNIQUE_PAYU_CREDENTIALS = 144,
  BAD_REQUEST = 202,
  EMAIL_TAKEN = 203,
  VARIANT_NOT_FOUND = 204,
  TAG_NAME_TAKEN = 206,
  TAG_NOT_FOUND = 207,
  VARIANT_TEMPLATE_NOT_FOUND = 208,
  ADDITION_TEMPLATE_NOT_FOUND = 209,
  PRODUCT_TEMPLATE_NOT_FOUND = 210,
  VARIANT_OPTION_TEMPLATE_NOT_FOUND = 211 ,
  CATEGORY_NOT_FOUND = 212,
  PRODUCT_NOT_FOUND = 213,
  ADDITION_NOT_FOUND = 214,
  VARIANT_OPTION_NOT_FOUND = 215,
  DUPLICATE_PRODUCT = 219,
  DUPLICATE_VARIANT = 220,
  DUPLICATE_ADDITION = 221,
  DUPLICATE_CATEGORY = 225,
  INVALID_IMAGE_DIMENSIONS = 226,
  FILE_TOO_LARGE = 227,
  ACTIVE_ORDERS_LEFT = 229,
  LOYALTY_CARD_NOT_FOUND = 232,
  LOYALTY_CARD_CODE_TAKEN = 237
}

export enum InternalSubcodes {
  FORCED_LOGOUT = 100
}

export enum Page {
  CREATE_MENU,
  AVAILABILITYT_MANAGEMENT,
  PRODUCT_PAGE
}