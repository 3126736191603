import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import DataApi from '../../api/data/DataApi'
import { mapPlaceDataToDto } from '../../api/data/mapping'
import { Success } from '../../types/common'
import { PlaceDetailsDto, PlaceFormData } from '../../types/place'
import { showErrorToast } from '../../utils/error'
import { mapPlaceDetailsToForm } from '../../utils/mapping'
import { queryNames } from '../queries'

const dataApi = DataApi.getInstance()

type MutationParams = {
  uuid: string
  data: PlaceFormData
}

export default function useEditPlace() {
  const queryClient = useQueryClient()
  const texts = useTranslation().t
  const mutation = useMutation((params: MutationParams) => dataApi.editPlace(params.uuid, mapPlaceDataToDto(params.data)), {
    onError: (e) => {
      showErrorToast(e, texts)
    },
    onSuccess: (data: Success<PlaceDetailsDto>, variables: MutationParams) => {
      const queryData = queryClient.getQueryData<PlaceDetailsDto[]>(queryNames.placeList)
      if ((queryData?.length ?? 0) > 0) {
        const index = queryData!.findIndex(p => p.uuid === variables.uuid)
        if (index !== -1) {
          queryData![index] = data.data!
          queryClient.setQueryData(queryNames.placeList, queryData)
        }
      }
      queryClient.setQueryData([queryNames.placeDetails, variables.uuid], mapPlaceDetailsToForm(data.data!))
      toast.success(texts('successes:place_updated_successfully'))
    }
  })
  return mutation
}