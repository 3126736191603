import * as Yup from 'yup'

export function putPlaceMenuValidation(t: (val: string) => string) {
  return Yup.object({
    items: Yup.array()
      .of(
        Yup.object({
          products: Yup.array()
            .of(
              Yup.object({
                variants: Yup.array().of(
                  Yup.object({
                    options: Yup.array()
                      .min(2, t('validations:min_two_options_variant'))
                      .test(function (val: any, ctx: any) {
                        let defaultCount = 0
                        for (const obj of val) {
                          if (obj.default) {
                            defaultCount++
                          }
                        }
                        if (defaultCount === 1) {
                          return true
                        }
                        if (defaultCount === 0) {
                          return this.createError({
                            message: t('validations:zero_default_options_variant'),
                            path: ctx.path
                          })
                        } 
                        return this.createError({
                          message: t('validations:too_many_default_options_variant'),
                          path: ctx.path
                        })
                      })
                  })
                )
              })
            )
            .min(1, t('validations:min_one_product_category'))
            .required(t('validations:min_one_product_category'))
        })
      )
      .min(1, t('validations:min_one_category_menu'))
      .max(25, t('validations:max_limit_passed_category_menu'))
      .required(t('validations:min_one_category_menu'))
  })
}