export const queryNames = {
  variantList: 'variantList',
  variantDetails: 'variantDetails',
  productList: 'productList',
  productDetails: 'productDetails',
  additionList: 'additionList',
  additionDetails: 'additionDetails',
  placeDetails: 'placeDetails',
  placeMenu: 'placeMenu',
  tagList: 'tagList',
  tagDetails: 'tagDetails',
  placeList: 'placeList',
  productAvailabilityList: 'productAvailabilityList',
  variantAvailabilityList: 'variantAvailabilityList',
  additionAvailabilityList: 'additionAvailabilityList',
  placeOrderList: 'placeOrderList',
  newsletterList: 'newsletterList',
  bannerList: 'bannerList',
  accountList: 'accountList',
  checkSession: 'checkSession',
  searchUsers: 'searchUsers',
  getLoyaltyCardList: 'getLoyaltyCardList',
  getLoyaltyCardDetails: 'getLoyaltyCardDetails',
  getFileList: 'getFileList'
}