import * as Yup from 'yup'
import { emailRegexp, phoneRegExp, postalCode } from './validationRegexps'
import moment from 'moment'

export function addPlaceValidation(t: (val: string) => string) {
  return Yup.object({
    name: Yup.string()
      .trim()
      .max(120, t('validations:max_120_signs_validation'))
      .required(t('validations:field_required')),
    email: Yup.string()
      .matches(emailRegexp, t('validations:invalid_email_validation')),
    street: Yup.string()
      .max(120, t('validations:max_120_signs_validation'))
      .required(t('validations:field_required')),    
    postalCode: Yup.string()
      .matches(postalCode, t('validations:invalid_format'))
      .required(t('validations:field_required')),
    phone: Yup.string()
      .required(t('validations:field_required'))
      .matches(phoneRegExp, t('validations:invalid_format')),
    town: Yup.string()
      .max(120, t('validations:max_120_signs_validation'))
      .required(t('validations:field_required')),
    coordinates: Yup.object().shape({
      lng: Yup.number()
        .typeError(t('validations:invalid_value'))
        .required(t('validations:field_required')),
      lat: Yup.number()
        .typeError(t('validations:invalid_value'))
        .required(t('validations:field_required')),
    }),
    pickupTypes: Yup.object().shape(
      {
        takeAway: Yup.bool()
          .test('pickup_method_required', t('validations:at_least_one_required'), function (val?: boolean) {
            let onSite = this.resolve(Yup.ref('onSite')) as boolean
            let delivery = this.resolve(Yup.ref('delivery')) as boolean
            return val || onSite || delivery
          }),
        onSite: Yup.bool()
          .test('pickup_method_required', t('validations:at_least_one_required'), function (val?: boolean) {
            let takeAway = this.resolve(Yup.ref('takeAway')) as boolean
            let delivery = this.resolve(Yup.ref('delivery')) as boolean
            return val || takeAway || delivery
          }),
        delivery: Yup.bool()
          .test('pickup_method_required', t('validations:at_least_one_required'), function (val?: boolean) {
            let onSite = this.resolve(Yup.ref('onSite')) as boolean
            let takeAway = this.resolve(Yup.ref('takeAway')) as boolean
            return val || onSite || takeAway
          })
      }
    ),
    openingHoursExceptions: Yup.array().of(
      Yup.object()
        .test(function (val: any, ctx: any) {
          if (moment(val.date).startOf('day').isBefore(moment().startOf('day'))) {
            return this.createError({
              message: t('validations:date_passed'),
              path: ctx.path
            })
          }
          return true
        })  
    ),
    payuCredentials: Yup.object().shape({
      posId: Yup.string().required(t('validations:field_required')),
      clientId: Yup.string().required(t('validations:field_required')),
      secondKey: Yup.string().required(t('validations:field_required')),
      clientSecret: Yup.string().required(t('validations:field_required'))
    })
  })
}